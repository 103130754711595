import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

const CREATE = 'PAGE_CONFIG_CREATE';
const UPDATE = 'PAGE_CONFIG_UPDATE';
const ADD_PROPERTIES = 'ADD_ELEMENTS_PROPERTIES';
const HANDLE_SIDEBAR_VISIBILITY = 'HANDLE_SIDEBAR_VISIBILITY';
const REMOVE = 'PAGE_CONFIG_REMOVE';

const initialState = {
  elementsProperties: {},
  isSideBarOpen: false,
};

export default function pageConfigReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE:
      return merge({}, action.data);

    case UPDATE:
      return merge({}, cloneDeep(state), action.data);

    case ADD_PROPERTIES:
      return Object.assign({}, cloneDeep(state), {
        elementsProperties: { ...state.elementsProperties, ...action.data },
      });

    case HANDLE_SIDEBAR_VISIBILITY:
      return Object.assign({}, cloneDeep(state), {
        isSideBarOpen: !state.isSideBarOpen,
      });

    case REMOVE:
      return {};

    default:
      return state;
  }
}

export function createPageConfigAction(data) {
  return { type: CREATE, data };
}

export function updatePageConfigAction(data) {
  return { type: UPDATE, data };
}

export function removePageConfigAction() {
  return { type: REMOVE };
}

export function addElementsPropertiesAction(data) {
  return { type: ADD_PROPERTIES, data };
}

export function handleSideBarVisibilityAction() {
  return {
    type: HANDLE_SIDEBAR_VISIBILITY,
  };
}
