import React from 'react';
import styled from 'styled-components';
import { color } from '../../styles/informatech/colours/colours';
import { typography } from '../../styles/informatech/typography/typography';
import { breakpoint } from '../../styles/shared';

const Copyright = styled.div`
  text-align: right;
  background-color: ${color.backgroundInformaTechBlue};
  color: ${color.lightest};
  font-family: ${typography.type.primary};
  font-size: ${typography.size.s2}px;
  line-height: 22px;

  @media (max-width: ${breakpoint.desktopMediumBreakpoint}) {
    text-align: center;
  }
`;

const CopyrightWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  align-items: center;

  @media (max-width: ${breakpoint.desktopMediumBreakpoint}) {
    flex-direction: column;
  }

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: ${breakpoint.mobileBreakpoint}) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const CopyrightMessage = styled.div`
  width: 100%;
  max-width: 1060px;

  p {
    margin: 0;
  }
`;

export const CopyrightFooter = ({ children }) => {
  return (
    <Copyright>
      <CopyrightWrap>
        <CopyrightMessage>{children}</CopyrightMessage>
      </CopyrightWrap>
    </Copyright>
  );
};
