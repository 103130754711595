import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../helpers';
import Image, {
  ImageTagProps as ImageProps,
} from '../../../../shared/ImageTag/ImageTag';

const ImageTagWrapper = styled(Image)`
  display: block;
  max-width: 100%;
  margin: 0 auto;

  ${({ pageWidth }) => {
    if (pageWidth) {
      return `margin: 0; width: 100%;`;
    }
  }}

  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.image)}
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles?.elements?.image)}
`;

export const ImageTag = ({
  imageConfig = {},
  displayStyles,
  visualStyles,
  ...props
}) => (
  <ImageTagWrapper
    displayStyles={displayStyles}
    visualStyles={visualStyles}
    src={imageConfig.path}
    {...props}
  />
);

export const ImageTagConfigProps = PropTypes.shape({
  path: PropTypes.string,
});

ImageTag.propTypes = {
  ...ImageProps,
  imageConfig: ImageTagConfigProps,
};

export const StyledImageTagWrapper = styled.div`
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.imageTag)}
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles?.elements?.imageTag)}
`;

export const StyledImageTag = ({
  displayStyles,
  visualStyles,
  onClick,
  ...props
}) => (
  <StyledImageTagWrapper
    displayStyles={displayStyles}
    visualStyles={visualStyles}
    onClick={onClick}
  >
    <ImageTag
      displayStyles={displayStyles}
      visualStyles={visualStyles}
      {...props}
    />
  </StyledImageTagWrapper>
);

StyledImageTag.propTypes = {
  displayStyles: PropTypes.object,
  visualStyles: PropTypes.object,
  onClick: PropTypes.func,
  imageConfig: ImageTagConfigProps,
};
