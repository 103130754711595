import { HTMLAttributes, PropsWithChildren, useState } from 'react';
import cn from 'classnames';
import styles from './CardWrapper.module.scss';

interface CardWrapperProps extends HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string;
}

// backgroundColor property is not currently used, but please do not delete it for now, probably it will be used in the future
// designer decided to use one background color for all products cards. All details here in comments: CAAS-6743
export function CardWrapper(props: PropsWithChildren<CardWrapperProps>) {
  const { backgroundColor, children, className, ...restProps } = props;
  const [hovered, setHovered] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const wrapperClassName = cn(className, styles.cardWrapper, {
    'hub-product-card--hover': hovered,
  });

  return (
    <div
      {...restProps}
      onTouchStart={() => setIsTouchDevice(true)}
      // isTouchDevice - preventing onMouseEnter event from being triggered on touch devices
      onMouseEnter={() => !isTouchDevice && setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={wrapperClassName}
    >
      {children}
    </div>
  );
}
