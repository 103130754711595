import styled, { css } from 'styled-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';

import { renderStyledElementStyles } from '../../../helpers';

export const StyledAccordionWrapper = styled.div`
  margin-top: 30px;
  border-top: 2px solid rgba(255, 255, 255, 0.1);

  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.accordion)}
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles?.elements?.accordion)}
`;

export const StyledAccessibleAccordion = styled(Accordion)`
  .accordion-link {
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 2px solid ${({ theme }) => theme.brandColors.accent};
    padding-bottom: 3px;
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module?.visualStyles?.elements?.accordionLink,
      )}
  }
`;

export const StyledAccessibleAccordionItem = styled(AccordionItem)`
  position: relative;
  height: 100%;
  transition: border-color 200ms ease;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.visualStyles.elements?.accordionPanel,
    )}
  &:last-child {
    border-bottom: none;
  }
`;

export const StyledAccessibleAccordionItemHeading = styled(
  AccordionItemHeading,
)`
  position: relative;
  cursor: pointer;
  font-size: 28px;
  font-weight: 300;
  line-height: 34px;
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.visualStyles.elements?.accordionHeading,
    )}

  & .accordion-expand-icon {
    position: absolute;
    top: 30px;
    width: 30px;
    height: 30px;
    font-size: 40px;
    line-height: 30px;
    ${({ theme }) =>
      theme.globalVariables.isRTL
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.visualStyles.elements?.accordionExpandIcon,
      )}
  }
`;

export const StyledAccessibleAccordionItemPanel = styled(AccordionItemPanel)`
  margin-bottom: 30px;
  overflow: hidden;

  p {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.visualStyles.elements?.accordionItem,
      )}
  }
`;

export const StyledAccessibleAccordionItemButton = styled(AccordionItemButton)`
  padding: ${({ theme }) =>
    theme.globalVariables.isRTL ? '30px 0 30px 30px;' : '30px 30px 30px 0'};
`;
