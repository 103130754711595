import qs from 'qs';

export const filterNamesByContentType = {
  CONTENT_TYPE: 'contentType',
  DATES: 'startDateMillis',
  PUBLISHED_DATES: 'datePublishedMillis',
  LOCATIONS: 'cityCountry',
  TOPICS: 'topics',
  COURSE_TYPES: 'courseType',
};

export function determineFilterApplying(filterParams, filterName) {
  const currentFilterParam = filterParams[filterName];

  if (filterName === 'contentType' && currentFilterParam?.all) {
    // The All value in the contentType filter is equal to not selected filter.
    return false;
  }

  if (Array.isArray(currentFilterParam)) {
    return !!currentFilterParam.length;
  } else {
    return Object.keys(filterParams).indexOf(filterName) !== -1;
  }
}

export function extractFilterValue(filters = [], contentType) {
  let filterValue = {};
  filters.forEach((filter) => {
    if (filter.type === contentType) {
      filterValue = filter.filterValue;
    }
  });
  return filterValue;
}

export function modifyQueryParamsFromRoute(queryParamsFromRoute) {
  // modifying query params by adding special contentType param and deleting particular contentType name
  let queryParams = qs.parse(queryParamsFromRoute.slice(1));
  const queryParamsKeys = Object.keys(queryParams);

  ['all', 'type', 'content', 'mediaSite', 'page'].forEach((contentType) => {
    if (queryParamsKeys.includes(contentType)) {
      queryParams = {
        ...queryParams,
        contentType: { [contentType]: queryParams[contentType] },
      };
      delete queryParams[contentType];
    }
  });

  return queryParams;
}

export function deleteContentTypeFromParams(paramsObject) {
  let modifyPrams = paramsObject;
  modifyPrams = { ...modifyPrams, ...modifyPrams.contentType };
  modifyPrams.contentType && delete modifyPrams.contentType;

  return modifyPrams;
}
