import { PropsWithChildren } from 'react';
import cn from 'classnames';
import s from './AnimatedCallToAction.module.scss';

export interface AnimatedCallToActionProps {
  icon: string;
}

const defaultProps = {
  icon: 'east',
};

export default function AnimatedCallToAction({
  icon,
  children,
}: PropsWithChildren<AnimatedCallToActionProps>) {
  return (
    <div className={s.wrapper}>
      <span className={s.label}>{children}</span>
      <span className={s.arrowIcon}>
        <span className={cn(s.icon, 'material-icons')}>{icon}</span>
      </span>
    </div>
  );
}

AnimatedCallToAction.defaultProps = defaultProps;
