import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gtm from '../../../utils/initialGTM';

import LeadGenTeaserPortrait from './LeadGenTeaserPortrait/LeadGenTeaserPortrait';
import LeadGenTeaserLandscape from './LeadGenTeaserLandscape/LeadGenTeaserLandscape';
import LeadGenTeaserArticle from './LeadGenTeaserArticle/LeadGenTeaserArticle';

import './LeadGenTeaser.scss';

export default class LeadGenTeaser extends Component {
  static propTypes = {
    agendaDownloadButton: PropTypes.func,
    teaserData: PropTypes.object,
    leadGenType: PropTypes.string,
  };

  componentDidMount() {
    const { isGateId, contentType } = this.props;
    if (contentType) {
      gtm.pushContentType(contentType);
    }
    gtm.isContentGated(!!isGateId);
  }

  getIconID() {
    const { contentType } = this.props;

    let icon;
    switch (contentType) {
      case 'VIDEO':
        icon = 'play_arrow';
        break;
      case 'AUDIO':
        icon = 'volume_up';
        break;
      case 'AGENDA_BROCHURE':
      case 'SPEX_BROCHURE':
      case 'WHITEPAPER':
        icon = 'get_app';
        break;
      default:
        icon = 'get_app';
    }

    return icon;
  }

  getContent() {
    const {
      agendaDownloadButton,
      teaserData = {},
      leadGenType,
      brand = {},
    } = this.props;

    switch (leadGenType) {
      case 'article': {
        return (
          <LeadGenTeaserArticle
            {...teaserData}
            data-test-lead-gen="lead-gen-teaser-article"
          />
        );
      }

      case 'agenda':
      case 'landing-page': {
        return teaserData.thumbStyle === 'PORTRAIT' ? (
          <LeadGenTeaserPortrait
            {...teaserData}
            agendaDownloadButton={agendaDownloadButton}
            data-test-lead-gen="lead-gen-teaser-portrait"
            iconID={this.getIconID()}
            brand={brand}
            leadGenType={leadGenType}
          />
        ) : teaserData.thumbStyle === 'LANDSCAPE' ? (
          <>
            <LeadGenTeaserLandscape
              {...teaserData}
              agendaDownloadButton={agendaDownloadButton}
              data-test-lead-gen="lead-gen-teaser-landscape"
              iconID={this.getIconID()}
              brand={brand}
              leadGenType={leadGenType}
            />
          </>
        ) : (
          <LeadGenTeaserPortrait
            {...teaserData}
            agendaDownloadButton={agendaDownloadButton}
            withoutImage
            data-test-lead-gen="lead-gen-teaser-portrait-without-img"
            iconID={this.getIconID()}
            brand={brand}
            leadGenType={leadGenType}
          />
        );
      }

      default: {
        return null;
      }
    }
  }

  render() {
    const { leadGenType } = this.props;

    return (
      <div
        className={`lead-gen-teaser ${
          leadGenType === 'article' ? 'lead-gen-teaser--article' : ''
        }`}
        data-test-lead-gen="lead-gen-teaser"
      >
        {this.getContent()}
      </div>
    );
  }
}
