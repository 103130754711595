import { createGenericAsyncThunk } from '../../../../../store/features/generic';
import { selectPageConfig } from '../../../../../store/features/pageConfig';
import {
  PricingDelegateWithOptions,
  SitePricingDelegateParams,
  UpdateSitePricingDelegateParams,
} from './types';
import qs from 'qs';

export const fetchSitePricingDelegate = createGenericAsyncThunk<
  PricingDelegateWithOptions,
  SitePricingDelegateParams
>(
  'sitePricingDelegate/fetchSitePricingDelegate',
  async (params, { extra, rejectWithValue, getState }) => {
    const { basketId, packageId, delegateId, country } = params;
    const { siteId } = selectPageConfig(getState());
    const query = qs.stringify(
      {
        selectedCountry: country,
      },
      {
        addQueryPrefix: true,
      },
    );

    const response = await extra.ajax.get(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/packages/${packageId}/delegates-options/${delegateId}${query}`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: PricingDelegateWithOptions = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const updateSitePricingDelegate = createGenericAsyncThunk<
  PricingDelegateWithOptions,
  UpdateSitePricingDelegateParams
>(
  'sitePricingDelegate/updateSitePricingDelegate',
  async (params, { extra, rejectWithValue, getState }) => {
    const { basketId, packageId, delegateId, country, values } = params;
    const { siteId } = selectPageConfig(getState());
    const query = qs.stringify(
      {
        selectedCountry: country,
      },
      {
        addQueryPrefix: true,
      },
    );

    const response = await extra.ajax.put(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/packages/${packageId}/delegates-options/${delegateId}${query}`,
      values,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: PricingDelegateWithOptions = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);
