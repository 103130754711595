import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { siteTypesEnum } from '../../../helpers';
import './SitePartners.scss';

export function SitePartners(props) {
  const { COURSE } = siteTypesEnum();
  const { t } = useTranslation();
  const {
    pageConfig: { siteType, leadGenType } = {},
    sitePartners: { data: { allOrganisationsNames = [] } = {} } = {},
    uppercase,
  } = props;
  let titleId = uppercase
    ? 'event.event-partners-header'
    : 'event.third-party-header';
  let bodyId =
    siteType === COURSE ? 'course.third-party-list' : 'event.third-party-list';

  if (siteType === COURSE) {
    titleId = uppercase
      ? 'course.event-partners-header'
      : 'course.third-party-header';
  }

  if (leadGenType === 'landing-page') {
    titleId = 'leadGen.third-party-header.content-partners';
    bodyId = 'leadGen.third-party-list';
  }

  return (
    <div className="event-partners">
      <h2 className="event-partners__heading">{t(titleId)}</h2>
      <p className="event-partners__body">
        <Trans i18nKey={t(bodyId)} />
        <Trans i18nKey="event.database-manager-contact" />
        <a href="mailto:integrity@informa.com" className="event-partners__link">
          integrity@informa.com
        </a>
      </p>
      <ul className="event-partners__list">
        {allOrganisationsNames.map((partner, i) => (
          <li key={i} className="event-partners__organisation-name">
            {partner}
          </li>
        ))}
      </ul>
    </div>
  );
}

SitePartners.propTypes = {
  pageConfig: PropTypes.object,
  sitePartners: PropTypes.shape({
    data: PropTypes.shape({
      allOrganisationsNames: PropTypes.array,
    }),
  }),
  uppercase: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
    sitePartners: state.sitePartners,
  };
}

export default connect(mapStateToProps)(SitePartners);
