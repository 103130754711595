import React, { useContext, PropsWithChildren } from 'react';
import { ThemeContext } from 'styled-components';

import { StyledBackgroundImage, StyledImageTag } from '../index';
import playIcon from '../../../../../assets/images/play-button.png';
import { Wrapper, Content } from './index.style';
import { PropsWithTestId } from '../../../../../types';

export interface ThumbnailProps {
  path: string;
  imgix: boolean;
  onClick?: () => void;
}

export const Thumbnail = ({
  path,
  imgix,
  onClick,
  children,
  testId,
}: PropsWithChildren<PropsWithTestId<ThumbnailProps>>) => {
  const themeConfig = useContext(ThemeContext);
  const { videoPosition, dStyles } = themeConfig.module || {};
  const displayStyles = dStyles.elements.thumbnail;
  const bgImageStyles = videoPosition
    ? dStyles[videoPosition]?.elements?.bgImage
    : {};

  return (
    <Wrapper>
      <StyledBackgroundImage
        path={path}
        imgix={imgix}
        dStyles={bgImageStyles}
        testId={testId}
      >
        <StyledImageTag
          imageConfig={{ path: playIcon }}
          imgix={false}
          onClick={onClick}
          displayStyles={displayStyles}
          testId="video-play-icon"
          visualStyles={{}}
        />
        <Content>{children}</Content>
      </StyledBackgroundImage>
    </Wrapper>
  );
};
