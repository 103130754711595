import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import MultiLevelNavBtn from '../MultiLevelNav/MultiLevelNavBtn/MultiLevelNavBtn';
import MultiLevelNavHomeBtn from '../MultiLevelNav/MultiLevelNavHomeBtn/MultiLevelNavHomeBtn';
import ChevronIcon from '../ChevronIcon/ChevronIcon';
import './NavAccordion.scss';

export default function NavAccordion({ withHomeNav, navBarItems = [] }) {
  const accordionParams = {
    allowMultipleExpanded: true,
    allowZeroExpanded: true,
  };

  return navBarItems.length === 0 ? null : (
    <Accordion className="c-nav-accordion" {...accordionParams}>
      {withHomeNav && (
        <AccordionItem className="c-nav-accordion__item">
          <AccordionItemHeading className="c-nav-accordion__heading">
            <AccordionItemButton className="c-nav-accordion__button">
              <MultiLevelNavHomeBtn />
            </AccordionItemButton>
          </AccordionItemHeading>
        </AccordionItem>
      )}
      {navBarItems.map((navBarItem, index) => (
        <AccordionItem key={index} className="c-nav-accordion__item">
          <AccordionItemHeading className="c-nav-accordion__heading">
            <AccordionItemButton className="c-nav-accordion__button">
              {navBarItem.groupItems && navBarItem.groupItems.length > 0 && (
                <>
                  <span className="c-nav-accordion__expand-chevron">
                    <ChevronIcon direction="bottom" />
                  </span>
                  <span className="c-nav-accordion__collapse-chevron">
                    <ChevronIcon direction="top" />
                  </span>
                </>
              )}
              <MultiLevelNavBtn navBarItem={navBarItem} />
              {navBarItem.groupItems && navBarItem.groupItems.length > 0 && (
                <>
                  <span className="c-nav-accordion__expand-icon">
                    <i className="material-icons">expand_more</i>
                  </span>
                  <span className="c-nav-accordion__collapse-icon">
                    <i className="material-icons">expand_less</i>
                  </span>
                </>
              )}
            </AccordionItemButton>
          </AccordionItemHeading>
          {navBarItem.groupItems && navBarItem.groupItems.length > 0 && (
            <AccordionItemPanel className="c-nav-accordion__panel">
              {navBarItem.groupItems.map((navBarItem, index) => (
                <MultiLevelNavBtn key={index} navBarItem={navBarItem} />
              ))}
            </AccordionItemPanel>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
}

NavAccordion.propTypes = {
  withHomeNav: PropTypes.bool,
  navBarItems: PropTypes.array.isRequired,
};
