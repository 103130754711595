import { DiscoveryPartner } from '../../../../../store/features/discovery';

export function getSitePartnersLogos<T extends DiscoveryPartner[]>(
  partners: T,
  listLength = 2,
) {
  return partners
    .slice(0, listLength)
    .map(({ logo: { path } = {} }) => path)
    .filter(Boolean) as string[];
}
