import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { HubModuleWrapper } from '../HubModuleWrapper';
import {
  NoProductsFound,
  ProductCountInfo,
  ProductFilter,
  ProductFilterProvider,
  ProductModule,
  ProductModuleHeading,
  ProductsPreviewGrid,
} from '../../productFilter/products';
import { FilterFacets, SortProductsFilter } from '../../productFilter/filters';
import { KeywordSearch } from '../components';
import {
  ResetFilterButton,
  ToggleFilterButton,
} from '../../productFilter/filters';
import { TextWithCount } from '../../productFilter/products/ShowMoreProductsButton/TextWithCount';
import { ContentTypeBuilder } from './ContentTypeBuilder';

import { DiscoveryContentTypes } from '../../../../../constants';
import { useProductPageFilter } from '../../productFilter/hooks';
import { getDiscoveryContentByType } from '../../helpers';
import {
  FetchHubPageDiscoveryParams,
  Discovery,
  HubDiscoveryValues,
  FacetsConfigData,
} from '../../../../../store/features/discovery';

import styles from './AggregatedProductSearchPage.module.scss';

type ContentDataMapType = {
  [key in DiscoveryContentTypes]: {
    heading: string;
    buttonText: string;
  };
};

export const contentTypesDataMap = {
  [DiscoveryContentTypes.ALL]: {
    heading: 'All Products',
  },
  [DiscoveryContentTypes.EVENT]: {
    heading: 'Events',
    buttonText: 'View All Event Results',
  },
  [DiscoveryContentTypes.COURSE]: {
    heading: 'Courses',
    buttonText: 'View All Course Results',
  },
  [DiscoveryContentTypes.ARTICLE]: {
    heading: 'News & Insights',
    buttonText: 'View All News & Insight Results',
  },
  [DiscoveryContentTypes.STREAMLY_VIDEO]: {
    heading: 'Videos',
    buttonText: 'View All Video Results',
  },
  [DiscoveryContentTypes.AUDIENCE_HOME]: {
    heading: 'Topic Homes',
    buttonText: 'View All Topic Home Results',
  },
  [DiscoveryContentTypes.SPEAKER]: {
    heading: 'Speakers',
    buttonText: 'View All Speaker Results',
  },
  [DiscoveryContentTypes.AGENDA_SESSION]: {
    heading: 'Agenda Sessions',
    buttonText: 'View All Agenda Results',
  },
  [DiscoveryContentTypes.EXTERNAL_SITE]: {
    heading: 'External Site',
    buttonText: 'View All External Site Results',
  },
} as ContentDataMapType;

export interface AggregatedProductSearchPageProps {
  facets: Discovery['facets'];
  records: Discovery['records'];
  title: string;
  subtitle: string;
  pageName: FetchHubPageDiscoveryParams['pageName'];
  defaultValues: HubDiscoveryValues;
  searchQueryData: FacetsConfigData;
}

export function AggregatedProductSearchPage(
  props: AggregatedProductSearchPageProps,
) {
  const {
    facets,
    records,
    title,
    subtitle,
    pageName,
    defaultValues,
    searchQueryData,
  } = props;
  const { search } = useLocation();
  const initialFilterValues = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  const previewCountToShow = 6;

  const form = useForm<HubDiscoveryValues>({
    defaultValues: {
      ...defaultValues,
      ...initialFilterValues,
    },
  });

  const types = form.watch('type') || [];
  const contentType = types[0] || DiscoveryContentTypes.ALL;
  const showPreviewMode = contentType === DiscoveryContentTypes.ALL;

  const allProducts = records.filter((record) => record.results.length > 0);
  const allProductsCount = allProducts.reduce(
    (totalCount, currentCount) => totalCount + currentCount.count,
    0,
  );
  const { count: productsCount } = getDiscoveryContentByType(
    contentType,
    records,
  );

  const {
    loading,
    changeFilterHandler,
    resetFilterHandler,
    resetTextFilterHandler,
    changeContentTypeFilterHandler,
    loadMoreFilterResultsHandler,
    keywordSearchProps,
    defaultFiltersApplied,
  } = useProductPageFilter({
    pageName,
    contentType,
    form,
    initialValue: initialFilterValues.searchInput?.toString(),
    defaultValues,
    searchQueryData,
  });

  const changeContentTypeHandler = (type: DiscoveryContentTypes) => {
    changeContentTypeFilterHandler([type]);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const getPreviewSearchContent = () => {
    return allProducts.map((productGroup) => {
      const { type, count } = productGroup;
      const isViewAll = count > previewCountToShow;

      return (
        <ProductsPreviewGrid
          heading={contentTypesDataMap[type]?.heading}
          subHeading={
            <>
              <ProductCountInfo
                count={isViewAll ? previewCountToShow : count}
                totalCount={count}
                textBefore="Showing"
                className={styles.count}
              />
              {isViewAll && (
                <ResetFilterButton
                  size="medium"
                  onClick={() => changeContentTypeHandler(type)}
                  className={styles.resetButton}
                >
                  View all
                </ResetFilterButton>
              )}
            </>
          }
          button={
            isViewAll && (
              <ToggleFilterButton
                onClick={() => changeContentTypeHandler(type)}
                theme="rounded"
              >
                <TextWithCount count={count}>
                  {contentTypesDataMap[type]?.buttonText}
                </TextWithCount>
              </ToggleFilterButton>
            )
          }
          key={type}
        >
          <ContentTypeBuilder
            contentType={type}
            viewMode="preview"
            previewCount={previewCountToShow}
          />
        </ProductsPreviewGrid>
      );
    });
  };

  return (
    <ProductFilterProvider form={form} onChangeFilter={changeFilterHandler}>
      <HubModuleWrapper
        type="search"
        elementId=""
        theme="LIGHT"
        className={styles.removeTopPaddingOnMobile}
      >
        <ProductModule
          heading={<ProductModuleHeading heading={title} text={subtitle} />}
          leftFilter={
            <ProductFilter
              heading="Filter"
              onResetFilter={resetFilterHandler}
              disableResetFilter={defaultFiltersApplied}
            >
              {facets && <FilterFacets facets={facets} />}
            </ProductFilter>
          }
          keywordSearch={
            <KeywordSearch
              {...keywordSearchProps}
              onResetValue={resetTextFilterHandler}
              placeholder="Filter by keyword"
              theme="bordered"
              form={form}
              enableSuggestions
            />
          }
          keywordSearchLeftToolbar={
            <div className={styles.leftToolbarWrapper}>
              <ProductCountInfo
                count={showPreviewMode ? allProductsCount : productsCount}
                productName={contentTypesDataMap[contentType]?.heading}
              />
              <ResetFilterButton
                onClick={resetFilterHandler}
                iconName="clear"
                disabled={defaultFiltersApplied}
                className={styles.resetAllButton}
              >
                Clear All Filters
              </ResetFilterButton>
            </div>
          }
          keywordSearchRightToolbar={
            showPreviewMode ? null : (
              <SortProductsFilter contentType={contentType} />
            )
          }
          loading={loading}
        >
          {allProducts.length === 0 ? (
            <NoProductsFound />
          ) : showPreviewMode ? (
            getPreviewSearchContent()
          ) : (
            <ContentTypeBuilder
              contentType={contentType}
              viewMode="list"
              loadMoreFilterResults={loadMoreFilterResultsHandler}
            />
          )}
        </ProductModule>
      </HubModuleWrapper>
    </ProductFilterProvider>
  );
}
