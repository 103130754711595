import React from 'react';
import PropTypes from 'prop-types';

import LeadGenConsentFirstStatements from './LeadGenConsentFirstStatements/LeadGenConsentFirstStatements';
import LeadGenConsentStatements from './LeadGenConsentStatements/LeadGenConsentStatements';

export default function LeadGenConsent(props) {
  const {
    consentForms = [],
    color,
    leadGenType,
    tenantName,
    fetchSitePartners,
  } = props;

  React.useEffect(() => {
    fetchSitePartners();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ marginTop: '30px' }} className="c-lead-gen-consent-section">
      {consentForms.map((consentData = {}) => {
        return (
          <LeadGenConsentFirstStatements
            key={consentData.id}
            consentData={consentData}
            color={color}
          />
        );
      })}
      <LeadGenConsentStatements
        tenantName={tenantName}
        leadGenType={leadGenType}
        color={color}
      />
    </div>
  );
}

LeadGenConsent.propTypes = {
  consentForms: PropTypes.array,
  color: PropTypes.string,
  leadGenType: PropTypes.string,
  tenantName: PropTypes.string,
  fetchSitePartners: PropTypes.func,
};
