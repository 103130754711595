import React from 'react';
import { useSelector } from 'react-redux';

import { VENUE_EVENT, VENUE_COURSE, VENUE_FANEXPO } from '../../constants';

import EventVenue from './EventVenue';
import CourseVenue from './CourseVenue';
import FanExpoVenue from './FanExpoVenue';

export function Venue() {
  const venueType = useSelector(
    (state) => state.siteHeader?.data?.venueInformationType,
  );

  switch (venueType) {
    case VENUE_EVENT:
      return <EventVenue />;

    case VENUE_COURSE:
      return <CourseVenue />;

    case VENUE_FANEXPO:
      return <FanExpoVenue />;

    default:
      return null;
  }
}
