import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';

export function useShowBackgroundVideo(videoId?: string) {
  const [shown, setShown] = useState<boolean>(false);

  useEffect(() => {
    setShown(Boolean(videoId && !isMobileOnly));
  }, [videoId]);

  return shown;
}
