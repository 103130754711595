import React from 'react';
import PropTypes from 'prop-types';

import {
  SITE_LOGO,
  MAIN_LOGO,
  PARTNER_LOGO,
  COUNTDOWN_TIMER,
  DATE_VENUE,
  SITE_LOGO_WITH_PARTNER_LOGO,
  PARTNER_LOGO_WITH_COUNTDOWN_TIMER,
} from '../../constants';

import { Logo, PartnerLogo, Timer, Venue, BuilderWrapper } from '../index';

export function HeaderBuilder({ type }) {
  switch (type) {
    case SITE_LOGO:
    case MAIN_LOGO:
      return <Logo />;

    case PARTNER_LOGO:
      return <PartnerLogo />;

    case COUNTDOWN_TIMER:
      return <Timer />;

    case DATE_VENUE:
      return <Venue />;

    case SITE_LOGO_WITH_PARTNER_LOGO:
      return (
        <BuilderWrapper>
          <Logo />
          <PartnerLogo />
        </BuilderWrapper>
      );

    case PARTNER_LOGO_WITH_COUNTDOWN_TIMER:
      return (
        <BuilderWrapper>
          <Logo />
          <Timer />
        </BuilderWrapper>
      );

    default:
      return null;
  }
}

HeaderBuilder.propTypes = {
  type: PropTypes.oneOf([
    SITE_LOGO,
    MAIN_LOGO,
    PARTNER_LOGO,
    DATE_VENUE,
    SITE_LOGO_WITH_PARTNER_LOGO,
    PARTNER_LOGO_WITH_COUNTDOWN_TIMER,
    COUNTDOWN_TIMER,
    'NONE',
  ]),
};
