import React from 'react';
import classNames from 'classnames';
import './LeadGenTeaserBadge.scss';

export function LeadGenTeaserBadge(props) {
  const { brand: { accentColor } = {}, iconID, isRound } = props;

  if (!iconID) {
    return null;
  }

  const leadGenTeaserBadgeContainer = classNames(
    'lead-gen-teaser-badge-container',
    { 'lead-gen-teaser-badge-container--round': isRound },
  );

  return (
    <div
      className={leadGenTeaserBadgeContainer}
      style={{ background: accentColor }}
    >
      <i className="material-icons">{iconID}</i>
    </div>
  );
}
