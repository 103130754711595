import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';

import { renderStyledElementStyles, devices } from '../../../../helpers';

import {
  CallToAction,
  ImageTag,
} from '../../../../siteBuilderModules/sharedComponents';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  height: 100%;
  @media only screen and (min-width: ${devices.breakpoints.tablet}) {
    justify-content: flex-start;
  }

  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.siteLayout.siteHeader.builder?.elements?.logo,
    );
  }}

  h5 {
    @media only screen and (max-width: ${devices.breakpoints.tablet}) {
      margin-bottom: 20px;
    }

    ${({ theme }) => {
      return renderStyledElementStyles(
        theme,
        theme.siteLayout.siteHeader.builder?.elements?.logo?.elements?.title,
      );
    }}
  }
`;

const ImageWrapper = styled.div`
  img {
    display: block;
    height: auto;
    max-width: 100%;
    max-height: ${({ maxHeight }) => maxHeight};

    ${({ theme }) => {
      return renderStyledElementStyles(
        theme,
        theme.siteLayout.siteHeader.builder?.elements?.logo?.elements?.image,
      );
    }}
  }
`;

const CTAWrapper = styled.div`
  a {
    ${({ theme }) => {
      return renderStyledElementStyles(
        theme,
        theme.siteLayout.siteHeader.builder?.elements?.logo?.elements?.cta,
      );
    }}
  }
`;

export function Logo() {
  const {
    data: {
      logo,
      logoSize = 1,
      title: pageTitle,
      paymentHub: isPaymentHub,
    } = {},
  } = useSelector((state) => state.siteHeader);
  const { data: { overrideLogo, pageLogo } = {} } = useSelector(
    (state) => state.siteContent,
  );
  const themeConfig = useContext(ThemeContext);
  const { imgixParams, maxHeight } =
    themeConfig.siteLayout?.siteHeader?.builder?.settings?.logoSize[logoSize];

  const siteLogo = overrideLogo && pageLogo ? pageLogo : logo;

  const logoComponent = siteLogo?.path ? (
    <ImageWrapper maxHeight={maxHeight}>
      <ImageTag
        src={siteLogo.path}
        lazy={false}
        alt={pageTitle}
        imgixParams={imgixParams}
      />
    </ImageWrapper>
  ) : (
    pageTitle
  );

  return (
    <Wrapper data-testid="header-logo">
      <h5>
        {!isPaymentHub ? (
          <CTAWrapper data-testid="header-logo-cta-wrapper">
            <CallToAction type="link" sitePath>
              {logoComponent}
            </CallToAction>
          </CTAWrapper>
        ) : (
          logoComponent
        )}
      </h5>
    </Wrapper>
  );
}
