import { ReactNode, SelectHTMLAttributes } from 'react';
import {
  RegisterOptions,
  useController,
  useFormContext,
} from 'react-hook-form';
import cn from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import s from './SelectField.module.scss';

export type SelectOption = {
  value: string;
  label: string;
};

export interface SelectFieldProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  options: SelectOption[];
  label: ReactNode;
  blankOption?: ReactNode;
  validation?: RegisterOptions;
}

export default function SelectField(props: SelectFieldProps) {
  const {
    name,
    options,
    label,
    blankOption,
    validation,
    disabled,
    ...inputProps
  } = props;

  const { control } = useFormContext();
  const {
    field,
    fieldState: { invalid },
    formState: { errors },
  } = useController({
    control,
    name,
    rules: validation,
  });

  return (
    <label
      className={cn(s.wrapper, {
        [s.error]: invalid,
      })}
    >
      <div className={s.labelWrapper}>
        <div className={s.label}>{label}</div>
        {invalid && (
          <div className={s.helperText}>
            <ErrorMessage name={name} errors={errors} />
          </div>
        )}
      </div>
      <select {...inputProps} {...field} disabled={disabled}>
        {blankOption}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
}
