import { ArticleHomeRelatedEvent } from '../../../../../../../../store/features/articles/home';
import { useRootSelector } from '../../../../../../../../store/hooks';
import { selectPageConfig } from '../../../../../../../../store/features/pageConfig';
import { createCanonicalUrl } from '../../../../../../../../helpers';
import {
  CardAnchor,
  Image,
  Title,
  Badge,
  CardButton,
  Date,
  TextListSpacer,
} from '../../../../../../shared/productFilter/cards/components';
import { ProductListCard } from '../../../../../../shared/productFilter/products';
import {
  getSiteAttendanceLabel,
  getSiteCardDeliveryTypes,
} from '../../../../../../shared/helpers';
import { DiscoveryContentTypes } from '../../../../../../../../constants';

export interface RelatedEventCardProps {
  event: ArticleHomeRelatedEvent;
}

export function RelatedEventCard(props: RelatedEventCardProps) {
  const {
    event: {
      details: {
        name: title,
        path,
        date: { startDate, endDate, formattedDate },
        address: { name: addressName, city, address, searchCity, country },
      },
      brand: {
        colours: { secondary },
      },
      backgroundImage: { path: imagePath = '' } = {},
      timeAsText,
      deliveryType,
    },
  } = props;
  const deliveryTypes = [deliveryType];
  const backgroundColor = secondary && `#${secondary}`;
  const { parentDomain } = useRootSelector(selectPageConfig);
  const date = {
    start: startDate,
    end: endDate,
    formatted: formattedDate,
  };
  const location = {
    name: addressName,
    city,
    address,
    country,
    searchCity,
  };
  const buttonLabel = getSiteAttendanceLabel(
    deliveryTypes,
    DiscoveryContentTypes.EVENT,
    endDate,
  );
  const deliveryTypesList = getSiteCardDeliveryTypes(deliveryTypes, [location]);

  return (
    <CardAnchor to="/" pathPrefix={path}>
      <ProductListCard
        image={<Image imageStyle="list" path={imagePath} />}
        title={<Title size="large">{title}</Title>}
        date={
          <Date
            dates={[date]}
            deliveryTypes={deliveryTypes}
            timeAsText={timeAsText}
            dateSize="large"
          />
        }
        deliveryTypes={
          deliveryTypesList.length > 0 && (
            <Badge size="large">
              <TextListSpacer list={deliveryTypesList} />
            </Badge>
          )
        }
        showMoreBadge={
          buttonLabel && <CardButton size="large">{buttonLabel}</CardButton>
        }
        backgroundColor={backgroundColor}
      />
      <div itemScope itemType="https://schema.org/Event">
        <meta itemProp="name" content={title} />
        <meta itemProp="startDate" content={startDate} />
        <meta itemProp="endDate" content={endDate} />
        <meta
          itemProp="url"
          content={createCanonicalUrl(path, null, parentDomain)}
        />
        <div itemProp="location" itemScope itemType="https://schema.org/Place">
          <meta
            itemProp="name"
            content={[addressName, city].filter(Boolean).join(', ')}
          />
          <meta itemProp="address" content={address} />
        </div>
      </div>
    </CardAnchor>
  );
}
