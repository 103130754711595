import { HubOptions } from '../../../../../types';
import { SubscriptionType } from '../../../brandHub/store/features/subscribe';
import get from 'lodash/get';

export type UseBuildEmailOptInDataReturn = {
  title: string;
  body: string;
  cookieName: string;
  productCode: string;
  subscriptionType: SubscriptionType;
};

export const useBuildEmailOptInData = (
  hubOption: HubOptions,
  pageType: string,
): UseBuildEmailOptInDataReturn => {
  const {
    path,
    brandUrlSuffix,
    productCode,
    newsletterSignUp,
    promotionalSignUp,
  } = hubOption;
  const uniqueSegmentForCookieName = brandUrlSuffix || path;

  if (get(newsletterSignUp, `${pageType}Enabled`)) {
    return {
      title: newsletterSignUp.title,
      body: newsletterSignUp.body,
      cookieName: `newsletter_subscription_${uniqueSegmentForCookieName}`,
      subscriptionType: SubscriptionType.newsletter,
      productCode,
    };
  }

  if (get(promotionalSignUp, `${pageType}Enabled`)) {
    return {
      title: promotionalSignUp.title,
      body: promotionalSignUp.body,
      cookieName: `promotional_subscription_${uniqueSegmentForCookieName}`,
      subscriptionType: SubscriptionType['email/audience'],
      productCode,
    };
  }

  return {
    title: '',
    body: '',
    cookieName: '',
    subscriptionType: SubscriptionType.newsletter,
    productCode: '',
  };
};
