import React from 'react';
import { Helmet } from 'react-helmet';
import { useRootSelector } from '../../../../store/hooks';
import { selectLocationConfig } from '../../../../store/features/locationConfig';

export interface MetaTagsProps {
  title: string;
  titleSuffix?: string;
  description?: string;
  thumbnail?: string;
  robots?: string;
}

export function MetaTags(props: MetaTagsProps) {
  const { title, titleSuffix, description, thumbnail, robots } = props;
  const { origin, pathname } = useRootSelector(selectLocationConfig);

  const url = [origin, pathname].join('');
  const logo = [origin, thumbnail].filter(Boolean).join('');
  const heading = [title, titleSuffix].filter(Boolean).join(' | ');

  return (
    <Helmet>
      <title>{heading}</title>
      <meta name="description" content={description} />
      {logo && <meta name="thumbnail" content={logo} />}
      {robots && <meta name="robots" content={robots} />}
      <meta property="og:title" content={heading} />
      <meta property="og:description" content={description} />
      {logo && <meta property="og:image" content={logo} />}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <link rel="canonical" href={url} />
    </Helmet>
  );
}
