import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../../../types';
import {
  DeliveryType,
  DiscoveryContentTypes,
  Languages,
} from '../../../../../../constants';
import { getLanguageName } from '../../../../../../helpers/getLanguageName';
import { getDeliveryTypeName } from '../../../../../../helpers/getDeliveryTypeName';
import { contentTypesDataMap } from '../../../siteBuilder/AggregatedProductSearchPage';
import styles from './Input.module.scss';

export * from './Radio';
export * from './Checkbox';

export function getFilterLabel(label: string) {
  if (label in DeliveryType) {
    return getDeliveryTypeName(label);
  }

  if (label in Languages) {
    return getLanguageName(label);
  }

  if (label.toLowerCase() === DiscoveryContentTypes.ALL) {
    return contentTypesDataMap[DiscoveryContentTypes.ALL].heading;
  }

  return label;
}

export interface InputProps {
  label: string;
  count?: number;
  disabled?: boolean;
}

export function Input(props: PropsWithTestId<PropsWithChildren<InputProps>>) {
  const { label, count, disabled, children, testId } = props;

  const wrapperClassName = cn(styles.wrapper, {
    [styles.disabled]: disabled,
  });

  return (
    <label className={wrapperClassName} data-testid={testId}>
      <span className={styles.inputWrapper}>
        <span className={styles.input}>{children}</span>
        <span className={styles.label}>{getFilterLabel(label)}</span>
      </span>
      <span className={styles.count}>{count === 10000 ? '10000+' : count}</span>
    </label>
  );
}

Input.defaultProps = {
  count: 0,
  testId: 'input',
};
