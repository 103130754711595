import { PropsWithChildren } from 'react';
import { HubPage } from '../../../../../types';
import { useRootSelector } from '../../../../../store/hooks';
import { selectAudienceHubPage } from '../../store/features/page';
import Layout, { LayoutProps } from '../Layout';

export default function PageLayout(
  props: Omit<PropsWithChildren<LayoutProps>, 'layout'>,
) {
  const { data: layout } = useRootSelector(selectAudienceHubPage<HubPage>());

  return <Layout {...props} layout={layout} />;
}
