import { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../../../../types';
import styles from './Badge.module.scss';

export enum BadgeSizes {
  tiny = 'tiny',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum BadgeTheme {
  text = 'text',
  outlined = 'outlined',
  whiteFilled = 'whiteFilled',
  accented = 'accented',
}

export interface BadgeProps
  extends PropsWithChildren<PropsWithTestId<HTMLAttributes<HTMLDivElement>>> {
  theme: keyof typeof BadgeTheme;
  size: keyof typeof BadgeSizes;
}

export const Badge = (props: BadgeProps) => {
  const { theme, size, className, children, testId, ...restProps } = props;

  return (
    <div
      {...restProps}
      className={cn(styles.wrapper, styles[theme], styles[size], className)}
      data-testid={testId}
    >
      {children}
    </div>
  );
};

Badge.defaultProps = {
  theme: BadgeTheme.text,
  size: BadgeSizes.small,
  testId: 'badge',
};
