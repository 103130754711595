import React from 'react';
import styled from 'styled-components';

import OneColumnMenu from '../OneColumnMenu';
import TwoColumnMenu from '../TwoColumnMenu';
import SocialMenu from '../SocialMenu';

import { color } from '../../styles/informatech/colours/colours';
import { breakpoint, typography } from '../../styles/shared';

const Footer = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || color.backgroundInformaTechBlue};

  color: ${color.lightest};
  font-family: ${typography.type.primary};
  font-size: ${typography.size.s2}px;

  a {
    text-decoration: none;
    color: white;
  }
`;

const FooterWrapper = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  display: flex;

  padding-bottom: 60px;
  @media screen and (max-width: ${breakpoint.desktopMediumBreakpoint}) {
    padding-bottom: 25px;
  }

  ${({ bottomBorder }) =>
    bottomBorder && `border-bottom: 1px solid ${color.lightest};`}

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    flex-direction: column;
  }

  @media (max-width: ${breakpoint.mobileBreakpoint}) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const LeftColumn = styled.div`
  display: flex;

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    justify-content: center;
  }
`;

const MiddleColumn = styled.div``;

const RightColumn = styled.div`
  text-align: right;
  margin-left: auto;

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    margin-left: 0;
  }
`;

export const MarketingFooter = ({
  backgroundColor,
  bottomBorder,
  eventName,
  discoverMenu,
  secondaryMenu,
  socialMenu,
}) => {
  return (
    <Footer backgroundColor={backgroundColor}>
      <FooterWrapper bottomBorder={!backgroundColor && bottomBorder}>
        <LeftColumn>
          <TwoColumnMenu
            title={discoverMenu.title}
            menuLeft={discoverMenu.menuLeft}
            menuRight={discoverMenu.menuRight}
          />
        </LeftColumn>
        <MiddleColumn>
          <OneColumnMenu
            title={secondaryMenu.title}
            menuItems={secondaryMenu.menu}
          />
        </MiddleColumn>
        <RightColumn>
          <SocialMenu
            title={`Follow ${eventName} on Social`}
            items={socialMenu}
          />
        </RightColumn>
      </FooterWrapper>
    </Footer>
  );
};
