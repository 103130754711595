import { useEffect, PropsWithChildren } from 'react';
import { FormProvider, UseFormReturn, Path } from 'react-hook-form';
import { HubDiscoveryValues } from '../../../../../../store/features/discovery';

export interface ProductFilterProviderProps<T> {
  form: UseFormReturn<Partial<T>>;
  onChangeFilter: (data: T, name?: Path<Partial<T>>) => void;
}

export function ProductFilterProvider<T extends Partial<HubDiscoveryValues>>(
  props: PropsWithChildren<ProductFilterProviderProps<T>>,
) {
  const { form, onChangeFilter, children } = props;
  const { watch } = form;

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      onChangeFilter(data as unknown as T, name);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, onChangeFilter]);

  return <FormProvider {...form}>{children}</FormProvider>;
}
