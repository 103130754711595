import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './LeadGenSelect.scss';

export default class LeadGenSelect extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      initialLabel: '',
    };
  }

  componentWillUnmount() {
    const { input = {} } = this.props;

    // when field input 'state' will be removed we reset field value in store
    if (input.name === 'state' || input.name === 'profile.state') {
      input.onChange(null);
    }
  }

  generateOptions(options) {
    return options.map((item) => {
      const value = item.value ? item.value : item;
      const label = item.label ? item.label : item;

      return (
        <option
          key={value}
          value={value}
          className="lead-gen-select__option"
          data-test-lead-gen="lead-gen-select-option"
        >
          {label}
        </option>
      );
    });
  }

  getInitialLabel(options, initialValue) {
    let initialLabel = 'Please specify';

    options.forEach((item) => {
      const value = item.value ? item.value : item;
      const label = item.label ? item.label : item;
      if (value === initialValue) {
        initialLabel = label;
      }
    });

    return initialLabel;
  }

  componentDidMount() {
    const { input = {}, options = [] } = this.props;
    this.setState({
      initialLabel: this.getInitialLabel(options, input.value),
    });
  }

  render() {
    const {
      input = {},
      options = [],
      meta = {},
      label,
      isRequired,
      isUpdate = false,
    } = this.props;
    const { initialLabel } = this.state;
    const isError = meta.error && meta.touched;
    let isPreReq = false;

    if (isUpdate) {
      isPreReq = isRequired && !meta.touched && !input.value && !isError;
    }

    const selectClass = classNames('lead-gen-select__input', {
      'lead-gen-select__input-error': isError,
      'lead-gen-select__input-pre-req': isPreReq,
    });
    const labelClass = classNames('lead-gen-select__label-error', {
      'lead-gen-select__label-pre-req': isPreReq,
    });

    return (
      <div className="lead-gen-select" data-test-lead-gen="lead-gen-select">
        <div className="lead-gen-select__label">
          <label
            className="lead-gen-select__label-name"
            data-test-lead-gen="lead-gen-select-label-name"
          >
            {label}
            {isRequired ? ' *' : ''}
          </label>
          {(isPreReq || isError) && (
            <div
              className={labelClass}
              data-test-lead-gen="lead-gen-select-label-error"
            >
              {isError ? meta.error : 'Required'}
            </div>
          )}
        </div>
        <div className="lead-gen-select__wrapper">
          <select
            {...input}
            id={input.name.replace(/profile./gi, '')}
            className={selectClass}
            data-test-lead-gen="lead-gen-select-input"
          >
            <option
              className="lead-gen-select__default-option"
              value=""
              disabled
              data-test-lead-gen="lead-gen-select-default-option"
            >
              {initialLabel}
            </option>
            {this.generateOptions(options)}
          </select>
        </div>
      </div>
    );
  }
}
