import { InputHTMLAttributes, ReactNode } from 'react';
import {
  RegisterOptions,
  useController,
  useFormContext,
} from 'react-hook-form';
import cn from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import s from './TextField.module.scss';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: ReactNode;
  validation?: RegisterOptions;
}

export default function TextField(props: TextFieldProps) {
  const { name, label, validation, disabled, ...inputProps } = props;

  const { control } = useFormContext();
  const {
    field,
    fieldState: { invalid },
    formState: { errors },
  } = useController({
    control,
    name,
    rules: validation,
  });

  const componentClassName = cn(s.wrapper, {
    [s.error]: invalid,
  });

  return (
    <label className={componentClassName}>
      <div className={s.labelWrapper}>
        <div className={s.label}>{label}</div>
        {invalid && (
          <div className={s.helperText}>
            <ErrorMessage name={name} errors={errors} />
          </div>
        )}
      </div>
      <input type="text" {...inputProps} {...field} disabled={disabled} />
    </label>
  );
}
