import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MultiLevelNavBtn from '../MultiLevelNavBtn/MultiLevelNavBtn';
import './MultiLevelNavItem.scss';

export default function MultiLevelNavItem(props) {
  const { level = '1', navBarItem = {}, background, children } = props;

  const componentClassNames = classNames(
    'c-multi-level-nav-item',
    `c-multi-level-nav-item--level-${level}`,
  );

  return (
    <div className={componentClassNames}>
      <MultiLevelNavBtn level={level} navBarItem={navBarItem}>
        {navBarItem.groupItems && navBarItem.groupItems.length > 0 && (
          <div
            className="c-multi-level-nav-item__overlay"
            style={{ background }}
          >
            <div className="c-multi-level-nav-item__body">
              {children(navBarItem)}
            </div>
          </div>
        )}
      </MultiLevelNavBtn>
    </div>
  );
}

MultiLevelNavItem.propTypes = {
  navBarItem: PropTypes.object.isRequired,
  background: PropTypes.string,
  level: PropTypes.string,
};
