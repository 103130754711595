import { PricingPackage } from '../../../store/features/packages/types';

export function mapPackagesByDelegates(initialPackagesList?: PricingPackage[]) {
  const packagesByDelegates: Record<string, PricingPackage[]> = {};

  initialPackagesList &&
    initialPackagesList.forEach((obj) => {
      const { delegateType, ...rest } = obj;
      if (!packagesByDelegates[delegateType]) {
        packagesByDelegates[delegateType] = [];
      }
      packagesByDelegates[delegateType].push({ delegateType, ...rest });
    });

  return packagesByDelegates;
}
