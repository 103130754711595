import SearchProposition from '../SearchProposition';
import s from './PageNotFound.module.scss';

export default function PageNotFound() {
  return (
    <div className={s.wrapper}>
      <div className={s.body}>
        <h3 className={s.title}>404</h3>
        <h4 className={s.subtitle}>Page not found</h4>
        <div className={s.text}>
          The page you are looking for has either moved or no longer exists
        </div>
      </div>
      <div className={s.footer}>
        <SearchProposition />
      </div>
    </div>
  );
}
