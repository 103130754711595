import merge from 'lodash/merge';
import { isFunction } from '../../helpers';
import { createSelector } from 'reselect';
import {
  BOOKING_CLOSED,
  fetchBookingClosedMessageAction,
} from '../siteDucks/bookingClosed';

const UPDATE = 'CHOSEN_PRODUCTS_UPDATE';
const SUCCESS = 'CHOSEN_PRODUCTS_SUCCESS';
const FAILURE = 'CHOSEN_PRODUCTS_FAILURE';

const initialState = {
  loaded: false,
};

export default function chosenProductsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      return processChosenProducts(state, action);

    case SUCCESS:
      return processChosenProducts(state, action);

    case BOOKING_CLOSED:
      return Object.assign(
        {},
        {
          loaded: true,
          ...action.data.json,
          statusCode: 423,
        },
      );

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

function processChosenProducts(state, action) {
  const data = merge({ data: {}, meta: {} }, action.data.json);
  const packages = data.data?.packages || [];

  data.meta.ticketCount = packages.reduce(
    (sum, item) => sum + item.quantity,
    0,
  );

  // Sorting packages depending on delegate's type
  const packagesMap = packages.reduce((acc, item) => {
    let delegatePackages;

    if (!item.delegateType) item.delegateType = 'noDelegate';

    if (acc[item.delegateType]) {
      acc[item.delegateType].push(item);
      delegatePackages = acc[item.delegateType];
    } else {
      delegatePackages = [item];
    }

    return { ...acc, [item.delegateType]: delegatePackages };
  }, {});

  // Adding of delegate's uniq number to each delegate in all packages through all order list
  let i = 1;
  Object.keys(packagesMap).forEach((delegateType) => {
    const delegateTypePackages = packagesMap[delegateType];
    return delegateTypePackages.forEach((item) => {
      item.delegates.forEach((delegate) => {
        delegate.number = i;
        i++;
      });
    });
  });

  return {
    loaded: true,
    statusCode: 200,
    booking: action.data.statusCode !== 423,
    data: packagesMap,
    meta: data.meta,
  };
}

export function fetchChosenProductsSuccess(data = {}) {
  return { type: SUCCESS, data };
}

export function updateChosenProductsSuccess(data = {}) {
  return { type: UPDATE, data };
}

export function chosenProductsFailure(data = {}) {
  return { type: FAILURE, data };
}

export function fetchChosenProductsAction(basketId, cb) {
  return function (dispatch, getState, { Api, i18n }) {
    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'GET',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .chosenProducts({ siteId, basketId })
      .then((response) => {
        if (isFunction(cb)) {
          cb(response);
        }

        if (response.statusCode === 200) {
          return dispatch(fetchChosenProductsSuccess(response));
        } else if (response.statusCode === 423) {
          return dispatch(fetchBookingClosedMessageAction());
        } else {
          return dispatch(chosenProductsFailure(response));
        }
      });
  };
}

export function addProductsToBasketAction(
  basketId,
  queryParams,
  bodyParams,
  callback,
) {
  return function (dispatch, getState, { Api, qs, i18n }) {
    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    const queryParamsString = qs.stringify(queryParams, {
      addQueryPrefix: true,
    });

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'POST',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
        body: JSON.stringify(bodyParams),
      })
      .chosenProducts({ siteId, basketId, queryParams: queryParamsString })
      .then((response) => {
        if (isFunction(callback)) {
          const data = {
            statusCode: response.statusCode,
            ...response.json,
          };

          callback(data);
        }

        if (response.statusCode === 200) {
          return dispatch(updateChosenProductsSuccess(response));
        } else {
          return dispatch(chosenProductsFailure(response));
        }
      });
  };
}

export function deleteChosenProductAction(basketId, packageId, callback) {
  return function (dispatch, getState, { Api, qs, i18n }) {
    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'DELETE',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .deleteChosenProduct({ siteId, basketId, packageId })
      .then((response) => {
        if (isFunction(callback)) {
          const data = {
            statusCode: response.statusCode,
            ...response.json,
          };

          callback(data);
        }

        if (response.statusCode === 200) {
          return dispatch(updateChosenProductsSuccess(response));
        } else {
          return dispatch(chosenProductsFailure(response));
        }
      });
  };
}

export function updateChosenProductsAction(
  basketId,
  packageId,
  quantity,
  callback,
) {
  return function (dispatch, getState, { Api, qs, i18n }) {
    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'PATCH',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .updateChosenProducts({ siteId, basketId, packageId, quantity })
      .then((response) => {
        if (isFunction(callback)) {
          const data = {
            statusCode: response.statusCode,
            ...response.json,
          };

          callback(data);
        }

        if (response.statusCode === 200) {
          return dispatch(updateChosenProductsSuccess(response));
        } else {
          return dispatch(chosenProductsFailure(response));
        }
      });
  };
}

export function clearBasketAction() {
  return function (dispatch) {
    return dispatch(updateChosenProductsSuccess());
  };
}

/** Selectors **/

export const getTicketCount = createSelector(
  (state) => state.chosenProducts,
  (chosenProducts) => {
    const { data = {} } = chosenProducts;

    if (data === undefined) {
      return;
    }

    return Object.values(data).reduce((acc, delegatePackages) => {
      let result = acc;
      delegatePackages.forEach((item) => {
        result += item.quantity;
      });

      return result;
    }, 0);
  },
);

export const getDivisionFromChosenProducts = createSelector(
  (state) => state.chosenProducts,
  (chosenProducts) => {
    const {
      booking,
      meta: { divisionId, divisionInfrontBusinessUnit, divisionName } = {},
    } = chosenProducts;

    if (!booking) {
      return null;
    }

    return { divisionId, divisionInfrontBusinessUnit, divisionName };
  },
);
