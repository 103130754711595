import fetch, { Request } from 'cross-fetch';
import { ServerConfig } from '../../types';
import { isAbsoluteUrl, isBrowser } from '../../helpers';
import clientConfig from '../../clientConfig';

enum Service {
  CommerceService = 'CommerceService',
  ContentService = 'ContentService',
  LeadGenService = 'LeadGenService',
  DiscoveryService = 'DiscoveryService',
}

const pathPrefixRegExp = /\/caas\/(commerce|content|leadgen|discovery)/i;

type Path = string;
type Body = object;
type RequestConfig = RequestInit;

export interface AjaxServiceConstructor {
  logger: any;
  preview: boolean;
  serverConfig: ServerConfig;
}

class AjaxService {
  private readonly logger;
  private readonly preview;
  private readonly serverConfig;

  constructor({ logger, preview, serverConfig }: AjaxServiceConstructor) {
    this.logger = logger;
    this.preview = preview;
    this.serverConfig = serverConfig;
  }

  public fetch(path: Path, requestConfig: RequestConfig) {
    const service = this.getServiceByPathPrefix(path);
    const url = this.getRequestURL(path, service);

    const request = new Request(url, requestConfig);

    if (typeof requestConfig?.body === 'string') {
      request.headers?.set('Content-Type', 'application/json');
    }

    if (!request.headers?.get('Accept-Language')) {
      request.headers?.set('Accept-Language', clientConfig.defaultLanguage);
    }

    return fetch(request)
      .then((response) => {
        this.logRequest(request, response, service);
        return response;
      })
      .catch((err) => {
        this.logRequest(request, err, service);
        return err;
      });
  }

  public get(path: Path, requestConfig?: RequestConfig) {
    return this.fetch(path, { method: 'GET', ...requestConfig });
  }

  public post(path: Path, body: Body, requestConfig?: RequestConfig) {
    return this.fetch(path, {
      method: 'POST',
      body: JSON.stringify(body),
      ...requestConfig,
    });
  }

  public patch(path: Path, body: Body, requestConfig?: RequestConfig) {
    return this.fetch(path, {
      method: 'PATCH',
      body: JSON.stringify(body),
      ...requestConfig,
    });
  }

  public put(path: Path, body: Body, requestConfig?: RequestConfig) {
    return this.fetch(path, {
      method: 'PUT',
      body: JSON.stringify(body),
      ...requestConfig,
    });
  }

  public delete(path: Path, requestConfig?: RequestConfig) {
    return this.fetch(path, { method: 'DELETE', ...requestConfig });
  }

  private getServiceByPathPrefix(path: Path) {
    const data = pathPrefixRegExp.exec(path) || [];

    switch (data[1]) {
      case 'commerce':
        return Service.CommerceService;
      case 'content':
        return Service.ContentService;
      case 'leadgen':
        return Service.LeadGenService;
      case 'discovery':
        return Service.DiscoveryService;
      default:
        return null;
    }
  }

  private getRequestURL(path: Path, service: Service | null) {
    const pathname = path.replace(pathPrefixRegExp, '');

    if (isBrowser() || isAbsoluteUrl(path) || !service) return pathname;

    const host = this.serverConfig.api.services[service].v1.host;

    return `${host}${pathname}`;
  }

  private logRequest(req: Request, res: Response, service: Service | null) {
    if (!service || !this.logger) return;

    const logObject = {
      preview: this.preview || undefined,
      service: service,
      method: req.method,
      statusCode: res.status,
      url: req.url,
    };

    if (res.status >= 500) {
      this.logger.error(Object.assign(logObject, { error: res }));
    } else if ([400, 410].includes(res.status)) {
      this.logger.warn(logObject);
    } else {
      this.logger.info(logObject);
    }
  }
}

export default AjaxService;
