import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderHTMLString, getImgixUrl } from '../../../../helpers';
import './LeadGenTeaserArticle.scss';

export function LeadGenTeaserArticle({
  agendaDownloadButton,
  articleTitle,
  teaserHeading,
  teaserBody,
  thumbnail,
  pageConfig: { tenantId } = {},
}) {
  return (
    <div
      className="lead-gen-teaser-article"
      data-test-lead-gen="lead-gen-teaser-article"
    >
      {articleTitle && (
        <h1
          className="lead-gen-teaser-article__title"
          data-test-lead-gen="lead-gen-teaser-article-title"
        >
          {articleTitle}
        </h1>
      )}
      {thumbnail && thumbnail.path ? (
        <div className="lead-gen-teaser-article__whitepaper">
          <img
            data-src={getImgixUrl(tenantId, thumbnail.path, 'w=180&h=255')}
            alt="ArticleImage"
            className="lead-gen-teaser-article__whitepaper-img lazy"
            data-test-lead-gen="lead-gen-teaser-article-img"
          />
          <div className="lead-gen-teaser-article__whitepaper-body">
            {renderArticleContent(
              teaserHeading,
              agendaDownloadButton,
              teaserBody,
            )}
          </div>
        </div>
      ) : (
        renderArticleContent(teaserHeading, agendaDownloadButton, teaserBody)
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(LeadGenTeaserArticle);

function renderArticleContent(teaserHeading, agendaDownloadButton, teaserBody) {
  return (
    <>
      {teaserHeading && (
        <h2
          className="lead-gen-teaser-article__subtitle"
          data-test-lead-gen="lead-gen-teaser-article-subtitle"
        >
          {teaserHeading}
        </h2>
      )}
      {agendaDownloadButton && agendaDownloadButton()}
      {teaserBody && (
        <div
          className="lead-gen-teaser-article__body"
          data-test-lead-gen="lead-gen-teaser-article-body"
        >
          {renderHTMLString(teaserBody)}
        </div>
      )}
    </>
  );
}

LeadGenTeaserArticle.propTypes = {
  agendaDownloadButton: PropTypes.func,
  articleTitle: PropTypes.string,
  teaserHeading: PropTypes.string,
  teaserBody: PropTypes.string,
  thumbnail: PropTypes.object,
};
