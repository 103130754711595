import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getImgixUrl } from '../../../helpers';

/**
 * WHITE LABEL
 */
export function ImageTag(props) {
  const {
    src,
    alt,
    style,
    className,
    lazy = true,
    imgix = true,
    imgixParams,
    pageConfig: { tenantId } = {},
    testId = 'informa-image-tag',
  } = props;

  const imageUrl = imgix ? getImgixUrl(tenantId, src, imgixParams) : src;
  const componentClassNames = classNames({
    [className]: className,
    lazy,
    'no-lazy': !lazy,
  });

  return imageUrl ? (
    <img
      src={lazy ? null : imageUrl}
      data-src={lazy ? imageUrl : null}
      alt={alt}
      className={componentClassNames}
      style={style}
      data-testid={testId}
    />
  ) : null;
}

export const ImageTagProps = {
  src: PropTypes.string,
  imgix: PropTypes.bool,
  imgixParams: PropTypes.string,
  lazy: PropTypes.bool,
  alt: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  pageConfig: PropTypes.object,
  testId: PropTypes.string,
};

ImageTag.propTypes = ImageTagProps;

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(ImageTag);
