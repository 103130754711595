import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { CacheProvider } from '@emotion/react';

const Router = typeof window !== 'undefined' ? BrowserRouter : StaticRouter;

export default function Root({
  store,
  theme,
  i18n,
  location,
  cookies,
  context = {},
  emotionCache,
  children,
}) {
  return (
    <ReduxProvider store={store}>
      <CookiesProvider cookies={cookies}>
        <I18nextProvider i18n={i18n}>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={theme}>
              <Router location={location} context={context}>
                {children}
              </Router>
            </ThemeProvider>
          </CacheProvider>
        </I18nextProvider>
      </CookiesProvider>
    </ReduxProvider>
  );
}

Root.propTypes = {
  i18n: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  location: PropTypes.string,
  cookies: PropTypes.object,
  context: PropTypes.object,
  emotionCache: PropTypes.object,
  children: PropTypes.node,
};
