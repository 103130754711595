import React from 'react';
import styles from './TopicsList.module.scss';

export interface TopicsListProps {
  list: string[];
  children: (name: string) => React.ReactNode;
}

export function TopicsList(props: TopicsListProps) {
  const { list, children } = props;

  return (
    <div className={styles.wrapper}>
      {list.map((item) => (
        <div key={item} className={styles.topic}>
          {children(item)}
        </div>
      ))}
    </div>
  );
}
