import React from 'react';
import cn from 'classnames';
import ImageTag from '../../../../../../shared/ImageTag/ImageTag';
import styles from './SponsorsList.module.scss';

export enum LabelAlign {
  left = 'left',
  center = 'center',
  right = 'right',
}

export interface SponsorsListProps {
  logos: string[];
  label: React.ReactNode;
  labelAlign: keyof typeof LabelAlign;
  countToShow: number;
}

export function SponsorsList(props: SponsorsListProps) {
  const { logos, label, labelAlign, countToShow } = props;

  return (
    <div>
      <div className={cn(styles.label, styles[labelAlign])}>{label}</div>
      <div className={styles.logosWrapper}>
        {logos.slice(0, countToShow).map((logo, index) => (
          <ImageTag key={index} src={logo} lazy={false} />
        ))}
      </div>
    </div>
  );
}

SponsorsList.defaultProps = {
  logos: [],
  label: 'Sponsored by:',
  labelAlign: LabelAlign.left,
  countToShow: 2,
};
