import i18next from 'i18next';
import CallToActionService from './CallToActionService';

// TODO: remove current service after "/src/components/shared/Link" component is removed

const service = {
  getButtonDetails(
    button,
    siteType = 'EVENT',
    isCoLoEvent = false,
    isNewPaymentJourney = false,
  ) {
    const isAgendaBtn =
      button.type === 'AGENDA_LINK' || button.type === 'AGENDA_LINK_SHORT';
    const buttonType =
      isAgendaBtn && isCoLoEvent ? 'COLO_AGENDA_LINK' : button.type;
    const isEvent = siteType === 'EVENT';

    const details = CallToActionService.getConfigByType(
      (buttonType || '').toUpperCase(),
    );

    if (!details) {
      return {
        path: button.customUrl || '/',
        label: button.customText,
      };
    }

    if (details.withConfiguredPath) {
      return {
        path: button.path,
        label: i18next.t(details.label),
      };
    }

    if (isNewPaymentJourney) {
      return {
        path: details.newPaymentJourneyPath || details.path,
        label: i18next.t(details.label),
      };
    }

    if (!isEvent && details.coursePath) {
      return {
        path: details.coursePath,
        label: i18next.t(details.label),
      };
    }

    return {
      path: details.path,
      label: i18next.t(details.label),
    };
  },
};

export default service;
