import React from 'react';
import PropTypes from 'prop-types';
import { Redirect as OriginalRedirect, Route } from 'react-router-dom';

export default function Redirect(props) {
  const { from, to, exact, status = 302 } = props;

  return (
    <Route
      render={(props) => {
        if (props.staticContext) {
          props.staticContext.status = status;
        }

        return <OriginalRedirect from={from} to={to} exact={exact} />;
      }}
    />
  );
}

Redirect.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  exact: PropTypes.bool,
  status: PropTypes.number,
  staticContext: PropTypes.object,
};
