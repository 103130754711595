import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UseFormReturn } from 'react-hook-form';
import qs from 'qs';

import {
  fetchHubPageDiscovery,
  fetchHubPageDiscoveryData,
  updatePreloadedProductsList,
  FacetsConfigData,
  FetchHubPageDiscoveryParams,
  Discovery,
  HubDiscoveryValues,
} from '../../../../../../store/features/discovery';
import { useRootDispatch } from '../../../../../../store/hooks';
import {
  UseInfiniteScrollProps,
  useKeywordSearch,
} from '../../../../../../hooks';
import { DiscoveryContentTypes } from '../../../../../../constants';
import { checkDefaultFiltersApplied } from '../../../helpers';

export interface UseProductPageFilterProps<T> {
  form: UseFormReturn<T>;
  pageName: FetchHubPageDiscoveryParams['pageName'];
  contentType: DiscoveryContentTypes;
  defaultValues: T;
  initialValue?: string;
  searchQueryData?: FacetsConfigData;
}

export function useProductPageFilter<T extends Partial<HubDiscoveryValues>>({
  pageName,
  contentType,
  initialValue,
  defaultValues,
  searchQueryData,
  form: { reset, getValues, setValue },
}: UseProductPageFilterProps<T>) {
  const history = useHistory();
  const dispatch = useRootDispatch();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const { facetsConfig, subBrands: initialSubBrands = [] } =
    searchQueryData || {};
  const subBrandsEnabled = facetsConfig?.subBrandsEnabled;

  const changeContentTypeFilterHandler = useCallback(
    (type: DiscoveryContentTypes[]) => {
      const allValues = getValues();

      reset({
        ...allValues,
        type,
      });
    },
    [getValues, reset],
  );

  const keywordSearchHandler = useCallback(
    (searchInput: string) => {
      const values = getValues();

      reset({
        ...defaultValues,
        ...values,
        searchInput,
      });
    },
    [defaultValues, getValues, reset],
  );

  const keywordSearchProps = useKeywordSearch(
    keywordSearchHandler,
    initialValue,
  );
  const { handleReset: resetSearchValueHandler } = keywordSearchProps;

  const changeFilterHandler = useCallback(
    (selectedFilterValues: T, name?: string) => {
      const { subBrands: selectedSubBrands = [] } = selectedFilterValues;

      // type.0 means radio buttons
      const typeFilter = name && ['type', 'type.0'].includes(name);

      if (typeFilter && selectedFilterValues.type) {
        return changeContentTypeFilterHandler(selectedFilterValues.type);
      }

      const subBrands =
        selectedSubBrands.length > 0 ? selectedSubBrands : initialSubBrands;

      const subBrandsEnabledValues = subBrandsEnabled
        ? {
            subBrands: subBrandsEnabled ? subBrands : defaultValues.subBrands,
            availableSubBrands:
              initialSubBrands.length > 0
                ? initialSubBrands
                : defaultValues.availableSubBrands,
          }
        : {};

      const values = {
        ...defaultValues,
        ...selectedFilterValues,
        ...subBrandsEnabledValues,
      };

      const stringifyValues = qs.stringify(values, {
        addQueryPrefix: true,
        encode: true,
      });

      if (stringifyValues) {
        history.push(stringifyValues);
      } else {
        history.replace(pathname);
      }

      setLoading(true);
      dispatch(
        fetchHubPageDiscovery({
          values,
          searchQueryData,
          pageName,
        }),
      ).finally(() => {
        setLoading(false);
      });
    },
    [
      defaultValues,
      searchQueryData,
      history,
      dispatch,
      pageName,
      changeContentTypeFilterHandler,
      initialSubBrands,
      subBrandsEnabled,
      pathname,
    ],
  );

  const loadMoreFilterResultsHandler: UseInfiniteScrollProps['fetchMoreData'] =
    useCallback(
      (page) => {
        const values = getValues();

        return dispatch(
          fetchHubPageDiscoveryData({
            values: { ...values, page },
            pageName,
          }),
        ).then(({ payload, meta: { requestStatus } }) => {
          if (requestStatus === 'fulfilled') {
            dispatch(
              updatePreloadedProductsList({
                data: payload as Discovery,
                contentType,
              }),
            );
          }
        });
      },
      [dispatch, getValues, pageName, contentType],
    );

  const resetFilterHandler = useCallback(() => {
    reset(defaultValues);
    resetSearchValueHandler();
  }, [defaultValues, reset, resetSearchValueHandler]);

  const resetTextFilterHandler = useCallback(() => {
    // @ts-ignore
    setValue('searchInput', '');
    resetSearchValueHandler();
  }, [resetSearchValueHandler, setValue]);

  return {
    loading,
    changeFilterHandler,
    resetFilterHandler,
    resetTextFilterHandler,
    changeContentTypeFilterHandler,
    loadMoreFilterResultsHandler,
    keywordSearchProps,
    defaultFiltersApplied: checkDefaultFiltersApplied(
      defaultValues,
      getValues(),
    ),
  };
}
