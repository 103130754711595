import React from 'react';
import PropTypes from 'prop-types';

import './FormHeading.scss';

export default function FormHeading(props) {
  const { size = 'default', className = '' } = props;

  return (
    <div className={`c-form-heading ${size} ${className}`}>
      {props.children}
    </div>
  );
}

FormHeading.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
