import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getVenueData } from '../../../../../../../store/siteDucks/siteHeader';

const Wrapper = styled.div`
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-family: Proxima Nova ExCn Rg, sans-serif;
`;

const StartDate = styled.h2`
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
`;

const VenueName = styled.p`
  font-size: 14.5px;
  line-height: 12px;
  text-transform: uppercase;
`;

export default function FanExpoVenue() {
  const { formattedDate, venueName } = useSelector(getVenueData);

  return (
    <Wrapper data-testid="header-fanexpo-venue">
      {formattedDate && <StartDate>{formattedDate}</StartDate>}
      {venueName && <VenueName>{venueName}</VenueName>}
    </Wrapper>
  );
}
