import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Auth from '../../../services/AuthService';

import ConfirmationMessage from './ConfirmationMessage/ConfirmationMessage';
import { FormBlock, FormHeading, FormMessage } from '../../shared/forms';
import Button from '../../shared/Button/Button';

import './LeadGenConfirmRegistration.scss';

export default class LeadGenConfirmRegistration extends Component {
  static propTypes = {
    success: PropTypes.bool.isRequired,
    username: PropTypes.string.isRequired,
    leadGenType: PropTypes.string.isRequired,
  };

  state = {
    message: null,
    loading: false,
  };

  sendConfirmation = () => {
    const { username } = this.props;
    this.setState({ loading: true });

    Auth.resendConfirmationEmail({ username })
      .then((response) => response.json())
      .then((res) => {
        const message = res.success
          ? { success: 'Confirmation email resent!' }
          : res;

        this.setState({ message, loading: false });
        // Remove message after 10s
        this.timerId = setTimeout(() => {
          this.setState({ message: null });
        }, 10000);
      })
      .catch((err) => console.error(err));
  };

  componentWillUnmount() {
    if (this.timerId) clearTimeout(this.timerId);
  }

  render() {
    // styles with type-1 for article pages
    // styles with type-2 for landing and agenda pages
    const { success, username, leadGenType } = this.props;
    const { message } = this.state;
    const titleClass =
      leadGenType === 'article'
        ? ''
        : 'lead-gen-confirm-registration__title-type-2';
    const descriptionClass =
      leadGenType === 'article'
        ? ''
        : 'lead-gen-confirm-registration__description-type-2';
    const btnClass =
      leadGenType === 'article' ? '' : 'c-button__lead-gen-confirm-type-2';
    const textTransform = leadGenType === 'article' ? 'capitalize' : null;

    return (
      <>
        {message && (
          <ConfirmationMessage
            message={message}
            data-test-lead-gen="lead-gen-confirm-message"
          />
        )}
        <div className="container">
          <div
            className="lead-gen-confirm-registration"
            data-test-lead-gen="lead-gen-confirm-registration"
          >
            <FormBlock enabled={success}>
              <FormHeading size="large">
                <h4
                  className={titleClass}
                  data-test-lead-gen="confirm-registration-title"
                >
                  Thanks for signing up!
                </h4>
              </FormHeading>
              <FormMessage>
                <p
                  className={descriptionClass}
                  data-test-lead-gen="confirm-registration-description"
                >
                  We&#39;ve sent an email to
                  <strong>{' ' + username}</strong>. Please check your inbox and
                  click on the email link to confirm your registration
                </p>
              </FormMessage>
              <Button
                onClick={this.sendConfirmation}
                look="classic"
                size="submit"
                className={`no-shadow full-width ${btnClass}`}
                textTransform={textTransform}
                disabled={this.state.loading}
                data-test-lead-gen="confirm-registration-button"
              >
                Resend Confirmation Email
              </Button>
            </FormBlock>
          </div>
        </div>
      </>
    );
  }
}
