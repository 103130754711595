import { isBefore } from 'date-fns';
import { DiscoveryContentTypes, DeliveryType } from '../../../../../constants';

export function getSiteAttendanceLabel(
  deliveryTypes: string[],
  contentType: DiscoveryContentTypes.EVENT | DiscoveryContentTypes.COURSE,
  endDate?: string,
) {
  const formattedEndDate = !!endDate && new Date(endDate).setHours(23);
  const isDateExpired =
    !formattedEndDate || isBefore(formattedEndDate, new Date());
  const isEvent = contentType === DiscoveryContentTypes.EVENT;

  if (isDateExpired) return null;

  if (
    deliveryTypes.includes(DeliveryType.ON_DEMAND) ||
    deliveryTypes.includes(DeliveryType.FLEXI)
  ) {
    return isEvent ? 'Watch Now' : 'Start Now';
  }

  return isEvent ? 'Book Now' : 'Find out more';
}
