import React from 'react';
import PropTypes from 'prop-types';

import LeadGenFormConstructor from '../../LeadGenFormItems/LeadGenFormConstructor';

import './LeadGenProfile.scss';

export default function LeadGenProfile(props) {
  const {
    profileFormData = [],
    stateOptions = [],
    audienceName = '',
    showCountryStates,
    isUpdate,
  } = props;

  return (
    <div className="row" data-testid="lead-gen-profile">
      <LeadGenFormConstructor
        formData={profileFormData}
        sectionType="profile"
        audienceName={audienceName}
        showCountryStates={showCountryStates}
        stateOptions={stateOptions}
        isUpdate={isUpdate}
      />
    </div>
  );
}

LeadGenProfile.propTypes = {
  profileFormData: PropTypes.array.isRequired,
  stateOptions: PropTypes.array,
  audienceName: PropTypes.string,
  showCountryStates: PropTypes.bool,
};
