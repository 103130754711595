import React from 'react';
import cn from 'classnames';
import { Anchor, AnchorProps } from '../../../../../../shared/Anchor';
import styles from './CardAnchor.module.scss';

export interface CardAnchorProps extends AnchorProps {
  fullWidth?: boolean;
}

export function CardAnchor(props: CardAnchorProps) {
  const { className, children, fullWidth, ...restProps } = props;
  const anchorClassNames = cn(styles.anchor, className, {
    [styles.fullWidth]: fullWidth,
  });

  return (
    <Anchor {...restProps} className={anchorClassNames}>
      {children}
    </Anchor>
  );
}
