import { reducer as formReducer } from 'redux-form';

import optionsReducer from './commonDucks/options';
import siteTypeReducer from './commonDucks/siteType';
import pageConfigReducer from './commonDucks/pageConfig';
import portfolioReducer from './commonDucks/portfolio';
import leadGenFormReducer from './commonDucks/leadGenForm';
import svgsReducer from './commonDucks/svgs';
import chosenSegmentsReducer from './commonDucks/maSegments';

import hubReducer from './commonDucks/hub';
import hubMenuReducer from './commonDucks/hubMenu';
import hubSearchReducer from './commonDucks/hubSearch';

import industryHubReducer from './industryDucks/industryHub';
import industryHeaderReducer from './industryDucks/industryHeader';

import audienceHubReducer from './audienceDucks/audienceHub';
import audienceHeaderReducer from './audienceDucks/audienceHeader';
import audienceEventsReducer from './audienceDucks/audienceEvents';
import audienceCoursesReducer from './audienceDucks/audienceCourses';
import audienceArticlesReducer from './audienceDucks/audienceArticles';

import articleHomeReducer from './articleDucks/articleHome';

import siteHeaderReducer from './siteDucks/siteHeader';
import siteContentReducer from './siteDucks/siteContent';
import siteFooterReducer from './siteDucks/siteFooter';
import siteSpeakersReducer from './siteDucks/siteSpeakers';
import sitePartnersReducer from './siteDucks/sitePartners';
import siteSpeakerDetailsReducer from './siteDucks/siteSpeakerDetails';
import siteSponsorDetailsReducer from './siteDucks/siteSponsorDetails';
import siteAgendaReducer from './siteDucks/siteAgenda';
import siteCoLoAgendaReducer from './siteDucks/siteCoLoAgenda';
import siteDownloadableAgendaReducer from './siteDucks/siteDownloadableAgenda';

import siteProductsReducer from './pricingDucks/siteProducts';
import courseOptionsReducer from './pricingDucks/courseOptions';
import chosenProductsReducer from './pricingDucks/chosenProducts';
import billingDetailsReducer from './pricingDucks/billingDetails';
import delegateDetailsReducer from './pricingDucks/delegateDetails';
import contactDetailsReducer from './pricingDucks/contactDetails';
import orderReceiptReducer from './pricingDucks/orderReceipt';
import coursePricingReducer from './pricingDucks/coursePricing';

import rootReducer from './rootReducer';

const reducers = {
  form: formReducer,

  // TS
  ...rootReducer,

  hub: hubReducer,
  hubMenu: hubMenuReducer,
  hubSearch: hubSearchReducer,

  industryHub: industryHubReducer,
  industryHeader: industryHeaderReducer,

  audienceHub: audienceHubReducer,
  audienceHeader: audienceHeaderReducer,
  audienceEvents: audienceEventsReducer,
  audienceCourses: audienceCoursesReducer,
  audienceArticles: audienceArticlesReducer,

  articleHome: articleHomeReducer,

  siteHeader: siteHeaderReducer,
  siteFooter: siteFooterReducer,
  siteContent: siteContentReducer,
  siteSpeakers: siteSpeakersReducer,
  siteAgenda: siteAgendaReducer,
  siteCoLoAgenda: siteCoLoAgendaReducer,
  siteDownloadableAgenda: siteDownloadableAgendaReducer,

  sitePartners: sitePartnersReducer,
  siteSpeakerDetails: siteSpeakerDetailsReducer,
  siteSponsorDetails: siteSponsorDetailsReducer,

  siteProducts: siteProductsReducer,
  courseOptions: courseOptionsReducer,
  coursePricing: coursePricingReducer,
  chosenProducts: chosenProductsReducer,
  billingDetails: billingDetailsReducer,
  delegateDetails: delegateDetailsReducer,
  contactDetails: contactDetailsReducer,
  orderReceipt: orderReceiptReducer,

  options: optionsReducer,
  leadGenForm: leadGenFormReducer,
  portfolio: portfolioReducer,
  pageConfig: pageConfigReducer,
  siteType: siteTypeReducer,
  svgs: svgsReducer,
  maSegments: chosenSegmentsReducer,
};

export default reducers;
