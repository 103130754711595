import { createSelector } from '@reduxjs/toolkit';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../../store/features/generic';
import {
  GenericState,
  HubOptions,
  UniqueIdentifier,
} from '../../../../../../types';
import { RootState } from '../../../../../../store/store';

export interface BrandHubOptions extends HubOptions {}

export interface BrandHubOptionsParams {
  brandHubId: UniqueIdentifier;
}

export const fetchBrandHubOptions = createGenericAsyncThunk<
  BrandHubOptions,
  BrandHubOptionsParams
>(
  'brandHubOptions/fetchBrandHubOptions',
  async ({ brandHubId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/brand-hub/${brandHubId}/options`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: BrandHubOptions = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const brandHubOptionsSlice = createGenericSlice({
  name: 'brandHubOptions',
  initialState: {
    status: 'idle',
    data: {},
  } as GenericState<BrandHubOptions>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<BrandHubOptions, BrandHubOptionsParams>(
      builder,
      fetchBrandHubOptions,
    );
  },
});

export const selectBrandHubOptions = createSelector(
  [(state: RootState) => state.brandHub.options],
  (data) => data,
);

export const { start, fulfilled, rejected } = brandHubOptionsSlice.actions;

export default brandHubOptionsSlice.reducer;
