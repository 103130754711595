import React, { useState, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  FormDescription,
  PermissionsText,
  Input,
  Select,
  NotificationMessage,
  NotificationMessageType,
} from './components';
import { Button } from '../Button';
import Spinner from '../../../shared/Spinner/Spinner';
import { SubscribeNewsletter } from '../../brandHub/store/features/subscribe';
import Patterns from '../../../../utils/pattern/patterns';
import { countriesList, statesList } from '../../../../services/Dictionary';
import styles from './EmailOptInForm.module.scss';

export interface EmailOptInFormProps {
  onSubmit: (values: SubscribeNewsletter) => Promise<{
    meta: {
      requestStatus: string;
    };
  }>;
  hideFormTimeout: number;
  successMessage: string;
  errorMessage: string;
  title: string;
  body?: string;
  permissionTextName: string;
  onClose?: () => void;
  renderCollapseButton: React.ReactNode;
}

export const EmailOptInForm = (props: EmailOptInFormProps) => {
  const {
    title,
    body,
    permissionTextName,
    onClose,
    onSubmit,
    successMessage,
    errorMessage,
    hideFormTimeout,
    renderCollapseButton,
  } = props;

  const [notificationMessageType, setNotificationMessageType] =
    useState<NotificationMessageType>();
  const form = useForm<SubscribeNewsletter>({
    defaultValues: {
      country: '',
      state: '',
    },
  });
  const { t } = useTranslation();
  const {
    handleSubmit: formHandleSubmit,
    formState: { isDirty, isSubmitting },
    watch,
  } = form;
  const country = watch('country') || '';
  const shouldRenderState = country === 'US' || country === 'CA';

  const countriesListOptions = useMemo(
    () =>
      Object.keys(countriesList).map((countryCode) => ({
        value: countryCode,
        label: t(countriesList[countryCode]),
      })),
    [t],
  );

  const statesListOptions = useMemo(() => {
    const statesByCountry = statesList[country] || [];
    return Object.keys(statesByCountry).map((stateCode) => ({
      value: stateCode,
      label: statesByCountry[stateCode],
    }));
  }, [country]);

  const handleClose = () => {
    if (onClose) {
      setTimeout(() => {
        onClose();
      }, hideFormTimeout);
    }
  };

  const handleSubmit = async (values: SubscribeNewsletter) => {
    await onSubmit(values)
      .then((response) => {
        const {
          meta: { requestStatus },
        } = response;

        if (requestStatus === 'fulfilled') {
          setNotificationMessageType(NotificationMessageType.SUCCESS);
          handleClose();
        }

        if (requestStatus === 'rejected') {
          setNotificationMessageType(NotificationMessageType.ERROR);
        }
      })
      .catch((error) => {
        console.log(error);
        setNotificationMessageType(NotificationMessageType.ERROR);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <FormDescription title={title} body={body} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className={styles.formWrapper}>
              <FormProvider {...form}>
                <form noValidate onSubmit={formHandleSubmit(handleSubmit)}>
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <Input
                        name="firstName"
                        placeholder="First name"
                        validation={{
                          required: 'Required',
                        }}
                      />
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <Input
                        name="lastName"
                        placeholder="Last name"
                        validation={{
                          required: 'Required',
                        }}
                      />
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        validation={{
                          required: 'Required',
                          validate: {
                            isEmail: (value) => {
                              if (value && !Patterns.isEmail(value)) {
                                return 'Please provide a valid email address';
                              }
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <Select
                        name="country"
                        blankOption="Country / Region"
                        validation={{ required: 'Required' }}
                        options={countriesListOptions}
                        testId="email-opt-in-country-select"
                      />
                    </div>
                    {shouldRenderState && (
                      <div className="col-xs-12 col-md-offset-6 col-md-6">
                        <Select
                          name="state"
                          blankOption="State"
                          validation={{ required: 'Required' }}
                          options={statesListOptions}
                          testId="email-opt-in-state-select"
                        />
                      </div>
                    )}
                    <div className="col-xs-12">
                      {isDirty && (
                        <PermissionsText
                          className={styles.fieldPadding}
                          permissionTextName={permissionTextName}
                        />
                      )}
                    </div>
                    {notificationMessageType && (
                      <div className="col-xs-12">
                        <NotificationMessage type={notificationMessageType}>
                          {notificationMessageType ===
                          NotificationMessageType.SUCCESS
                            ? successMessage
                            : errorMessage}
                        </NotificationMessage>
                      </div>
                    )}
                    <div className="col-xs-12">
                      <div className={styles.formFooter}>
                        <Button
                          to=""
                          size="large"
                          onClick={formHandleSubmit(handleSubmit)}
                          data-testid="email-opt-in-subscribe-button"
                        >
                          {isSubmitting && <Spinner />}
                          Subscribe
                        </Button>
                        <div className="hidden-lg hidden-md">
                          {renderCollapseButton}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EmailOptInForm.defaultProps = {
  hideFormTimeout: 4000,
  successMessage: 'Great! Thank you for signing up for email updates.',
  errorMessage: 'Something went wrong. Please try again later.',
  renderCloseButton: null,
};
