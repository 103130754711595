import { HTMLAttributes, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import { concatPath } from '../../../../helpers';
import { useHubContext } from '../context/hubContext';
import { useKeywordSearch } from '../../../../hooks';
import { KeywordSearch } from '../siteBuilder/components';
import s from './SearchProposition.module.scss';
import { useRootSelector } from '../../../../store/hooks';
import { selectAudienceHubPrimaryTopic } from '../../audienceHub/store/features/options';
import { HubDiscoveryValues } from '../../../../store/features/discovery';

export interface SearchPropositionProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  title?: string;
  subTitle?: string;
}

const defaultProps = {
  title: 'Can’t find what you’re looking for?',
  subTitle: 'Search our events, training, articles and people',
};

export default function SearchProposition(props: SearchPropositionProps) {
  const { title, subTitle, className } = props;

  const { indexPath, brandName } = useHubContext();
  const mainFilterTopic = useRootSelector(selectAudienceHubPrimaryTopic);
  const form = useForm<HubDiscoveryValues>({
    defaultValues: {
      subBrands: [brandName],
      mainFilterTopic,
    },
  });

  const searchHandler = useCallback(
    (searchValue: string) => {
      const value = encodeURIComponent(searchValue);
      const url = concatPath([indexPath, '/search/']);

      document.location.assign(`${url}?searchInput=${value}`);
    },
    [indexPath],
  );
  const keywordSearchProps = useKeywordSearch(searchHandler);

  return (
    <div className={cn(s.wrapper, className)}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            {title && <div className={s.title}>{title}</div>}
            {subTitle && <div className={s.subTitle}>{subTitle}</div>}
            <div className={s.searchWrapper}>
              <KeywordSearch
                {...keywordSearchProps}
                theme="bordered"
                form={form}
                enableSuggestions
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SearchProposition.defaultProps = defaultProps;
