import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { DiscoveryContentTypes } from '../../constants';
import { useRootDispatch } from '../../store/hooks';
import {
  DiscoverySuggestion,
  DiscoverySuggestionsConfig,
  discoverySuggestionsConfigInitialValues,
  fetchDiscoverySuggestionsConfig,
  getDiscoverySuggestionKeywordItem,
} from '../../store/features/discoverySuggestions';
import { HubDiscoveryValues } from '../../store/features/discovery';

const initialValues = discoverySuggestionsConfigInitialValues;

export type KeywordSearchSelectSuggestionHandler = (
  suggestion: DiscoverySuggestion,
) => void;

export function useKeywordSearchSuggestions(
  onSelectSuggestion: KeywordSearchSelectSuggestionHandler,
  form?: UseFormReturn<HubDiscoveryValues>,
) {
  const dispatch = useRootDispatch();
  const [open, setOpen] = useState(false);
  const [cursorIndex, setCursorIndex] = useState<number>(-1);
  const [suggestionsConfig, setSuggestionsConfig] =
    useState<DiscoverySuggestionsConfig>(initialValues);

  useEffect(() => {
    function handleArrowMove(event: KeyboardEvent) {
      const len = suggestionsConfig.suggestions.length;

      switch (event.key) {
        case 'ArrowUp':
          if (cursorIndex <= 0) {
            setCursorIndex(len - 1);
          } else {
            setCursorIndex((index) => index - 1);
          }
          break;

        case 'ArrowDown':
          if (cursorIndex === len - 1) {
            setCursorIndex(0);
          } else {
            setCursorIndex((index) => index + 1);
          }
          break;

        case 'Enter':
          if (suggestionsConfig.suggestions[cursorIndex]) {
            onSelectSuggestion(suggestionsConfig.suggestions[cursorIndex]);
          }
          setOpen(false);
          setCursorIndex(-1);
          break;

        default:
          break;
      }
    }

    document.addEventListener('keydown', handleArrowMove);

    return () => {
      document.removeEventListener('keydown', handleArrowMove);
    };
  }, [cursorIndex, onSelectSuggestion, suggestionsConfig.suggestions]);

  const fetchAction = useMemo(
    () =>
      debounce((searchInput) => {
        if (searchInput.length < 2) return;

        const values = (form as UseFormReturn<HubDiscoveryValues>).getValues();
        const { type = [], subBrands = [], mainFilterTopic } = values;
        const count =
          type.length === 0 || type[0] === DiscoveryContentTypes.ALL ? 2 : 10;

        dispatch(
          fetchDiscoverySuggestionsConfig({
            searchInput,
            type,
            subBrands,
            mainFilterTopic,
            count,
          }),
        ).then(({ payload, meta: { requestStatus } }) => {
          if (requestStatus === 'fulfilled') {
            const config = payload as DiscoverySuggestionsConfig;
            const keywordItem = getDiscoverySuggestionKeywordItem(searchInput);
            const extendedSuggestions = [keywordItem, ...config.suggestions];

            setSuggestionsConfig({
              ...config,
              suggestions: extendedSuggestions,
            });
            setOpen(config.total !== 0);
            setCursorIndex(-1);
          }
        });
      }, 400),
    [dispatch, form],
  );

  const handleSelectSuggestion = useCallback(
    (suggestion: DiscoverySuggestion) => {
      onSelectSuggestion(suggestion);
      setOpen(false);
    },
    [onSelectSuggestion],
  );

  return {
    open,
    setOpen,
    setCursorIndex,
    fetchAction,
    disableSearchOnEnter: cursorIndex !== -1,
    suggestions: suggestionsConfig.suggestions,
    total: suggestionsConfig.total,
    onSelectSuggestion: handleSelectSuggestion,
    cursorIndex,
  };
}
