import React from 'react';
import styled from 'styled-components';
import { color as siteColor } from '../../../styles/informaconnect/colours/colours';

const Label = styled.label`
  min-height: 20px;
  margin-bottom: 18px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: initial;
  cursor: pointer;
`;

const LabelText = styled.div`
  color: ${siteColor.formText};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  padding-bottom: 3px;
`;

const StyledRadio = styled.div`
  height: 20px;
  width: 20px;
  min-width: 20px;
  background-color: ${siteColor.lightest};
  border: 1px solid ${siteColor.formBorder};
  ${(props) => props.selected && `border: 6px solid ${props.color};`}
  border-radius: 10px;
  margin-top: 1px;
  margin-right: 16px;
`;

const RadioLabel = (props) => {
  const { prefix, radioBtn, color = '#a1a1a1', isError, isChecked } = props;

  return (
    <Label className={prefix + '__label'} htmlFor={radioBtn.value}>
      <StyledRadio
        selected={isChecked}
        color={color}
        className={
          'lead-gen-radio-btn__custom-radio' +
          ((isChecked && ' lead-gen-radio-btn__custom-radio--active') || '') +
          ((isError && ' lead-gen-radio-btn__custom-radio--error') || '')
        }
        data-test-lead-gen={prefix + '-custom-radio'}
      />
      <LabelText className={prefix + '__text'}>{radioBtn.label}</LabelText>
    </Label>
  );
};

export default RadioLabel;
