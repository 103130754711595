import { createSelector } from 'reselect';
import qs from 'qs';

import { stringifyQueryParams } from '../../helpers';
import {
  getAgendaDayFromPath,
  addLocaleToQuery,
  sortSessionsByTime,
  getMinMaxSessionsTime,
  getTopOffsetAsMinutes,
  convertMinutesToPixels,
  sortOutStreamsByBreakGroups,
  stableGridConfig,
} from '../../utils/coLoAgenda/index';

export const REQUEST = 'CO_LO_AGENDA_REQUEST';
export const SUCCESS = 'CO_LO_AGENDA_SUCCESS';
export const FAILURE = 'CO_LO_AGENDA_FAILURE';
export const HANDLE_VISIBILITY = 'CO_LO_AGENDA_HANDLE_VISIBILITY';
export const HANDLE_MODAL = 'CO_LO_AGENDA_HANDLE_MODAL';
export const CHANGE_FILTER_PARAMS = 'CO_LO_AGENDA_CHANGE_FILTER_PARAMS';
export const SET_FILTER_PARAMS = 'CO_LO_AGENDA_SET_FILTER_PARAMS';

const initialState = {
  loaded: false,
  loading: false,
  isFiltersVisible: true,
  isGridView: true,
  params: {
    searchInput: '',
  },
  data: {},
};

export default function siteCoLoAgendaReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        loading: true,
        loaded: false,
      });

    case SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        statusCode: action.data.statusCode,
        ...action.data.json,
      });

    case FAILURE:
      return Object.assign({}, state, {
        loaded: false,
        loading: false,
        error: true,
        statusCode: action.data.statusCode,
        ...action.data.json,
      });

    case HANDLE_VISIBILITY: {
      const { visibilityFlagName } = action.data;

      return Object.assign({}, state, {
        [visibilityFlagName]: !state[visibilityFlagName],
      });
    }

    case HANDLE_MODAL: {
      const { modalData, modalName, isModalOpen } = action.data;

      return Object.assign({}, state, {
        [modalName]: {
          isModalOpen,
          modalData,
        },
      });
    }

    case CHANGE_FILTER_PARAMS:
      return Object.assign({}, state, {
        params: { ...state.params, ...action.data },
      });

    case SET_FILTER_PARAMS:
      return Object.assign({}, state, {
        params: action.data,
      });

    default:
      return state;
  }
}

export function fetchCoLoAgendaRequest() {
  return { type: REQUEST };
}

export function fetchCoLoAgendaSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchCoLoAgendaFailure(data) {
  return { type: FAILURE, data };
}

export function handleCoLoAgendaVisibility(data) {
  return { type: HANDLE_VISIBILITY, data };
}

export function changeCoLoAgendaFilters(data) {
  return { type: CHANGE_FILTER_PARAMS, data };
}

export function setCoLoAgendaFilters(data) {
  return { type: SET_FILTER_PARAMS, data };
}

export function handleModal(data) {
  return { type: HANDLE_MODAL, data };
}

/** Side effects **/

export function changeCoLoAgendaFilterParams(data = {}, actionSettings = {}) {
  const { history, isFetchData = true } = actionSettings;

  return function (dispatch) {
    if (data.searchInput || data.searchInput === '') {
      // in case with changing searchInput we need to reset all other params
      dispatch(setCoLoAgendaFilters(data));
    } else {
      dispatch(changeCoLoAgendaFilters(data));
    }
    isFetchData && dispatch(fetchCoLoAgendaAction({ history }));
  };
}

export function fetchCoLoAgendaAction(actionSettings = {}) {
  const {
    queryParamsFromRoute = '',
    isInitialLoading = false,
    history,
  } = actionSettings;

  return function (dispatch, getState, { Api, i18n }) {
    dispatch(fetchCoLoAgendaRequest());
    const {
      siteCoLoAgenda: { params: queryParamsFromStore },
      pageConfig: { isPreview, siteId, location, siteTypePath },
      siteType: { data: { primaryLocale = 'en' } = {} } = {},
    } = getState();
    const agendaDay = getAgendaDayFromPath(location.pathname);
    let query;

    if (isInitialLoading && queryParamsFromRoute) {
      query = queryParamsFromRoute;
      const mergedQueryParams = Object.assign(
        {},
        initialState.params,
        qs.parse(queryParamsFromRoute.slice(1)),
      );
      dispatch(setCoLoAgendaFilters(mergedQueryParams));
    } else {
      query = stringifyQueryParams(queryParamsFromStore);
      !isInitialLoading && history.push(query);
    }

    query = addLocaleToQuery(query, primaryLocale);

    return Api()
      .cache(true)
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchCoLoAgenda({ siteTypePath, siteId, agendaDay, query })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchCoLoAgendaSuccess(response));
        } else {
          return dispatch(fetchCoLoAgendaFailure(response));
        }
      });
  };
}

/** Selectors **/
export const getDateData = createSelector(
  [
    (state) => state.siteCoLoAgenda.data.pinnedDates,
    (state) => state.siteCoLoAgenda.data.selectedDay,
  ],
  (pinnedDates = [], selectedDay) => {
    const defaultDayData = pinnedDates.find((dateData) => dateData.defaultDay);
    const selectedDayData = pinnedDates.find(
      (dateData) => dateData.dayNumber === selectedDay,
    );

    return {
      defaultDayNumber: defaultDayData?.dayNumber || 1,
      selectedDayData,
    };
  },
);

export const getParentEventInfo = createSelector(
  [
    (state) => state.siteCoLoAgenda.data.parentEvent,
    (state) => state.siteCoLoAgenda.data.events,
  ],
  (parentEvent = {}, events = []) => {
    const parentEventData = events.find(
      (eventFilter) => eventFilter.id === parentEvent.id,
    );

    return {
      disabled: parentEventData ? parentEventData.disabled : true,
      ...parentEvent,
    };
  },
);

export const getFiltersData = createSelector(
  [
    (state) => state.siteCoLoAgenda.data.events,
    (state) => state.siteCoLoAgenda.data.dates,
    (state) => state.siteCoLoAgenda.data.types,
    (state) => state.siteCoLoAgenda.data.topics,
  ],
  (events = [], dates = [], types = [], topics = []) => {
    const sortedDates = dates.sort(function (a, b) {
      return new Date(a.name) - new Date(b.name);
    });

    return {
      events,
      types,
      topics,
      dates: sortedDates,
    };
  },
);

export const getListViewData = createSelector(
  [
    (state) => state.siteCoLoAgenda.data.sessions,
    (state) => state.siteCoLoAgenda.data.eventsHeaders,
  ],
  (sessions = [], eventsHeaders = []) => {
    const listViewData = {};

    for (const header of eventsHeaders) {
      for (const stream of header.eventStreams) {
        const key = header.eventName + '__' + stream.name;
        listViewData[key] = [];
      }
    }

    for (const session of sessions) {
      const key = session.eventName + '__' + session.streamName;
      if (Object.prototype.hasOwnProperty.call(listViewData, key)) {
        listViewData[key].push(session);
      }
    }

    for (const key in listViewData) {
      listViewData[key] = sortSessionsByTime(listViewData[key]);
    }

    return listViewData;
  },
);

export const getGridViewData = createSelector(
  [
    (state) => state.siteCoLoAgenda.data.sessions,
    (state) => state.siteCoLoAgenda.data.eventsHeaders,
  ],
  (sessions = [], eventsHeaders = []) => {
    const { minSessionTime, maxSessionTime } = getMinMaxSessionsTime(sessions);
    const gridConfig = {
      sessions,
      minSessionTime,
      maxSessionTime,
      leftOffsetByStreams: {},
      gridWidth: null,
      gridHeight: null,
    };
    const { streamWidth, streamMargin, sessionTimeStep, sessionTimeStepValue } =
      stableGridConfig;
    let leftOffset = 0;
    const totalStreamWidth = streamWidth + streamMargin;

    // calculating left offset for each stream
    eventsHeaders.forEach((event, eventIndex) => {
      event.eventStreams.forEach((stream, streamIndex) => {
        // don't need to add left offset for first stream in first event
        leftOffset +=
          eventIndex === 0 && streamIndex === 0 ? 0 : totalStreamWidth;

        gridConfig.leftOffsetByStreams[stream.name] = leftOffset;
      });
    });

    // calculating total grid width and height
    // adding totalStreamWidth value because we don't add left offset for first stream
    gridConfig.gridWidth = leftOffset + totalStreamWidth;
    gridConfig.gridHeight = convertMinutesToPixels(
      getTopOffsetAsMinutes(
        gridConfig.maxSessionTime,
        gridConfig.minSessionTime,
      ),
      sessionTimeStep,
      sessionTimeStepValue,
    );

    // calculating top offset and grouping gathered streams for each session
    gridConfig.sessions.forEach((session) => {
      session.topOffset = getTopOffsetAsMinutes(
        session.startTime,
        gridConfig.minSessionTime,
      );
      session.gatheredStreamGroups = sortOutStreamsByBreakGroups(
        session,
        gridConfig.leftOffsetByStreams,
      );
    });

    return gridConfig;
  },
);

export const getEventsColorsMap = createSelector(
  (state) => state.siteCoLoAgenda.data.eventsHeaders,
  (eventsHeaders = []) => {
    const eventsColorsMap = {};

    eventsHeaders.forEach((event) => {
      eventsColorsMap[event.eventName] = event.eventColor;
    });

    return eventsColorsMap;
  },
);

const getElementsProperties = (state) => state.pageConfig.elementsProperties;

export const getInitialElPositions = createSelector(
  [getElementsProperties],
  (elementsProperties = {}) => {
    const {
      siteHeader: { height: siteHeaderHeight = 0 } = {},
      multiLevelNavMenu: { height: multiLevelNavMenuHeight = 0 } = {},
      informaRibbon: { height: topBarHeight = 0 } = {},
      coLoAgendaActionBar: { height: coLoAgendaActionBarHeight = 0 } = {},
      coLoAgendaMenuBar: { height: coLoAgendaMenuBarHeight = 0 } = {},
      cookieMessage: { height: cookieMessageHeight = 0 } = {},
    } = elementsProperties;
    const coLoAgendaMenuBarPseudoElementHeight = 20;

    return {
      initialPosOfCoLoAgendaMenuBar:
        cookieMessageHeight +
        siteHeaderHeight +
        multiLevelNavMenuHeight +
        topBarHeight +
        coLoAgendaActionBarHeight,
      initialPosOfCoLoAgendaMenuBarMobile:
        cookieMessageHeight +
        siteHeaderHeight +
        multiLevelNavMenuHeight +
        topBarHeight,
      initialPosOfCoLoAgendaFilters:
        cookieMessageHeight +
        siteHeaderHeight +
        multiLevelNavMenuHeight +
        topBarHeight +
        coLoAgendaActionBarHeight +
        coLoAgendaMenuBarHeight,
      initialPosOfGridContent:
        cookieMessageHeight +
        siteHeaderHeight +
        multiLevelNavMenuHeight +
        topBarHeight +
        coLoAgendaActionBarHeight +
        coLoAgendaMenuBarHeight +
        (coLoAgendaMenuBarPseudoElementHeight - 1), // -1 it's for better visual effects
      fixedPosOfGridHeader:
        multiLevelNavMenuHeight +
        coLoAgendaMenuBarHeight +
        coLoAgendaMenuBarPseudoElementHeight,
    };
  },
);
