import { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './TextWithCount.module.scss';

export interface TextWithCountProps extends HTMLAttributes<HTMLDivElement> {
  count: number | false;
}

export const TextWithCount = (props: PropsWithChildren<TextWithCountProps>) => {
  const { count, className, children, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.wrapper, className)}>
      {children}
      {count !== 0 && count && (
        <span className={styles.count}>
          {count === 10000 ? '10000+' : count}
        </span>
      )}
    </div>
  );
};
