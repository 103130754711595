export interface CountriesListType {
  [key: string]: string;
}

export interface StateListType {
  [key: string]: {
    [key: string]: string;
  };
}

export enum CardTypesMap {
  AMEX = 'AMEX',
  DINERS = 'DINERS',
  DSC = 'DSC',
  MC = 'MC',
  VISA = 'VISA',
}
type CardType = Record<CardTypesMap, string>;

export const cardTypesList: CardType = {
  AMEX: 'event.option.CardType.AMEX',
  DINERS: 'event.option.CardType.DINERS',
  DSC: 'event.option.CardType.DSC',
  MC: 'event.option.CardType.MC',
  VISA: 'event.option.CardType.VISA',
};

export enum HonorificMap {
  Mr = 'Mr',
  Ms = 'Ms',
  Dr = 'Dr',
  Prof = 'Prof',
}
type HonorificListType = Record<HonorificMap, string>;

export const honorificList: HonorificListType = {
  Mr: 'event.purchase.person.honorific.options.mr',
  Ms: 'event.purchase.person.honorific.options.ms',
  Dr: 'event.purchase.person.honorific.options.dr',
  Prof: 'event.purchase.person.honorific.options.prof',
};

export const countriesList: CountriesListType = {
  GB: 'event.option.Country.GB',
  US: 'event.option.Country.US',
  AF: 'event.option.Country.AF',
  AL: 'event.option.Country.AL',
  DZ: 'event.option.Country.DZ',
  AS: 'event.option.Country.AS',
  AD: 'event.option.Country.AD',
  AO: 'event.option.Country.AO',
  AI: 'event.option.Country.AI',
  AQ: 'event.option.Country.AQ',
  AG: 'event.option.Country.AG',
  AR: 'event.option.Country.AR',
  AM: 'event.option.Country.AM',
  AW: 'event.option.Country.AW',
  AU: 'event.option.Country.AU',
  AT: 'event.option.Country.AT',
  AZ: 'event.option.Country.AZ',
  BS: 'event.option.Country.BS',
  BH: 'event.option.Country.BH',
  BD: 'event.option.Country.BD',
  BB: 'event.option.Country.BB',
  BY: 'event.option.Country.BY',
  BE: 'event.option.Country.BE',
  BZ: 'event.option.Country.BZ',
  BJ: 'event.option.Country.BJ',
  BM: 'event.option.Country.BM',
  BT: 'event.option.Country.BT',
  BO: 'event.option.Country.BO',
  BA: 'event.option.Country.BA',
  BW: 'event.option.Country.BW',
  BV: 'event.option.Country.BV',
  BR: 'event.option.Country.BR',
  IO: 'event.option.Country.IO',
  BN: 'event.option.Country.BN',
  BG: 'event.option.Country.BG',
  BF: 'event.option.Country.BF',
  BI: 'event.option.Country.BI',
  KH: 'event.option.Country.KH',
  CM: 'event.option.Country.CM',
  CA: 'event.option.Country.CA',
  CV: 'event.option.Country.CV',
  KY: 'event.option.Country.KY',
  CF: 'event.option.Country.CF',
  TD: 'event.option.Country.TD',
  CL: 'event.option.Country.CL',
  CN: 'event.option.Country.CN',
  CX: 'event.option.Country.CX',
  CC: 'event.option.Country.CC',
  CO: 'event.option.Country.CO',
  KM: 'event.option.Country.KM',
  CG: 'event.option.Country.CG',
  CK: 'event.option.Country.CK',
  CR: 'event.option.Country.CR',
  HR: 'event.option.Country.HR',
  CU: 'event.option.Country.CU',
  CY: 'event.option.Country.CY',
  CZ: 'event.option.Country.CZ',
  DK: 'event.option.Country.DK',
  DJ: 'event.option.Country.DJ',
  DM: 'event.option.Country.DM',
  DO: 'event.option.Country.DO',
  TP: 'event.option.Country.TP',
  EC: 'event.option.Country.EC',
  EG: 'event.option.Country.EG',
  SV: 'event.option.Country.SV',
  GQ: 'event.option.Country.GQ',
  ER: 'event.option.Country.ER',
  EE: 'event.option.Country.EE',
  ET: 'event.option.Country.ET',
  FK: 'event.option.Country.FK',
  FO: 'event.option.Country.FO',
  FJ: 'event.option.Country.FJ',
  FI: 'event.option.Country.FI',
  FR: 'event.option.Country.FR',
  GF: 'event.option.Country.GF',
  PF: 'event.option.Country.PF',
  TF: 'event.option.Country.TF',
  GA: 'event.option.Country.GA',
  GM: 'event.option.Country.GM',
  GE: 'event.option.Country.GE',
  DE: 'event.option.Country.DE',
  GH: 'event.option.Country.GH',
  GI: 'event.option.Country.GI',
  GR: 'event.option.Country.GR',
  GL: 'event.option.Country.GL',
  GD: 'event.option.Country.GD',
  GP: 'event.option.Country.GP',
  GU: 'event.option.Country.GU',
  GT: 'event.option.Country.GT',
  GN: 'event.option.Country.GN',
  GW: 'event.option.Country.GW',
  GY: 'event.option.Country.GY',
  HT: 'event.option.Country.HT',
  HM: 'event.option.Country.HM',
  HN: 'event.option.Country.HN',
  HK: 'event.option.Country.HK',
  HU: 'event.option.Country.HU',
  IS: 'event.option.Country.IS',
  IN: 'event.option.Country.IN',
  ID: 'event.option.Country.ID',
  IR: 'event.option.Country.IR',
  IQ: 'event.option.Country.IQ',
  IE: 'event.option.Country.IE',
  IL: 'event.option.Country.IL',
  IT: 'event.option.Country.IT',
  CI: 'event.option.Country.CI',
  JM: 'event.option.Country.JM',
  JP: 'event.option.Country.JP',
  JO: 'event.option.Country.JO',
  KZ: 'event.option.Country.KZ',
  KE: 'event.option.Country.KE',
  KI: 'event.option.Country.KI',
  KW: 'event.option.Country.KW',
  KG: 'event.option.Country.KG',
  LA: 'event.option.Country.LA',
  LV: 'event.option.Country.LV',
  LB: 'event.option.Country.LB',
  LS: 'event.option.Country.LS',
  LR: 'event.option.Country.LR',
  LY: 'event.option.Country.LY',
  LI: 'event.option.Country.LI',
  LT: 'event.option.Country.LT',
  LU: 'event.option.Country.LU',
  MO: 'event.option.Country.MO',
  MK: 'event.option.Country.MK',
  MG: 'event.option.Country.MG',
  MW: 'event.option.Country.MW',
  MY: 'event.option.Country.MY',
  MV: 'event.option.Country.MV',
  ML: 'event.option.Country.ML',
  MT: 'event.option.Country.MT',
  MH: 'event.option.Country.MH',
  MQ: 'event.option.Country.MQ',
  MR: 'event.option.Country.MR',
  MU: 'event.option.Country.MU',
  YT: 'event.option.Country.YT',
  FX: 'event.option.Country.FX',
  MX: 'event.option.Country.MX',
  FM: 'event.option.Country.FM',
  MD: 'event.option.Country.MD',
  MC: 'event.option.Country.MC',
  MN: 'event.option.Country.MN',
  ME: 'event.option.Country.ME',
  MS: 'event.option.Country.MS',
  MA: 'event.option.Country.MA',
  MZ: 'event.option.Country.MZ',
  MM: 'event.option.Country.MM',
  NA: 'event.option.Country.NA',
  NR: 'event.option.Country.NR',
  NP: 'event.option.Country.NP',
  NL: 'event.option.Country.NL',
  AN: 'event.option.Country.AN',
  NC: 'event.option.Country.NC',
  NZ: 'event.option.Country.NZ',
  NI: 'event.option.Country.NI',
  NE: 'event.option.Country.NE',
  NG: 'event.option.Country.NG',
  NU: 'event.option.Country.NU',
  NF: 'event.option.Country.NF',
  KP: 'event.option.Country.KP',
  MP: 'event.option.Country.MP',
  NO: 'event.option.Country.NO',
  OM: 'event.option.Country.OM',
  PK: 'event.option.Country.PK',
  PW: 'event.option.Country.PW',
  PS: 'event.option.Country.PS',
  PA: 'event.option.Country.PA',
  PG: 'event.option.Country.PG',
  PY: 'event.option.Country.PY',
  PE: 'event.option.Country.PE',
  PH: 'event.option.Country.PH',
  PN: 'event.option.Country.PN',
  PL: 'event.option.Country.PL',
  PT: 'event.option.Country.PT',
  PR: 'event.option.Country.PR',
  QA: 'event.option.Country.QA',
  RE: 'event.option.Country.RE',
  RO: 'event.option.Country.RO',
  RU: 'event.option.Country.RU',
  RW: 'event.option.Country.RW',
  KN: 'event.option.Country.KN',
  LC: 'event.option.Country.LC',
  VC: 'event.option.Country.VC',
  WS: 'event.option.Country.WS',
  SM: 'event.option.Country.SM',
  ST: 'event.option.Country.ST',
  SA: 'event.option.Country.SA',
  SN: 'event.option.Country.SN',
  RS: 'event.option.Country.RS',
  SC: 'event.option.Country.SC',
  SL: 'event.option.Country.SL',
  SG: 'event.option.Country.SG',
  SK: 'event.option.Country.SK',
  SI: 'event.option.Country.SI',
  SB: 'event.option.Country.SB',
  SO: 'event.option.Country.SO',
  ZA: 'event.option.Country.ZA',
  GS: 'event.option.Country.GS',
  KR: 'event.option.Country.KR',
  ES: 'event.option.Country.ES',
  LK: 'event.option.Country.LK',
  SH: 'event.option.Country.SH',
  PM: 'event.option.Country.PM',
  SD: 'event.option.Country.SD',
  SR: 'event.option.Country.SR',
  SJ: 'event.option.Country.SJ',
  SZ: 'event.option.Country.SZ',
  SE: 'event.option.Country.SE',
  CH: 'event.option.Country.CH',
  SY: 'event.option.Country.SY',
  TW: 'event.option.Country.TW',
  TJ: 'event.option.Country.TJ',
  TZ: 'event.option.Country.TZ',
  TH: 'event.option.Country.TH',
  TG: 'event.option.Country.TG',
  TK: 'event.option.Country.TK',
  TO: 'event.option.Country.TO',
  TT: 'event.option.Country.TT',
  TN: 'event.option.Country.TN',
  TR: 'event.option.Country.TR',
  TM: 'event.option.Country.TM',
  TC: 'event.option.Country.TC',
  TV: 'event.option.Country.TV',
  UG: 'event.option.Country.UG',
  UA: 'event.option.Country.UA',
  AE: 'event.option.Country.AE',
  UM: 'event.option.Country.UM',
  UY: 'event.option.Country.UY',
  UZ: 'event.option.Country.UZ',
  VU: 'event.option.Country.VU',
  VA: 'event.option.Country.VA',
  VE: 'event.option.Country.VE',
  VN: 'event.option.Country.VN',
  VG: 'event.option.Country.VG',
  VI: 'event.option.Country.VI',
  WF: 'event.option.Country.WF',
  EH: 'event.option.Country.EH',
  YE: 'event.option.Country.YE',
  YU: 'event.option.Country.YU',
  ZM: 'event.option.Country.ZM',
  ZW: 'event.option.Country.ZW',
};

export const statesList: StateListType = {
  US: {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  CA: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'New Brunswick',
    NL: 'Newfoundland and Labrador',
    NS: 'Nova Scotia',
    NT: 'Northwest Territories',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Prince Edward Island',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
};
