import { PricingProduct } from '../../../store/features/products/types';

export function getRelatedSecondaryProducts(
  secondaryProducts: PricingProduct[],
  productIds: string[],
  includeOnlyBundledProducts?: boolean,
) {
  return secondaryProducts.filter((product) => {
    const bundledProductIds = product.bundledWith
      .map((bundledItem) => bundledItem.mandatoryProductId)
      .filter(Boolean);
    const isBundled = bundledProductIds.some((bundledId) =>
      productIds.includes(bundledId),
    );

    return includeOnlyBundledProducts
      ? isBundled
      : bundledProductIds.length === 0 || isBundled;
  });
}
