import React, { useEffect } from 'react';
import GTM from '../../../../../utils/initialGTM';
import { useRootSelector } from '../../../../../store/hooks';
import {
  selectArticleHome,
  selectArticleHomeGated,
} from '../../../../../store/features/articles/home';
import {
  selectLeadGenForm,
  selectLeadGenFormLoaded,
} from '../../../../../store/features/leadGen';
import { MetaTags } from '../../../shared/MetaTags';
import { ArticleHome } from './ArticleHome';
import { LeadGenForm } from './LeadGenForm';

export function Article() {
  const {
    articleHome: {
      data: { title: articleTitle, summary, imageUrl, contentType },
    },
    articleGated,
    leadGen: { teaser },
    leadGenLoaded,
  } = useRootSelector((state) => ({
    articleHome: selectArticleHome(state),
    articleGated: selectArticleHomeGated(state),
    leadGen: selectLeadGenForm(state),
    leadGenLoaded: selectLeadGenFormLoaded(state),
  }));

  const title = articleTitle || teaser?.articleTitle;
  const description = summary || teaser?.teaserHeading;
  const thumbnail = imageUrl || teaser?.thumbnail?.path;

  useEffect(() => {
    GTM.pushContentType(contentType);
    GTM.isContentGated(articleGated);
  }, [articleGated, contentType]);

  return (
    <>
      <MetaTags title={title} description={description} thumbnail={thumbnail} />
      {leadGenLoaded ? <LeadGenForm /> : <ArticleHome />}
    </>
  );
}
