import qs from 'qs';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../../store/store';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../store/features/generic';
import { selectPageConfig } from '../../../../../store/features/pageConfig';
import { GenericState, SiteTypes } from '../../../../../types';
import {
  PricingProduct,
  PricingProducts,
  SitePricingProductsData,
  SitePricingProductsParams,
} from './types';
import { fetchSitePricingBookingClosed } from '../bookingClosed';
import { getAllProductsFromProductType } from '../../../helpers/selectPackage/getPackageFormDefaultValues';
import { getBundledProductsMap } from '../../../helpers/selectPackage/getBundledProductsMap';

export const fetchSitePricingProducts = createGenericAsyncThunk<
  SitePricingProductsData,
  SitePricingProductsParams
>(
  'sitePricingProducts/fetchSitePricingProducts',
  async (params, { extra, rejectWithValue, getState, dispatch }) => {
    const { basketId, queryParams, courseOptionSubPath } = params;
    const queryString = qs.stringify(queryParams, {
      addQueryPrefix: true,
    });
    const { siteType, siteId } = selectPageConfig(getState());
    const optionSubPath = courseOptionSubPath || 1;

    const response = await extra.ajax.get(
      siteType === SiteTypes.EVENT
        ? `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/site-products${queryString}`
        : `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/course-products/${optionSubPath}${queryString}`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: SitePricingProductsData = await response.json();

    if (response.status !== 200) {
      if (response.status === 423) {
        dispatch(fetchSitePricingBookingClosed());
      }

      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const sitePricingProductsSlice = createGenericSlice({
  name: 'sitePricingProducts',
  initialState: {
    status: 'idle',
    data: {
      data: {
        delegateTypes: [],
        packageDiscountsDescriptions: [],
        productTypesWithProducts: [],
        optionalProductTypesWithProducts: [],
      } as unknown as PricingProducts,
      meta: {},
    },
  } as GenericState<SitePricingProductsData>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<
      SitePricingProductsData,
      SitePricingProductsParams
    >(builder, fetchSitePricingProducts);
  },
});

export const selectSitePricingProductsData = (state: RootState) =>
  state.pricing.products.data.data;

export const selectSitePricingProductsMeta = (state: RootState) =>
  state.pricing.products.data.meta;

export const selectSitePricingProductsStatusCode = (state: RootState) =>
  state.pricing.products.statusCode;

export const selectPricingPrimaryProducts = createSelector(
  (state: RootState) =>
    selectSitePricingProductsData(state).productTypesWithProducts,
  (productTypesWithProducts) => {
    return getAllProductsFromProductType(productTypesWithProducts);
  },
);

export const selectPricingSecondaryProducts = createSelector(
  (state: RootState) =>
    selectSitePricingProductsData(state).optionalProductTypesWithProducts,
  (optionalProductTypesWithProducts) => {
    return getAllProductsFromProductType(optionalProductTypesWithProducts);
  },
);

export const selectPricingBundledProductsMap = createSelector(
  selectPricingSecondaryProducts,
  (secondaryProducts) => getBundledProductsMap(secondaryProducts),
);

export const selectAllPricingProducts = createSelector(
  [selectPricingPrimaryProducts, selectPricingSecondaryProducts],
  (primaryProducts, secondaryProducts) => {
    return [...primaryProducts, ...secondaryProducts];
  },
);

export const selectPricingProductById = createSelector(
  [
    selectAllPricingProducts,
    (state: RootState, productId: string) => productId,
  ],
  (allProducts, productId) => {
    const foundProduct = allProducts.find((product) => {
      return product.id === productId;
    });

    return foundProduct || ({} as PricingProduct);
  },
);

export const selectPricingKeyInformationSection = createSelector(
  (state: RootState) =>
    selectSitePricingProductsData(state).keyInformationSection,
  (keyInformationSection) => {
    const accordionItems = keyInformationSection?.items?.map(
      (accordionItem) => {
        const { id, question = '', answer = '' } = accordionItem;
        return {
          id,
          title: question,
          content: answer,
        };
      },
    );

    return { ...keyInformationSection, items: accordionItems };
  },
);

export const selectPricingProductsForGTM = createSelector(
  (state: RootState) =>
    selectSitePricingProductsData(state).productTypesWithProducts,
  (productTypesWithProducts) => {
    return productTypesWithProducts.flatMap((productType) =>
      productType.products.map(({ name, id, price }) => ({
        name,
        brand: name,
        id,
        price,
      })),
    );
  },
);

export const { start, fulfilled, rejected } = sitePricingProductsSlice.actions;

export default sitePricingProductsSlice.reducer;
