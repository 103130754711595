import cn from 'classnames';
import s from './SuggestedItem.module.scss';
import { HTMLtoJSX } from '../../../../../../../../helpers';
import {
  DiscoverySuggestion,
  DiscoverySuggestionContentType,
} from '../../../../../../../../store/features/discoverySuggestions';

const DiscoverySuggestionContentTypeMap = {
  [DiscoverySuggestionContentType.KEYWORD]: 'Keyword',
  [DiscoverySuggestionContentType.EVENT]: 'Event',
  [DiscoverySuggestionContentType.COURSE]: 'Course',
  [DiscoverySuggestionContentType.ARTICLE]: 'News & Insights',
  [DiscoverySuggestionContentType.STREAMLY_VIDEO]: 'Video',
  [DiscoverySuggestionContentType.AUDIENCE_HOME]: 'Topic Home',
};

export interface SuggestedItemProps {
  suggestion: DiscoverySuggestion;
  onClick: (suggestion: DiscoverySuggestion) => void;
  selected: boolean;
}

export default function SuggestedItem(props: SuggestedItemProps) {
  const { suggestion, onClick, selected } = props;

  const wrapperClassName = cn(s.wrapper, {
    [s.selected]: selected,
  });

  const title = suggestion.highlights?.[0] || suggestion.title;

  return (
    <div onClick={() => onClick(suggestion)} className={wrapperClassName}>
      <span className={cn(s.icon, 'material-icons')}>search</span>
      <span className={s.title}>{HTMLtoJSX(title)}</span>
      <span className={s.type}>
        {DiscoverySuggestionContentTypeMap[suggestion.type]}
      </span>
    </div>
  );
}

SuggestedItem.defaultProps = {
  selected: false,
};
