import React from 'react';

import {
  YoutubeVimeoEmbed,
  WistiaEmbed,
  StreamlyEmbed,
} from './innerComponents';
import { Wrapper } from './index.style';
import { VideoSource } from '../../../../../types';

export interface VideoPlayerProps {
  source: VideoSource;
  id: string;
  title: string;
  streamlyUrl: string;
  autoplay: boolean;
  controls: boolean;
}

export const VideoPlayer = ({
  source,
  id,
  autoplay,
  controls,
  title,
  streamlyUrl,
}: VideoPlayerProps) => (
  <Wrapper>
    {(source === VideoSource.YOUTUBE || source === VideoSource.VIMEO) && (
      <YoutubeVimeoEmbed
        source={source}
        id={id}
        title={title}
        autoplay={autoplay}
        controls={controls}
      />
    )}
    {source === VideoSource.WISTIA && (
      <WistiaEmbed
        id={id}
        autoPlay={autoplay}
        controlsVisibleOnLoad={controls}
      />
    )}
    {source === VideoSource.STREAMLY_VIDEO && (
      <StreamlyEmbed title={title} streamlyUrl={streamlyUrl} />
    )}
  </Wrapper>
);

VideoPlayer.defaultProps = {
  autoplay: true,
  controls: true,
  title: '',
};
