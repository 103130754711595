import { ReactNode } from 'react';
import cn from 'classnames';
import { CardWrapper } from '../../../cards/components/CardWrapper';
import styles from './ProductInlineCard.module.scss';

export interface ProductInlineCardProps {
  title: ReactNode;
  leftHeader?: ReactNode;
  rightHeader?: ReactNode;
  backgroundColor?: string;
}

export function ProductInlineCard(props: ProductInlineCardProps) {
  const { title, leftHeader, rightHeader, backgroundColor } = props;
  const leftHeaderClassNames = cn(rightHeader ? 'col-xs-8' : 'col-xs-12');
  const rightHeaderClassNames = cn(leftHeader ? 'col-xs-4' : 'col-xs-12');

  return (
    <CardWrapper backgroundColor={backgroundColor} className={styles.wrapper}>
      {(leftHeader || rightHeader) && (
        <div className={styles.header}>
          <div className="row">
            {leftHeader && (
              <div className={leftHeaderClassNames}>
                <div className={styles.leftHeader}>{leftHeader}</div>
              </div>
            )}
            {rightHeader && (
              <div className={rightHeaderClassNames}>
                <div className={styles.rightHeader}>{rightHeader}</div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-xs-12">
          <div className={styles.title}>{title}</div>
        </div>
      </div>
    </CardWrapper>
  );
}
