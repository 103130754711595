import { UploadedImage } from '../../types';
import { getImgixUrl } from '../../helpers';
import { useRootSelector } from '../../store/hooks';
import { selectTenantConfig } from '../../store/features/tenantConfig';

export function useGetImgixImageUrl(imagePath: UploadedImage['path']) {
  const { id: tenantId } = useRootSelector(selectTenantConfig);

  return getImgixUrl(tenantId, imagePath) as string | undefined;
}
