import styles from './Breadcrumbs.module.scss';
import { Breadcrumb } from '../../../../types';
import { Anchor } from '../../../shared/Anchor';

export interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

export function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  return (
    <ul className={styles.list}>
      {breadcrumbs.map((item) => (
        <li key={item.path} className={styles.listItem}>
          <Anchor to={item.path} tenant={item.tenant} className={styles.anchor}>
            {item.label}
          </Anchor>
        </li>
      ))}
    </ul>
  );
}
