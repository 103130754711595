import React from 'react';
import { useRootSelector } from '../../../../../store/hooks';
import { selectIndustryHeader } from '../../store/features/industryHeader';
import { selectBrandHubOptions } from '../../store/features/options';
import { selectPageConfig } from '../../../../../store/features/pageConfig';
import { selectIndustryHome } from '../../store/features/industryHome';
import StaticPageLayout from '../../layouts/StaticPageLayout';
import { MetaTags } from '../../../shared/MetaTags';
import IndustryHomeHero from './components/IndustryHomeHero';
import IndustryHomeModule from './components/IndustryHomeModule';
import SearchProposition from '../../../shared/SearchProposition';
import { getImgixUrl } from '../../../../../helpers';
import styles from './IndustryHome.module.scss';

export function IndustryHome() {
  const {
    data: { name: hubName },
  } = useRootSelector(selectBrandHubOptions);

  const { name, headerLeft, headerImage, adUnit, networkId } =
    useRootSelector(selectIndustryHeader);

  const { firstFeaturedTopic, secondFeaturedTopic, relatedTopics } =
    useRootSelector(selectIndustryHome);

  const { tenantId } = useRootSelector(selectPageConfig);

  const featuredTopics = [firstFeaturedTopic, secondFeaturedTopic].filter(
    Boolean,
  );

  const thumbnail = headerImage
    ? getImgixUrl(tenantId, headerImage?.path, 'w=1200&h=627')
    : '';

  return (
    <StaticPageLayout adUnit={adUnit} networkId={networkId}>
      <MetaTags
        title={name}
        titleSuffix={hubName}
        description={headerLeft}
        thumbnail={thumbnail}
      />
      <IndustryHomeHero />
      <div className={styles.industryHomeWrapper}>
        <IndustryHomeModule
          title={name}
          text={headerLeft}
          featuredTopics={featuredTopics}
          relatedTopics={relatedTopics}
          withJigsaw
        />
      </div>
      <SearchProposition />
    </StaticPageLayout>
  );
}
