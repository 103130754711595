import { useCallback, useEffect, useState } from 'react';
import Auth from '../../../../../../services/AuthService';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectBrandHubLayout } from '../../../store/features/layout';
import { selectBrandHubOptions } from '../../../store/features/options';
import s from './Profile.module.scss';
import StaticPageLayout from '../../../layouts/StaticPageLayout';
import { MetaTags } from '../../../../shared/MetaTags';
import PublicPageLayout from '../shared/PublicPageLayout';
import Paper from '../shared/Paper';
import { Profile as ProfileType } from '../EditProfile/ProfileForm';
import ProfilePhoto from './ProfilePhoto';
import ProfileDetails from './ProfileDetails';

export function Profile() {
  const {
    data: {
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectBrandHubLayout);
  const {
    data: { name: hubName },
  } = useRootSelector(selectBrandHubOptions);

  const [profile, setProfile] = useState<ProfileType>();

  const fetchProfile = useCallback(async () => {
    try {
      const response = await Auth.fetchAccountDetails('core');
      const data = await response.json();
      setProfile(data.profile?.core || {});
    } catch (error) {
      console.error(error);
    }
  }, []);

  const updateProfile = useCallback(
    async (values: ProfileType) => {
      try {
        const response = await Auth.updateAccountDetails(values);

        if (response.ok) {
          await fetchProfile();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [fetchProfile],
  );

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  return (
    <StaticPageLayout className={s.body}>
      <MetaTags
        title="Profile"
        titleSuffix={hubName}
        thumbnail={brandLogo?.path}
      />
      <PublicPageLayout>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-10 col-lg-offset-1">
              {profile && (
                <Paper heading="My Profile">
                  <div className="row">
                    <div className="col-xs-12 col-sm-4">
                      <div className={s.profilePhoto}>
                        <ProfilePhoto
                          profile={profile}
                          onUpdateProfile={updateProfile}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-8">
                      <ProfileDetails profile={profile} />
                    </div>
                  </div>
                </Paper>
              )}
            </div>
          </div>
        </div>
      </PublicPageLayout>
    </StaticPageLayout>
  );
}
