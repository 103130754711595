import {
  useRef,
  useState,
  ChangeEventHandler,
  MouseEventHandler,
  KeyboardEventHandler,
} from 'react';

export interface UseMagnifyingGlassSearch {
  onSearch: (value: string) => void;
  initialValue?: string;
  openInitially?: boolean;
  autoCloseTimer?: number; // 0 - to disable auto close behaviour
}

export function useMagnifyingGlassSearch(props: UseMagnifyingGlassSearch) {
  const {
    onSearch,
    initialValue = '',
    openInitially = false,
    autoCloseTimer = 5000,
  } = props;
  const [open, setOpen] = useState(openInitially);
  const [searchValue, setSearchValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);
  let closeTimer: NodeJS.Timeout;

  const clearTimer = () => {
    clearTimeout(closeTimer);
  };

  const clearFilter = () => {
    setSearchValue('');
  };

  const focusFilter = () => {
    inputRef.current?.focus();
  };

  const openFilter = () => {
    setOpen(true);
    focusFilter();
  };

  const closeFilter = () => {
    clearTimer();
    setOpen(false);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFocus = () => {
    clearTimer();
  };

  const handleBlur = () => {
    clearTimer();

    if (!searchValue && autoCloseTimer) {
      closeTimer = setTimeout(() => {
        closeFilter();
      }, autoCloseTimer);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    switch (e.key) {
      case 'Enter': {
        closeFilter();
        onSearch(searchValue);
        break;
      }

      case 'Escape': {
        closeFilter();
        break;
      }

      default:
        break;
    }
  };

  const handleSearch: MouseEventHandler<HTMLDivElement> = () => {
    if (open) {
      if (searchValue) {
        closeFilter();
        onSearch(searchValue);
      } else {
        focusFilter();
      }
    } else {
      openFilter();
    }
  };

  const handleClose: MouseEventHandler<HTMLDivElement> = () => {
    if (searchValue) {
      clearFilter();
      focusFilter();
    } else {
      closeFilter();
    }
  };

  return {
    open,
    value: searchValue,
    inputRef,
    handleChange,
    handleFocus,
    handleBlur,
    handleKeyDown,
    handleSearch,
    handleClose,
  } as const;
}
