export const color = {
  primary: '#001F3B',
  accent: '#C617B3',
  highlightBlue: '#2E2DC4',
  highlightGrey: '#D4E0E5',
  backgroundMidGrey: '#525A5C',
  backgroundGreyBlue: '#3B4755',
  backgroundInformaTechBlue: '#40444B',
  lightest: '#FFFFFF',
  lighter: '#F3F3F3',
  mediumlight: '#E1E1E1',
  medium: '#C5C5C5',
  mediumdark: '#2D2D2D',
  dark: '#000000',
  formBorder: '#DEE0E5',
  formText: '#333333',
  formError: '#C9474D',
};

export const brand = {
  darkPurple: '#542650',
  purple: '#68356e',
  lightPurple: '#8f48c2',
  lighterPurple: '#bd10e0',
  green: '#70cb14',
  darkGreen: '#268830',
  lightGreen: '#15cb8e',
  limeGreen: '#31b03f',
  orange: '#f5aa2f',
  red: '#c9474d',
  blue: '#337ab7',
};
