import { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import style from './Heading.module.scss';
import { PropsWithTestId } from '../../../../../../types';

export type HeadingProps = PropsWithTestId<
  PropsWithChildren<HTMLAttributes<HTMLElement>>
>;

export const Heading = (props: HeadingProps) => {
  const { className, children, testId, ...restProps } = props;
  return (
    <h2
      {...restProps}
      data-testid={testId}
      className={cn(style.header, className)}
    >
      {children}
    </h2>
  );
};

Heading.defaultProps = {
  testId: 'hub-module-heading',
};
