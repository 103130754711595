import { createSelector } from '@reduxjs/toolkit';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../store/features/generic';
import { selectSiteHeader } from '../../../../../store/features/siteHeader';
import {
  PricingOrderReceipt,
  PricingOrderReceiptData,
  PricingOrderReceiptParams,
} from './types';
import { fetchSitePricingBookingClosed } from '../bookingClosed';
import { GenericState } from '../../../../../types';
import { selectPageConfig } from '../../../../../store/features/pageConfig';
import { RootState } from '../../../../../store/store';
import { getPackageSelectionDataForGTM } from '../../../helpers/orderSuccess/getPackageSelectionDataForGTM';

export const fetchSitePricingOrderReceipt = createGenericAsyncThunk<
  PricingOrderReceiptData,
  PricingOrderReceiptParams
>(
  'sitePricingOrderReceipt/fetchSitePricingOrderReceipt',
  async (params, { extra, rejectWithValue, dispatch, getState }) => {
    const { orderId } = params;
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.get(
      `/caas/commerce/v1/site/${siteId}/payment/${orderId}/receipt`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data = await response.json();

    if (response.status !== 200) {
      if (response.status === 423) {
        dispatch(fetchSitePricingBookingClosed());
      }

      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const sitePricingOrderReceiptSlice = createGenericSlice({
  name: 'sitePricingOrderReceipt',
  initialState: {
    status: 'idle',
    data: {
      data: {
        ticketPackages: [],
        infoWidgets: {
          items: [],
        },
      } as unknown as PricingOrderReceipt,
    },
  } as GenericState<PricingOrderReceiptData>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<
      PricingOrderReceiptData,
      PricingOrderReceiptParams
    >(builder, fetchSitePricingOrderReceipt);
  },
});

export const selectSitePricingOrderReceiptData = (state: RootState) =>
  state.pricing.orderReceipt.data.data;

export const selectSitePricingOrderReceiptStatusCode = (state: RootState) =>
  state.pricing.orderReceipt.statusCode;

export const selectPricingOrderReceiptPackagesForGTM = createSelector(
  selectSitePricingOrderReceiptData,
  selectSiteHeader,
  ({ ticketPackages }, { data: { title } }) => {
    return ticketPackages.map((ticket) => {
      const { variant, category } = getPackageSelectionDataForGTM(
        ticket.packageSelection,
      );

      return {
        name: title,
        brand: title,
        quantity: ticket.delegates.length,
        variant,
        category,
      };
    });
  },
);

export const { start, fulfilled, rejected } =
  sitePricingOrderReceiptSlice.actions;

export default sitePricingOrderReceiptSlice.reducer;
