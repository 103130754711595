export const whiteListQueryParams = [
  '_mc',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'code',
  'mkt1',
  'mkt2',
  'mkt3',
  'mkt4',
  'mkt5',
  'partnerref',
  'trackingcode',
  'email',
  'asset',
];
