import { combineReducers } from '@reduxjs/toolkit';
import pageReducer from './features/page';
import layoutReducer from './features/layout';
import optionsReducer from './features/options';

const audienceHubReducer = {
  audienceHUB: combineReducers({
    page: pageReducer,
    layout: layoutReducer,
    options: optionsReducer,
  }),
};

export default audienceHubReducer;
