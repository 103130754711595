import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import merge from 'lodash/merge';
import Sticky from 'react-sticky-el';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import gtm from '../../../utils/initialGTM';
import Auth from '../../../services/AuthService';
import InformaRibbon from '../../common/InformaRibbon/InformaRibbon';
import CookieBanner from '../../common/CookieBanner/CookieBanner';
import './HubLayout.scss';

const HubKnect365Header = loadable(() =>
  import(
    /* webpackChunkName: "HubKnect365Header" */ './knect365/HubKnect365Header/HubKnect365Header'
  ),
);
const HubKnect365Footer = loadable(() =>
  import(
    /* webpackChunkName: "HubKnect365Footer" */ './knect365/HubKnect365Footer/HubKnect365Footer'
  ),
);
const HubICHeader = loadable(() =>
  import(
    /* webpackChunkName: "HubICHeader" */ './informaconnect/HubICHeader/HubICHeader'
  ),
);
const HubICFooter = loadable(() =>
  import(
    /* webpackChunkName: "HubICFooter" */ './informaconnect/HubICFooter/HubICFooter'
  ),
);

export class HubLayout extends Component {
  render() {
    const {
      accountWidget = true,
      browseTopicsPopup,
      searchBar,
      staticMenu,
      shortAccountWidget,
      shortStaticMenu,
      disableStickyMainHeader,
      hub: { data: { blocks = [] } = {}, meta: { title } = {} } = {},
      portfolio: { data: { shortName: portfolioShortName } = {} } = {},
      pageConfig: {
        tenantConfig: { outputName } = {},
        canonicalUrl,
        portfolioId,
        tenantId,
        account: { authorized = false } = {},
        sitePath,
        renderError,
      } = {},
      fullHeightChild = false,
      helmet: helmetConfig = {},
    } = this.props;

    const StickyComponent = disableStickyMainHeader
      ? (props) => <>{props.children}</>
      : Sticky;

    const hubHeaderComponent =
      tenantId === 'KNECT365' ? (
        <HubKnect365Header
          portfolio={portfolioId}
          portfolioShort={portfolioShortName}
        />
      ) : (
        <div className="l-hub-sticky">
          <StickyComponent>
            <HubICHeader
              accountWidget={accountWidget}
              sitePath={sitePath}
              authorized={authorized}
              browseTopicsPopup={browseTopicsPopup}
              searchBar={searchBar}
              staticMenu={staticMenu}
              shortStaticMenu={shortStaticMenu}
              shortAccountWidget={shortAccountWidget}
            />
          </StickyComponent>
        </div>
      );

    const hubFooterComponent =
      tenantId === 'KNECT365' ? (
        <HubKnect365Footer mainMenuItems={blocks} portfolioId={portfolioId} />
      ) : (
        <HubICFooter />
      );

    const helmet = merge(
      {},
      {
        canonical: canonicalUrl,
        title: outputName + (title ? ' - ' + title : ''),
        description: '',
        image: '',
        url: canonicalUrl,
        meta: {
          robots: '',
          keywords: '',
        },
      },
      helmetConfig,
      { meta: {}, og: {} },
    );

    return (
      <div className="l-hub">
        <Helmet>
          <title>{helmet.title}</title>
          <meta name="robots" content={helmet.meta.robots} />
          <meta name="keywords" content={helmet.meta.keywords} />
          <meta
            name="description"
            content={helmet.meta.description || helmet.description}
          />
          <meta
            name="thumbnail"
            content={helmet.meta.thumbnail || helmet.image}
          />
          <meta
            name="twitter:card"
            content={helmet.meta['twitter:card'] || 'summary_large_image'}
          />
          {helmet.twitterSite && (
            <meta name="twitter:site" content={helmet.twitterSite} />
          )}
          <meta property="og:title" content={helmet.og.title || helmet.title} />
          <meta
            property="og:description"
            content={helmet.og.description || helmet.description}
          />
          <meta property="og:image" content={helmet.og.image || helmet.image} />
          <meta property="og:type" content={helmet['og.type'] || 'website'} />
          {tenantId === 'INFORMACONNECT' && (
            <meta property="og:site_name" content="Informa Connect" />
          )}
          {!renderError && (
            <meta property="og:url" content={helmet.og.url || helmet.url} />
          )}
          {!renderError && <link rel="canonical" href={helmet.canonical} />}
        </Helmet>
        <div className="l-hub__top">
          <CookieBanner />
          <InformaRibbon />
          {hubHeaderComponent}
        </div>
        <main
          className={`l-hub__content ${
            fullHeightChild ? 'l-hub__content--full-height' : ''
          }`}
        >
          {this.props.children}
        </main>
        <div className="l-hub__bottom">{hubFooterComponent}</div>
      </div>
    );
  }

  componentDidMount() {
    const { 'cognito:username': userId, loggedIn } =
      Auth.getShortAccountDetails();

    if (loggedIn) {
      gtm.pushUserID(userId);
    }
    gtm.isUserLoggedIn(loggedIn);
  }
}

HubLayout.propTypes = {
  hub: PropTypes.object,
  pageConfig: PropTypes.object,
  helmet: PropTypes.object,
  children: PropTypes.node,
  fullHeightChild: PropTypes.bool,
  disableStickyMainHeader: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    hub: state.hub,
    portfolio: state.portfolio,
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(HubLayout);
