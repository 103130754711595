import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Button from '../../shared/Button/Button';
import styles from './ScrollToTop.module.scss';

export type ScrollToTopProps = {
  mobileOffsetFromTop: number;
  desktopOffsetFromTop: number;
};

export default function ScrollToTop(props: ScrollToTopProps) {
  const { desktopOffsetFromTop, mobileOffsetFromTop } = props;
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  const offsetFromTop = isMobile ? mobileOffsetFromTop : desktopOffsetFromTop;

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToTopClassName = cn(styles.container, {
    [styles.visible]: scrollPosition > offsetFromTop,
  });

  const iconClassName = cn('material-icons', styles.icon);

  return (
    <Button
      className={cn('scroll-to-top-button', scrollToTopClassName)}
      onClick={handleScrollToTop}
    >
      <span className="hidden-xs hidden-sm">
        {t('global.scroll-to-top.desktop.label')}
      </span>
      <span className="hidden-md hidden-lg hidden-xl">
        {t('global.scroll-to-top.mobile.label')}
      </span>
      <i className={iconClassName}>arrow_upward</i>
    </Button>
  );
}

ScrollToTop.defaultProps = {
  desktopOffsetFromTop: 1000,
  mobileOffsetFromTop: 700,
};
