import { useMemo } from 'react';
import { useRootSelector } from '../../../../../../store/hooks';
import { Breadcrumbs as HubBreadcrumbs } from '../../../../../headers/ClassicHeader/Breadcrumbs';
import { selectSiteHeader } from '../../../../../../store/features/siteHeader';

export default function Breadcrumbs() {
  const {
    data: { breadcrumbs },
  } = useRootSelector(selectSiteHeader);

  const labeledBreadcrumbs = useMemo(() => {
    return breadcrumbs.map((breadcrumb) => ({
      ...breadcrumb,
      label: breadcrumb.name,
    }));
  }, [breadcrumbs]);

  if (labeledBreadcrumbs.length === 0) return null;

  const [brand, audience] = labeledBreadcrumbs;
  const audienceBreadcrumb = audience
    ? [
        {
          ...audience,
          path: `/${brand.path}/${audience.path}`,
        },
      ]
    : [];

  const breadcrumbsWithPath = [brand, ...audienceBreadcrumb];

  return <HubBreadcrumbs breadcrumbs={breadcrumbsWithPath} />;
}
