import qs from 'qs';
import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';

import {
  determineFilterApplying,
  modifyQueryParamsFromRoute,
  deleteContentTypeFromParams,
  filterNamesByContentType,
} from '../../utils/search';

const contentTypeMapping = {
  all: 'ALL',
  topicHubL2: 'TOPIC_HUB_L2',
  event: 'EVENT',
  course: 'COURSE',
  article: 'ARTICLE',
  speaker: 'SPEAKER',
  agenda: 'AGENDA',
  mediaSite: 'MEDIA_SITE',
  page: 'PAGE',
};

/** Actions **/
export const REQUEST = 'HUB_SEARCH_REQUEST';
export const SILENT_REQUEST = 'HUB_SEARCH_SILENT_REQUEST';
export const SUCCESS = 'HUB_SEARCH_SUCCESS';
export const LOAD_MORE_SUCCESS = 'HUB_SEARCH_LOAD_MORE_SUCCESS';
export const FAILURE = 'HUB_SEARCH_FAILURE';
export const CHANGE_PARAMS = 'HUB_SEARCH_CHANGE_PARAMS';
export const CHANGE_CONTENT_TYPE_PARAMS =
  'HUB_SEARCH_CHANGE_CONTENT_TYPE_PARAMS';
export const CLEAR_PARAMS = 'HUB_SEARCH_CLEAR_PARAMS';
export const DELETE_PARAM = 'HUB_SEARCH_DELETE_PARAM';
export const HANDLE_FILTERS_VISIBILITY = 'HUB_SEARCH_HANDLE_FILTERS_VISIBILITY';

const initialState = {
  loaded: false,
  loading: false,
  statusCode: null,
  isFiltersVisibleUntilDesktop: false,
  data: {
    facets: {},
    records: [],
  },
  params: {
    pageNumber: 1,
    perPage: 100,
    query: '',
    contentType: {
      all: 'all',
    },
  },
};

/** Reducers **/
export default function hubSearchReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case SILENT_REQUEST:
      return Object.assign({}, state, {
        silentLoading: true,
      });

    case SUCCESS:
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        silentLoading: false,
        statusCode: action.data.statusCode,
        data: action.data.json,
      });

    case LOAD_MORE_SUCCESS: {
      const currentContentType =
        state.params.contentType && Object.values(state.params.contentType)[0];
      // we use this reducer for "load more" functionality by merge fetched and stored data
      // fetched data
      const { records: fetchRecords = [] } = action.data.json;
      const fetchContentTypeData = fetchRecords.length
        ? fetchRecords.find(
            (fetchRecord) =>
              fetchRecord.type === currentContentType.toUpperCase(),
          )
        : {};
      const { results: fetchResults = [] } = fetchContentTypeData;
      // stored redux data
      const { records: storeRecords = [], facets } = cloneDeep(state.data);
      const storeContentTypeData = storeRecords.length
        ? storeRecords.find(
            (storeRecord) =>
              storeRecord.type === currentContentType.toUpperCase(),
          )
        : {};
      const { results: storeResults = [] } = storeContentTypeData;
      // merged data which is based on fetched and stored content type data
      const mergedSearchData = {
        records: [
          {
            ...storeContentTypeData,
            results: storeResults.concat(fetchResults),
          },
        ],
        facets,
      };
      return Object.assign({}, state, {
        loaded: true,
        loading: false,
        silentLoading: false,
        statusCode: action.data.statusCode,
        data: { ...mergedSearchData },
      });
    }

    case FAILURE:
      return Object.assign(
        {},
        {
          params: state.params,
          loaded: false,
          loading: false,
          silentLoading: false,
          statusCode: action.data.statusCode,
          data: {},
        },
      );

    case CHANGE_PARAMS:
      return Object.assign({}, state, {
        params: { ...state.params, ...action.data },
      });

    case CHANGE_CONTENT_TYPE_PARAMS:
      return Object.assign({}, state, {
        params: action.data,
      });

    case CLEAR_PARAMS:
      return Object.assign({}, state, {
        params: Object.assign({}, initialState.params, {
          query: state.params.query,
        }),
      });

    case DELETE_PARAM:
      state.params[action.data.name] && delete state.params[action.data.name];
      return Object.assign({}, state, {
        params: { ...state.params },
      });

    case HANDLE_FILTERS_VISIBILITY:
      return Object.assign({}, state, {
        isFiltersVisibleUntilDesktop: !state.isFiltersVisibleUntilDesktop,
      });

    default:
      return state;
  }
}

/** Action Creators **/
export function fetchHubSearchRequest(data) {
  return { type: REQUEST, data };
}

export function fetchHubSearchSilentRequest(data) {
  return { type: SILENT_REQUEST, data };
}

export function fetchHubSearchSuccess(data) {
  return { type: SUCCESS, data };
}

export function loadMoreHubSearchSuccess(data) {
  return { type: LOAD_MORE_SUCCESS, data };
}

export function fetchHubSearchFailure(data) {
  return { type: FAILURE, data };
}

export function changeFilterParam(data) {
  return { type: CHANGE_PARAMS, data };
}

export function setFilterParams(data) {
  return { type: CHANGE_CONTENT_TYPE_PARAMS, data };
}

export function clearFilterParams(data) {
  return { type: CLEAR_PARAMS, data };
}

export function deleteFilterParam(data) {
  return { type: DELETE_PARAM, data };
}

export function handleFiltersVisibility() {
  return { type: HANDLE_FILTERS_VISIBILITY };
}

/** Side effects **/
export function fetchHubSearchAction(payload = {}) {
  const {
    history = {},
    queryParamsFromRoute = '',
    isInitialLoading = false,
    isRouteNavigation = false,
    isLoadMore = false,
    silent = false,
  } = payload;

  return function (dispatch, getState, { Api }) {
    // setting loading flag for different entities of search
    // silent flag is for search "load more" functionality
    if (silent) {
      dispatch(fetchHubSearchSilentRequest());
    } else {
      dispatch(fetchHubSearchRequest());
    }

    const state = getState();
    let queryParams;

    // setting filters before fetching data
    // in case with initial loading or navigation by route
    if ((isInitialLoading || isRouteNavigation) && queryParamsFromRoute) {
      const parseDataFromRoute = Object.assign(
        {},
        initialState.params,
        modifyQueryParamsFromRoute(queryParamsFromRoute),
      );
      queryParams = `?${qs.stringify(
        deleteContentTypeFromParams(parseDataFromRoute),
      )}`;

      dispatch(setFilterParams(parseDataFromRoute));
    } else {
      queryParams = `?${qs.stringify(
        deleteContentTypeFromParams(state.hubSearch.params),
      )}`;
      !isInitialLoading && !isRouteNavigation && history.push(queryParams);
    }

    return Api()
      .service('DiscoveryService')
      .version('v1')
      .fetchDiscoveryContent(queryParams)
      .then((response) => {
        if (response.statusCode === 200) {
          return isLoadMore
            ? dispatch(loadMoreHubSearchSuccess(response))
            : dispatch(fetchHubSearchSuccess(response));
        } else {
          return dispatch(fetchHubSearchFailure(response));
        }
      });
  };
}

export function changeFilterParamAction(data, actionSettings = {}) {
  const {
    isFetchData = true,
    history = {},
    isContentType = false,
    isLoadMore = false,
    silent = false,
  } = actionSettings;
  return function (dispatch, getState) {
    if (isContentType) {
      const {
        hubSearch: { params: { query } = {} },
      } = getState();
      const modifyData = Object.assign({}, initialState.params, data, {
        query,
      });
      dispatch(setFilterParams(modifyData));
    } else {
      dispatch(changeFilterParam(data));
    }

    if (isFetchData) {
      dispatch(fetchHubSearchAction({ history, isLoadMore, silent }));
    }
  };
}

export function clearFilterParamsAction(history = {}) {
  return function (dispatch) {
    dispatch(clearFilterParams());
    dispatch(fetchHubSearchAction({ history }));
  };
}

export function deleteFilterParamAction(data, actionSettings = {}) {
  const { isFetchData = true, history = {} } = actionSettings;

  return function (dispatch) {
    dispatch(deleteFilterParam(data));
    if (isFetchData) {
      dispatch(fetchHubSearchAction({ history }));
    }
  };
}

/** Selectors **/
export const getContentTypeDataSelector = createSelector(
  (state) => state.hubSearch,
  (hubSearch) => {
    const {
      params: { contentType = {} } = {},
      data: { facets: { CONTENT_TYPE = [] } = {} } = {},
    } = hubSearch;
    const currentContentType = contentType && Object.values(contentType)[0];
    const ContentTypeResult = CONTENT_TYPE.filter((contentType) => {
      return contentType.type === contentTypeMapping[currentContentType];
    });

    return ContentTypeResult.length ? ContentTypeResult[0] : {};
  },
);

export const detectFiltersApplying = createSelector(
  (state) => state.hubSearch?.params,
  (state) => state.hubSearch?.data?.facets,
  (params = {}, facets = {}) => {
    const filtersList = Object.keys(facets);

    return filtersList.some((filterName) =>
      determineFilterApplying(params, filterNamesByContentType[filterName]),
    );
  },
);
