import React from 'react';
import { connect } from 'react-redux';

const MenuItemGroup = ({ WrappingComponent, menuItems, pageConfig }) => {
  return menuItems.map(({ title, href, brand, type, withSitePath }, index) => {
    return (
      <WrappingComponent
        key={index}
        type={type}
        link={
          withSitePath && !pageConfig.siteMaskingPath
            ? '/' + pageConfig.sitePath + href
            : href
        }
        content={title}
        brand={brand}
      />
    );
  });
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(MenuItemGroup);
