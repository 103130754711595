import React from 'react';
import styled, { css } from 'styled-components';
import { typography } from '../../styles/informaconnect/typography/typography';
import CallToAction from '../CallToAction';

const CTA = styled(CallToAction)`
  & {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 40px;
    max-height: 40px;
    text-align: center;
    border-radius: 20px;
    font-weight: bold;
    line-height: 22px;
    font-family: ${typography.type.secondary};
    border: 0;
    padding: 6px 0 4px;
    transition: all 0.2s linear;
    font-size: 18px;
    font-size: ${(props) => props.fontSize};
    background-color: #cc31c2;
    color: #ffffff;
    background-color: ${(props) => props.colours.activeBG};
    color: ${(props) => props.colours.activeText};

    .c-spinner {
      bottom: 2px;
    }

    &.disabled,
    &.disabled:hover {
      background: #cccccc;
      color: #ffffff;
      background: ${(props) => props.colours.inactiveBG};
      color: ${(props) => props.colours.inactiveText};
      ${(props) =>
        props.isActive &&
        css`
          background-color: #cc31c2 !important;
          color: #ffffff !important;
          background-color: ${(props) => props.colours.activeBG} !important;
          color: ${(props) => props.colours.activeText} !important;
        `}
    }

    ${(props) =>
      props.icon &&
      css`
        &:after {
          content: '${(props) => props.icon}';
          color: #ffffff;
          color: '${(props) => props.colours.activeText}';
          font-family: ${typography.type.materialIcons};
          font-weight: normal;
          font-style: normal;
          display: inline-block;
          line-height: 1;
          white-space: nowrap;
          direction: ltr;
          position: absolute;
          top: 11px;
          padding-left: 6px;
          font-size: ${(props) => props.fontSize};
          font-feature-settings: 'liga';
        }

        && {
          padding-right: 36px;
          min-width: 200px;
        }
      `}

    ${(props) =>
      props.elementType === 'button' &&
      css`
        padding-top: 8px;
      `}
  }
`;

const CurvedCTA = (props) => {
  const { colours, className } = props;
  return <CTA {...props} colours={colours} className={className} />;
};

export default CurvedCTA;
