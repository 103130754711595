import { createSelector } from '@reduxjs/toolkit';
import {
  GenericState,
  HubLayout,
  UniqueIdentifier,
} from '../../../../../../types';
import { RootState } from '../../../../../../store/store';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../../store/features/generic';

export interface AudienceHubLayout extends HubLayout {}

export interface AudienceHubLayoutParams {
  audienceHubId: UniqueIdentifier;
}

export const fetchAudienceHubLayout = createGenericAsyncThunk<
  AudienceHubLayout,
  AudienceHubLayoutParams
>(
  'audienceHubLayout/fetchAudienceHubLayout',
  async ({ audienceHubId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/audience-home/${audienceHubId}/layout`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: AudienceHubLayout = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const audienceHubLayoutSlice = createGenericSlice({
  name: 'audienceHubLayout',
  initialState: {
    status: 'idle',
    data: {},
  } as GenericState<AudienceHubLayout>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<AudienceHubLayout, AudienceHubLayoutParams>(
      builder,
      fetchAudienceHubLayout,
    );
  },
});

export const selectAudienceHubLayout = createSelector(
  [(state: RootState) => state.audienceHUB.layout],
  (data) => data,
);

export const { start, fulfilled, rejected } = audienceHubLayoutSlice.actions;

export default audienceHubLayoutSlice.reducer;
