import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { renderStyledElementStyles, devices } from '../../../../helpers';

import { Wrapper as LogoWrapper } from '../Logo';
import { Wrapper as PartnerLogoWrapper } from '../PartnerLogo';
import { Wrapper as TimerWrapper } from '../Timer';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  @media only screen and (max-width: ${devices.breakpoints.tablet}) {
    justify-content: space-between;
  }

  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.siteLayout.siteHeader.builder?.elements?.builderWrapper,
    );
  }}

  ${LogoWrapper} {
    img {
      ${({ theme }) => {
        return renderStyledElementStyles(
          theme,
          theme.siteLayout.siteHeader.builder?.elements?.builderWrapper
            ?.elements?.logo,
        );
      }}
    }
  }

  ${PartnerLogoWrapper} {
    ${({ theme }) =>
      theme.globalVariables.isRTL
        ? css`
            margin-right: 0;
          `
        : css`
            margin-left: 0;
          `};
    @media only screen and (min-width: ${devices.breakpoints.tablet}) {
      ${({ theme }) =>
        theme.globalVariables.isRTL
          ? css`
              margin-right: 40px;
            `
          : css`
              margin-left: 40px;
            `};
    }

    img {
      ${({ theme }) => {
        return renderStyledElementStyles(
          theme,
          theme.siteLayout.siteHeader.builder?.elements?.builderWrapper
            ?.elements?.partnerLogo,
        );
      }}
    }
  }

  ${TimerWrapper} {
    ${({ theme }) =>
      theme.globalVariables.isRTL
        ? css`
            margin-right: 12px;
          `
        : css`
            margin-left: 12px;
          `};
    @media only screen and (min-width: ${devices.breakpoints.tablet}) {
      ${({ theme }) =>
        theme.globalVariables.isRTL
          ? css`
              margin-right: 42px;
            `
          : css`
              margin-left: 42px;
            `};
    }
  }
`;

export const BuilderWrapper = ({ children, testId }) => {
  return (
    <Wrapper
      className="c-site-header-components-container"
      data-testid={testId}
    >
      {children}
    </Wrapper>
  );
};

BuilderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string,
};

BuilderWrapper.defaultProps = {
  testId: 'header-builder-wrapper',
};

export default BuilderWrapper;
