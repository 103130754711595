import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './BaseSectionTitle.scss';

export default function BaseSectionTitle(props) {
  const { text, h1, h3, styled, small, sentenceCase, className, style } = props;

  if (!text) {
    return null;
  }

  const componentClassNames = classNames('c-base-section-title', {
    'c-base-section-title--styled': h1 || styled,
    'c-base-section-title--small': small,
    'c-base-section-title--sentence-case': sentenceCase,
    [className]: className,
  });

  return h1 ? (
    <h1 className={componentClassNames} style={style}>
      {text}
    </h1>
  ) : h3 ? (
    <h3 className={componentClassNames} style={style}>
      {text}
    </h3>
  ) : (
    <h2 className={componentClassNames} style={style}>
      {text}
    </h2>
  );
}

BaseSectionTitle.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  h1: PropTypes.bool,
  h3: PropTypes.bool,
  styled: PropTypes.bool,
  small: PropTypes.bool,
  sentenceCase: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};
