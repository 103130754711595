import { SyntheticEvent } from 'react';
import cn from 'classnames';
import { useRootSelector } from '../../../../../../../store/hooks';
import { selectPageConfig } from '../../../../../../../store/features/pageConfig';
import styles from './ShareBar.module.scss';

export interface SocialMediaItemConfig {
  faIcon: string;
  params: string[];
}

interface SocialMediaConfig {
  [key: string]: SocialMediaItemConfig;
}

export function getShareUrl(
  canonicalUrl: string,
  socialMediaItemConfig: SocialMediaItemConfig,
) {
  const encodedParams = socialMediaItemConfig.params
    .map((param) => encodeURIComponent(param))
    .join('&');

  return `${canonicalUrl}?${encodedParams}`;
}

export interface ShareItemProps {
  canonicalUrl: string;
  socialMediaItemConfig: SocialMediaItemConfig;
}

export function LinkedIn(props: ShareItemProps) {
  const { canonicalUrl, socialMediaItemConfig } = props;

  const url = getShareUrl(canonicalUrl, socialMediaItemConfig);

  return (
    <a href={`https://www.linkedin.com/shareArticle?url=${url}`}>
      <i className={cn(socialMediaItemConfig.faIcon, 'linkedin')} />
    </a>
  );
}

export function Twitter(props: ShareItemProps & { text?: string }) {
  const { canonicalUrl, socialMediaItemConfig, text = '' } = props;

  const url = getShareUrl(canonicalUrl, socialMediaItemConfig);
  const encodedText = encodeURIComponent(text);

  return (
    <a href={`https://twitter.com/intent/tweet?text=${encodedText}&url=${url}`}>
      <i className={cn(socialMediaItemConfig.faIcon, 'twitter')} />
    </a>
  );
}

export function Facebook(props: ShareItemProps) {
  const { canonicalUrl, socialMediaItemConfig } = props;

  const url = getShareUrl(canonicalUrl, socialMediaItemConfig);

  return (
    <a href={`https://www.facebook.com/sharer.php?u=${url}`}>
      <i className={cn(socialMediaItemConfig.faIcon, 'facebook')} />
    </a>
  );
}

export function Email(props: ShareItemProps & { mailToUrl: string }) {
  const { canonicalUrl, socialMediaItemConfig, mailToUrl } = props;

  const url = getShareUrl(canonicalUrl, socialMediaItemConfig);

  return (
    <a href={`${mailToUrl}${url}`}>
      <i className={cn(socialMediaItemConfig.faIcon, 'email')} />
    </a>
  );
}

export function CopyToClipboard(props: ShareItemProps) {
  const { canonicalUrl, socialMediaItemConfig } = props;

  const url = getShareUrl(canonicalUrl, socialMediaItemConfig);

  const copyHandler = (event: SyntheticEvent) => {
    event.preventDefault();
    void navigator.clipboard.writeText(url);
  };

  return (
    <a href="/" onClick={copyHandler}>
      <i className={cn(socialMediaItemConfig.faIcon, 'link')} />
    </a>
  );
}

export interface ShareBarProps {
  socialMediaConfig: SocialMediaConfig;
  text?: string;
  mailToUrl?: string;
  label?: string;
}

export function ShareBar(props: ShareBarProps) {
  const { label, socialMediaConfig, text, mailToUrl } = props;
  const {
    pageConfig: { canonicalUrl },
  } = useRootSelector((state) => ({
    pageConfig: selectPageConfig(state),
  }));

  return (
    <div className={styles.wrapper}>
      {label && <h5 className={styles.label}>{label}</h5>}
      <div className={styles.body}>
        <LinkedIn
          canonicalUrl={canonicalUrl}
          socialMediaItemConfig={socialMediaConfig.linkedIn}
        />
        <Twitter
          canonicalUrl={canonicalUrl}
          socialMediaItemConfig={socialMediaConfig.twitter}
          text={text}
        />
        <Facebook
          canonicalUrl={canonicalUrl}
          socialMediaItemConfig={socialMediaConfig.facebook}
        />
        {mailToUrl && (
          <Email
            canonicalUrl={canonicalUrl}
            socialMediaItemConfig={socialMediaConfig.email}
            mailToUrl={mailToUrl}
          />
        )}
        <CopyToClipboard
          canonicalUrl={canonicalUrl}
          socialMediaItemConfig={socialMediaConfig.link}
        />
      </div>
    </div>
  );
}
