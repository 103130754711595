import loadable from '@loadable/component';
import { HubHomePage } from '../../../../../types';
import { useRootSelector } from '../../../../../store/hooks';
import { selectAudienceHubPage } from '../../store/features/page';
import { selectAudienceHubOptions } from '../../store/features/options';
import PageLayout from '../../layouts/PageLayout';
import { MetaTags } from '../../../shared/MetaTags';
import { HubModuleBuilder } from '../../../shared/siteBuilder/HubModuleBuilder';
import ErrorBoundary from '../../../../common/ErrorBoundary';

const HubHeroModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubHeroModule" */ '../../../shared/siteBuilder/modules/HeroModule'
    ),
);
const FloatingEmailOptInModule = loadable(
  () =>
    import(
      /* webpackChunkName: "FloatingEmailOptInModule" */ '../../../shared/FloatingEmailOptInModule'
    ),
);

export function Home() {
  const {
    data: {
      home: {
        modules,
        details: { title, metaDescription },
      },
      header: {
        heroBanner: { inlineLogo, ...heroBanner },
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectAudienceHubPage<HubHomePage>());
  const { data: options } = useRootSelector(selectAudienceHubOptions);
  const { name: hubName, newsletterSignUp, promotionalSignUp } = options;

  const heading = title || 'Home';
  const emailOptInEnabled =
    newsletterSignUp.homeEnabled || promotionalSignUp.homeEnabled;

  return (
    <PageLayout>
      <MetaTags
        title={heading}
        titleSuffix={hubName}
        description={metaDescription}
        thumbnail={brandLogo?.path}
      />
      {emailOptInEnabled && (
        <ErrorBoundary>
          <FloatingEmailOptInModule
            pageType="home"
            permissionTextName={hubName}
            hubOptions={options}
          />
        </ErrorBoundary>
      )}
      <HubHeroModule module={heroBanner} home />
      {modules.map((module, index) => (
        <ErrorBoundary key={index}>
          <HubModuleBuilder module={module} home />
        </ErrorBoundary>
      ))}
    </PageLayout>
  );
}
