import { useRootSelector } from '../../../../../store/hooks';
import { selectBrandHubLayout } from '../../store/features/layout';
import { selectBrandHubOptions } from '../../store/features/options';
import s from './NotFound.module.scss';
import StaticPageLayout from '../../layouts/StaticPageLayout';
import { MetaTags } from '../../../shared/MetaTags';
import PageNotFound from '../../../shared/PageNotFound';

export default function NotFound() {
  const {
    data: {
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectBrandHubLayout);
  const {
    data: { name: hubName },
  } = useRootSelector(selectBrandHubOptions);

  return (
    <StaticPageLayout className={s.body}>
      <MetaTags
        title="Not Found"
        titleSuffix={hubName}
        thumbnail={brandLogo?.path}
      />
      <PageNotFound />
    </StaticPageLayout>
  );
}
