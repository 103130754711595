import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LeadGenCheckbox from '../LeadGenCheckbox/LeadGenCheckbox';

import './LeadGenCheckboxAdHoc.scss';

export default class LeadGenCheckboxAdHoc extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    color: PropTypes.string,
    isCheckboxGroup: PropTypes.bool,
    meta: PropTypes.object,
    isRequired: PropTypes.bool,
  };

  generateCheckboxGroup(input, color, isCheckboxGroup, isError, options = []) {
    return options.map(({ label, value }, index) => {
      const isChecked = input.value.includes(value);
      return (
        <div
          className="lead-gen-checkbox-ad-hoc__item"
          key={value}
          data-test-lead-gen="lead-gen-checkbox-ad-hoc-item"
        >
          <LeadGenCheckbox
            value={value}
            isCheckboxGroup={isCheckboxGroup}
            label={label}
            index={index}
            input={input}
            isChecked={isChecked}
            color={color}
            isError={isError}
          />
        </div>
      );
    });
  }

  render() {
    const {
      meta = {},
      isRequired,
      input = {},
      options = [],
      color,
      label = '',
      isCheckboxGroup,
    } = this.props;
    const isError = meta.error && meta.touched;

    return (
      <div
        className="lead-gen-checkbox-ad-hoc"
        data-test-lead-gen="lead-gen-checkbox-ad-hoc"
      >
        <div className="lead-gen-checkbox-ad-hoc__header">
          <p
            className="lead-gen-checkbox-ad-hoc__title"
            data-test-lead-gen="lead-gen-checkbox-ad-hoc-title"
          >
            {label}
            {isRequired ? ' *' : ''}
          </p>
          {isError && (
            <div
              className="lead-gen-checkbox-ad-hoc__error"
              data-test-lead-gen="lead-gen-checkbox-ad-hoc-error"
            >
              {meta.error}
            </div>
          )}
        </div>
        <div className="lead-gen-checkbox-ad-hoc__group">
          {this.generateCheckboxGroup(
            input,
            color,
            isCheckboxGroup,
            isError,
            options,
          )}
        </div>
      </div>
    );
  }
}
