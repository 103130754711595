import { useMemo } from 'react';
import { HubBasePage } from '../../../../../types';
import { DiscoveryContentTypes } from '../../../../../constants';
import { useRootSelector } from '../../../../../store/hooks';
import { selectAudienceHubPage } from '../../store/features/page';
import { selectAudienceHubOptions } from '../../store/features/options';
import {
  selectHubDiscoveryArticleRecord,
  selectHubDiscoveryFacets,
  DiscoveryArticle,
  HubPageNames,
  SortTypes,
} from '../../../../../store/features/discovery';
import PageLayout from '../../layouts/PageLayout';
import { MetaTags } from '../../../shared/MetaTags';
import { ArticleCard } from '../../../shared/productFilter/cards';
import { AggregatedProductPage } from '../../../shared/siteBuilder/AggregatedProductPage';

export function Articles() {
  const {
    data: {
      page: {
        details: { title, description, metaDescription, searchQueryData },
      },
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectAudienceHubPage<HubBasePage>());
  const {
    data: { name: hubName, subBrandName: brandName, l2Topic: topicName },
  } = useRootSelector(selectAudienceHubOptions);
  const facets = useRootSelector(selectHubDiscoveryFacets);
  const record = useRootSelector(selectHubDiscoveryArticleRecord);

  const heading = title || 'News & Insights';

  const defaultValues = useMemo(
    () => ({
      subBrands: [brandName],
      sectors: [],
      topics: [],
      availableSubBrands: [brandName],
      mainFilterTopic: topicName,
      requestedSortType: SortTypes.FEATURED,
      page: 1,
      count: 20,
      searchInput: '',
      type: [DiscoveryContentTypes.ARTICLE],
    }),
    [brandName, topicName],
  );

  return (
    <PageLayout>
      <MetaTags
        title={heading}
        titleSuffix={hubName}
        description={metaDescription}
        thumbnail={brandLogo?.path}
      />
      <AggregatedProductPage<DiscoveryArticle>
        pageName={HubPageNames.articles}
        contentType={DiscoveryContentTypes.ARTICLE}
        title={heading}
        subtitle={description}
        facets={facets}
        record={record}
        renderProduct={(product) => (
          <ArticleCard data={product} cardType="list" />
        )}
        defaultValues={defaultValues}
        searchQueryData={searchQueryData}
      />
    </PageLayout>
  );
}
