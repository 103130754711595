import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { allowScroll, preventScroll } from '../../../../../helpers';
import './Hamburger.scss';

export default function Hamburger(props) {
  const [mobileMenuOpened, setMobileMenuState] = useState(false);

  const onHamburgerClick = () => {
    const active = !mobileMenuOpened;
    setMobileMenuState(active);

    if (active) {
      preventScroll();
      document.body.classList.add('multi-level-nav-opened');
    } else {
      allowScroll();
      document.body.classList.remove('multi-level-nav-opened');
    }
  };

  const { align = 'right', withWord, children } = props;
  const { t } = useTranslation();

  const componentClassNames = classNames('c-hamburger', {
    [`c-hamburger--${align}`]: align,
    'c-hamburger--with-word': withWord,
    'c-hamburger--active': mobileMenuOpened,
  });

  return (
    <>
      <span className={componentClassNames} onClick={onHamburgerClick}>
        {[1, 2, 3].map((index) => (
          <span key={index} className="c-hamburger__line" />
        ))}
      </span>
      {withWord && (
        <div
          role="button"
          className="c-hamburger__word"
          onClick={onHamburgerClick}
        >
          {mobileMenuOpened ? t('event.close') : t('event.menu')}
        </div>
      )}
      {mobileMenuOpened && children}
    </>
  );
}

Hamburger.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  withWord: PropTypes.bool,
};
