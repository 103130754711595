import React from 'react';
import { connect } from 'react-redux';
import { renderCSSString } from '../../../../../helpers';

export function DefineBrandStyles(props) {
  const {
    pageConfig: {
      tenantConfig: {
        ui: { theme },
      },
    },
    brand: {
      primaryColor,
      secondaryColor,
      accentColor,
      getCustomAccentColor,
    } = {},
  } = props;

  let randomStyledElements = null;

  if (theme === 'FanExpoHQ' || theme === 'InteriorDesignShow') {
    randomStyledElements = renderCSSString(`
        .pswp__button--close {
          background-color: ${accentColor};
        }
      `);
  }

  let callToActionLook;
  if (theme === 'NajahStack') {
    callToActionLook = renderCSSString(`
    .c-cta--primary:hover {
      background-color: ${primaryColor};
    }

    .c-cta--secondary:hover {
      background-color: ${primaryColor};
    }

    .c-cta--accent:hover {
      background-color: ${primaryColor};
    }
  `);
  } else {
    callToActionLook = renderCSSString(`
    .c-cta--primary,
    .c-cta--primary:hover {
      background-color: ${primaryColor};
    }

    .c-cta--secondary,
    .c-cta--secondary:hover {
      background-color: ${secondaryColor};
    }

    .c-cta--accent,
    .c-cta--accent:hover {
      background-color: ${accentColor};
      color: rgba(0, 0, 0, 0.8);
    }
  `);
  }

  let callToActionMode = null;

  if (theme === 'FanExpoHQ' || theme === 'InteriorDesignShow') {
    callToActionMode = renderCSSString(`
        .c-cta--black,
        .c-cta--black:hover,
        .c-cta--black-on-primary,
        .c-cta--black-on-primary:hover {
          background-color: transparent;
          border-color: black;
          color: black;
        }

        .c-cta--black:hover {
          background-color: ${accentColor};
        }
        
        .c-cta--black-on-primary:hover {
          background-color: white;
        }

        .c-cta--white,
        .c-cta--white:hover {
          background-color: transparent;
          border-color: white;
          color: white;
        }

        .c-cta--white:hover {
          background-color: ${accentColor};
        }

        .c-cta--color,
        .c-cta--color:hover {
          background-color: transparent;
          border-color: ${accentColor};
          color: ${accentColor};
        }

        .c-cta--color:hover {
          background-color: white;
        }

        .c-cta--styled:hover {
          background-color: white;
          border-color: black;
          color: black;
        }
      `);
  }

  if (theme === 'OneOfAKind') {
    callToActionMode = renderCSSString(`
        .c-cta--white-on-black,
        .c-cta--white-on-black:hover {
          background-color: #d9b8d7 !important;
        }
      `);
  }

  return (
    <>
      {randomStyledElements}
      {callToActionLook}
      {callToActionMode}
      {renderCSSString(`
        :root {
         --site-color-scheme-primary-main: ${primaryColor};
         --site-color-scheme-secondary-main: ${secondaryColor};
         --site-color-scheme-accent-main: ${accentColor};
        }
        .fc-primary {
          color: ${primaryColor};
        }
        .fc-secondary {
          color: ${secondaryColor};
        }
        .fc-accent {
          color: ${accentColor};
        }
        .bc-primary {
          background-color: ${primaryColor};
        }
        .bc-secondary {
          background-color: ${secondaryColor};
        }
        .bc-accent {
          background-color: ${accentColor};
        }
        .bc-accent-30 {
          background-color: ${getCustomAccentColor(30)};
        }
      `)}
    </>
  );
}

export default connect((state) => ({
  pageConfig: state.pageConfig,
}))(DefineBrandStyles);
