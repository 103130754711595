import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './LeadGenInput.scss';

export default class LeadGenInput extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    inputType: PropTypes.string.isRequired,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    isButtonShown: PropTypes.bool,
    customId: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    isRequired: false,
    isButtonShown: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    };
  }

  handleClick = () => {
    this.setState((state) => {
      return {
        showPassword: !state.showPassword,
      };
    });
  };

  handleOnblur = ({ target }) => {
    const { input = {} } = this.props;
    // if focus of element is lost we will trim all unnecessary spaces in the value
    target.value && input.onChange(target.value.trim());
    input.onBlur();
  };

  renderInput(
    input,
    inputType,
    inputErrorClass,
    isButtonShown,
    customId,
    preReqClass,
    autofill,
  ) {
    const showHideBtnName = this.state.showPassword ? 'Hide' : 'Show';
    const passInputType = this.state.showPassword ? 'text' : 'password';
    const autoComplete = autofill ? input.value : undefined;

    if (inputType === 'password') {
      return (
        <div
          className="lead-gen-input__password-wrap"
          data-test-lead-gen="lead-gen-input-password-wrap"
        >
          <input
            id={customId || input.name.replace(/profile./gi, '')}
            type={passInputType}
            autoComplete={autoComplete}
            className={`lead-gen-input__password ${inputErrorClass}`}
            {...input}
            data-test-lead-gen="lead-gen-input-password"
          />
          {isButtonShown && (
            <div
              onClick={this.handleClick}
              className="lead-gen-input__password-show"
              data-test-lead-gen="lead-gen-input-password-show"
            >
              {showHideBtnName}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <input
          id={customId || input.name.replace(/profile./gi, '')}
          type={inputType}
          autoComplete={autoComplete}
          className={`lead-gen-input__text ${inputErrorClass} ${preReqClass}`}
          {...input}
          onBlur={this.handleOnblur}
          data-test-lead-gen="lead-gen-input-text"
        />
      );
    }
  }

  render() {
    const {
      input,
      label,
      isRequired,
      inputType,
      isButtonShown,
      customId = '',
      meta: { error, touched } = {},
      isUpdate = false,
      autofill = false,
    } = this.props;

    const isError = error && touched;
    const inputErrorClass = isError ? 'lead-gen-input__error' : '';

    let labelPreReqClass = '';
    let inputPreReqClass = '';
    let isPreReq = false;

    if (isUpdate) {
      isPreReq = isRequired && !touched && !input.value && !isError;
      inputPreReqClass = isPreReq ? 'lead-gen-input__pre-req' : '';
      labelPreReqClass = isPreReq ? 'lead-gen-input__label-pre-req' : '';
    }

    return (
      <div className="lead-gen-input" data-test-lead-gen="lead-gen-input">
        <div className="lead-gen-input__label">
          <label
            className="lead-gen-input__label-name"
            data-test-lead-gen="lead-gen-input-label-name"
          >
            {label}
            {isRequired ? ' *' : ''}
          </label>
          {isPreReq && !isError && (
            <div className={`lead-gen-input__label-error ${labelPreReqClass}`}>
              Required
            </div>
          )}
          {isError && (
            <div className="lead-gen-input__label-error">{error}</div>
          )}
        </div>
        {this.renderInput(
          input,
          inputType,
          inputErrorClass,
          isButtonShown,
          customId,
          inputPreReqClass,
          autofill,
        )}
      </div>
    );
  }
}
