import qs from 'qs';
import { whiteListQueryParams } from '../../helpers/whiteListQueryParams';

export const deleteForbiddenQueryParams = (queryParams = {}) => {
  Object.keys(queryParams).forEach((query) => {
    if (!whiteListQueryParams.includes(query)) {
      delete queryParams[query];
    }
  });

  return queryParams;
};

export const mergeQueryParamsForIframeUrl = (
  iFrameUrl,
  iFramePath,
  queryFromSiteUrl,
) => {
  const queryFromAdmin = iFramePath.match(/\?(.*)/) || [];
  const parsedQPFromAdmin = qs.parse(queryFromAdmin[0], {
    ignoreQueryPrefix: true,
  });
  const parsedQPFromUrl = qs.parse(queryFromSiteUrl, {
    ignoreQueryPrefix: true,
  });
  const targetQueryParams = qs.stringify(
    {
      ...parsedQPFromAdmin,
      ...deleteForbiddenQueryParams(parsedQPFromUrl),
    },
    {
      addQueryPrefix: true,
    },
  );

  return iFrameUrl.replace(/\?.*|$/, targetQueryParams);
};

export const addUserDataToIframeUrl = (iFrameUrl, email) => {
  return iFrameUrl.replace(/\/+$/, '').concat(`/${email}/`);
};

export const getQueryParamsFromStorage = (siteId) => {
  const iFrameProxyParamsList = JSON.parse(
    localStorage.getItem('iFrameProxyParams'),
  );
  return (iFrameProxyParamsList && iFrameProxyParamsList[siteId]) || {};
};
