import formattedTime from './formattedTime';
import getSessions from './getSessions';
import getEventDates from './getEventDates';
import testResizeWindow from './testResizeWindow';
import highlights from './highlights';
import getLocaleContent from './getLocaleContent';
import addScriptTag from './addScriptTag';

const utils = {
  formattedTime,
  getSessions,
  getEventDates,
  testResizeWindow,
  highlights,
  getLocaleContent,
  addScriptTag,
};

export default utils;
