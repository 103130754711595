import Auth from '../services/AuthService';

export default function getPOneUserEvents() {
  return Auth.getAccountDetails({ p1: true })
    .then((response) => response.json())
    .then((response) => response.profile?.p1 || {})
    .then((profileData) => {
      const p1UserEvents = profileData?.events
        ? profileData.events.map((event) => event.eventId)
        : [];
      localStorage.setItem('p1Events', JSON.stringify(p1UserEvents));

      return p1UserEvents;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}
