import React from 'react';
import styled from 'styled-components';
import { color } from '../../../styles/informaconnect/colours/colours';

const ErrorMessage = styled.div`
  color: ${color.formError};
`;

const Error = (props) => {
  return <>{props.isError && <ErrorMessage>{props.error}</ErrorMessage>}</>;
};

export default Error;
