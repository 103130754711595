import { SiteCard } from '../../../../productFilter/cards';
import {
  ProductsList,
  ProductsPreviewList,
} from '../../../../productFilter/products';

import { ViewMode } from '../index';
import { selectHubDiscoverySiteRecord } from '../../../../../../../store/features/discovery';
import { DiscoveryContentTypes } from '../../../../../../../constants';
import { UseInfiniteScrollProps } from '../../../../../../../hooks';
import { useRootSelector } from '../../../../../../../store/hooks';

export interface SiteListProps {
  type: DiscoveryContentTypes;
  mode: keyof typeof ViewMode;
  loadMoreResults?: UseInfiniteScrollProps['fetchMoreData'];
  previewCount?: number;
}

export function SiteList(props: SiteListProps) {
  const { type, mode, loadMoreResults, previewCount } = props;
  const { results, totalPages } = useRootSelector((state) =>
    selectHubDiscoverySiteRecord(state, type),
  );

  return mode === ViewMode.list && loadMoreResults ? (
    <ProductsList
      products={results}
      totalPage={totalPages}
      onLoadMore={loadMoreResults}
    >
      {(product) => <SiteCard data={product} cardType="list" />}
    </ProductsList>
  ) : (
    <ProductsPreviewList products={results} countToShow={previewCount}>
      {(product) => <SiteCard data={product} />}
    </ProductsPreviewList>
  );
}
