import { SiteTypes } from '../types';

export function siteTypesEnum() {
  return {
    HUB: SiteTypes.HUB,
    STATIC: SiteTypes.STATIC,
    BRAND_HUB: SiteTypes.BRAND_HUB,
    AUDIENCE_HUB: SiteTypes.AUDIENCE_HUB,
    PORTFOLIO: SiteTypes.PORTFOLIO,
    ARTICLE: SiteTypes.ARTICLE,
    EVENT: SiteTypes.EVENT,
    COURSE: SiteTypes.COURSE,
    L1_TOPIC_HUB: SiteTypes.L1_TOPIC_HUB,
    L2_TOPIC_HUB: SiteTypes.L2_TOPIC_HUB,
  };
}

export function deliveryTypesEnum() {
  return {
    PHYSICAL: 'PHYSICAL',
    VIRTUAL: 'VIRTUAL',
    CLASSROOM: 'CLASSROOM',
    DISTANCE: 'DISTANCE',
    ONLINE: 'ONLINE',
  };
}
