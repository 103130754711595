import { InputHTMLAttributes, ReactNode, useState } from 'react';
import {
  RegisterOptions,
  useController,
  useFormContext,
} from 'react-hook-form';
import cn from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import s from './PasswordField.module.scss';

enum InputType {
  Text = 'text',
  Password = 'password',
}

export interface PasswordFieldProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  name: string;
  label: ReactNode;
  validation?: RegisterOptions;
}

export default function PasswordField(props: PasswordFieldProps) {
  const { name, label, validation, ...inputProps } = props;

  const { control } = useFormContext();
  const {
    field,
    fieldState: { invalid },
    formState: { errors },
  } = useController({
    control,
    name,
    rules: validation,
  });
  const [type, setType] = useState(InputType.Password);

  return (
    <label
      className={cn(s.wrapper, {
        [s.error]: invalid,
      })}
    >
      <div className={s.labelWrapper}>
        <div className={s.label}>{label}</div>
        {invalid && (
          <div className={s.helperText}>
            <ErrorMessage name={name} errors={errors} />
          </div>
        )}
      </div>
      <div className={s.inputWrapper}>
        <input type={type} {...inputProps} {...field} />
        <button
          type="button"
          onClick={() => {
            setType(
              type === InputType.Password ? InputType.Text : InputType.Password,
            );
          }}
          className={s.visibilityToggle}
        >
          {type === InputType.Password ? 'Show' : 'Hide'}
        </button>
      </div>
    </label>
  );
}
