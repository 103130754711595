import cloneDeep from 'lodash/cloneDeep';
import { HubDiscoveryValues } from '../../../../../store/features/discovery';

export const deleteValueFromFilters = (
  filterConfig: HubDiscoveryValues,
  valueToDelete: string,
  searchInputAsString?: boolean,
) => {
  const filters = cloneDeep(filterConfig);
  let filterKey: keyof typeof filters;

  for (filterKey in filters) {
    if (Array.isArray(filters[filterKey])) {
      const values = filters[filterKey] as string[];
      // @ts-ignore
      filters[filterKey] = values
        .filter(Boolean)
        .filter((value) => value !== valueToDelete);
    }
  }

  // TODO: multiple search input temp fix. Remove from hub after adding support on FE
  // Site search already working fine, live filters.searchInput || ''
  const searchInput = searchInputAsString
    ? filters.searchInput || ''
    : [filters.searchInput].filter(Boolean);

  return {
    ...filters,
    searchInput,
  };
};
