import { useState, useEffect, useRef, useCallback } from 'react';

export interface UseInfiniteScrollProps {
  fetchMoreData: (page: number) => Promise<void>;
  totalPage: number;
  initialPage?: number;
  offsetContainerHeight?: number;
  enableScroll?: boolean;
}

export function useInfiniteScroll<T extends HTMLElement>({
  fetchMoreData,
  totalPage,
  initialPage = 1,
  offsetContainerHeight = 0,
  enableScroll = true,
}: UseInfiniteScrollProps) {
  const [page, setPage] = useState<number>(initialPage);
  const [loading, setLoading] = useState<boolean>(false);
  const containerRef = useRef<T>(null);

  const scrollPageHandler = useCallback(() => {
    if (!enableScroll || !containerRef.current) return;

    const { offsetTop, clientHeight } = containerRef.current;
    const scrollBottomPosition = window.scrollY + window.innerHeight;
    const totalContainerHeight =
      offsetTop + clientHeight - offsetContainerHeight;

    if (scrollBottomPosition >= totalContainerHeight && !loading) {
      setLoading(true);
    }
  }, [loading, offsetContainerHeight, enableScroll]);

  const loadMore = () => {
    const nextPage = page + 1;
    document.removeEventListener('scroll', scrollPageHandler);
    setPage(nextPage);

    fetchMoreData(nextPage).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (page === totalPage || loading) return;

    document.addEventListener('scroll', scrollPageHandler);

    return () => document.removeEventListener('scroll', scrollPageHandler);
  }, [page, totalPage, scrollPageHandler, loading]);

  useEffect(() => {
    if (!enableScroll || !loading) return;

    loadMore();
    // eslint-disable-next-line
  }, [loading]);

  return { containerRef, loading };
}
