import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { DiscoveryFilter } from '../../../../../../store/features/discovery';
import { ShowMoreToggle, ResetFilterButton } from '../index';
import styles from './FilterFacetGroup.module.scss';

export interface FilterFacetGroupProps {
  type: 'radio' | 'checkbox';
  name: string;
  maxFiltersToShowCount: number;
  heading: React.ReactNode;
  filters: DiscoveryFilter[];
  renderFilterItem: (
    filter: DiscoveryFilter,
    type: FilterFacetGroupProps['type'],
  ) => React.ReactNode;
}

export function FilterFacetGroup({
  type,
  name,
  maxFiltersToShowCount,
  heading,
  filters,
  renderFilterItem,
}: FilterFacetGroupProps) {
  const { setValue, watch } = useFormContext();
  const filterValues = watch(name);

  const [listVisible, setListVisible] = useState<boolean>(true);
  const [listExpanded, setListExpanded] = useState<boolean>(false);

  const resetFilterHandler = useCallback(() => {
    setValue(name, type === 'radio' ? ['all'] : []);
  }, [name, setValue, type]);

  const showCount =
    filters.length - maxFiltersToShowCount === 1
      ? filters.length
      : maxFiltersToShowCount;
  const displayedFilters = listExpanded ? filters : filters.slice(0, showCount);
  const showMoreCount = filters.length - showCount;

  const isFilterInactive = useCallback((values: string[] = []) => {
    if (values.length === 1) {
      return values.includes('all');
    }

    return values.length === 0;
  }, []);

  return (
    <div className={styles.wrapper}>
      <div
        onClick={() => {
          setListVisible(!listVisible);
        }}
        className={styles.header}
      >
        <div className={styles.heading}>{heading}</div>

        <div className={styles.toolBar}>
          <ResetFilterButton
            onClick={(event) => {
              event.stopPropagation();
              resetFilterHandler();
            }}
            disabled={isFilterInactive(filterValues)}
            className={styles.resetButton}
          >
            Clear
          </ResetFilterButton>

          <span className={cn('material-icons', styles.collapseToggleIcon)}>
            {listVisible ? 'expand_less' : 'expand_more'}
          </span>
        </div>
      </div>

      {listVisible && (
        <ul className={styles.list}>
          {displayedFilters.map((filter) => (
            <li key={filter.label} className={styles.listItem}>
              {renderFilterItem(filter, type)}
            </li>
          ))}
        </ul>
      )}

      {listVisible && (
        <div className={styles.footer}>
          {showMoreCount > 0 && (
            <ShowMoreToggle
              open={listExpanded}
              onClick={() => {
                setListExpanded(!listExpanded);
              }}
              count={showMoreCount}
            />
          )}
        </div>
      )}
    </div>
  );
}
