import isEqual from 'lodash/isEqual';
import { HubDiscoveryValues } from '../../../../../store/features/discovery';
import { deleteValueFromFilters } from '../deleteValueFromFilters';

export function checkDefaultFiltersApplied<T extends HubDiscoveryValues>(
  defaultFilters: T,
  selectedFilters: T,
) {
  return isEqual(
    deleteValueFromFilters(defaultFilters, 'all'),
    deleteValueFromFilters(selectedFilters, 'all'),
  );
}
