export const SUCCESS = 'MA_SEGMENTS_SUCCESS';
export const FAILURE = 'MA_SEGMENTS_FAILURE';

const initialState = {
  loaded: false,
};

export function chosenSegmentsSuccess(data) {
  return { type: SUCCESS, data };
}

export function chosenSegmentsFailure(data) {
  return { type: FAILURE, data };
}

export default function chosenSegmentsReducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS:
      return chosenSegmentsSuccess(state, action);

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

export function sendChosenSegmentsAction(cid, segment) {
  return function (dispatch, getState, { Api }) {
    const {
      pageConfig: { sitePath, siteType },
    } = getState();

    return Api()
      .service('MAService')
      .version('v1')
      .setup({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: segment,
          meta: {
            type: siteType,
            event: sitePath,
          },
          source: 'USER',
        }),
      })
      .sendMASegments({ cid })
      .then((response) => {
        if (response.statusCode === 200 || response.statusCode === 201) {
          return dispatch(chosenSegmentsSuccess(response));
        } else {
          return dispatch(chosenSegmentsFailure(response));
        }
      });
  };
}
