const constructURL = (
  path: string,
  skipSlash: boolean,
  query: string | null = null,
) => {
  if (
    path.search(/[?#]/) > -1 ||
    path.indexOf('tel:') === 0 ||
    path.indexOf('mailto:') === 0 ||
    /\.[a-zA-Z]{3,4}$/.test(path)
  ) {
    return path;
  }

  let newPath = path;
  if (!/\/$/.test(path)) {
    newPath += '/';
  }

  if (skipSlash) {
    newPath = newPath.replace(/\/+$/, '');
  }

  if (!query) {
    return newPath;
  } else {
    return newPath + query;
  }
};

export default constructURL;
