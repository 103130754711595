import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { allowScroll, preventScroll } from '../../../helpers';
import { withBrand } from '../../../providers/BrandProvider';
import Link from '../Link/Link';
import BookingButton from '../../shared/BookingButton/BookingButton';

import './Modal.scss';

export default function Modal(
  WrappedComponent,
  ModalContent,
  wrappedComponentProps = {},
  modalClasses = '',
  ModalContentProps = {},
) {
  class WithModalContainer extends Component {
    static propTypes = {
      index: PropTypes.number,
      content: PropTypes.object,
      modalPosition: PropTypes.oneOf(['top', 'center']),
      withTopPanel: PropTypes.bool,
      withBookingButton: PropTypes.bool,
      brand: PropTypes.object,
      isShowing: PropTypes.bool,
      superModal: PropTypes.bool,
      bookingLink: PropTypes.string,
    };

    constructor(props) {
      super(props);

      this.state = {
        isModalOpen: !!props.isShowing,
      };
    }

    componentDidMount() {
      if (this.state.isModalOpen) {
        preventScroll();
      } else {
        allowScroll(window.scrollY);
      }
    }

    handleOpen = (event) => {
      event.preventDefault();

      preventScroll();
      this.setState({ isModalOpen: true });
    };

    handleClose = (event) => {
      const { superModal = false } = this.props;
      event && event.preventDefault();

      if (!superModal) {
        allowScroll();
        this.setState({ isModalOpen: false });
      }
    };

    render() {
      const {
        index,
        content,
        modalPosition = 'center',
        withTopPanel,
        withBookingButton,
        brand,
        bookingLink,
        ...rest
      } = this.props;

      const modalPositionStyle =
        modalPosition === 'top'
          ? { alignItems: 'flex-start' }
          : modalPosition === 'center'
          ? { alignItems: 'center' }
          : null;

      return (
        <>
          <WrappedComponent
            onClick={this.handleOpen}
            {...wrappedComponentProps}
            {...rest}
          />
          {this.state.isModalOpen && (
            <div className={`c-modal ${modalClasses}`}>
              <div className="c-modal__wrapper" style={modalPositionStyle}>
                <div
                  className="c-modal__overlay"
                  onClick={this.handleClose}
                  role="presentation"
                />
                <div className="c-modal__container">
                  {withTopPanel && (
                    <div
                      className="c-modal__top-panel"
                      style={{ backgroundColor: brand.secondaryColor }}
                    >
                      <span>
                        {withBookingButton && bookingLink ? (
                          <Link type="button" to={bookingLink} size="popup">
                            Book now
                          </Link>
                        ) : withBookingButton ? (
                          <BookingButton size="popup" />
                        ) : null}
                      </span>
                      <span
                        className="c-modal__close"
                        onClick={this.handleClose}
                      >
                        ×
                      </span>
                    </div>
                  )}
                  <ModalContent
                    index={index}
                    {...content}
                    {...ModalContentProps}
                    closeModal={this.handleClose}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  }

  return withBrand(WithModalContainer);
}
