import React from 'react';
import PropTypes from 'prop-types';
import SearchFormWidget from '../../discovery/SearchFormWidget/SearchFormWidget';
import './SearchFormSection.scss';

export default function SearchFormSection({ fullWidth }) {
  return (
    <div className="c-search-form-section">
      <div className={`${fullWidth ? 'container-fluid' : 'container'}`}>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="c-search-form-section__title">
              Can't find what you're looking for?
            </div>
            <SearchFormWidget searchProposition="Browse other topics or search by keyword" />
          </div>
        </div>
      </div>
    </div>
  );
}

SearchFormSection.propTypes = {
  fullWidth: PropTypes.bool,
};
