import { DiscoveryContentTypes } from '../../../../../constants';
import {
  Discovery,
  DiscoveryRecord,
  DiscoveryArticle,
  DiscoverySite,
  DiscoveryTopicHome,
  DiscoveryStreamlyVideo,
  DiscoveryModule,
  DiscoverySpeaker,
  DiscoverySiteSponsor,
  defaultRecord,
} from '../../../../../store/features/discovery';

export function getDiscoveryContentByType<
  T extends
    | DiscoveryArticle
    | DiscoverySite
    | DiscoveryTopicHome
    | DiscoveryModule
    | DiscoverySpeaker
    | DiscoverySiteSponsor
    | DiscoveryStreamlyVideo,
>(type: DiscoveryContentTypes, records: Discovery['records'] | [] = []) {
  const record =
    records.find((record) => record.type === type) || defaultRecord;

  return record as DiscoveryRecord<T>;
}
