import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonProps,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../../Button';
import { TextWithCount } from './TextWithCount';

type Params = {
  sitePath: string;
  brandPath: string;
};

export interface ShowMoreProductsButtonProps extends ButtonProps {
  count: number | false;
}

export function ShowMoreProductsButton(props: ShowMoreProductsButtonProps) {
  const { count, children, ...restProps } = props;

  const { sitePath, brandPath } = useParams<Params>();

  return (
    <Button pathPrefix={[brandPath, sitePath]} {...restProps}>
      <TextWithCount count={count}>{children}</TextWithCount>
    </Button>
  );
}

ShowMoreProductsButton.defaultProps = {
  type: ButtonType.button,
  variant: ButtonVariant.contained,
  size: ButtonSize.large,
};
