import React, { useMemo } from 'react';
import { HubBasePage } from '../../../../../types';
import { DiscoveryContentTypes } from '../../../../../constants';
import { useRootSelector } from '../../../../../store/hooks';
import { selectBrandHubPage } from '../../store/features/page';
import { selectBrandHubOptions } from '../../store/features/options';
import {
  DiscoveryStreamlyVideo,
  selectHubDiscoveryFacets,
  selectHubDiscoveryStreamlyVideosRecord,
  HubPageNames,
} from '../../../../../store/features/discovery';
import PageLayout from '../../layouts/PageLayout';
import { MetaTags } from '../../../shared/MetaTags';
import VideoCard from '../../../shared/productFilter/cards/VideoCard';
import { AggregatedProductPage } from '../../../shared/siteBuilder/AggregatedProductPage';
import { useStreamlyVideoParams } from '../../../shared/hooks';
import StreamlyVideoModal from '../../../shared/StreamlyVideoModal';

export function StreamlyVideos() {
  const {
    data: {
      page: {
        details: { title, description, metaDescription, searchQueryData },
      },
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectBrandHubPage<HubBasePage>());
  const { params, handleCleanParams, handleUpdateParams } =
    useStreamlyVideoParams();
  const {
    data: { name: hubName },
  } = useRootSelector(selectBrandHubOptions);
  const facets = useRootSelector(selectHubDiscoveryFacets);
  const record = useRootSelector((state) =>
    selectHubDiscoveryStreamlyVideosRecord(state),
  );

  const streamlyVideoTitle = title || 'Streamly Videos';

  const defaultValues = useMemo(
    () => ({
      sectors: [],
      topics: [],
      accessTypes: [],
      createdTimeFrame: [],
      page: 1,
      count: 20,
      searchInput: '',
      type: [DiscoveryContentTypes.STREAMLY_VIDEO],
    }),
    [],
  );

  return (
    <PageLayout>
      <MetaTags
        title={streamlyVideoTitle}
        titleSuffix={hubName}
        description={metaDescription}
        thumbnail={brandLogo?.path}
      />
      <AggregatedProductPage<DiscoveryStreamlyVideo>
        pageName={HubPageNames.streamlyVideos}
        contentType={DiscoveryContentTypes.STREAMLY_VIDEO}
        title={streamlyVideoTitle}
        subtitle={description}
        facets={facets}
        record={record}
        renderProduct={(product) => (
          <VideoCard
            data={product}
            cardType="list"
            onOpen={handleUpdateParams}
          />
        )}
        defaultValues={defaultValues}
        searchQueryData={searchQueryData}
      />
      {params && (
        <StreamlyVideoModal
          open={!!params}
          videoParams={params}
          onClose={handleCleanParams}
        />
      )}
    </PageLayout>
  );
}
