import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { isBrowser } from '../../../../helpers';
import Patterns from '../../../../utils/pattern/patterns';
import Link from '../../Link/Link';
import Button from '../../Button/Button';

import './FormField.scss';

export class FormField extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf([
      'submit',
      'text',
      'email',
      'number',
      'url',
      'password',
      'select',
      'hidden',
      'file',
    ]).isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,

    disabled: PropTypes.bool,
    loading: PropTypes.bool,

    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.any,
      }),
    ),
    blankValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    pattern: PropTypes.object,
    children: PropTypes.node,

    error: PropTypes.bool,

    look: PropTypes.string,
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    selectFileButtonValue: PropTypes.string,
    fileExtensions: PropTypes.string,

    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.state = {
      formIsValid: true,
      validationMessage: null,
      targetValue: null,
    };

    this.onValueChanged = this.onValueChanged.bind(this);
    this.changeSelectValue = this.changeSelectValue.bind(this);
    this.openFileDialog = this.openFileDialog.bind(this);
  }

  componentDidMount() {
    if (isBrowser()) {
      this.onValueChanged();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.props.type === 'select' &&
        this.setState({
          selectValue: this.props.value,
        });

      this.onValueChanged();
    }
  }

  openFileDialog(e) {
    e.preventDefault();
    this.fileInputRef.current.click();
  }

  onValueChanged(event) {
    const value = event
      ? this.props.type === 'file' || this.props.type === 'image'
        ? event
        : event.target.value
      : this.props.value || '';
    const pattern = this.props.pattern;

    if (!pattern) {
      return false;
    }

    const targetValue =
      this.props.type === 'file' || this.props.type === 'image'
        ? value
        : value.trim();
    let validationObject = {
      valid: true,
      message: '',
    };

    function matchRequired(targetValue) {
      return targetValue.length > 0;
    }

    function matchEmail(targetValue) {
      return Patterns.isEmail(targetValue);
    }

    function matchTelephone(targetValue) {
      return Patterns.isTelephoneNumber(targetValue);
    }

    function matchPassword(targetValue) {
      return Patterns.isPassword(targetValue);
    }

    function matchPasswordLength(targetValue) {
      return targetValue.length >= Patterns.passwordLength;
    }

    function matchInvalidChars(targetValue) {
      return Patterns.isValidChar(targetValue);
    }

    const matchMessage =
      (pattern.match && pattern.match.message) || 'Not matched';
    const requiredMessage =
      typeof pattern.required === 'string' ? pattern.required : 'Required';
    const emailMessage =
      typeof pattern.email === 'string'
        ? pattern.email
        : 'Please enter a valid email address';
    const telephoneMessage =
      typeof pattern.telephone === 'string'
        ? pattern.telephone
        : 'Not a valid phone number';
    const passwordMessage =
      typeof pattern.password === 'string'
        ? pattern.password
        : 'Password must have at least 1 upper case letter, 1 lowercase letter and 1 number';
    const passwordLengthMessage =
      typeof pattern.password === 'string'
        ? pattern.password
        : 'Your password must be at least 8 characters long';
    const invalidCharMessage =
      typeof pattern.valid === 'string' ? pattern.valid : 'Invalid characters';
    const errorMessage = invalidCharMessage ? 'Required' : 'Invalid characters';

    if (pattern.required) {
      validationObject.valid = pattern.password
        ? matchRequired(targetValue)
        : matchRequired(targetValue) && matchInvalidChars(targetValue);
      validationObject.message = errorMessage;
    }

    if (pattern.email) {
      const matchedRequired = matchRequired(targetValue);
      const matchedEmail = matchEmail(targetValue);
      const isForbiddenSymbol = targetValue.includes('+');

      if (!targetValue) {
        validationObject.message = requiredMessage;
        validationObject.valid = matchedRequired;
      } else if (!matchedEmail) {
        validationObject.message = emailMessage;
        validationObject.valid = matchedEmail;
      } else if (isForbiddenSymbol) {
        validationObject.message = 'Invalid characters';
        validationObject.valid = !isForbiddenSymbol;
      }
    }

    if (pattern.telephone) {
      const matchedRequired = matchRequired(targetValue);
      const matchedTelephone = matchTelephone(targetValue);
      validationObject = {
        valid: pattern.required
          ? matchedRequired && matchedTelephone
          : targetValue
          ? matchedTelephone
          : true,
        message: targetValue ? telephoneMessage : requiredMessage,
      };
    }

    if (pattern.password) {
      const matchedRequired = matchRequired(targetValue);
      const matchedPasswordRegExp = matchPassword(targetValue);
      const matchedPasswordLength = matchPasswordLength(targetValue);
      validationObject = {
        valid:
          matchedRequired && matchedPasswordRegExp && matchedPasswordLength,
        message: !matchedRequired
          ? requiredMessage
          : !matchedPasswordLength
          ? passwordLengthMessage
          : !matchedPasswordRegExp
          ? passwordMessage
          : null,
      };
    }

    if (pattern.match) {
      const matchedRequired = matchRequired(targetValue);
      const matchedField =
        pattern.match.form &&
        pattern.match.form.current &&
        pattern.match.form.current[pattern.match.refName].current[
          pattern.match.field
        ];
      const matchedValue = matchedField ? matchedField.value.trim() : null;
      validationObject = {
        valid: matchedRequired && targetValue === matchedValue,
        message: matchedRequired ? matchMessage : requiredMessage,
      };
    }

    const matchedRequired = matchRequired(targetValue);
    const matchedInvalidChars = matchInvalidChars(targetValue);

    if (!matchedInvalidChars) {
      if (pattern.password) {
        validationObject.valid = pattern.required
          ? matchedRequired
          : targetValue
          ? matchedInvalidChars
          : true;
      } else {
        validationObject.valid = pattern.required
          ? matchedRequired && matchedInvalidChars
          : targetValue
          ? matchedInvalidChars
          : true;
      }
      validationObject.message = targetValue
        ? invalidCharMessage
        : requiredMessage;
    }

    this.setState({
      formIsValid: validationObject.valid,
      validationMessage: validationObject.message,
      targetValue,
    });

    if (this.props.onChange && typeof this.props.onChange === 'function') {
      this.props.onChange(targetValue, this.props.name, validationObject.valid);
    }
  }

  changeSelectValue(event) {
    this.setState({
      selectValue: event.target.value,
    });
    this.onValueChanged(event);
  }

  render() {
    const { formIsValid, validationMessage, targetValue, selectValue } =
      this.state;
    const {
      t,
      type,
      name,
      value = '',
      label = '',
      blankValue = false,
      className = '',
      disabled = false,
      loading = false,
      look = 'secondary',
      options = [],
      children,
      buttonClassName,
      selectFileButtonValue = 'Choose File',
      fileExtensions = '',
      rowClassName = '',
      tReady,
      ...rest
    } = this.props;

    let Component = null;

    switch (type) {
      case 'submit':
        Component = (
          <Button
            type="submit"
            look={look}
            size="submit"
            defaultValue={value}
            loading={loading}
            disabled={disabled || loading}
            className={`${className}`}
            {...rest}
          >
            {value}
          </Button>
        );
        break;

      case 'file':
        Component = (
          <div className={`c-form-field ${type} ${className}`}>
            <input
              type={type}
              name={name}
              disabled={disabled || loading}
              data-valid={formIsValid}
              accept={fileExtensions}
              {...rest}
              onChange={this.onValueChanged}
              ref={this.fileInputRef}
            />
            <Link
              type="button"
              look={look}
              size="submit"
              onClick={this.openFileDialog}
              className={buttonClassName}
            >
              {selectFileButtonValue}
            </Link>
            {children}
          </div>
        );
        break;

      case 'select':
        Component = (
          <select
            name={name}
            defaultValue={value}
            value={selectValue}
            disabled={disabled}
            className={`c-form-field ${type} ${className}`}
            {...rest}
            data-valid={formIsValid}
            onChange={this.changeSelectValue}
          >
            {((blankValue && !targetValue) || !value) && (
              <option value="">
                {typeof blankValue === 'string' ? blankValue : 'Please select'}
              </option>
            )}
            {options.map(
              ({ label: optionLabel, value: optionValue, i18nKey }, index) => {
                return (
                  <option key={index} value={optionValue}>
                    {t(i18nKey || optionLabel)}
                  </option>
                );
              },
            )}
          </select>
        );

        break;

      default:
        Component = (
          <input
            type={type}
            name={name}
            // placeholder={placeholder}
            defaultValue={value}
            disabled={disabled || loading}
            className={`c-form-field ${type} ${className}`}
            {...rest}
            data-valid={formIsValid}
            onChange={this.onValueChanged}
          />
        );
        break;
    }

    return (
      <div
        className={`form-row ${
          formIsValid ? 'valid' : 'error'
        } test ${rowClassName}`}
      >
        {label && (
          <label htmlFor="" className="c-input-label">
            {label}
          </label>
        )}
        {Component}
        {children}
        {type !== 'submit' && !formIsValid && (
          <div className="c-input-error">{validationMessage}</div>
        )}
      </div>
    );
  }
}

export default withTranslation()(FormField);
