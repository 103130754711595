import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../store/features/generic';
import { GenericState } from '../../../../../types';
import { RootState } from '../../../../../store/store';
import { selectPageConfig } from '../../../../../store/features/pageConfig';

export interface SitePricingBookingClosedData {
  bookingClosedMessage: string;
}

export const fetchSitePricingBookingClosed =
  createGenericAsyncThunk<SitePricingBookingClosedData>(
    'sitePricingBookingClosed/fetchSitePricingBookingClosed',
    async (params, { extra, rejectWithValue, getState }) => {
      const { siteId, siteTypePath } = selectPageConfig(getState());

      const response = await extra.ajax.get(
        `/caas/content/api/v1/${siteTypePath}/${siteId}/booking-closed-message`,
        {
          headers: {
            'Accept-Language': extra.headers['Accept-Language'],
            'Published-State': extra.headers['Published-State'],
          },
        },
      );

      const data = await response.json();

      if (response.status !== 200) {
        return rejectWithValue(extra.processRejectedValue(response, data));
      }

      return data;
    },
  );

const sitePricingBookingClosedSlice = createGenericSlice({
  name: 'sitePricingBookingClosed',
  initialState: {
    status: 'idle',
    data: {},
  } as GenericState<SitePricingBookingClosedData>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<SitePricingBookingClosedData>(
      builder,
      fetchSitePricingBookingClosed,
    );
  },
});

export const selectSitePricingBookingClosedMessage = (state: RootState) =>
  state.pricing.bookingClosed.data.bookingClosedMessage;

export const { start, fulfilled, rejected } =
  sitePricingBookingClosedSlice.actions;

export default sitePricingBookingClosedSlice.reducer;
