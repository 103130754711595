// constants related to header builder
export const SITE_LOGO = 'SITE_LOGO';
export const MAIN_LOGO = 'MAIN_LOGO';
export const PARTNER_LOGO = 'PARTNER_LOGO';
export const DATE_VENUE = 'DATE_VENUE';
export const SITE_LOGO_WITH_PARTNER_LOGO = 'SITE_LOGO_WITH_PARTNER_LOGO';
export const PARTNER_LOGO_WITH_COUNTDOWN_TIMER =
  'PARTNER_LOGO_WITH_COUNTDOWN_TIMER';
export const COUNTDOWN_TIMER = 'COUNTDOWN_TIMER';

// constants related to venue builder
export const VENUE_EVENT = 'EVENT';
export const VENUE_COURSE = 'COURSE';
export const VENUE_FANEXPO = 'FANEXPO';
