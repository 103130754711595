let scrollTop: number | null = null;

function allowScroll(offsetTop?: number): void {
  document.body.classList.remove('scroll-prevented');
  document.body.style.position = '';
  document.body.style.top = '';
  document.body.style.width = 'auto';

  const scrollBehavior = document.documentElement.style.scrollBehavior;
  document.documentElement.style.scrollBehavior = 'initial';

  window.scrollTo(0, offsetTop || scrollTop || 0);

  document.documentElement.style.scrollBehavior = scrollBehavior;
}

function preventScroll() {
  if (document.body.style.position !== 'fixed') {
    scrollTop = window.scrollY;
  }

  document.body.classList.add('scroll-prevented');
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollTop}px`;
  document.body.style.width = '100%';
}

function anchorPointScroll(offsetTop: number): void {
  document.body.classList.remove('scroll-prevented');
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollTop}px`;
  document.body.style.width = '100%';

  window.scrollTo(0, offsetTop);
}

export { allowScroll, preventScroll, anchorPointScroll };
