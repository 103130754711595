import { useTranslation } from 'react-i18next';
import { countriesList as countries } from '../../../../../../../services/Dictionary';
import { Profile } from '../../EditProfile/ProfileForm';
import s from './ProfileDetails.module.scss';
import Anchor from '../../shared/Anchor';
import RoundIcon from '../../shared/RoundIcon';

export interface ProfileDetailsProps {
  profile: Profile;
}

export default function ProfileDetails({ profile }: ProfileDetailsProps) {
  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className={s.fullName}>
          {profile.forename} {profile.surname}
        </div>
        <Anchor to="/account/profile/edit/" className={s.editProfileAnchor}>
          <RoundIcon icon="edit" />
        </Anchor>
      </div>
      <table className={s.table}>
        <tbody>
          <tr>
            <th>Job Title</th>
            <td>{profile.jobTitle}</td>
          </tr>
          <tr>
            <th>Company</th>
            <td>{profile.companyName}</td>
          </tr>
          <tr>
            <th>Country / Region</th>
            <td>{t(countries[profile.country])}</td>
          </tr>
          <tr>
            <th>Email Address</th>
            <td>{profile.email}</td>
          </tr>
          <tr>
            <th>Telephone</th>
            <td>{profile.telephone || '-'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
