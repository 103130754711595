import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseSectionWrapper from '../baseSectionComponents/BaseSectionWrapper/BaseSectionWrapper';
import BaseSectionTitle from '../baseSectionComponents/BaseSectionTitle/BaseSectionTitle';
import BaseSectionText from '../baseSectionComponents/BaseSectionText/BaseSectionText';
import BaseSectionCTA from '../baseSectionComponents/BaseSectionCTA/BaseSectionCTA';
import './NotFoundSection.scss';

export default function NotFoundSection() {
  const { t } = useTranslation();

  return (
    <BaseSectionWrapper section={{ type: 'not_found' }} backgroundColor="#fff">
      <div className="c-not-found-section">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <BaseSectionTitle text={t('event.404.title')} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <BaseSectionText text={t('event.404.message')} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <BaseSectionCTA primary={{ type: 'HOME_LINK' }} mode="black" />
            </div>
          </div>
        </div>
      </div>
    </BaseSectionWrapper>
  );
}
