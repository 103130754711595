import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { renderCSSString } from '../../../../../../helpers';
import { withBrand } from '../../../../../../providers/BrandProvider';
import { cleanPath } from '../MultiLevelNav';
import CallToActionService from '../../../../../../services/CallToActionService';
import CallToAction from '../../../../../shared/CallToAction/CallToAction';
import MultiLevelNavBtnContent from './MultiLevelNavBtnContent/MultiLevelNavBtnContent';
import './MultiLevelNavBtn.scss';

export function MultiLevelNavBtn(props) {
  const {
    level = '1',
    navBarItem,
    pageConfig: {
      siteType,
      sitePath,
      siteSubPath,
      tenantConfig: {
        ui: { theme },
      },
      location: { pathname = '' } = {},
    },
    siteHeader: {
      oneLineNavBarTitles,
      colo: isCoLocatedAgenda,
      agendaPageName,
      trainersMenuTitle,
    },
    brand: { accentColor },
    children,
    t,
  } = props;

  const navBarItemConfig = CallToActionService.getNavLinkDetailsByConfig(
    navBarItem,
    {
      isCoLocatedAgenda,
      agendaPageName,
      trainersMenuTitle,
      siteType,
    },
  );

  const siteSubPathCleaned =
    sitePath === '/' ? cleanPath(pathname) : cleanPath(siteSubPath);
  let isActive;
  if (navBarItemConfig.group) {
    isActive =
      navBarItem.groupItems.filter(
        (groupItem) => siteSubPathCleaned === cleanPath(groupItem.href),
      ).length > 0;
  } else if (siteSubPath || (sitePath === '/' && pathname.length > 1)) {
    isActive = siteSubPathCleaned === cleanPath(navBarItemConfig.path);
  }

  const componentClassNames = classNames('c-multi-level-nav-btn', {
    'c-multi-level-nav-btn--active': isActive,
    [`c-multi-level-nav-btn--group`]: !navBarItemConfig.path,
    [`c-multi-level-nav-btn--group-active`]: !navBarItemConfig.path && isActive,
    [`c-multi-level-nav-btn--level-${level}`]: true,
    [`c-multi-level-nav-btn--level-${level}-active`]: isActive,
    [`c-multi-level-nav-btn--one-line`]: oneLineNavBarTitles,
    [`c-multi-level-nav-btn--one-line-active`]: oneLineNavBarTitles && isActive,
    [`c-multi-level-nav-btn--multi-line`]: !oneLineNavBarTitles,
    [`c-multi-level-nav-btn--multi-line-active`]:
      !oneLineNavBarTitles && isActive,
  });

  const groupLinkClassNames = classNames('c-multi-level-nav-btn__link', {
    [`c-multi-level-nav-btn__link--group-level-${level}`]:
      !navBarItemConfig.path && level === '1',
  });

  const bodyContent = (
    <MultiLevelNavBtnContent
      navBarItemConfig={navBarItemConfig}
      oneLineNavBarTitles={oneLineNavBarTitles}
      t={t}
    />
  );

  const target = navBarItem.openInNewTab ? '_blank' : undefined;

  return (
    <div
      className={componentClassNames}
      style={{ borderBottomColor: accentColor }}
    >
      <span className="c-multi-level-nav-btn__body">
        {navBarItemConfig.path ? (
          <CallToAction
            type="link"
            to={navBarItemConfig.path}
            sitePath
            className="c-multi-level-nav-btn__link"
            target={target}
          >
            {bodyContent}
          </CallToAction>
        ) : (
          <span className={groupLinkClassNames}>{bodyContent}</span>
        )}
      </span>
      {children}
      {[
        'OneOfAKind',
        'NajahStack',
        'ArtToronto',
        'TheArtistProject',
        'InteriorDesign',
      ].includes(theme) &&
        renderCSSString(`
            .c-multi-level-nav-btn--active, .c-multi-level-nav-btn--group-active {
              border-top: 2px solid ${accentColor};
            }
            .c-multi-level-nav-btn:not(.c-multi-level-nav-btn--active) {
              border-top: 2px solid transparent;
            }
            .c-multi-level-nav-btn:not(.c-multi-level-nav-btn--active):hover {
              border-top: 2px solid ${accentColor};
            }
        `)}
    </div>
  );
}

MultiLevelNavBtn.propTypes = {
  level: PropTypes.string,
  navBarItem: PropTypes.shape({
    '@type': PropTypes.oneOf([
      'PredefinedNavBarItemView',
      'LandingNavBarItemView',
      'GroupNavBarItemView',
      'CustomLinkNavBarItemView',
    ]).isRequired,
    href: PropTypes.string,
    name: PropTypes.string,
    nameUpper: PropTypes.string,
    nameLower: PropTypes.string,
    groupTitle: PropTypes.string,
    groupItems: PropTypes.arrayOf(PropTypes.object),
    openInNewTab: PropTypes.bool,
  }).isRequired,
  pageConfig: PropTypes.shape({
    siteSubPath: PropTypes.string,
  }),
  siteHeader: PropTypes.shape({
    oneLineNavBarTitles: PropTypes.bool,
  }),
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
    siteHeader: state.siteHeader.data,
  };
}

export default withTranslation()(
  connect(mapStateToProps)(withBrand(MultiLevelNavBtn)),
);
