import React, { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../../types';
import {
  HubModuleThemes,
  HubModuleTypes,
  HubVisualStyles,
} from '../../../../../constants';
import styles from './HubModuleWrapper.module.scss';

export interface HubModuleWrapperProps extends HTMLAttributes<HTMLDivElement> {
  type: string;
  theme: string;
  elementId: string;
  noPadding?: boolean;
  home?: boolean;
  overlay?: ReactNode;
}

export function HubModuleWrapper({
  type,
  theme,
  elementId,
  home,
  noPadding,
  className,
  overlay,
  children,
  testId,
  ...props
}: PropsWithTestId<PropsWithChildren<HubModuleWrapperProps>>) {
  const sectionClassName = cn(styles.section, 'hub-module', `${type}-module`, {
    'hub-home-module': home,
    [HubModuleThemes.Dark]: theme === HubVisualStyles.Dark,
    [HubModuleThemes.Light]:
      theme === HubVisualStyles.Light ||
      theme === HubVisualStyles.LightNoBorder,
    [HubModuleThemes.BrandDark]: theme === HubVisualStyles.BrandDark,
    [HubModuleThemes.BrandLight]: theme === HubVisualStyles.BrandLight,
  });

  const wrapperClassName = cn(styles.wrapper, 'hub-module-wrapper', className, {
    [styles.noPadding]: noPadding,
  });

  return (
    <section id={elementId} className={sectionClassName} data-testid={testId}>
      <div className={wrapperClassName} {...props}>
        {overlay}
        <div className={cn(styles.body, 'hub-module-body')}>{children}</div>
        <div className={styles.brandElements}>
          <div className="hub-module-styled-element-1" />
          <div className="hub-module-styled-element-2" />
          <div className="hub-module-styled-element-3" />
          {type === HubModuleTypes.Hero && (
            <div className="hub-module-ripples">
              {new Array(30).fill(0).map((elem, index) => (
                <div key={index} className={`hub-module-ripple-${index + 1}`} />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

HubModuleWrapper.defaultProps = {
  theme: HubVisualStyles.Dark,
};
