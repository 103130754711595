import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { addElementsPropertiesAction } from '../../../store/commonDucks/pageConfig';
import CallToAction from '../../shared/CallToAction/CallToAction';
import './InformaRibbon.scss';

function getInformaRibbonConfig(tenantId) {
  if (tenantId === 'KNECT365') {
    return {
      leftParagraph:
        '$PORTFOLIO_NAME is part of the Informa Tech Division of Informa PLC',
      rightParagraph:
        "This site is operated by a business or businesses owned by Informa PLC and all copyright resides with them. Informa PLC's registered office is 5 Howick Place, London SW1P 1WG. Registered in England and Wales. Number 3099067.",
      menuConfig: [
        {
          path: 'https://www.informa.com/',
          label: 'Informa PLC',
        },
        {
          path: 'https://www.informa.com/about-us/',
          label: 'About Us',
        },
        {
          path: 'https://www.informa.com/investors/',
          label: 'Investor relations',
        },
        {
          path: 'https://www.informa.com/talent/',
          label: 'Talent',
        },
      ],
    };
  }

  return {
    leftParagraph:
      'This site is part of the Informa Connect Division of Informa PLC',
    rightParagraph:
      "This site is operated by a business or businesses owned by Informa PLC and all copyright resides with them. Informa PLC's registered office is 5 Howick Place, London SW1P 1WG. Registered in England and Wales. Number 3099067.",
    menuConfig: [
      {
        path: 'https://informa.com/',
        label: 'Informa PLC',
      },
      {
        path: 'https://informa.com/about-us/',
        label: 'About Us',
      },
      {
        path: 'https://informa.com/investors/',
        label: 'Investor relations',
      },
      {
        path: 'https://informa.com/talent/',
        label: 'Talent',
      },
    ],
  };
}

export class InformaRibbon extends Component {
  componentRef = React.createRef();

  state = {
    ribbonState: false,
  };

  componentDidMount() {
    if (this.componentRef.current) {
      const { addElementsPropertiesAction } = this.props;
      const { height } = this.componentRef.current.getBoundingClientRect();

      addElementsPropertiesAction({
        informaRibbon: {
          height,
        },
      });
    }
  }

  getLeftParagraph(paragraph, tenantId) {
    const {
      pageConfig: {
        portfolioId,
        sitePath,
        tenantConfig: { outputName: tenantName } = {},
      } = {},
      hub: { data: { blocks: hubBlocks = [] } = {} } = {},
      portfolio: { data: { shortName: portfolioShortName } = {} } = {},
      siteHeader: { meta: { title: eventName } = {} } = {},
    } = this.props;

    if (tenantId === 'KNECT365') {
      let portfolioName = eventName || tenantName;

      const portfolio = hubBlocks.find(
        (el) => el.shortName === portfolioShortName || el.name === portfolioId,
      );

      if (portfolio) {
        portfolioName = portfolio.name;
      }

      if (portfolioId === 'AUTOMOTIVE' && !sitePath) {
        portfolioName = 'TU-Auto';
      }

      return paragraph.replace('$PORTFOLIO_NAME', portfolioName || 'This site');
    }

    return paragraph;
  }

  updateRibbonState = () => {
    this.setState((state) => ({
      ribbonState: !state.ribbonState,
    }));
  };

  render() {
    const { pageConfig: { tenantId } = {} } = this.props;

    const {
      leftParagraph,
      rightParagraph,
      menuConfig = [],
    } = getInformaRibbonConfig(tenantId);

    const componentClassNames = classNames('c-informa-ribbon', {
      'c-informa-ribbon--expanded': this.state.ribbonState,
    });

    if (!leftParagraph && !rightParagraph && menuConfig.length === 0) {
      return null;
    }

    return (
      <div ref={this.componentRef} className={componentClassNames}>
        <div className="c-informa-ribbon__wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-7 col-md-6">
                <p className="c-informa-ribbon__paragraph">
                  {this.getLeftParagraph(leftParagraph, tenantId)}
                </p>
                <nav className="c-informa-ribbon__nav">
                  <ul>
                    {menuConfig.map(({ path, label }, index) => (
                      <li key={index}>
                        <CallToAction type="link" to={path} label={label} />
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
              {rightParagraph && (
                <div className="col-xs-12 col-sm-5 col-md-6">
                  <p className="c-informa-ribbon__paragraph c-informa-ribbon__paragraph--right">
                    {rightParagraph}
                  </p>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div
                  className="c-informa-ribbon__active-logo"
                  onClick={this.updateRibbonState}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InformaRibbon.propTypes = {
  hub: PropTypes.shape({
    data: PropTypes.shape({
      blocks: PropTypes.array,
    }),
  }),
  siteHeader: PropTypes.shape({
    meta: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  portfolio: PropTypes.shape({
    data: PropTypes.shape({
      shortName: PropTypes.string,
    }),
  }),
  pageConfig: PropTypes.shape({
    tenantId: PropTypes.string,
    portfolioId: PropTypes.string,
    sitePath: PropTypes.string,
    tenantConfig: PropTypes.shape({
      outputName: PropTypes.string,
    }),
  }),
  addElementsPropertiesAction: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    hub: state.hub,
    portfolio: state.portfolio,
    siteHeader: state.siteHeader,
    pageConfig: state.pageConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addElementsPropertiesAction(properties) {
      return dispatch(addElementsPropertiesAction(properties));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InformaRibbon);
