import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Link from '../../../../../shared/Link/Link';
import './SiteLowerFooterMenu.scss';

/**
 * WHITE LABEL
 */
export function SiteLowerFooterMenu(props) {
  const {
    pageConfig: { sitePath } = {},
    lowerFooter: { menu: menuItems = [] },
    t,
  } = props;

  return (
    <ul className="c-site-lower-footer-menu">
      {menuItems.map((item, i) => (
        <li key={i} className="c-site-lower-footer-menu__item">
          <Link
            to={item.href}
            parent={item.parent}
            event={!item.parent && sitePath}
            className="c-site-lower-footer-menu__link"
            target={item.target}
          >
            {t(item.title)}
          </Link>
        </li>
      ))}
    </ul>
  );
}

SiteLowerFooterMenu.propTypes = {
  pageConfig: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default withTranslation()(connect(mapStateToProps)(SiteLowerFooterMenu));
