/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export function useComponentProperties(
  element,
  elementId,
  addElementsPropertiesAction,
) {
  useEffect(() => {
    function addElementsProperties() {
      if (element.current && addElementsPropertiesAction) {
        const { bottom, height, left, right, top, width, x, y } =
          element.current.getBoundingClientRect();

        addElementsPropertiesAction({
          [elementId]: { bottom, height, left, right, top, width, x, y },
        });
      }
    }

    window.addEventListener('load', addElementsProperties);

    return () => {
      window.removeEventListener('load', addElementsProperties);
    };
  }, [element, elementId, addElementsPropertiesAction]);
}
