import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { getImgixUrl } from '../../../../../helpers';
import { renderStyledElementStyles } from '../../../helpers';

export const WrapperImageBg = styled.div`
  position: relative;
  background: center center no-repeat;
  background-size: contain;
  background-origin: content-box;
  ${({ theme, dStyles }) => renderStyledElementStyles(theme, dStyles)}
  ${({ theme, vStyles }) => renderStyledElementStyles(theme, vStyles)}
}
`;

function BackgroundImage(props) {
  const {
    pageConfig: { tenantId },
    path,
    imgixParams,
    imgix = true,
    dStyles,
    vStyles,
    children,
    testId,
  } = props;
  const imagePath = imgix ? getImgixUrl(tenantId, path, imgixParams) : path;

  return (
    imagePath && (
      <WrapperImageBg
        className="lazy"
        data-background-image={imagePath}
        dStyles={dStyles}
        vStyles={vStyles}
        data-testid={testId}
      >
        {children}
      </WrapperImageBg>
    )
  );
}

BackgroundImage.propTypes = {
  pageConfig: PropTypes.shape({
    tenantId: PropTypes.string,
  }),
  path: PropTypes.string,
  imgixParams: PropTypes.string,
  imgix: PropTypes.bool,
  dStyles: PropTypes.object,
  vStyles: PropTypes.object,
  children: PropTypes.node,
  testId: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export const StyledBackgroundImage = connect(mapStateToProps)(BackgroundImage);
