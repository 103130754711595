import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { renderStyledElementStyles } from '../../../../helpers';

import CountdownTimer from './CountdownTimer';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.siteLayout.siteHeader.builder?.elements?.timer,
    );
  }}
`;

export function Timer() {
  const {
    data: {
      countdownTimer: { counterEnabled, heading, startDate, startTime } = {},
    } = {},
  } = useSelector((state) => state.siteHeader);

  return (
    <Wrapper data-testid="header-timer">
      {counterEnabled && startDate && (
        <CountdownTimer date={startDate} time={startTime} title={heading} />
      )}
    </Wrapper>
  );
}
