import React from 'react';
import './Spinner.scss';

export default function Spinner() {
  return (
    <span data-testid="spinner" className="c-spinner">
      <span />
      <span />
      <span />
      <span />
    </span>
  );
}
