const patterns = {
  passwordLength: 8,

  isPassword(value) {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=(.*[a-zA-Z]){2}).{8,}$/.test(
      value,
    );
  },

  isEmail(value) {
    return /^[\w-+.&]+@([\w-]+\.)+[\w-]{2,14}$/.test(value);
  },

  isTelephoneNumber(value) {
    // eslint-disable-next-line no-useless-escape
    return /^((\+)*\d([\-#,. *()]*\d){5,31})[\-#,. *()]*$/i.test(value);
  },

  isMobileNumber(value) {
    // eslint-disable-next-line no-useless-escape
    return /^((\+)*\d([\-#,. *()]*\d){5,31})[\-#,. *()]*$/i.test(value);
  },

  isValidChar(value) {
    // eslint-disable-next-line no-useless-escape
    return /^[a-zA-Z0-9-~\s!;@#$<>?:|"='/~%&*()\\-`.+,/\"{}[]*$/i.test(value);
  },

  // Next validation rule is based on GEN-153 requirements
  isInFrontAllowedChars(value) {
    // eslint-disable-next-line no-useless-escape
    return /^[\x20-\x7E\xA0-\xFF]*$/i.test(value);
  },

  isPostcode(value) {
    return /^[a-zA-Z0-9 ]*$/.test(value);
  },
};

export default patterns;
