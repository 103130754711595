import { createGenericAsyncThunk } from '../../../../../store/features/generic';
import { selectPageConfig } from '../../../../../store/features/pageConfig';
import { fetchSitePricingBookingClosed } from '../bookingClosed';

export const fetchDefaultDelegateTypeId = createGenericAsyncThunk<string>(
  'delegateTypes/fetchDefaultDelegateTypeId',
  async (_, { extra, rejectWithValue, getState, dispatch }) => {
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.get(
      `/caas/commerce/v1/events/${siteId}/delegates/id`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: string = await response.text();

    if (response.status !== 200) {
      if (response.status === 423) {
        dispatch(fetchSitePricingBookingClosed());
      }

      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);
