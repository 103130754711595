import React from 'react';
import PropTypes from 'prop-types';
import CallToAction from '../../../shared/CallToAction/CallToAction';
import './BaseSectionCTA.scss';
import { connect } from 'react-redux';

const buttonLookConfig = {
  OneOfAKind: {
    primary: 'accent',
    secondary: 'secondary',
  },
  NajahStack: {
    primary: 'accent',
    secondary: 'secondary',
  },
  ArtToronto: {
    primary: 'accent',
    secondary: 'secondary',
  },
  TheArtistProject: {
    primary: 'accent',
    secondary: 'secondary',
  },
  InteriorDesign: {
    primary: 'accent',
    secondary: 'secondary',
  },
};

function BaseSectionCTA(props) {
  const {
    mode = 'none',
    size,
    pageConfig,
    primary = { shown: false },
    secondary = { shown: false },
  } = props;

  const theme = pageConfig?.tenantConfig?.ui?.theme;
  const {
    primary: primaryLook = 'accent',
    secondary: secondaryLook = 'hollow',
  } = buttonLookConfig[theme] || {};

  return (primary.shown === false && secondary.shown === false) ||
    (!primary.type && !secondary.type) ? null : (
    <div className="c-base-section-cta">
      <CallToAction
        ctaConfig={primary}
        look={primaryLook}
        mode={mode}
        size={size}
      />
      <CallToAction
        ctaConfig={secondary}
        look={secondaryLook}
        mode={mode}
        size={size}
      />
    </div>
  );
}

BaseSectionCTA.propTypes = {
  mode: PropTypes.oneOf([
    'none',
    'black',
    'white',
    'color',
    'black-on-primary',
  ]),
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'styled']),
  primary: PropTypes.object,
  secondary: PropTypes.object,
  pageConfig: PropTypes.shape({
    tenantConfig: PropTypes.shape({
      ui: PropTypes.shape({
        theme: PropTypes.string.isRequired,
      }),
    }),
  }),
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(BaseSectionCTA);
