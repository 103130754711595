import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { renderHTMLString } from '../../../../helpers';
import './BaseSectionText.scss';

export default function BaseSectionText(props) {
  const { text, styled, className, style, jsx } = props;

  const componentClassNames = classNames('c-base-section-text', {
    'c-base-section-text--styled': styled,
    [className]: className,
  });

  return !text ? null : (
    <div className={componentClassNames} style={style}>
      {jsx ? text : renderHTMLString(text)}
    </div>
  );
}

BaseSectionText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  jsx: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};
