import React from 'react';
import qs from 'qs';
import { VideoSource } from '../../../../../../../types';
import {
  sourceSettings,
  defaultOptions,
} from '../../../../VideoModule/constants';

export interface YoutubeVimeoEmbedProps {
  source: VideoSource.VIMEO | VideoSource.YOUTUBE;
  id: string;
  title: string;
  autoplay: boolean;
  controls: boolean;
}

export const YoutubeVimeoEmbed = ({
  source,
  id,
  title,
  ...options
}: YoutubeVimeoEmbedProps) => {
  const params = qs.stringify(
    {
      ...defaultOptions,
      ...options,
    },
    { addQueryPrefix: true },
  );
  const settings = sourceSettings[source];

  return (
    <iframe
      src={`${settings.path}${id}${params}`}
      title={title}
      allowFullScreen
      allow={settings.allow}
      frameBorder="0"
      data-testid={`${source}-embed`}
    />
  );
};
