import React from 'react';
import styles from './SmallRelatedProducts.module.scss';

export interface SmallRelatedProductsProps<T> {
  products: T[];
  keyName: keyof T;
  heading?: React.ReactNode;
  showMoreCTA?: React.ReactNode;
  children: (product: T) => React.ReactNode;
}

export function SmallRelatedProducts<T>(props: SmallRelatedProductsProps<T>) {
  const { products, keyName, heading, showMoreCTA, children } = props;

  return (
    <div className={styles.wrapper}>
      {heading && <h3 className={styles.heading}>{heading}</h3>}

      {products.length > 0 && (
        <ul>
          {products.map((product) => (
            <li
              className={styles.product}
              key={product[keyName] as unknown as string}
            >
              {children(product)}
            </li>
          ))}
        </ul>
      )}

      {showMoreCTA && <div>{showMoreCTA}</div>}
    </div>
  );
}
