import { PropsWithChildren } from 'react';
import { useRootSelector } from '../../../../../store/hooks';
import { selectAudienceHubLayout } from '../../store/features/layout';
import Layout, { LayoutProps } from '../Layout';

export default function StaticPageLayout(
  props: Omit<PropsWithChildren<LayoutProps>, 'layout'>,
) {
  const { data: layout } = useRootSelector(selectAudienceHubLayout);

  return <Layout {...props} layout={layout} />;
}
