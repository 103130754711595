import { ChangeEvent, useCallback, useRef, useState } from 'react';
import Auth from '../../../../../../../services/AuthService';
import s from './ProfilePhoto.module.scss';
import { Profile } from '../../EditProfile/ProfileForm';
import ImageTag from '../../../../../../shared/ImageTag/ImageTag';
import RoundIcon from '../../shared/RoundIcon';
import Button from '../../shared/Button';

export interface ProfilePhotoProps {
  profile: Profile;
  onUpdateProfile: (profile: Profile) => Promise<void>;
}

export default function ProfilePhoto(props: ProfilePhotoProps) {
  const { profile, onUpdateProfile } = props;

  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const hasProfilePhoto = !!profile.profilePhotoUrl;

  const handleChangePhoto = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }
      const photo = e.target.files.item(0);

      if (photo) {
        setLoading(true);

        try {
          const response = await Auth.uploadProfilePhoto(photo);
          const data = await response.json();

          if (data.success) {
            await onUpdateProfile({
              ...profile,
              profilePhotoUrl: `/account/uploads/${data.fileName}`,
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    },
    [onUpdateProfile, profile],
  );

  const handleAddPhoto = useCallback(() => {
    if (loading) {
      return;
    }

    inputRef.current?.click();
  }, [loading]);

  const handleRemovePhoto = useCallback(async () => {
    setLoading(true);

    try {
      await fetch('/remove-profile-image', {
        method: 'POST',
      }).then(() => {
        onUpdateProfile({ ...profile, profilePhotoUrl: '' });
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [onUpdateProfile, profile]);

  return (
    <div className={s.wrapper}>
      <div className={s.photoWrapper}>
        <div className={s.profilePhoto}>
          <ImageTag
            src={profile.profilePhotoUrl || '/images/default-silhouette.png'}
            alt={`${profile.forename} ${profile.surname}`}
            imgix={hasProfilePhoto}
            imgixParams="w=300&h=300"
            lazy={false}
            className={s.photo}
          />
          {hasProfilePhoto && (
            <div
              onClick={handleAddPhoto}
              className={s.editPhotoButton}
              role="button"
            >
              <RoundIcon icon="edit" className={s.editPhotoButtonIcon} />
            </div>
          )}
        </div>
      </div>
      <div className={s.buttonWrapper}>
        {hasProfilePhoto ? (
          <Button onClick={handleRemovePhoto} color="grey" disabled={loading}>
            Remove image
          </Button>
        ) : (
          <Button onClick={handleAddPhoto} color="grey" disabled={loading}>
            Add image
          </Button>
        )}
      </div>
      <input
        type="file"
        ref={inputRef}
        onChange={handleChangePhoto}
        className={s.input}
        data-testid="file-uploader"
      />
    </div>
  );
}
