import React from 'react';
import PropTypes from 'prop-types';

import './FormHelper.scss';

export default function FormHelper(props) {
  const { className = '' } = props;

  return <div className={`c-form-helper ${className}`}>{props.children}</div>;
}

FormHelper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
