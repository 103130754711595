import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
import { L2Topic } from '../../../../brandHub/store/features/industryHome';
import { PropsWithTestId } from '../../../../../../types';
import ImageTag from '../../../../../shared/ImageTag/ImageTag';
import { Anchor } from '../../../../../shared/Anchor';
import AnimatedCallToAction from '../../../AnimatedCallToAction';
import { concatPath, isAbsoluteUrl } from '../../../../../../helpers';
import styles from './TopicCard.module.scss';
import { DiscoveryTopicHome } from '../../../../../../store/features/discovery';
import { Badge } from '../components';

export interface TopicCardProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  brandPath?: string;
  topic: L2Topic | DiscoveryTopicHome;
}

export default function TopicCard(props: PropsWithTestId<TopicCardProps>) {
  const { topic, brandPath, className } = props;
  const { name, description, path, link } = topic;

  const hasFeaturedBrandImages =
    topic.featuredBrandImages && topic.featuredBrandImages.length > 0;

  return (
    <Anchor
      to={link || concatPath([brandPath, path])}
      className={cn(styles.wrapper, className, 'animated-cta-trigger')}
    >
      <div className={styles.content}>
        {topic.featured && (
          <div className={styles.tagsList}>
            <Badge theme="accented" size="tiny">
              Featured
            </Badge>
          </div>
        )}
        <h4 className={styles.title}>{name}</h4>
        {description && <div className={styles.description}>{description}</div>}
        {hasFeaturedBrandImages && (
          <div className={styles.brandsWrapper}>
            <span className={styles.brandsTitle}>Featured brands:</span>
            <div className={styles.brandsList}>
              {topic.featuredBrandImages.map((featuredBrandImage, index) => (
                <span key={index} className={styles.brandsItem}>
                  <ImageTag src={featuredBrandImage.path} imgixParams="h=32" />
                </span>
              ))}
            </div>
          </div>
        )}
        <div className={styles.ctaWrapper}>
          <AnimatedCallToAction
            icon={isAbsoluteUrl(link ?? '') ? 'launch' : 'east'}
          >
            Explore Topic
          </AnimatedCallToAction>
        </div>
      </div>
    </Anchor>
  );
}
