import React, { useMemo } from 'react';
import { createCanonicalUrl } from '../../../../../../helpers';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectPageConfig } from '../../../../../../store/features/pageConfig';
import { AnchorProps } from '../../../../../shared/Anchor';
import {
  CardAnchor,
  CardButton,
  TopicsList,
  Image,
  SponsorsList,
  Title,
  Badge,
  Date,
  TextListSpacer,
  CardButtonProps,
  ImageProps,
  TitleProps,
  BadgeProps,
} from '../components';
import { ProductCard, ProductListCard } from '../../products';
import { DiscoverySite } from '../../../../../../store/features/discovery';
import { useHubContext } from '../../../context/hubContext';
import {
  getDiscoveryTopicsByBrand,
  getSiteCardDeliveryTypes,
  getSiteCardLanguages,
  getSitePartnersLogos,
  getSiteAttendanceLabel,
} from '../../../helpers';
import { useBuildSiteUrl } from '../../../../../../hooks/useBuildSiteUrl';

import styles from './SiteCard.module.scss';

export enum SiteCardTypes {
  default = 'default',
  list = 'list',
}

export interface TopicsListType extends Partial<Omit<BadgeProps, 'children'>> {
  count: number;
}

export interface SiteCardProps extends Omit<AnchorProps, 'to'> {
  data: DiscoverySite;
  cardType?: keyof typeof SiteCardTypes;
  showFilter?: boolean;
}

export const SiteCard = (props: SiteCardProps) => {
  const {
    data: {
      path,
      tenant,
      vertical: domain,
      imageBackground,
      topics = [],
      name,
      date: { start, end } = {},
      date,
      dates = [],
      timeAsText,
      deliveryTypes = [],
      sponsorLogo1Path,
      sponsorLogo2Path,
      partners = [],
      description,
      secondaryColor,
      featured,
      location,
      locations = [],
      languages = [],
      type,
    },
    cardType,
    showFilter,
    ...restProps
  } = props;
  const url = useBuildSiteUrl({ path, domain, tenant });
  const { parentDomain } = useRootSelector(selectPageConfig);
  const backgroundColor = secondaryColor && `#${secondaryColor}`;
  const { brandName, showSponsorLogos } = useHubContext();
  const topicsList = useMemo(
    () => getDiscoveryTopicsByBrand(topics, brandName),
    [topics, brandName],
  );

  const getImage = (style?: ImageProps['imageStyle']) => (
    <Image imageStyle={style} path={imageBackground} />
  );

  const getTopicsList = ({ count, ...props }: TopicsListType) =>
    topicsList.length > 0 && (
      <TopicsList list={topicsList.slice(0, count)}>
        {(topic) => (
          <Badge theme="outlined" size="tiny" {...props}>
            {topic}
          </Badge>
        )}
      </TopicsList>
    );

  const getTitle = (size?: TitleProps['size']) => (
    <Title size={size}>{name}</Title>
  );

  const getDate = (
    dateSize?: BadgeProps['size'],
    extraDateSize?: BadgeProps['size'],
  ) => {
    return (
      <Date
        dates={dates.length > 0 ? dates : [date]}
        deliveryTypes={deliveryTypes}
        timeAsText={timeAsText}
        dateSize={dateSize}
        extraDateSize={extraDateSize}
      />
    );
  };

  const getLanguage = (size?: BadgeProps['size']) => {
    const languageList = getSiteCardLanguages(languages);

    return (
      languageList.length > 0 && (
        <Badge size={size}>
          <TextListSpacer list={languageList} />
        </Badge>
      )
    );
  };

  const getDeliveryTypeList = (size?: BadgeProps['size']) => {
    const deliveryTypeList = getSiteCardDeliveryTypes(
      deliveryTypes,
      locations.length > 0 ? locations : [location],
    );

    return (
      deliveryTypeList.length > 0 && (
        <Badge size={size} className={styles.bold}>
          <TextListSpacer list={deliveryTypeList} />
        </Badge>
      )
    );
  };

  const getCardButton = (size?: CardButtonProps['size']) => {
    const endDate = dates.length > 0 ? dates[0].end : end;
    const buttonLabel = getSiteAttendanceLabel(deliveryTypes, type, endDate);

    return buttonLabel && <CardButton size={size}>{buttonLabel}</CardButton>;
  };

  const getSponsorsList = () => {
    if (!showSponsorLogos) {
      return null;
    }

    const sponsorsLogos = [sponsorLogo1Path, sponsorLogo2Path].filter(Boolean);
    const partnersLogos = getSitePartnersLogos(partners);
    const isPartners = partnersLogos.length > 0;

    const logos = isPartners ? partnersLogos : sponsorsLogos;
    const label = isPartners ? 'In association with:' : 'Sponsored By:';

    return logos.length > 0 && <SponsorsList logos={logos} label={label} />;
  };

  const getFeaturedBadge = (size?: BadgeProps['size']) =>
    featured && (
      <Badge size={size} className={styles.bold}>
        Featured
      </Badge>
    );

  const getDescription = (size?: BadgeProps['size']) =>
    description && (
      <Badge size={size} className={styles.description}>
        {description}
      </Badge>
    );

  return (
    <CardAnchor {...restProps} to={url}>
      {cardType === SiteCardTypes.default && (
        <ProductCard
          image={getImage()}
          topics={getTopicsList({ count: 1 })}
          title={getTitle('extraSmall')}
          date={getDate()}
          language={getLanguage()}
          deliveryTypes={getDeliveryTypeList()}
          description={getDescription()}
          sponsors={getSponsorsList()}
          leftFooter={getCardButton()}
          showMoreBadge={getCardButton()}
          backgroundColor={backgroundColor}
        />
      )}
      {cardType === SiteCardTypes.list && (
        <ProductListCard
          image={getImage('list')}
          imageSize={showFilter ? 'medium' : 'small'}
          leftHeader={getTopicsList({ count: 3 })}
          rightHeader={getFeaturedBadge()}
          title={getTitle('small')}
          date={getDate('medium', 'small')}
          language={getLanguage('medium')}
          deliveryTypes={getDeliveryTypeList('medium')}
          description={getDescription()}
          sponsors={getSponsorsList()}
          showMoreBadge={getCardButton('large')}
          backgroundColor={backgroundColor}
        />
      )}
      <div itemScope itemType="https://schema.org/Event">
        <meta itemProp="name" content={name} />
        <meta itemProp="startDate" content={start} />
        <meta itemProp="endDate" content={end} />
        <meta
          itemProp="url"
          content={createCanonicalUrl(path, null, parentDomain)}
        />
        <div itemProp="location" itemScope itemType="https://schema.org/Place">
          <meta itemProp="name" content={location?.name} />
          <meta itemProp="city" content={location?.city} />
          <meta itemProp="address" content={location?.address} />
        </div>
      </div>
    </CardAnchor>
  );
};

SiteCard.defaultProps = {
  cardType: SiteCardTypes.default,
};
