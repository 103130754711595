import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../store/features/generic';
import {
  GenericState,
  SiteModuleLink,
  SiteTypePath,
  UniqueIdentifier,
} from '../../../../../types';
import { RootState } from '../../../../../store/store';

export interface ReceiptSection {
  displayCustomOrderConfirmationMessage: boolean;
  title?: string;
  titleSize?: string;
  body?: string;
  primaryLink?: SiteModuleLink;
  secondaryLink?: SiteModuleLink;
}

export interface SitePricingContent {
  receiptSection: ReceiptSection;
}

export interface SitePricingContentParams {
  siteId: UniqueIdentifier;
  siteTypePath: SiteTypePath;
}

export const fetchSitePricingContent = createGenericAsyncThunk<
  SitePricingContent,
  SitePricingContentParams
>(
  'sitePricingContent/fetchSitePricingContent',
  async ({ siteTypePath, siteId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/${siteTypePath}/${siteId}/pricing-content`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: SitePricingContent = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const sitePricingContentSlice = createGenericSlice({
  name: 'sitePricingContent',
  initialState: {
    status: 'idle',
    data: {
      receiptSection: {},
    },
  } as GenericState<SitePricingContent>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<SitePricingContent, SitePricingContentParams>(
      builder,
      fetchSitePricingContent,
    );
  },
});

export const selectSitePricingContent = (state: RootState) =>
  state.pricing.content.data;

export const { start, fulfilled, rejected } = sitePricingContentSlice.actions;

export default sitePricingContentSlice.reducer;
