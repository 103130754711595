import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useState,
} from 'react';

type KeywordSearchValue = string;
type KeywordSearchHandler = (value: string) => void;

export function useKeywordSearch(
  onSearch: KeywordSearchHandler,
  initialValue: KeywordSearchValue = '',
) {
  const [inputValue, setInputValue] =
    useState<KeywordSearchValue>(initialValue);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setInputValue(e.target.value);
    },
    [],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onSearch(inputValue);
      }
    },
    [inputValue, onSearch],
  );

  const handleSearch = useCallback(
    (value: KeywordSearchValue) => {
      onSearch(value);
    },
    [onSearch],
  );

  const handleReset = useCallback(() => {
    setInputValue('');
  }, []);

  const setValue = useCallback((value: KeywordSearchValue) => {
    setInputValue(value);
  }, []);

  return {
    value: inputValue,
    setValue,
    handleChange,
    handleKeyDown,
    handleSearch,
    handleReset,
  };
}
