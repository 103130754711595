import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './VIPBanner.scss';

export class VIPBanner extends Component {
  static propTypes = {
    discount: PropTypes.string,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      opened: true,
    };

    this.closeVIPBanner = this.closeVIPBanner.bind(this);
  }

  closeVIPBanner() {
    const history = this.props.history;

    if (history) {
      history.location.search = history.location.search.replace(
        /vip_code=([A-Za-z0-9])\w+&?/,
        '',
      );
      history.push(history.location);
    }

    this.setState({ opened: false });
  }

  render() {
    const { discount, t } = this.props;

    return !this.state.opened ? null : (
      <div className="c-vip-code-notification-sticky-wrapper">
        <div className="c-vip-code-notification-wrapper">
          <div className="c-vip-code-notification">
            <div className="table">
              <div className="icon success">
                <i className="material-icons">&#xE86C;</i>
              </div>
              <div className="message">
                <p className="title">
                  {t('event.purchase.vipBanner.title', { 0: discount })}
                </p>
                <p className="description">
                  {t('event.purchase.vipBanner.description')}
                </p>
              </div>
              <div className="icon close" onClick={this.closeVIPBanner}>
                <i className="material-icons icon-close">close</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(VIPBanner));
