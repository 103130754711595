import { useRootSelector } from '../../../../../../store/hooks';
import { selectBrandHubLayout } from '../../../store/features/layout';
import { selectBrandHubOptions } from '../../../store/features/options';
import s from './ForgottenPassword.module.scss';
import StaticPageLayout from '../../../layouts/StaticPageLayout';
import { MetaTags } from '../../../../shared/MetaTags';
import PublicPageLayout from '../shared/PublicPageLayout';
import Paper from '../shared/Paper';
import ForgottenPasswordForm from './ForgottenPasswordForm';

export function ForgottenPassword() {
  const {
    data: {
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectBrandHubLayout);
  const {
    data: { name: hubName },
  } = useRootSelector(selectBrandHubOptions);

  return (
    <StaticPageLayout className={s.body}>
      <MetaTags
        title="Forgotten Password"
        titleSuffix={hubName}
        thumbnail={brandLogo?.path}
      />
      <PublicPageLayout>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
              <Paper
                heading="Forgotten Password"
                text="We will send you an email to reset your password."
              >
                <ForgottenPasswordForm />
              </Paper>
            </div>
          </div>
        </div>
      </PublicPageLayout>
    </StaticPageLayout>
  );
}
