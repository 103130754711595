import React, { useMemo } from 'react';
import loadable from '@loadable/component';
import { HubHeader, HubOptions, HubSector } from '../../../../types';
import { useHubContext } from '../../shared/context/hubContext';

const ClassicHeader = loadable(
  () =>
    import(
      /* webpackChunkName: "ClassicHeader" */ '../../../headers/ClassicHeader'
    ),
);

const Nav = loadable(
  () => import(/* webpackChunkName: "HubNav" */ '../../shared/Nav'),
);

export interface HeaderProps {
  header: HubHeader;
  options: HubOptions;
  sectors: HubSector[];
}

export function Header({
  header: {
    headerNavBar: {
      displayKeywordSearch,
      searchPlaceholderText,
      searchDisplayPlace,
      displayTextContent,
      textContent,
    },
    navBarItems,
  },
  options: { name: audienceHubName, subBrandName },
  sectors,
}: HeaderProps) {
  const { brandPath } = useHubContext();

  const breadcrumbs = useMemo(
    () => [
      {
        path: `/${brandPath}/`,
        label: subBrandName,
      },
    ],
    [brandPath, subBrandName],
  );

  return (
    <ClassicHeader
      heading={audienceHubName}
      breadcrumbs={breadcrumbs}
      displaySearch={displayKeywordSearch && searchDisplayPlace === 'HEADER'}
      displayMobileSearchButton={displayKeywordSearch}
      searchPlaceholderText={searchPlaceholderText}
      displayTextContent={displayTextContent}
      textContent={textContent}
      mobileNavBarContent={
        <Nav
          links={navBarItems}
          sectors={sectors}
          displayKeywordSearch={displayKeywordSearch}
          searchPlaceholderText={searchPlaceholderText}
        />
      }
    />
  );
}
