import { useMemo } from 'react';
import loadable from '@loadable/component';
import { HubFooter, HubSector } from '../../../../types';
import { useRootSelector } from '../../../../store/hooks';
import { selectTenantConfig } from '../../../../store/features/tenantConfig';

const ClassicFooter = loadable(
  () =>
    import(
      /* webpackChunkName: "ClassicFooter" */ '../../../footers/ClassicFooter'
    ),
);

export interface FooterProps {
  footer: HubFooter;
  sectors: HubSector[];
}

export function Footer({
  footer: {
    footerLogo,
    footerLinks,
    footerLinksTitle,
    publicLinks,
    socialLinks,
  },
  sectors,
}: FooterProps) {
  const { copyright } = useRootSelector(selectTenantConfig);

  const sectorLinks = useMemo(
    () =>
      sectors.map((sector) => ({
        url: `/${sector.brandUrlSuffix}/${sector.path}/`,
        text: sector.name,
        openInNewTab: false,
      })),
    [sectors],
  );

  return (
    <ClassicFooter
      primaryNav={sectorLinks}
      primaryNavHeading="Sectors"
      secondaryNav={footerLinks}
      secondaryNavHeading={footerLinksTitle}
      socialNetworkNav={socialLinks}
      publicNav={publicLinks}
      logo={footerLogo}
      logoAlt="Brand Logo"
      copyright={copyright}
    />
  );
}
