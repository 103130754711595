import React from 'react';
import styled, { css } from 'styled-components';
import { typography } from '../../styles/shared';
import CallToAction from '../CallToAction';

const CTA = styled(CallToAction)`
  & {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 18px;
    font-family: ${typography.type.secondary};
    font-size: 25px;
    font-weight: 400;
    text-decoration: none;
    text-align: center;
    //text-transform: uppercase;
    line-height: 1;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.2s linear;
    outline: none;
    background-color: #efefef;
    color: #333333;
    background-color: ${(props) => props.colours.activeBG};
    color: ${(props) => props.colours.activeText};
    font-size: ${(props) => props.fontSize};

    .c-spinner {
      bottom: 2px;
    }

    &.switcherButton {
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    &.disabled {
      background-color: #efefef;
      color: #333333;
      background-color: ${(props) => props.colours.inactiveBG};
      color: ${(props) => props.colours.inactiveText};
    }

    ${(props) =>
      props.isActive &&
      css`
        background-color: #cc31c2;
        color: #333333;
        background-color: ${(props) => props.colours.activeBG};
        color: ${(props) => props.colours.activeText};

        &.disabled {
          background-color: #cc31c2;
          color: #333333;
          background-color: ${(props) => props.colours.activeBG};
          color: ${(props) => props.colours.activeText};
        }
      `}

    ${(props) =>
      props.icon &&
      css`
        &:after {
          content: '${(props) => props.icon}';
          color: #333333;
          color: '${(props) => props.colours.activeText}';
          font-family: ${typography.type.materialIcons};
          display: inline-block;
          //text-transform: none;
          position: absolute;
          top: 9px;
          padding-left: 6px;
          font-size: 25px;
          font-size: ${(props) => props.fontSize};
        }

        && {
          ${(props) =>
            props.elementType !== 'input' &&
            css`
              padding-right: 36px;
            `}
          min-width: 250px;
        }
      `}
  }
`;

const BlockCTA = (props) => {
  const { colours, className } = props;
  return <CTA {...props} colours={colours} className={className} />;
};

export default BlockCTA;
