import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { redirect } from '../../../../../../../helpers';
import Auth from '../../../../../../../services/AuthService';
import Patterns from '../../../../../../../utils/pattern/patterns';
import { countriesList as countries } from '../../../../../../../services/Dictionary';
import { validateCharacters } from '../../Register/RegisterForm';
import s from './ProfileForm.module.scss';
import Form from '../../shared/Form';
import TextField from '../../shared/TextField';
import SelectField from '../../shared/SelectField';
import HelperText from '../../shared/HelperText';
import InputControl from '../../shared/InputControl';
import Button from '../../shared/Button';

const errors = {
  DEFAULT: 'Something went wrong. Please try again later.',
};
const REQUIRED = 'Required';

const defaultValues = {
  forename: '',
  surname: '',
  companyName: '',
  jobTitle: '',
  country: '',
  telephone: '',
};

export interface Profile {
  forename: string;
  surname: string;
  companyName: string;
  jobTitle: string;
  country: string;
  email: string;
  telephone: string;
  profilePhotoUrl: string;
}

export interface ProfileFormProps {
  profile: Profile;
}

export default function ProfileForm({ profile }: ProfileFormProps) {
  const form = useForm<Profile>({
    defaultValues: {
      ...defaultValues,
      ...profile,
    },
  });
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const {
    formState: { isSubmitting },
  } = form;

  const handleSubmit: SubmitHandler<Profile> = useCallback(async (values) => {
    setError('');

    const response = await Auth.updateAccountDetails(values);
    const data = await response.json();

    if (data.success) {
      redirect('/account/profile/');
      return;
    }

    setError(errors.DEFAULT);
  }, []);

  const countryOptions = useMemo(() => {
    return Object.keys(countries).map((countryCode) => {
      return {
        value: countryCode,
        label: t(countries[countryCode]),
      };
    });
  }, [t]);

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <InputControl>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <TextField
              type="text"
              name="forename"
              label="First Name *"
              validation={{
                required: REQUIRED,
                validate: {
                  isValid: validateCharacters,
                },
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <TextField
              type="text"
              name="surname"
              label="Last Name *"
              validation={{
                required: REQUIRED,
                validate: {
                  isValid: validateCharacters,
                },
              }}
            />
          </div>
        </div>
      </InputControl>
      <InputControl>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <TextField
              type="text"
              name="companyName"
              label="Company *"
              validation={{
                required: REQUIRED,
                validate: {
                  isValid: validateCharacters,
                },
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <TextField
              type="text"
              name="jobTitle"
              label="Job Title *"
              validation={{
                required: REQUIRED,
                validate: {
                  isValid: validateCharacters,
                },
              }}
            />
          </div>
        </div>
      </InputControl>
      <InputControl>
        <SelectField
          name="country"
          options={countryOptions}
          label="Country / Region *"
          validation={{ required: REQUIRED }}
        />
      </InputControl>
      <InputControl>
        <TextField
          type="email"
          name="email"
          label="Email Address *"
          validation={{
            required: REQUIRED,
            validate: {
              isValid(value) {
                return !value || Patterns.isEmail(value)
                  ? true
                  : 'Please enter a valid email address';
              },
            },
          }}
          disabled
        />
      </InputControl>
      <InputControl>
        <TextField
          name="telephone"
          label="Telephone Number"
          validation={{
            validate: {
              isValid(value) {
                return !value || Patterns.isTelephoneNumber(value)
                  ? true
                  : 'Please enter a valid telephone number';
              },
            },
          }}
        />
      </InputControl>
      {error && (
        <HelperText color="danger" className={s.errorHelperText}>
          {error}
        </HelperText>
      )}
      <Button
        type="submit"
        color="accent"
        disabled={isSubmitting}
        className={s.submitButton}
      >
        Update profile
      </Button>
    </Form>
  );
}
