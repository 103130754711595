class VisualEditor {
  _sections = [];
  _activeSectionId = null;
  _prevSectionsLength = 0;

  constructor() {
    this.refresh();
  }

  refresh() {
    this._prevSectionsLength = this.getSectionsLength();
    this._sections = Array.from(
      document.querySelectorAll('section[data-section-id]'),
    );
  }

  getSectionById(sectionId) {
    const [foundSection] = this._sections.filter((section) => {
      return section.dataset.sectionId === sectionId;
    });

    return foundSection;
  }

  getSectionByClass() {
    const [foundSection] = this._sections.filter((section) => {
      return section.classList.contains('visual-editor-active-section');
    });

    return foundSection;
  }

  getActiveSectionId() {
    return this._activeSectionId;
  }

  setActiveSectionId(sectionId) {
    this._activeSectionId = sectionId;
  }

  getSections() {
    return this._sections;
  }

  getSectionsLength() {
    return this._sections.length;
  }

  getPrevSectionsLength() {
    return this._prevSectionsLength;
  }

  getActiveSection() {
    const activeSection = this.getSectionByClass();

    if (activeSection) {
      return activeSection;
    }

    return this.getActiveSectionById();
  }

  getActiveSectionById() {
    return this.getSectionById(this._activeSectionId);
  }

  removeSectionsAttrs() {
    this._sections.forEach((section) => {
      section.classList.remove('visual-editor-active-section');
    });
  }

  setActiveSectionAttrs(section) {
    if (section) {
      section.classList.add('visual-editor-active-section');
    }
  }

  scrollToSection(section, behavior) {
    if (section) {
      this.scrollToPosition(section.offsetTop, behavior);
    }
  }

  scrollToPosition(top, behavior = 'smooth') {
    window.scrollTo({ top, behavior });
  }
}

export default VisualEditor;
