import React from 'react';
import PropTypes from 'prop-types';

export default function Button(props) {
  const {
    style = {},
    type,
    size,
    look,
    className,
    onClick,
    disabled,
    loading,
    textTransform,
  } = props;

  const buttonClasses = ['c-button'];

  if (look) {
    buttonClasses.push('c-button--' + look);
  }

  if (size) {
    buttonClasses.push('c-button--' + size);
  }

  if (textTransform) {
    buttonClasses.push(textTransform);
  } else {
    buttonClasses.push('uppercase');
  }

  if (className) {
    buttonClasses.push(className);
  }

  return (
    <button
      style={style}
      type={type}
      onClick={(event) => onClick && onClick(event)}
      disabled={disabled || loading}
      className={buttonClasses.join(' ')}
    >
      {loading && (
        <div className="c-button__loader">
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      {props.value || props.children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  look: PropTypes.oneOf([
    'classic-without-shadow',
    'classic',
    'primary',
    'secondary',
    'accent',
    'transparent',
    'white',
  ]),
  size: PropTypes.oneOf([
    'default',
    'submit',
    'minuscule',
    'tiny',
    'small',
    'medium',
    'large',
    'submit-wide',
    'full',
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  value: PropTypes.string,
  textTransform: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
};
