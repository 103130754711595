import { ArticleHomeRelatedEvent } from '../../../../../../../../store/features/articles/home';
import {
  getSiteAttendanceLabel,
  getSiteCardDeliveryTypes,
} from '../../../../../../shared/helpers';
import {
  CardAnchor,
  Image,
  Title,
  Badge,
  CardButton,
  Date,
  TextListSpacer,
} from '../../../../../../shared/productFilter/cards/components';
import { SmallFeaturedProductCard } from '../../../../../../shared/productFilter/products';
import { DiscoveryContentTypes } from '../../../../../../../../constants';

export interface SmallRelatedEventCardProps {
  event: ArticleHomeRelatedEvent;
}

export function SmallRelatedEventCard(props: SmallRelatedEventCardProps) {
  const {
    event: {
      details: {
        name: title,
        path,
        date: { startDate, endDate, formattedDate },
        address,
      },
      backgroundImage: { path: imagePath = '' } = {},
      timeAsText,
      deliveryType,
    },
  } = props;
  const deliveryTypes = [deliveryType];
  const date = {
    start: startDate,
    end: endDate,
    formatted: formattedDate,
  };
  const buttonLabel = getSiteAttendanceLabel(
    deliveryTypes,
    DiscoveryContentTypes.EVENT,
    endDate,
  );
  const deliveryTypesList = getSiteCardDeliveryTypes(deliveryTypes, [address]);

  return (
    <CardAnchor to="/" pathPrefix={path}>
      <SmallFeaturedProductCard
        image={<Image imageStyle="smallList" path={imagePath} />}
        title={<Title size="tiny">{title}</Title>}
        date={
          <Date
            dates={[date]}
            deliveryTypes={deliveryTypes}
            timeAsText={timeAsText}
            dateSize="tiny"
            extraDateSize="tiny"
          />
        }
        deliveryTypes={
          deliveryTypesList.length > 0 && (
            <Badge size="tiny">
              <TextListSpacer list={deliveryTypesList} />
            </Badge>
          )
        }
        showMoreBadge={
          buttonLabel && <CardButton size="small">{buttonLabel}</CardButton>
        }
      />
    </CardAnchor>
  );
}
