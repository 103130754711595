import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withBrand } from '../../../../../providers/BrandProvider';
import { getTicketCount } from '../../../../../store/pricingDucks/chosenProducts';
import CallToAction from '../../../../shared/CallToAction/CallToAction';
import './TicketCount.scss';

export function TicketCount(props) {
  const {
    ticketCount,
    siteHeader: { oneLineNavBarTitles } = {},
    brand: { accentColor } = {},
  } = props;

  const componentClassNames = classNames('c-ticket-count', {
    'c-ticket-count--one-line': oneLineNavBarTitles,
  });

  return ticketCount <= 0 ? null : (
    <CallToAction
      to="/purchase/view-basket/"
      type="link"
      sitePath
      className={componentClassNames}
    >
      <i className="material-icons c-ticket-count__icon">shopping_cart</i>
      <span
        className="c-ticket-count__counter"
        style={{ backgroundColor: accentColor }}
      >
        {ticketCount > 9 ? '9+' : ticketCount}
      </span>
    </CallToAction>
  );
}

TicketCount.propTypes = {
  ticketCount: PropTypes.number,
  siteHeader: PropTypes.shape({
    oneLineNavBarTitles: PropTypes.bool,
  }),
  brand: PropTypes.shape({
    accentColor: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    ticketCount: getTicketCount(state),
    siteHeader: state.siteHeader.data,
  };
}

export default connect(mapStateToProps)(withBrand(TicketCount));
