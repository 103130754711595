import { useRootSelector } from '../../../../../../../store/hooks';
import { useGetImgixImageUrl } from '../../../../../../../hooks';
import { renderHTMLString } from '../../../../../../../helpers';
import { selectArticleHome } from '../../../../../../../store/features/articles/home';
import { selectPageConfig } from '../../../../../../../store/features/pageConfig';
import { replaceImagePath } from './replaceImagePath';

import TurtlEmbed from '../../../../../../shared/TurtlEmbed';
import { Button } from '../../../../../shared/Button';
import { Author } from '../Author';
import { DownloadFiles } from '../DownloadFiles';
import { ShareBar } from '../ShareBar';
import ImageTag from '../../../../../../shared/ImageTag/ImageTag';
import { TurtlPosition } from '../../../../../../../store/features/siteArticles/siteArticleDetails';

import styles from './ArticleContent.module.scss';

export const socialMediaConfig = {
  linkedIn: {
    faIcon: 'fab fa-linkedin-in',
    params: [
      'utm_campaign=Share+Widget',
      'utm_medium=Article+Share',
      'utm_source=linkedin',
    ],
  },
  twitter: {
    faIcon: 'fab fa-x-twitter',
    params: [
      'utm_campaign=Share+Widget',
      'utm_medium=Article+Share',
      'utm_source=twitter',
    ],
  },
  facebook: {
    faIcon: 'fab fa-facebook-f',
    params: [
      'utm_campaign=Share+Widget',
      'utm_medium=Article+Share',
      'utm_source=facebook',
    ],
  },
  email: {
    faIcon: 'far fa-envelope',
    params: [
      'utm_campaign=Share+Widget',
      'utm_medium=Article+Share',
      'utm_source=email',
    ],
  },
  link: {
    faIcon: 'fa fa-link',
    params: [
      'utm_campaign=Share+Widget',
      'utm_medium=Article+Share',
      'utm_source=url',
    ],
  },
};

export default function ArticleContent() {
  const {
    data: {
      title,
      summary,
      content,
      author,
      imageUrl: imagePath = '',
      mailToLink: mailToUrl,
      dateModified,
      datePublished,
      turtl = {},
      turtl: { storyPosition: turtlPosition = undefined } = {},
      uploadedPdfUrls = [],
      primaryTopic,
    },
  } = useRootSelector(selectArticleHome);
  const { canonicalUrl } = useRootSelector(selectPageConfig);
  const imageUrl = useGetImgixImageUrl(imagePath);

  const shareBar = (
    <ShareBar
      label="Share this article"
      socialMediaConfig={socialMediaConfig}
      text={title}
      mailToUrl={mailToUrl}
    />
  );
  const turtlEmbed = <TurtlEmbed turtl={turtl} />;

  return (
    <>
      <meta itemProp="mainEntityOfPage" content={canonicalUrl} />
      <meta
        itemProp="datePublished"
        content={datePublished}
        data-testid="meta-date-published"
      />
      <meta
        itemProp="dateModified"
        content={dateModified}
        data-testid="meta-date-modified"
      />
      {imageUrl && <meta itemProp="image.url" content={imageUrl} />}
      <meta
        itemProp="description"
        content={summary}
        data-testid="meta-description"
      />
      {primaryTopic && (
        <div className={styles.primaryTopic}>{primaryTopic}</div>
      )}
      <h1 className={styles.heading} itemProp="headline">
        {title}
      </h1>
      {uploadedPdfUrls.length > 0 && (
        <div
          className={styles.downloadFilesWrapper}
          data-testid="download-files"
        >
          {uploadedPdfUrls.map((url) => (
            <Button
              key={url}
              to={`/uploads/${url}`}
              size="medium"
              startIcon="get_app"
            >
              Download Now
            </Button>
          ))}
        </div>
      )}
      <div className={styles.authorWrapper}>
        <Author author={author} publishDate={datePublished} />
      </div>
      {imageUrl && (
        <ImageTag
          src={imageUrl}
          imgixParams="w=1045"
          className={styles.image}
        />
      )}
      <div className={styles.shareBarWrapper}>{shareBar}</div>
      {turtlPosition === TurtlPosition.TOP && (
        <div className={styles.turtlWrapper}>{turtlEmbed}</div>
      )}
      <div itemProp="articleBody" className={styles.content}>
        {renderHTMLString(replaceImagePath(content))}
      </div>
      {uploadedPdfUrls.length > 0 && (
        <div
          className={styles.downloadFilesWidgetWrapper}
          data-testid="download-files"
        >
          <DownloadFiles urls={uploadedPdfUrls} />
        </div>
      )}
      {turtlPosition === TurtlPosition.BOTTOM && (
        <div className={styles.turtlWrapper}>{turtlEmbed}</div>
      )}
      <div className={styles.shareBarWrapper}>{shareBar}</div>
    </>
  );
}
