import { useEffect, useState } from 'react';
import Auth from '../../../../../../services/AuthService';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectBrandHubLayout } from '../../../store/features/layout';
import { selectBrandHubOptions } from '../../../store/features/options';
import s from './EditProfile.module.scss';
import StaticPageLayout from '../../../layouts/StaticPageLayout';
import { MetaTags } from '../../../../shared/MetaTags';
import PublicPageLayout from '../shared/PublicPageLayout';
import Paper from '../shared/Paper';
import ProfileForm, { Profile } from './ProfileForm';

export function EditProfile() {
  const {
    data: {
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectBrandHubLayout);
  const {
    data: { name: hubName },
  } = useRootSelector(selectBrandHubOptions);

  const [profile, setProfile] = useState<Profile>();

  useEffect(() => {
    async function fetchProfile() {
      try {
        const response = await Auth.fetchAccountDetails('core');
        const data = await response.json();
        setProfile(data.profile?.core || {});
      } catch (error) {
        console.error(error);
      }
    }

    fetchProfile();
  }, []);

  return (
    <StaticPageLayout className={s.body}>
      <MetaTags
        title="Edit Profile"
        titleSuffix={hubName}
        thumbnail={brandLogo?.path}
      />
      <PublicPageLayout>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
              {profile && (
                <Paper heading="Edit profile" backAnchor="/account/profile/">
                  <ProfileForm profile={profile} />
                </Paper>
              )}
            </div>
          </div>
        </div>
      </PublicPageLayout>
    </StaticPageLayout>
  );
}
