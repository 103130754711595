import { combineReducers } from '@reduxjs/toolkit';
import pageReducer from './features/page';
import layoutReducer from './features/layout';
import optionsReducer from './features/options';
import industryHomeReducer from './features/industryHome';
import industryHeaderReducer from './features/industryHeader';

const brandHubReducer = {
  brandHub: combineReducers({
    page: pageReducer,
    layout: layoutReducer,
    options: optionsReducer,
    industryHome: industryHomeReducer,
    industryHeader: industryHeaderReducer,
  }),
};

export default brandHubReducer;
