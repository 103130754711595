import React from 'react';
import SiteLayout from '../../../layouts/SiteLayout/SiteLayout';
import ServerErrorSection from '../../../siteBaseSections/ServerErrorSection/ServerErrorSection';

export default function ServerErrorView() {
  return (
    <SiteLayout helmet={{ title: 'Error' }}>
      <ServerErrorSection />
    </SiteLayout>
  );
}
