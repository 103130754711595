import React, { Component } from 'react';
import { scroller } from 'react-scroll';
import classnames from 'classnames';

export class TabbedViewSwitcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: props.noInitialState ? null : props.viewMap[0].view,
      activeTabIndex: props.noInitialState ? null : 0,
    };
  }

  handleTabClick = (view, i) => () => {
    this.setState({ view, activeTabIndex: i });
    scroller.scrollTo('viewOuter', {
      duration: 1000,
      offset: -60,
      smooth: true,
    });
  };

  renderTabs = () => {
    return this.props.viewMap.map((viewObject, i) =>
      React.cloneElement(viewObject.tab, {
        ...viewObject.tab.props,
        key: i,
        isActive: this.state.activeTabIndex === i,
        activeClass: this.state.activeTabIndex !== null ? 'disabled' : '',
        handleClick: this.handleTabClick(viewObject.view, i),
      }),
    );
  };

  render() {
    const {
      tabContainerClass = '',
      tabInnerClass = '',
      viewContainerClass = '',
      viewInnerClass = '',
      className = '',
    } = this.props;

    const viewOuterClass = classnames({
      viewOuter: this.state.view,
    });

    return (
      <div className={className}>
        <div className="tabOuter">
          <div className={`container ${tabContainerClass}`}>
            <div className={`tabInner ${tabInnerClass}`}>
              {this.renderTabs()}
            </div>
          </div>
        </div>
        <div className={viewOuterClass} name="viewOuter">
          {this.state.view && (
            <div className={`container ${viewContainerClass}`}>
              <div className={`viewInner ${viewInnerClass}`}>
                {this.state.view}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TabbedViewSwitcher;
