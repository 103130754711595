import React from 'react';
import PropTypes from 'prop-types';

import './ConfirmationMessage.scss';

export default function ConfirmationMessage({ message = {} }) {
  return (
    <div
      className="confirmation-message"
      data-test-lead-gen="confirmation-message"
    >
      <div className="confirmation-message__container">
        <div
          className={`confirmation-message__icon ${
            message.error ? 'discount-message__icon--error' : ''
          }`}
          data-test-lead-gen="confirmation-message-icon"
        >
          <i className="material-icons" data-test-lead-gen="material-icons">
            {message.error ? 'cancel' : 'check_circle'}
          </i>
        </div>
        <div className="confirmation-message__text-container">
          <p
            className={`confirmation-message__text ${
              message.error ? 'confirmation-message__text--error' : ''
            }`}
            data-test-lead-gen="confirmation-message-text"
          >
            {message.success || message.error}
          </p>
        </div>
      </div>
    </div>
  );
}

ConfirmationMessage.propTypes = {
  message: PropTypes.object,
};
