import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll/modules';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { withBrand } from '../../providers/BrandProvider';
import { isBrowser } from '../../helpers';
import LeadGenForm from './LeadGenForm/LeadGenForm';
import LeadGenTeaser from './LeadGenTeaser/LeadGenTeaser';
import LeadGenConfirmRegistration from './LeadGenConfirmRegistration/LeadGenConfirmRegistration';
import LoginRegisterSwitcher from '../../informakit/components/LoginRegisterSwitcher';
import gtm from '../../utils/initialGTM';
import './LeadGenContainer.scss';

export class LeadGenContainer extends Component {
  static propTypes = {
    leadGenForm: PropTypes.object,
    pageConfig: PropTypes.object,
    brand: PropTypes.object,
    audienceName: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      success: false,
      username: null,
    };
  }

  componentDidMount() {
    gtm.pushContentType(this.props.leadGenForm.data.contentType);
  }

  componentDidUpdate() {
    if (isBrowser() && this.state.success && window.pageYOffset > 0) {
      Scroll.animateScroll.scrollToTop();
    }
  }

  setSuccessAndUsernameStates = (success, username) => {
    this.setState({
      success,
      username,
    });
  };

  render() {
    const {
      leadGenForm: {
        data: { teaser = {}, gateId, form = {}, contentType } = {},
        leadGenType: leadGenTypeFromStore,
      } = {},
      pageConfig: {
        tenantId,
        tenantConfig: { outputName } = {},
        isPreview,
        leadGenType: leadGenTypeFromServer,
        location: { origin, pathname } = {},
        siteType,
      } = {},
      brand: { secondaryColor, accentColor, darkAccentColor } = {},
      audienceName,
    } = this.props;

    // we set leadGenType on server side in pageConfig
    // but leadGenType in pageConfig is undefined when you get leadGen form for agenda without reload page (SPA way) by click on download agenda btn
    // only for SPA case we get leadGenType from redux store
    const leadGenType = leadGenTypeFromServer || leadGenTypeFromStore;
    const { success, username } = this.state;
    const formClass =
      leadGenType === 'article'
        ? 'lead-gen-container__form-article'
        : 'lead-gen-container__form-event';
    const buttonStyle =
      accentColor && darkAccentColor
        ? {
            backgroundColor: accentColor,
            borderColor: darkAccentColor,
          }
        : {};
    const isTeaser = !!(
      teaser.teaserBody ||
      teaser.teaserHeading ||
      teaser.articleTitle
    );
    const isArticle = leadGenType === 'article';

    const teaserClass = isTeaser
      ? 'lead-gen-container__teaser'
      : 'lead-gen-container__teaser--empty';
    const containerClass = isArticle
      ? 'lead-gen-container article-container'
      : 'lead-gen-container';
    const formContainerClass =
      form.type === 'REGISTRATION'
        ? 'lead-gen-container__switcher-row'
        : isArticle
        ? 'lead-gen-container__update-row article-container'
        : 'lead-gen-container__update-row';

    const LGFormData = {
      leadGenType,
      color: secondaryColor,
      audienceName,
      buttonStyle,
      gateId,
      pageUrl: [origin, pathname].filter(Boolean).join(''),
      setSuccessAndUsernameStates: this.setSuccessAndUsernameStates,
      tenantName: outputName,
    };

    const LGLoginFormData = {
      leadGenType,
      color: secondaryColor,
      buttonStyle,
      isPreview,
      tenantName: outputName,
      tenantId,
    };

    const lgContainerClass = classNames(`${teaserClass} col-xs-12`, {
      'col-md-9': isArticle,
    });

    return (
      <div style={{ direction: 'ltr' }}>
        {success ? (
          <LeadGenConfirmRegistration
            success={success}
            username={username}
            leadGenType={leadGenType}
            isPreview
            data-test-lead-gen="lead-gen-confirm-registration"
          />
        ) : (
          <>
            <div className="lead-gen-container__teaser-row">
              <div className="lead-gen-container container">
                <div
                  className={`lead-gen-container__wrapper ${
                    isTeaser ? '' : 'lead-gen-container__wrapper--empty-teaser'
                  }`}
                >
                  <div className={lgContainerClass}>
                    <LeadGenTeaser
                      teaserData={teaser}
                      leadGenType={leadGenType}
                      isGateId={gateId}
                      brand={this.props.brand}
                      contentType={contentType}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={formContainerClass}>
              <div
                className={containerClass}
                data-test-lead-gen="lead-gen-container"
              >
                {form.type === 'REGISTRATION' ? (
                  <LoginRegisterSwitcher
                    LGFormData={LGFormData}
                    LGLoginFormData={LGLoginFormData}
                    leadGenType={leadGenType}
                    formClass={formClass}
                    data-test-lead-gen="lead-gen-switcher"
                    siteType={siteType}
                  />
                ) : (
                  <>
                    <div className="update-title-container">
                      <div className="container">
                        <div className={`col-xs-12 col-md-9 ${formClass}`}>
                          <h2 className="update-title">
                            {leadGenType === 'article'
                              ? 'Additional information is required to access this content'
                              : 'Access to this content requires additional details'}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="update-form-container">
                      <div className="container">
                        <div className={`col-xs-12 col-md-9 ${formClass}`}>
                          <LeadGenForm {...LGFormData} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    leadGenForm: state.leadGenForm,
    pageConfig: state.pageConfig,
  };
}

export default withTranslation()(
  connect(mapStateToProps)(withBrand(LeadGenContainer)),
);
