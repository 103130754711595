import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../FormField/FormField';
import FormHelper from '../FormHelper/FormHelper';

import './PasswordField.scss';

export default class PasswordField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    withConfirmField: PropTypes.bool,
    withStrengthBar: PropTypes.bool,
    withShowButton: PropTypes.bool,
    withStarChar: PropTypes.bool,
    newAccount: PropTypes.bool,
    isPassValidation: PropTypes.bool,
    form: PropTypes.object,
    onValueChanged: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasLowerCase: false,
      hasUpperCase: false,
      hasNumbers: false,
      hasMinLength: false,
      passwordIsVisible: false,
    };

    this.updateStrengthBar = this.updateStrengthBar.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  togglePasswordVisibility() {
    this.setState((prevState) => ({
      passwordIsVisible: !prevState.passwordIsVisible,
    }));
  }

  onValueChange(val, fieldName, updateBar) {
    if (
      this.props.onValueChanged &&
      typeof this.props.onValueChanged === 'function'
    ) {
      this.props.onValueChanged(val, fieldName);
    }

    if (this.props.withStrengthBar && updateBar) {
      this.updateStrengthBar(val);
    }
  }

  updateStrengthBar(val) {
    const minLength = 8;

    const hasMinLength = val.length >= minLength;
    const hasLowerCase = /[a-z]/.test(val);
    const hasUpperCase = /[A-Z]/.test(val);
    const hasNumbers = /\d/.test(val);

    this.setState({ hasMinLength, hasLowerCase, hasUpperCase, hasNumbers });
  }

  render() {
    const {
      className = null,
      isPassValidation = true,
      newAccount = false,
      withStarChar = false,
      withConfirmField = false,
      withStrengthBar = false,
      withShowButton = false,
    } = this.props;
    let ConfirmField = null;
    let StrengthBar = null;
    let showPasswordButton = null;

    if (withConfirmField) {
      ConfirmField = (
        <FormField
          type="password"
          name="confirmPassword"
          label={`Confirm Password ${withStarChar ? '*' : ''}`}
          pattern={{
            required: true,
            password: true,
            match: {
              field: 'password',
              refName: 'formRef',
              form: this.props.form,
              message: 'Passwords do not match',
            },
          }}
          onChange={(val) => this.onValueChange(val, 'confirmPassword')}
        />
      );
    }

    if (withShowButton) {
      showPasswordButton = (
        <span
          className="show-password-btn"
          onClick={this.togglePasswordVisibility}
        >
          {this.state.passwordIsVisible ? 'Hide' : 'Show'}
        </span>
      );
    }

    if (withStrengthBar) {
      StrengthBar = (
        <div className="form-row">
          <div className="c-strength-password-container">
            <FormHelper className="password-helper">
              <p>
                Minimum requirements for a <span>strong</span> password:
              </p>
            </FormHelper>
            <ul>
              <li className={this.state.hasMinLength ? 'valid' : 'invalid'}>
                8 characters
              </li>
              <li className={this.state.hasUpperCase ? 'valid' : 'invalid'}>
                1 uppercase
              </li>
              <li className={this.state.hasLowerCase ? 'valid' : 'invalid'}>
                1 lowercase
              </li>
              <li className={this.state.hasNumbers ? 'valid' : 'invalid'}>
                1 number
              </li>
            </ul>
          </div>
        </div>
      );
    }

    return (
      <div className={`form-row ${className}`}>
        <FormField
          // placeholder={placeholder}
          type={this.state.passwordIsVisible ? 'text' : 'password'}
          name="password"
          label={`${newAccount ? 'New' : ''} Password ${
            withStarChar ? '*' : ''
          }`}
          pattern={{ required: true, password: isPassValidation }}
          onChange={(val) => this.onValueChange(val, 'password', true)}
          rowClassName={`${withStrengthBar ? 'password-row' : ''}`}
        >
          {showPasswordButton}
        </FormField>
        {ConfirmField}
        {StrengthBar}
      </div>
    );
  }
}
