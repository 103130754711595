import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { renderCSSString } from '../../../../../../helpers';
import { withBrand } from '../../../../../../providers/BrandProvider';
import { addElementsPropertiesAction } from '../../../../../../store/commonDucks/pageConfig';
import { useComponentProperties } from '../../../../../../hooks/useComponentProperties';
import MultiLevelNavList from '../MultiLevelNavList/MultiLevelNavList';
import MultiLevelNavItem from '../MultiLevelNavItem/MultiLevelNavItem';
import MultiLevelNavMobile from '../MultiLevelNavMobile/MultiLevelNavMobile';
import CallToAction from '../../../../../shared/CallToAction/CallToAction';
import ImageTag from '../../../../../shared/ImageTag/ImageTag';
import Hamburger from '../../Hamburger/Hamburger';
import TicketCount from '../../TicketCount/TicketCount';
import MagnifyingGlassSearch from '../../../../../shared/MagnifyingGlassSearch';
import './MultiLevelNavMenu.scss';

const purchasePageRegExp = /^purchase\//i;

export function MultiLevelNavMenu(props) {
  const {
    navBarItems = [],
    withHomeNav,
    withMobileLogo,
    background,
    siteHeader: {
      data: {
        title,
        oneLineNavBarTitles,
        enableSearchField,
        enableCallToActionButton,
        callToActionButton,
        fullScreen,
        bookingForm: { displayNavBarCTA } = {},
      },
    } = {},
    brand: { logoPath },
    addElementsPropertiesAction,
    leadGenForm: {
      data: { form: { navBarOption = 'DEFAULT' } = {} } = {},
    } = {},
    pageConfig: { siteSubPath, tenantConfig: { ui: { theme } = {} } = {} } = {},
    miniBasket,
  } = props;

  const { t } = useTranslation();
  const componentRef = useRef(null);
  const leadGenGate =
    navBarOption === 'HAMBURGER_MENU_ONLY' ||
    navBarOption === 'HAMBURGER_MENU_PLUS_BOOK_NOW_BTN';

  useComponentProperties(
    componentRef,
    'multiLevelNavMenu',
    addElementsPropertiesAction,
  );

  const componentClassNames = classNames('c-multi-level-nav-menu', {
    'c-multi-level-nav-menu--fullscreen': fullScreen,
  });
  const isLargeCta = [
    'OneOfAKind',
    'NajahStack',
    'ArtToronto',
    'TheArtistProject',
    'InteriorDesign',
  ].includes(theme);

  const bookNowButtonClassNames = classNames(
    'c-multi-level-nav-menu__book-now',
    {
      'c-multi-level-nav-menu__book-now--large':
        !oneLineNavBarTitles || isLargeCta,
    },
  );
  const mobileMenuClassName = classNames('c-multi-level-nav-menu__body', {
    'c-multi-level-nav-menu__body--lead-gen-gate': leadGenGate,
    'c-multi-level-nav-menu__body--hamburger-menu-only':
      navBarOption === 'HAMBURGER_MENU_ONLY',
  });

  const displayBookNowCTA = purchasePageRegExp.test(siteSubPath)
    ? displayNavBarCTA
    : true;

  const displaySecondaryCallToAction =
    enableCallToActionButton && callToActionButton;

  const desktopMenu = (
    <div className="c-multi-level-nav-menu__body">
      <div className="c-multi-level-nav-menu__body-left">
        <MultiLevelNavList
          withHomeNav={withHomeNav}
          navBarItems={navBarItems}
          level="1"
        >
          {(item) => (
            <MultiLevelNavItem
              navBarItem={item}
              background={background}
              level="1"
            >
              {({ groupItems }) => (
                <MultiLevelNavList navBarItems={groupItems} level="2">
                  {(item) => <MultiLevelNavItem navBarItem={item} level="2" />}
                </MultiLevelNavList>
              )}
            </MultiLevelNavItem>
          )}
        </MultiLevelNavList>
      </div>
      <div className="c-multi-level-nav-menu__body-right">
        {enableSearchField && (
          <>
            {renderCSSString(`:root { --cover-shadow-color: ${background}; }`)}
            <div className="c-multi-level-nav-menu__cover-shadow" />
          </>
        )}
        {enableSearchField && <MagnifyingGlassSearch />}
        {miniBasket || <TicketCount />}
        {displaySecondaryCallToAction && (
          <CallToAction
            size={isLargeCta ? 'large' : 'medium'}
            look="hollow"
            ctaConfig={callToActionButton}
            noWrap
            isAutoWidthOnMobile
            className={bookNowButtonClassNames}
          />
        )}
        {displayBookNowCTA && (
          <CallToAction
            size={isLargeCta ? 'large' : 'medium'}
            ctaConfig={{ type: 'BUY_LINK' }}
            noWrap
            isAutoWidthOnMobile
            className={bookNowButtonClassNames}
          />
        )}
      </div>
    </div>
  );

  const mobileMenu = (
    <div className={mobileMenuClassName}>
      <div className="c-multi-level-nav-menu__body-left">
        {withHomeNav && (
          <Hamburger align="left" withWord>
            <MultiLevelNavMobile
              withHomeNav={withHomeNav}
              navBarItems={navBarItems}
              background={background}
            />
          </Hamburger>
        )}
        {withMobileLogo && (
          <CallToAction
            type="link"
            sitePath
            className="c-multi-level-nav-menu__link"
          >
            <ImageTag
              src={logoPath}
              alt={title}
              lazy={false}
              imgixParams="h=60"
              className="c-multi-level-nav-menu__logo"
            />
          </CallToAction>
        )}
      </div>
      <div className="c-multi-level-nav-menu__body-right">
        {enableSearchField && (
          <div
            className="c-multi-level-nav-menu__magnifying-glass-search"
            style={{ background }}
          >
            <MagnifyingGlassSearch disableAnimation />
          </div>
        )}
        {miniBasket || <TicketCount />}
        {displayBookNowCTA && (
          <CallToAction
            size="medium"
            ctaConfig={{ type: 'BUY_LINK' }}
            noWrap
            isAutoWidthOnMobile
            className="c-multi-level-nav-menu__book-now-mobile"
          >
            {({ props, translationKey, bookingMobileCustomText }) => {
              if (props.siteHeader.bookingForm.bookingFormType === 'CUSTOM') {
                return bookingMobileCustomText;
              }

              return t(translationKey + '.mobile');
            }}
          </CallToAction>
        )}
        {!withHomeNav && (
          <Hamburger align="right">
            <MultiLevelNavMobile
              withHomeNav={withHomeNav}
              navBarItems={navBarItems}
              background={background}
            />
          </Hamburger>
        )}
      </div>
      {displaySecondaryCallToAction && (
        <div className="c-multi-level-nav-menu__body-bottom">
          <CallToAction
            size="medium"
            noWrap
            look="hollow"
            ctaConfig={callToActionButton}
            isAutoWidthOnMobile
            className="c-multi-level-nav-menu__secondary-cta"
          />
        </div>
      )}
    </div>
  );

  return (
    <div
      ref={componentRef}
      className={componentClassNames}
      style={{ background }}
    >
      {leadGenGate ? (
        <>{mobileMenu}</>
      ) : (
        <>
          <div className="c-multi-level-nav-menu__desktop">{desktopMenu}</div>
          <div className="c-multi-level-nav-menu__mobile">{mobileMenu}</div>
        </>
      )}
    </div>
  );
}

MultiLevelNavMenu.propTypes = {
  navBarItems: PropTypes.array.isRequired,
  background: PropTypes.string,
  withHomeNav: PropTypes.bool,
  withMobileLogo: PropTypes.bool,
  siteHeader: PropTypes.object,
  brand: PropTypes.object,
  addElementsPropertiesAction: PropTypes.func,
  leadGenForm: PropTypes.object,
  pageConfig: PropTypes.shape({
    tenantConfig: PropTypes.shape({
      ui: PropTypes.shape({
        theme: PropTypes.string,
      }),
    }),
  }),
  miniBasket: PropTypes.node,
};

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader,
    leadGenForm: state.leadGenForm,
    pageConfig: state.pageConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addElementsPropertiesAction(elProperties) {
      return dispatch(addElementsPropertiesAction(elProperties));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withBrand(MultiLevelNavMenu));
