import { HTMLAttributes, PropsWithChildren } from 'react';
import { PropsWithTestId } from '../../../../../../types';
import cn from 'classnames';
import styles from './ResetFilterButton.module.scss';

export enum ResetFilterButtonSizes {
  normal = 'normal',
  medium = 'medium',
}

export interface ResetFilterButtonProps
  extends PropsWithTestId<PropsWithChildren<HTMLAttributes<HTMLSpanElement>>> {
  size: keyof typeof ResetFilterButtonSizes;
  disabled?: boolean;
  iconName?: string;
}

export function ResetFilterButton(props: ResetFilterButtonProps) {
  const {
    size,
    disabled,
    iconName,
    className,
    children,
    testId,
    ...restProps
  } = props;

  const wrapperClassName = cn(styles.button, className, styles[size], {
    [styles.disabled]: disabled,
  });

  return (
    <span {...restProps} className={wrapperClassName} data-testid={testId}>
      <span className={styles.text}>{children}</span>
      {iconName && (
        <span
          className={cn('material-icons', styles.icon)}
          data-testid="reset-filter-icon"
        >
          {iconName}
        </span>
      )}
    </span>
  );
}

ResetFilterButton.defaultProps = {
  size: ResetFilterButtonSizes.normal,
  testId: 'reset-filter-button-action',
};
