import React from 'react';
import styled from 'styled-components';
import MenuItemGroup from '../../utils/MenuItemGroup';
import SectionHeading from '../SectionHeading';
import { breakpoint } from '../../styles/shared';

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 230px;

  @media screen and (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    text-align: center;
  }

  @media screen and (min-width: ${breakpoint.tabletPlusBreakpoint}) and (max-width: ${breakpoint.desktopMediumBreakpoint}) {
    min-width: 200px;
  }

  @media screen and (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    margin-bottom: 60px;
  }
`;

const Item = styled.a`
  display: flex;
  padding-bottom: 30px;

  &:last-of-type {
    padding-bottom: 0px;
  }

  @media screen and (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    justify-content: center;
  }
`;

const StackedMenu = styled.div`
  width: 75%;
  padding-right: 35px;

  @media screen and (max-width: ${breakpoint.desktopBreakpoint}) {
    width: 100%;
    padding-right: 0;
  }
`;

const MenuAligner = styled.br`
  display: none;
  @media screen and (min-width: ${breakpoint.tabletPlusBreakpoint}) and (max-width: ${breakpoint.desktopMediumBreakpoint}) {
    display: inline-block;
  }
`;

const MenuItem = ({ link, content }) => {
  return <Item href={link}>{content}</Item>;
};

const OneColumnMenu = ({ title, menuItems }) => {
  return (
    <Menu>
      <SectionHeading>
        {title}
        <MenuAligner />
        &nbsp;
      </SectionHeading>
      <StackedMenu>
        <MenuItemGroup WrappingComponent={MenuItem} menuItems={menuItems} />
      </StackedMenu>
    </Menu>
  );
};

export default OneColumnMenu;
