import React from 'react';
import Moment from '../modules/react-moment';

import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import 'moment/locale/ru';
import 'moment/locale/vi';
import 'moment/locale/fr-ca';
import 'moment/locale/ar';
import 'moment/locale/zh-cn';

import { getCorrectLocale } from '../helpers';

export default function formattedTime(
  data,
  format = 'dddd, D MMMM YYYY',
  locale = 'en',
  parse = 'YYYY-MM-DD',
) {
  const momentLocale = getCorrectLocale(locale);

  return (
    <Moment parse={parse} format={format} locale={momentLocale}>
      {data}
    </Moment>
  );
}
