import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';

import Patterns from '../../../utils/pattern/patterns';
import Auth from '../../../services/AuthService';

import LeadGenInput from '../LeadGenFormItems/LeadGenInput/LeadGenInput';
import Link from '../../shared/Link/Link';
import StyledCTA from '../../../informakit/components/StyledCTA';
import { withTranslation } from 'react-i18next';

import './LeadGenLoginForm.scss';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  } else if (!Patterns.isEmail(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  if (!values.password) {
    errors.password = 'Required';
  }

  return errors;
};

export class LeadGenLoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    leadGenType: PropTypes.string,
    submitting: PropTypes.bool,
    color: PropTypes.string,
    buttonStyle: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      submitError: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    if (values.company) return;

    return Auth.login({
      username: values.email,
      password: values.password,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token && data.expiresIn) {
          Auth.setToken(data);
          document.location.reload(true);
        } else if (data.error) {
          const { t } = this.props;
          let errorText = data.error;

          if (data.errorCode) {
            switch (data.errorCode) {
              case 'PASSWORD_RESET_REQUIRED':
                errorText = t('component.login.errors.password-expired');
                break;
              case 'USER_NOT_FOUND':
                errorText = t('component.login.errors.account-not-found');
                break;
              default:
                break;
            }
          }
          this.setState({
            submitError: errorText,
          });
          throw new Error(data.error);
        }
      })
      .catch((error) => {
        console.error('error', error);
      });
  }

  render() {
    const {
      leadGenType,
      tenantName,
      tenantId,
      color,
      buttonStyle = {},
      submitting,
    } = this.props;
    const articleHeaderClass =
      leadGenType === 'article' ? 'lead-gen-login-form__header--article' : '';
    const articleLinkClass =
      leadGenType === 'article' ? 'lead-gen-login-form__link-article' : '';
    const buttonClass =
      leadGenType === 'article'
        ? 'c-button__lead-gen-type-1'
        : 'c-button__lead-gen-type-2';
    const linkStyle = color && { color };
    const loginButtonText =
      tenantId === 'INFORMACONNECT' ? 'Login to access' : 'Login to continue';

    return (
      <div
        className="lead-gen-login-form"
        data-test-lead-gen="lead-gen-login-form"
      >
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="row">
            <div className="col-xs-12">
              <h3
                className={`lead-gen-login-form__header ${articleHeaderClass}`}
                data-test-lead-gen="lead-gen-login-form-header"
              >
                {`Log in to your ${tenantName} account`}
              </h3>
              {this.state.submitError && (
                <div className="lead-gen-login-form__error">
                  {this.state.submitError}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <Field
                name="email"
                label="Email"
                component={LeadGenInput}
                isRequired
                inputType="text"
                customId="emailLogIn"
                autofill
              />
              <div hidden>
                <Field type="text" name="company" component={LeadGenInput} />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6">
              <Field
                name="password"
                label="Password"
                component={LeadGenInput}
                isRequired
                inputType="password"
                isButtonShown={false}
                customId="passwordLogIn"
                autofill
              />
              <Link
                style={linkStyle}
                to="/account/forgotten-password"
                className={`lead-gen-login-form__forgot-link ${articleLinkClass}`}
                data-test-lead-gen="lead-gen-login-form-forgot-link"
                parent
              >
                Forgot your password?
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <StyledCTA
                elementType="button"
                messaging={loginButtonText}
                colours={{
                  activeBG: buttonStyle.backgroundColor,
                  borderColour: buttonStyle.borderColor,
                }}
                styleType={leadGenType === 'article' ? 'curved' : 'block'}
                className={`lead-gen-login-button ${buttonClass}`}
                data-test-lead-gen="lead-gen-login-button"
                disabled={submitting}
                loading={submitting}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'leadGenLogin',
  validate,
})(withTranslation()(withCookies(LeadGenLoginForm)));
