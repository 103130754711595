import React from 'react';
import cn from 'classnames';
import { L2Topic } from '../../../../store/features/industryHome';
import TopicCard from '../../../../../shared/productFilter/cards/TopicCard';
import styles from './IndustryHomeModule.module.scss';

export interface IndustryHomeModuleProps {
  title: string;
  text?: string;
  featuredTopics: L2Topic[];
  relatedTopics: L2Topic[];
  withJigsaw?: boolean;
}

export default function IndustryHomeModule(props: IndustryHomeModuleProps) {
  const { title, text, featuredTopics, relatedTopics, withJigsaw } = props;
  const headerClassName = cn(styles.header, {
    [styles.withJigsawHeader]: withJigsaw,
  });
  const featuredTopicGridClassName = cn('col-xs-12', {
    'col-sm-6': featuredTopics.length > 1,
  });

  return (
    <div className="container">
      {withJigsaw && (
        <div className="row">
          <div className="col-xs-12">
            <div
              data-testid="industry-jigsaw"
              className={styles.industryJigsaw}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-xs-12 col-sm-8 col-sm-offset-2">
          <div className={headerClassName}>
            <h1 className={styles.title}>{title}</h1>
            {text && <p className={styles.text}>{text}</p>}
          </div>
        </div>
      </div>
      <div className="row">
        {featuredTopics.map((topic) => (
          <div className={featuredTopicGridClassName} key={topic.id}>
            <TopicCard topic={topic} className={styles.featuredCard} />
          </div>
        ))}
        {relatedTopics.map((topic) => (
          <div key={topic.id} className="col-xs-12 col-sm-4 col-md-3">
            <TopicCard topic={topic} />
          </div>
        ))}
      </div>
    </div>
  );
}
