import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withBrand } from '../../../../../../providers/BrandProvider';
import CallToAction from '../../../../../shared/CallToAction/CallToAction';
import ImageTag from '../../../../../shared/ImageTag/ImageTag';
import './MultiLevelNavHomeBtn.scss';
import { renderCSSString } from '../../../../../../helpers';

export function MultiLevelNavHomeBtn({
  siteHeader: { oneLineNavBarTitles, navBarLogo: { path } = {} },
  pageConfig: {
    sitePath,
    siteSubPath,
    tenantConfig: {
      ui: { theme },
    },
    location: { pathname = '' } = {},
  },
  brand: { accentColor },
}) {
  const { t } = useTranslation();

  const isActive = sitePath === '/' ? pathname === '/' : siteSubPath === '';
  const componentClassNames = classNames('c-multi-level-nav-home-btn', {
    'c-multi-level-nav-home-btn--active': isActive,
    'c-multi-level-nav-home-btn--one-line': oneLineNavBarTitles,
  });

  return (
    <CallToAction
      type="link"
      ctaConfig={{ type: 'HOME_LINK' }}
      className={componentClassNames}
      style={{ borderColor: isActive ? accentColor : null }}
    >
      <span className="c-multi-level-nav-home-btn__text">
        {t('event.home-page')}
      </span>
      {path ? (
        <ImageTag
          className="c-multi-level-nav-home-btn__nav-logo"
          src={path}
          lazy={false}
        />
      ) : (
        <i className="material-icons c-multi-level-nav-home-btn__icon">home</i>
      )}

      {[
        'OneOfAKind',
        'NajahStack',
        'ArtToronto',
        'TheArtistProject',
        'InteriorDesign',
      ].includes(theme) &&
        renderCSSString(`
          .c-multi-level-nav-home-btn:not(.c-multi-level-nav-home-btn--active):hover {
            border-top-color: ${accentColor};
          }
      `)}
    </CallToAction>
  );
}

MultiLevelNavHomeBtn.propTypes = {
  siteHeader: PropTypes.shape({
    oneLineNavBarTitles: PropTypes.bool,
    navBarLogo: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }),
  }),
  pageConfig: PropTypes.shape({
    siteSubPath: PropTypes.string,
  }),
  brand: PropTypes.shape({
    accentColor: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader.data,
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(withBrand(MultiLevelNavHomeBtn));
