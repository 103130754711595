import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getImgixUrl } from '../../../../helpers';
import './BaseSectionWrapper.scss';

export function BaseSectionWrapper(props) {
  const {
    size = 'medium',
    backgroundColor,
    backgroundImage,
    className = '',
    section: { type: sectionType = '', elementId: sectionId } = {},
    pageConfig: { tenantId } = {},
    style,
  } = props;

  const componentClassNames = classNames('c-base-section-wrapper lazy', {
    [`c-base-section-wrapper--${sectionType
      .replace(/^event_/, '')
      .replace(/_/g, '-')}`]: sectionType,
    [`c-base-section-wrapper--${size}`]: true,
    [className]: className,
  });

  return (
    <section
      id={sectionId}
      data-background-image={getImgixUrl(tenantId, backgroundImage)}
      className={componentClassNames}
      style={{ ...style, backgroundColor }}
    >
      {props.children}
    </section>
  );
}

BaseSectionWrapper.propTypes = {
  section: PropTypes.shape({
    type: PropTypes.string.isRequired,
    elementId: PropTypes.string,
  }).isRequired,
  size: PropTypes.oneOf(['zero', 'tiny', 'small', 'medium', 'large', 'huge']),
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  style: PropTypes.object,
  pageConfig: PropTypes.shape({
    tenantId: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(BaseSectionWrapper);
