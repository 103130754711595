import qs from 'qs';
import { createSelector } from '@reduxjs/toolkit';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../store/features/generic';
import { GenericState } from '../../../../../types';
import { RootState } from '../../../../../store/store';
import { selectPageConfig } from '../../../../../store/features/pageConfig';
import {
  PricingPackages,
  SitePricingPackagesData,
  SitePricingPackagesDeleteParams,
  SitePricingPackagesParams,
  SitePricingPackagesUpdateParams,
  SitePricingSavedPackagesData,
} from './types';
import { fetchSitePricingBookingClosed } from '../bookingClosed';
import { mapPackagesByDelegates } from '../../../helpers/viewBasket/mapPackagesByDelegates';
import { DiscountResultTypes } from '../../../types/enums';
import { getPriceAsNumber } from '../../../helpers/shared/getPriceAsNumber';

export const createSitePricingPackage = createGenericAsyncThunk<
  SitePricingPackagesData,
  SitePricingPackagesParams
>(
  'sitePricingPackages/createSitePricingPackage',
  async (params, { extra, rejectWithValue, getState }) => {
    const { basketId, values, queryParams } = params;
    const queryString = qs.stringify(queryParams, {
      addQueryPrefix: true,
    });
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.post(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/packages${queryString}`,
      values,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: SitePricingPackagesData = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const fetchSitePricingPackages = createGenericAsyncThunk<
  SitePricingPackagesData,
  Pick<SitePricingPackagesParams, 'basketId'>
>(
  'sitePricingPackages/fetchSitePricingPackages',
  async ({ basketId }, { extra, rejectWithValue, getState, dispatch }) => {
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.get(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/packages`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: SitePricingPackagesData = await response.json();

    if (response.status !== 200) {
      if (response.status === 423) {
        dispatch(fetchSitePricingBookingClosed());
      }

      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const fetchSitePricingSavedPackages = createGenericAsyncThunk<
  SitePricingSavedPackagesData,
  Pick<SitePricingPackagesParams, 'storedId'>
>(
  'sitePricingPackages/fetchSitePricingSavedPackages',
  async (params, { extra, rejectWithValue, dispatch }) => {
    const { storedId } = params;

    const response = await extra.ajax.get(
      `/caas/commerce/v1/saved-packages/${storedId}`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: SitePricingSavedPackagesData = await response.json();

    if (response.status !== 200) {
      if (response.status === 423) {
        dispatch(fetchSitePricingBookingClosed());
      }

      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const updateSitePricingPackage = createGenericAsyncThunk<
  SitePricingPackagesData,
  SitePricingPackagesUpdateParams
>(
  'sitePricingPackages/updateSitePricingPackage',
  async (params, { extra, rejectWithValue, getState }) => {
    const { basketId, packageId, quantity } = params;
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.patch(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/packages/${packageId}/quantity/${quantity}`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: SitePricingPackagesData = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const deleteSitePricingPackage = createGenericAsyncThunk<
  SitePricingPackagesData,
  SitePricingPackagesDeleteParams
>(
  'sitePricingPackages/deleteSitePricingPackage',
  async ({ basketId, packageId }, { extra, getState, rejectWithValue }) => {
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.delete(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/packages/${packageId}`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: SitePricingPackagesData = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const sitePricingPackagesSlice = createGenericSlice({
  name: 'sitePricingPackages',
  initialState: {
    status: 'idle',
    data: {
      data: {
        packages: [],
        outdatedPackages: [],
        contacts: [],
        infoWidgets: {
          items: [],
        },
        contactMyComplete: false,
        contactOtherComplete: false,
      } as PricingPackages,
      meta: {},
    },
  } as GenericState<SitePricingPackagesData>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<
      SitePricingPackagesData,
      SitePricingPackagesParams
    >(builder, createSitePricingPackage);

    createGenericBuilderCases<
      SitePricingPackagesData,
      Pick<SitePricingPackagesParams, 'basketId'>
    >(builder, fetchSitePricingPackages);

    createGenericBuilderCases<
      SitePricingSavedPackagesData,
      Pick<SitePricingPackagesParams, 'storedId'>
    >(builder, fetchSitePricingSavedPackages);

    createGenericBuilderCases<
      SitePricingPackagesData,
      SitePricingPackagesUpdateParams
    >(builder, updateSitePricingPackage);

    createGenericBuilderCases<
      SitePricingPackagesData,
      SitePricingPackagesDeleteParams
    >(builder, deleteSitePricingPackage);
  },
});

export const selectSitePricingPackagesData = (state: RootState) =>
  state.pricing.packages.data.data;

export const selectSitePricingPackagesMeta = (state: RootState) =>
  state.pricing.packages.data.meta;

export const selectSitePricingPackagesStatusCode = (state: RootState) =>
  state.pricing.packages.statusCode;

export const selectSitePricingPackagesStatus = (state: RootState) =>
  state.pricing.packages.status;

export const selectPricingPackagesVIPCodeDiscount = createSelector(
  selectSitePricingPackagesMeta,
  ({ discountApplyResults }) => {
    return discountApplyResults.find(
      (discount) =>
        discount['@type'] === DiscountResultTypes.PromotionCodeApplyResult,
    );
  },
);

export const selectPricingPackagesDiscountsExcludingVIPCode = createSelector(
  selectSitePricingPackagesMeta,
  ({ discountApplyResults }) => {
    return discountApplyResults.filter(
      (discount) =>
        discount['@type'] !== DiscountResultTypes.PromotionCodeApplyResult,
    );
  },
);

export const selectSitePricingPackagesByDelegate = createSelector(
  selectSitePricingPackagesData,
  ({ packages }) => mapPackagesByDelegates(packages),
);

export const selectSitePricingOutdatedPackagesByDelegate = createSelector(
  selectSitePricingPackagesData,
  ({ outdatedPackages }) => mapPackagesByDelegates(outdatedPackages),
);

export const selectPricingPackagesCount = createSelector(
  selectSitePricingPackagesData,
  ({ packages = [] }) => packages.reduce((acc, item) => acc + item.quantity, 0),
);

export const selectPricingPackagesForGTM = createSelector(
  selectSitePricingPackagesData,
  ({ packages }) => {
    return packages.flatMap((packageData) =>
      packageData.packageSelection.map((product) => ({
        name: product.productName,
        variant: product.optionNames || null,
        price: packageData.packagePrice.originalPrice,
        quantity: packageData.quantity,
        brand: product.productName,
      })),
    );
  },
);

export const selectSitePricingIsZeroBilling = createSelector(
  selectSitePricingPackagesMeta,
  (meta) => getPriceAsNumber(meta.totalPrice?.totalInclVat) === 0,
);

export const { start, fulfilled, rejected } = sitePricingPackagesSlice.actions;

export default sitePricingPackagesSlice.reducer;
