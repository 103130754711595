import React from 'react';
import classNames from 'classnames';
import './IconBadge.scss';

export function IconBadge(props) {
  const {
    brand: { accentColor } = {},
    iconID,
    isRound,
    className = '',
  } = props;

  const iconBadgeContainer = classNames('c-icon-badge', {
    [className]: className,
    'c-icon-badge--round': isRound,
  });

  return (
    <div className={iconBadgeContainer} style={{ background: accentColor }}>
      <i className="material-icons">{iconID}</i>
    </div>
  );
}
