import React, { useState, useEffect } from 'react';
import { useRootDispatch } from '../../../../store/hooks';
import cn from 'classnames';
import { useCookies } from 'react-cookie';
import { HubOptions, PropsWithTestId } from '../../../../types';
import { useBuildEmailOptInData } from '../EmailOptInForm/hooks/useBuildEmailOptInData';
import {
  SubscribeNewsletter,
  subscribeToNewsletter,
} from '../../brandHub/store/features/subscribe';
import { EmailOptInForm } from '../EmailOptInForm';
import { FormHeader } from '../EmailOptInForm/components';
import { Button } from '../Button';
import { isBrowser } from '../../../../helpers';
import { isMobile } from 'react-device-detect';
import styles from './FloatingEmailOptInModule.module.scss';

export interface FloatingEmailOptInModuleProps
  extends React.HTMLAttributes<HTMLElement> {
  timeOut?: number;
  pageType: string;
  permissionTextName: string;
  hubOptions: HubOptions;
}

export enum FloatingEmailOptInStates {
  active = 'active',
  collapsed = 'collapsed',
  closed = 'closed',
  subscribed = 'subscribed',
}

const distance = new Date(Date.now() + 31622400000);

export default function FloatingEmailOptInModule({
  pageType,
  permissionTextName,
  timeOut,
  hubOptions,
  testId,
  ...restProps
}: PropsWithTestId<FloatingEmailOptInModuleProps>) {
  const dispatch = useRootDispatch();
  const [cookies, setCookies] = useCookies();
  const emailOptInFormData = useBuildEmailOptInData(hubOptions, pageType);
  const { title, body, cookieName, subscriptionType, productCode } =
    emailOptInFormData;
  const [emailOptInModuleState, setEmailOptInModuleState] =
    useState<FloatingEmailOptInStates>();
  const active = emailOptInModuleState === FloatingEmailOptInStates.active;
  const collapsed =
    emailOptInModuleState === FloatingEmailOptInStates.collapsed;
  const closed = emailOptInModuleState === FloatingEmailOptInStates.closed;

  useEffect(() => {
    if (isBrowser()) {
      const initialState = isMobile
        ? FloatingEmailOptInStates.collapsed
        : FloatingEmailOptInStates.active;
      const timer = setTimeout(
        () => setEmailOptInModuleState(cookies[cookieName] || initialState),
        timeOut,
      );

      return () => {
        clearTimeout(timer);
      };
    }
  }, [cookies, cookieName, emailOptInModuleState, timeOut]);

  const wrapperClassName = cn(styles.wrapper, {
    [styles.active]: active,
    [styles.collapsed]: collapsed,
    [styles.closed]: closed,
  });

  const collapsedClassName = cn({
    [styles.collapsedContainer]: collapsed,
  });

  const handleSubmit = (values: SubscribeNewsletter) => {
    const valuesWithProductCode = { ...values, productCode };
    return dispatch(
      subscribeToNewsletter({
        subscriptionType,
        values: valuesWithProductCode,
      }),
    );
  };

  const onChangeFloatingFormState = (state: FloatingEmailOptInStates) => {
    setEmailOptInModuleState(state);

    setCookies(cookieName, state, {
      expires: distance,
      path: '/',
    });
  };

  const handleActive = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onChangeFloatingFormState(FloatingEmailOptInStates.active);
  };

  const handleCollapse = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onChangeFloatingFormState(FloatingEmailOptInStates.collapsed);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    onChangeFloatingFormState(FloatingEmailOptInStates.closed);
  };

  const collapseButton = (
    <Button
      to=""
      size="large"
      onClick={handleCollapse}
      endIcon="expand_more"
      variant="outlined"
      data-testid="email-opt-in-collapse-button"
    >
      Hide
    </Button>
  );

  return (
    <div {...restProps} className={wrapperClassName} data-testid={testId}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <FormHeader
              title={title}
              emailOptInModuleState={emailOptInModuleState}
              onActive={handleActive}
              onCollapse={handleCollapse}
              onClose={handleClose}
            />
          </div>
        </div>
      </div>

      <div className={collapsedClassName}>
        <EmailOptInForm
          title={title}
          body={body}
          onClose={() =>
            onChangeFloatingFormState(FloatingEmailOptInStates.closed)
          }
          onSubmit={handleSubmit}
          permissionTextName={permissionTextName}
          renderCollapseButton={collapseButton}
        />
      </div>
    </div>
  );
}

FloatingEmailOptInModule.defaultProps = {
  timeOut: 4000,
  testId: 'email-opt-in-appearance',
};
