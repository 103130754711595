import qs from 'qs';
import { DiscoveryStreamlyVideoAccessType } from '../../store/features/discovery';

type ParseStreamlyUrlReturn = {
  accessType: DiscoveryStreamlyVideoAccessType;
  thumbnailUrl: string;
  sourceUrl: string;
};

export const isPublicVideo = (accessType?: DiscoveryStreamlyVideoAccessType) =>
  !accessType || accessType === DiscoveryStreamlyVideoAccessType.PUBLIC;

export const isSubscribeVideo = (
  accessType?: DiscoveryStreamlyVideoAccessType,
) => accessType === DiscoveryStreamlyVideoAccessType.SUBSCRIBE;

export const isRegisterVideo = (
  accessType?: DiscoveryStreamlyVideoAccessType,
) => accessType === DiscoveryStreamlyVideoAccessType.REGISTER;

export const isPrivateVideo = (accessType?: DiscoveryStreamlyVideoAccessType) =>
  isRegisterVideo(accessType) || isSubscribeVideo(accessType);

export function parseStreamlyUrl(url?: string) {
  if (!url) return {} as ParseStreamlyUrlReturn;

  const [sourceUrl, query] = url.split('?');
  return { sourceUrl, ...qs.parse(query) } as ParseStreamlyUrlReturn;
}
