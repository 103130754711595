import React from 'react';
import { UniqueIdentifier } from '../../../../../../types';
import { Loader } from '../Loader';
import {
  useInfiniteScroll,
  UseInfiniteScrollProps,
} from '../../../../../../hooks';
import styles from './ProductsList.module.scss';

export interface ProductsListProps<T> {
  products: T[];
  totalPage: number;
  onLoadMore: UseInfiniteScrollProps['fetchMoreData'];
  children: (product: T) => React.ReactNode;
}

export function ProductsList<T extends { id: UniqueIdentifier }>(
  props: ProductsListProps<T>,
) {
  const { products, totalPage, onLoadMore, children } = props;

  const { containerRef, loading } = useInfiniteScroll<HTMLUListElement>({
    fetchMoreData: onLoadMore,
    totalPage,
    offsetContainerHeight: 300,
  });

  return (
    <>
      <ul className={styles.list} ref={containerRef}>
        {products.map((product) => (
          <li key={product.id} className={styles.listItem}>
            {children(product)}
          </li>
        ))}
      </ul>

      {loading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </>
  );
}
