import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getImgixUrl, renderHTMLString } from '../../../../helpers';
import './LeadGenTeaserLandscape.scss';
import { LeadGenTeaserBadge } from '../LeadGenTeaserBadge/LeadGenTeaserBadge';
import { Link } from 'react-scroll';

export function LeadGenTeaserLandscape(props) {
  const {
    agendaDownloadButton,
    brand = {},
    iconID,
    teaserHeading,
    teaserBody,
    thumbnail = {},
    pageConfig: { tenantId } = {},
  } = props;

  const imageSrc = getImgixUrl(tenantId, thumbnail.path, 'w=540&h=300');
  const backgroundImage = `linear-gradient(181.25deg, rgba(0,0,0,0) 0%, #000000 100%), url(${imageSrc})`;

  return (
    <div
      className="lead-gen-teaser-landscape row"
      data-test-lead-gen="lead-gen-teaser-landscape"
    >
      <div className="col-xs-12 col-sm-5 col-md-6">
        <div
          className="lead-gen-teaser-landscape__image"
          style={thumbnail.path && { backgroundImage }}
          data-test-lead-gen="lead-gen-teaser-landscape-image"
        >
          {iconID && (
            <Link to="viewOuter" smooth duration={500}>
              <LeadGenTeaserBadge brand={brand} iconID={iconID} />
            </Link>
          )}
        </div>
        {teaserHeading && (
          <div
            className="lead-gen-teaser-landscape__title-wrapper"
            data-test-lead-gen="lead-gen-teaser-landscape-title-wrapper"
          >
            <h1
              className="lead-gen-teaser-landscape__title"
              data-test-lead-gen="lead-gen-teaser-landscape-title"
            >
              {teaserHeading}
            </h1>
          </div>
        )}
      </div>

      <div className="col-xs-12 col-sm-7 col-md-6">
        {agendaDownloadButton && agendaDownloadButton()}
        {teaserBody && (
          <div
            className="lead-gen-teaser-landscape__body"
            data-test-lead-gen="lead-gen-teaser-landscape-body"
          >
            {renderHTMLString(teaserBody)}
          </div>
        )}
      </div>
    </div>
  );
}

LeadGenTeaserLandscape.propTypes = {
  agendaDownloadButton: PropTypes.func,
  teaserHeading: PropTypes.string,
  teaserBody: PropTypes.string,
  thumbnail: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(LeadGenTeaserLandscape);
