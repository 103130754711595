import { DownloadAnchor } from './DownloadAnchor';
import styles from './DownloadFiles.module.scss';

export interface DownloadFilesProps {
  urls: string[];
}

export function DownloadFiles({ urls }: DownloadFilesProps) {
  return (
    <div className={styles.wrapper}>
      <h5 className={styles.heading}>Downloads</h5>
      <div className={styles.body}>
        {urls.map((url) => {
          const urlChunks = url.split('/');

          return (
            <DownloadAnchor key={url} to={`/uploads/${url}`}>
              {urlChunks[urlChunks.length - 1].slice(37)}
            </DownloadAnchor>
          );
        })}
      </div>
    </div>
  );
}
