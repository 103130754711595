import React from 'react';
import styled from 'styled-components';
import { typography } from '../../styles/shared/index';

const Heading = styled.h4`
  font-family: ${typography.type.primary};
  font-weight: ${typography.weight.semibold};
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 40px;
`;

const SectionHeading = (props) => {
  return props.children ? <Heading>{props.children}</Heading> : null;
};

export default SectionHeading;
