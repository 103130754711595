import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../../../types';
import styles from './NotificationMessage.module.scss';

export enum NotificationMessageType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface NotificationMessageProps extends HTMLAttributes<HTMLElement> {
  type?: NotificationMessageType;
}

export function NotificationMessage(
  props: PropsWithTestId<NotificationMessageProps>,
) {
  const { type, className, children, testId, ...restProps } = props;

  const wrapperClassName = cn(styles.wrapper, className, {
    [styles.success]: type === NotificationMessageType.SUCCESS,
    [styles.error]: type === NotificationMessageType.ERROR,
  });

  return (
    <div className={wrapperClassName} {...restProps} data-testid={testId}>
      {children}
    </div>
  );
}

NotificationMessage.defaultProps = {
  testId: 'email-opt-in-notification-message',
};
