import { createSelector } from '@reduxjs/toolkit';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../../store/features/generic';
import {
  GenericState,
  UniqueIdentifier,
  UploadedImage,
} from '../../../../../../types';

export enum L2TopicUrlTypes {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

export interface L2Topic {
  id: UniqueIdentifier;
  name: string;
  description?: string;
  backgroundImage: UploadedImage;
  featuredBrandImages: UploadedImage[];
  type: L2TopicUrlTypes;
  link: string;
  path: string;
  featured: boolean;
}

export interface IndustryHome {
  data: {
    relatedTopics: L2Topic[];
    firstFeaturedTopic: L2Topic;
    secondFeaturedTopic: L2Topic;
  };
}

export interface IndustryHomeParams {
  siteId: UniqueIdentifier;
}

export const fetchIndustryHome = createGenericAsyncThunk<
  IndustryHome,
  IndustryHomeParams
>(
  'industryHome/fetchIndustryHome',
  async ({ siteId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/l1hubs/${siteId}`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: IndustryHome = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const industryHomeSlice = createGenericSlice({
  name: 'industryHome',
  initialState: {
    status: 'idle',
    data: {},
  } as GenericState<IndustryHome>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<IndustryHome, IndustryHomeParams>(
      builder,
      fetchIndustryHome,
    );
  },
});

export const selectIndustryHome = createSelector(
  [(state) => state.brandHub.industryHome],
  (data) => data.data.data,
);

export const { start, fulfilled, rejected } = industryHomeSlice.actions;

export default industryHomeSlice.reducer;
