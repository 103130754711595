import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { renderStyledElementStyles } from '../../helpers';
import { useComponentProperties } from '../../../../hooks/useComponentProperties';
import Auth from '../../../../services/AuthService';

// AccountWidget was not refactored on styled components
import AccountWidget from '../../../shared/AccountWidget/AccountWidget';
import { LanguageSelector, HeaderBuilder } from './components';
import Breadcrumbs from './components/Breadcrumbs';
import BrandHomeMenu from './components/BrandHomeMenu';

const Wrapper = styled.div`
  border-bottom: ${({ isBorder, theme }) =>
    isBorder && `4px solid ${theme.brandColors.secondary}`};
  background-color: ${({ isBackground, theme }) =>
    isBackground ? theme.brandColors.secondary : '#fff'};

  ${({ theme, brandHomeTopMenuEnabled }) => {
    if (brandHomeTopMenuEnabled) return null;
    return renderStyledElementStyles(theme, theme.siteLayout.siteHeader);
  }}
`;

const WidgetsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`;

const HeaderBuilderWrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.siteLayout.siteHeader.builder)}
`;

const SiteHeader = (props) => {
  const {
    siteHeader: {
      data: {
        partneringOne,
        paymentHub,
        userProfileBlock,
        headerBackground,
        headerItems = {},
        breadcrumbEnabled,
        fullScreen,
        brandHomeMenu: brandHomeMenuEnabled,
      } = {},
    } = {},
    addElementsProperties,
  } = props;

  const siteHeaderRef = useRef(null);
  useComponentProperties(siteHeaderRef, 'siteHeader', addElementsProperties);

  const shownAccountWidgetOnP1Event =
    !partneringOne || (partneringOne && Auth.getShortAccountDetails().loggedIn);

  return (
    <Wrapper
      ref={siteHeaderRef}
      isBorder={paymentHub}
      isBackground={headerBackground}
      brandHomeTopMenuEnabled
    >
      {brandHomeMenuEnabled && <BrandHomeMenu fullScreen={fullScreen} />}
      <div className={fullScreen ? 'container-fluid' : 'container'}>
        <div className="row">
          <div className="col-xs-12">
            <WidgetsWrapper>
              {userProfileBlock && shownAccountWidgetOnP1Event && (
                <AccountWidget />
              )}
              <LanguageSelector />
            </WidgetsWrapper>
          </div>
        </div>
        <HeaderBuilderWrapper>
          <div className="row">
            {Object.keys(headerItems).map((colName) => {
              return (
                <div key={colName} className="col-xs-12 col-sm-4 col-md-4">
                  {colName === 'firstColumn' && breadcrumbEnabled && (
                    <Breadcrumbs />
                  )}
                  <HeaderBuilder type={headerItems[colName]} />
                </div>
              );
            })}
          </div>
        </HeaderBuilderWrapper>
      </div>
    </Wrapper>
  );
};

SiteHeader.propTypes = {
  siteHeader: PropTypes.shape({
    data: PropTypes.shape({
      partneringOne: PropTypes.bool,
      paymentHub: PropTypes.bool,
      userProfileBlock: PropTypes.bool,
      headerBackground: PropTypes.bool,
      fullScreen: PropTypes.bool,
      headerItems: PropTypes.object,
    }),
  }),
  addElementsProperties: PropTypes.func,
};

export default React.memo(SiteHeader);
