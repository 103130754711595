import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Scroll from 'react-scroll';
import { AccordionItemState } from 'react-accessible-accordion';

import { isFunction } from '../../../../../helpers';
import {
  StyledAccordionWrapper,
  StyledAccessibleAccordion,
  StyledAccessibleAccordionItem,
  StyledAccessibleAccordionItemHeading,
  StyledAccessibleAccordionItemPanel,
  StyledAccessibleAccordionItemButton,
} from './Styles';

export const Accordion = (props) => {
  const {
    items,
    ExpandIcon,
    accordionSettings,
    accordionItemSetting,
    testId,
    firstAccordionBlockOpen,
  } = props;

  const accordionRef = useRef(null);
  const navbarHeight = useSelector((state) =>
    get(
      state.pageConfig,
      ['elementsProperties', 'multiLevelNavMenu', 'height'],
      65,
    ),
  );

  const onChange = (activeKey) => {
    if (isFunction(props.onChange)) {
      props.onChange(activeKey);
    }

    if (props.scrollToActive) {
      if (activeKey.length === 0) return false;

      const activeSection = accordionRef.current.querySelectorAll(
        '[data-accordion-component="AccordionItem"]',
      )[activeKey];

      const { top } = activeSection.getBoundingClientRect();

      Scroll.animateScroll.scrollTo(window.pageYOffset + top - navbarHeight, {
        duration: 200,
      });
    }
  };

  return (
    <div ref={accordionRef} data-testid={testId}>
      <StyledAccessibleAccordion
        onChange={onChange}
        preExpanded={[firstAccordionBlockOpen ? 0 : '']}
        {...accordionSettings}
      >
        {items.map(({ title, titleSize = 'H2', content }, index) => {
          const Header = titleSize?.toLowerCase();
          return (
            <StyledAccessibleAccordionItem
              key={index}
              uuid={index}
              {...accordionItemSetting}
            >
              <StyledAccessibleAccordionItemHeading>
                <StyledAccessibleAccordionItemButton>
                  <AccordionItemState>
                    {({ expanded }) => <ExpandIcon isActive={expanded} />}
                  </AccordionItemState>
                  <Header>{title}</Header>
                </StyledAccessibleAccordionItemButton>
              </StyledAccessibleAccordionItemHeading>
              <StyledAccessibleAccordionItemPanel>
                {content}
              </StyledAccessibleAccordionItemPanel>
            </StyledAccessibleAccordionItem>
          );
        })}
      </StyledAccessibleAccordion>
    </div>
  );
};

const expandIcon = ({ isActive }) => {
  return (
    <i
      className="material-icons accordion-expand-icon"
      data-testid="accordion-expand-icon"
    >
      {isActive ? 'expand_less' : 'expand_more'}
    </i>
  );
};

Accordion.propTypes = {
  items: PropTypes.array,
  scrollToActive: PropTypes.bool,
  onChange: PropTypes.func,
  testId: PropTypes.string,
  expandIcon: PropTypes.element,
  accordionSettings: PropTypes.object,
  accordionItemSetting: PropTypes.object,
};

Accordion.defaultProps = {
  items: [],
  ExpandIcon: expandIcon,
  accordionSettings: {
    allowZeroExpanded: true,
  },
  accordionItemSetting: {},
  testId: 'informa-accordion',
};

export const StyledAccordion = (props) => {
  return (
    <StyledAccordionWrapper
      displayStyles={props.displayStyles}
      visualStyles={props.visualStyles}
    >
      <Accordion {...props} />
    </StyledAccordionWrapper>
  );
};
