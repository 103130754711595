import { createGenericAsyncThunk } from '../../../../../store/features/generic';
import { selectPageConfig } from '../../../../../store/features/pageConfig';
import { fetchSitePricingBookingClosed } from '../bookingClosed';
import {
  PricingMyContactDetails,
  PricingOtherContactDetails,
  SitePricingContactDetailsParams,
  UpdateSitePricingMyContactDetailsParams,
  UpdateSitePricingOtherContactDetailsParams,
} from './types';

export const fetchSitePricingMyContactDetails = createGenericAsyncThunk<
  PricingMyContactDetails,
  SitePricingContactDetailsParams
>(
  'sitePricingMyContactDetails/fetchSitePricingMyContactDetails',
  async (params, { extra, rejectWithValue, getState, dispatch }) => {
    const { basketId } = params;
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.get(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/contacts/my`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: PricingMyContactDetails = await response.json();

    if (response.status !== 200) {
      if (response.status === 423) {
        dispatch(fetchSitePricingBookingClosed());
      }

      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const fetchSitePricingOtherContactDetails = createGenericAsyncThunk<
  PricingOtherContactDetails,
  SitePricingContactDetailsParams
>(
  'sitePricingOtherContactDetails/fetchSitePricingOtherContactDetails',
  async (params, { extra, rejectWithValue, getState, dispatch }) => {
    const { basketId } = params;
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.get(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/contacts/other`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: PricingOtherContactDetails = await response.json();

    if (response.status !== 200) {
      if (response.status === 423) {
        dispatch(fetchSitePricingBookingClosed());
      }

      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const updateSitePricingMyContactDetails = createGenericAsyncThunk<
  PricingMyContactDetails,
  UpdateSitePricingMyContactDetailsParams
>(
  'sitePricingMyContactDetails/updateSitePricingMyContactDetails',
  async (params, { extra, rejectWithValue, getState }) => {
    const { basketId, values } = params;
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.put(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/contacts/my`,
      values,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: PricingMyContactDetails = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const updateSitePricingOtherContactDetails = createGenericAsyncThunk<
  PricingOtherContactDetails,
  UpdateSitePricingOtherContactDetailsParams
>(
  'sitePricingOtherContactDetails/updateSitePricingOtherContactDetails',
  async (params, { extra, rejectWithValue, getState }) => {
    const { basketId, values } = params;
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.put(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/contacts/other`,
      values.contacts,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: PricingOtherContactDetails = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);
