import { format } from 'date-fns';
import type { ArticleHome } from '../../../../../../../store/features/articles/home';
import styles from './Author.module.scss';

type Article = ArticleHome['data'];

export interface AuthorProps {
  author?: Article['author'];
  publishDate?: Article['datePublished'];
}

export function Author(props: AuthorProps) {
  const { author, publishDate } = props;
  const fullName = [author?.forename, author?.surname]
    .filter(Boolean)
    .join(' ');
  const published =
    publishDate && format(new Date(publishDate), 'dd MMMM yyyy');

  if (!fullName) {
    return null;
  }

  const authorContent = (
    <span itemProp="author" className={styles.name}>
      {fullName}
    </span>
  );
  const dateContent = published && <span> on {published}</span>;

  return (
    <div className={styles.wrapper}>
      Posted by {authorContent}
      {dateContent}
    </div>
  );
}
