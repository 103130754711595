import { HTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './ProductsCountInfo.module.scss';

export interface ProductCountInfoProps extends HTMLAttributes<HTMLSpanElement> {
  count: number;
  totalCount?: number;
  productName?: string;
  textBefore?: string;
}

const getResultWord = (count: number) => (count === 1 ? 'result' : 'results');

export function ProductCountInfo(props: ProductCountInfoProps) {
  const {
    count,
    totalCount,
    productName,
    textBefore,
    className,
    ...restProps
  } = props;

  const countElement = totalCount
    ? `${count} of ${totalCount} ${getResultWord(totalCount)}`
    : `${count === 10000 ? '10000+' : count} ${getResultWord(count)}`;

  return (
    <span {...restProps} className={cn(className, styles.wrapper)}>
      {textBefore && <span>{textBefore} </span>}
      {countElement}
      {productName && (
        <span>
          {' '}
          in <strong>{productName}</strong>
        </span>
      )}
    </span>
  );
}
