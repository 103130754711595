import React from 'react';
import styled from 'styled-components';

import RadioLabel from '../RadioLabel';

const HiddenInput = styled.input`
  display: none;
`;

const RadioWrapper = styled.div`
  display: flex;
  width: 50%;

  &.fullWidth {
    width: 100%;
  }
`;

const Radio = ({
  radioBtn,
  input,
  type,
  prefix,
  color,
  isError,
  fullWidth,
}) => {
  const isChecked =
    radioBtn === input.value || input.value.includes(radioBtn.value);
  const widthClass = fullWidth ? ' fullWidth' : '';
  return (
    <RadioWrapper className={widthClass} key={radioBtn.value}>
      <HiddenInput
        {...input}
        id={radioBtn.value}
        value={radioBtn.value}
        type={type}
        checked={isChecked}
        className={prefix + '__native-radio'}
        data-test-lead-gen={prefix + '-native-radio'}
      />
      <RadioLabel
        prefix={prefix}
        radioBtn={radioBtn}
        input={input}
        color={color}
        isError={isError}
        isChecked={isChecked}
      />
    </RadioWrapper>
  );
};

export default Radio;
