import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Link from '../../../shared/Link/Link';
import { renderHTMLString } from '../../../../helpers';

import './LeadGenCheckbox.scss';

export class LeadGenCheckbox extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    isCheckboxGroup: PropTypes.bool.isRequired,
    value: PropTypes.string,
    labelSize: PropTypes.oneOf(['small']),
    isChecked: PropTypes.bool,
    index: PropTypes.number,
    color: PropTypes.string,
    linkValue: PropTypes.string,
    linkName: PropTypes.string,
    isError: PropTypes.bool,
  };

  static setActiveClass(checked, isError) {
    const errorClass = isError ? 'lead-gen-checkbox__label-square--error' : '';
    const activeClass = checked
      ? 'lead-gen-checkbox__label-square--active'
      : '';

    return `lead-gen-checkbox__label-square ${activeClass} ${errorClass}`;
  }

  static setActiveColor(checked, color) {
    if (color && checked) {
      return { backgroundColor: color, border: `1px solid ${color}` };
    }
  }

  handleChange = (value, input) => (event) => {
    const currentValues = [...input.value];

    if (event.target.checked) {
      currentValues.push(value);
    } else {
      currentValues.splice(currentValues.indexOf(value), 1);
    }
    return input.onChange(currentValues);
  };

  renderLabel() {
    const { isCheckboxGroup, label, color, linkValue, linkName, labelSize } =
      this.props;
    const labelSizeClass =
      labelSize === 'small'
        ? `lead-gen-checkbox__label-text--${labelSize}`
        : '';
    const isLink = label.includes('{link}');

    if (isCheckboxGroup) {
      return (
        <div
          className={`lead-gen-checkbox__label-text ${labelSizeClass}`}
          data-test-lead-gen="lead-gen-checkbox-label-text"
        >
          {label}
        </div>
      );
    } else {
      return (
        <div className="lead-gen-checkbox__label-text-wrapper">
          <div
            className={`lead-gen-checkbox__label-text ${labelSizeClass}`}
            data-test-lead-gen="lead-gen-checkbox-label-text"
          >
            {renderHTMLString(label.replace(/{link}/gi, ''))}
          </div>
          {isLink && linkValue && linkName && (
            <Link
              className="lead-gen-checkbox__label-link"
              target="_blank"
              style={{ color }}
              sub={linkValue}
              data-test-lead-gen="lead-gen-checkbox-label-link"
            >
              {linkName}
            </Link>
          )}
        </div>
      );
    }
  }

  render() {
    const {
      isError = false,
      value,
      input = {},
      index,
      isChecked,
      color,
      isCheckboxGroup,
      labelSize,
    } = this.props;
    const isInputChecked = isCheckboxGroup ? isChecked : input.checked;
    const inputId = isCheckboxGroup ? value : input.name;
    const wrapperSizeClass =
      labelSize === 'small'
        ? `lead-gen-checkbox__label-square-wrapper--${labelSize}`
        : '';

    return (
      <div className="lead-gen-checkbox" data-test-lead-gen="lead-gen-checkbox">
        {isCheckboxGroup ? (
          <input
            id={inputId}
            name={`${input.name}[${index}]`}
            value={value}
            checked={isChecked}
            onChange={this.handleChange(value, input)}
            type="checkbox"
            className="lead-gen-checkbox__input"
          />
        ) : (
          <input
            {...input}
            id={inputId}
            type="checkbox"
            className="lead-gen-checkbox__input"
          />
        )}
        <label htmlFor={inputId} className="lead-gen-checkbox__label">
          <div
            className={`lead-gen-checkbox__label-square-wrapper ${wrapperSizeClass}`}
            data-test-lead-gen="lead-gen-checkbox-label-square-wrapper"
          >
            <div
              className={LeadGenCheckbox.setActiveClass(
                isInputChecked,
                isError,
              )}
              style={LeadGenCheckbox.setActiveColor(isInputChecked, color)}
              data-test-lead-gen="lead-gen-checkbox-label-square"
            >
              <span className="lead-gen-checkbox__label-square-tick" />
            </div>
          </div>
          {this.renderLabel()}
        </label>
      </div>
    );
  }
}

export default withTranslation()(LeadGenCheckbox);
