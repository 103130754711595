import qs from 'qs';

const SUCCESS = 'SITE_TYPE_SUCCESS';
const FAILURE = 'SITE_TYPE_FAILURE';

const initialState = {
  data: {},
};

export default function siteTypeReducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS:
      return Object.assign(
        {},
        {
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case FAILURE:
      return Object.assign(
        {},
        {
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

export function fetchSiteTypeSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchSiteTypeFailure(data) {
  return { type: FAILURE, data };
}

export function fetchSiteTypeAction() {
  return function (dispatch, getState, { Api }) {
    const {
      pageConfig: {
        isPreview,
        sitePath,
        siteSubPath,
        tenantId,
        portfolioId,
        singleSiteMode,
        siteMaskingPath,
      },
    } = getState();

    const query = qs.stringify(
      {
        subDomain: portfolioId || undefined,
        subPath: siteSubPath || undefined,
      },
      {
        encode: true,
        indices: false,
        addQueryPrefix: true,
      },
    );

    return Api()
      .cache(true)
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchSiteType({
        tenantId,
        sitePath,
        query,
        singleSiteMode,
        siteMaskingPath,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchSiteTypeSuccess(response));
        } else {
          return dispatch(fetchSiteTypeFailure(response));
        }
      });
  };
}
