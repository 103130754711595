import { useMemo } from 'react';
import { DiscoveryContentTypes } from '../../../../../../constants';
import { SortTypes } from '../../../../../../store/features/discovery';
import { Select } from '../Input/Select';
import s from './SortProductsFilter.module.scss';

const contentTypeSortBy = {
  [DiscoveryContentTypes.ARTICLE]: [],
  [DiscoveryContentTypes.STREAMLY_VIDEO]: [
    SortTypes.FEATURED,
    SortTypes.ALPHABETICAL,
  ],
  [DiscoveryContentTypes.AUDIENCE_HOME]: [
    SortTypes.FEATURED,
    SortTypes.ALPHABETICAL,
  ],
};

const options = [
  {
    value: SortTypes.FEATURED,
    label: 'Featured',
  },
  {
    value: SortTypes.DATE,
    label: 'Start Date',
  },
  {
    value: SortTypes.ALPHABETICAL,
    label: 'A to Z',
  },
];

export interface SortProductsFilterProps {
  contentType: DiscoveryContentTypes;
}

export function SortProductsFilter({ contentType }: SortProductsFilterProps) {
  const sortingOptions = useMemo(() => {
    if (contentType in contentTypeSortBy) {
      return options.filter((option) => {
        // @ts-ignore
        return contentTypeSortBy[contentType].includes(option.value);
      });
    }

    return options;
  }, [contentType]);

  if (sortingOptions.length === 0) return null;

  return (
    <div className={s.wrapper}>
      <label className={s.label}>Sort by:</label>
      <Select name="requestedSortType" options={sortingOptions} />
    </div>
  );
}
