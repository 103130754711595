import React from 'react';
import { Field } from 'redux-form';
import i18next from 'i18next';
import Patterns from '../../../../utils/pattern/patterns';

import LeadGenInputPass from '../../LeadGenFormItems/LeadGenInputPass/LeadGenInputPass';
import LeadGenInput from '../../LeadGenFormItems/LeadGenInput/LeadGenInput';

export default function LeadGenEmailAndPass() {
  return (
    <div className="row">
      <div className="col-xs-12">
        <Field
          name="email"
          label="Business email"
          component={LeadGenInput}
          isRequired
          inputType="text"
          autofill
          validate={[required, email]}
        />
      </div>
      <div className="col-xs-12">
        <Field
          name="password"
          label="Password"
          component={LeadGenInputPass}
          isRequired
          inputType="password"
          isButtonShown
          autofill
          validate={[required, password]}
        />
      </div>
    </div>
  );
}

function required(value) {
  return value
    ? undefined
    : i18next.t('org.hibernate.validator.constraints.NotEmpty.message');
}

function email(value) {
  return !Patterns.isValidChar(value) || value.indexOf('+') > -1
    ? 'Invalid characters'
    : Patterns.isEmail(value)
    ? undefined
    : i18next.t('org.hibernate.validator.constraints.Email.message');
}

function password(value) {
  // minimum eight characters, at least one uppercase letter, one lowercase letter and one number
  const isStrongPass = Patterns.isPassword(value);
  if (!isStrongPass) {
    return 'Password is not strong enough';
  }
}
