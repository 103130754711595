import React, { ReactNode } from 'react';
import cn from 'classnames';
import { CardWrapper } from '../../../cards/components/CardWrapper';
import {
  ImageBadge,
  ImageBadgePosition,
} from '../../../cards/components/Image/ImageBadge';
import styles from './ProductListCard.module.scss';

export enum ProductListCardImageSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface ProductListCardProps {
  title: ReactNode;
  backgroundColor?: string;
  image?: ReactNode;
  imageSize?: keyof typeof ProductListCardImageSizes;
  imageBadge?: ReactNode;
  imageBadgePosition?: keyof typeof ImageBadgePosition;
  date?: ReactNode;
  language?: ReactNode;
  deliveryTypes?: ReactNode;
  description?: ReactNode;
  showMoreBadge?: ReactNode;
  leftHeader?: ReactNode;
  rightHeader?: ReactNode;
  sponsors?: ReactNode;
}

export function ProductListCard(props: ProductListCardProps) {
  const {
    title,
    image,
    imageSize,
    imageBadge,
    imageBadgePosition,
    backgroundColor,
    date,
    language,
    deliveryTypes,
    description,
    sponsors,
    leftHeader,
    rightHeader,
    showMoreBadge,
  } = props;
  const isFooterBig =
    sponsors ||
    (showMoreBadge && (description || sponsors)) ||
    (description && sponsors);
  const { large, medium, small } = ProductListCardImageSizes;

  const leftColumnClassNames = cn('col-xs-5', {
    'col-sm-5': imageSize === large,
    'col-sm-3 col-md-4': imageSize === medium,
    'col-sm-3': imageSize === small,
  });
  const rightColumnClassNames = cn(image ? 'col-xs-7' : 'col-xs-12', {
    'col-sm-7': imageSize === large,
    'col-sm-9 col-md-8': imageSize === medium,
    'col-sm-9': imageSize === small,
  });
  const leftHeaderClassNames = cn(
    rightHeader ? 'col-xs-8 col-md-10' : 'col-xs-12',
  );
  const rightHeaderClassNames = cn(
    leftHeader ? 'col-xs-4 col-md-2' : 'col-xs-12',
  );
  const footerClassNames = cn(styles.footer, {
    [styles.bigFooter]: isFooterBig,
    [styles.smallFooter]: !isFooterBig,
  });
  const contentWrapperClassNames = cn({
    [styles.contentWrapper]: showMoreBadge,
  });

  return (
    <CardWrapper backgroundColor={backgroundColor} className={styles.wrapper}>
      <div className="row">
        {image && (
          <div className={leftColumnClassNames}>
            <div className={styles.image}>
              {image}

              {imageBadge && (
                <ImageBadge position={imageBadgePosition}>
                  {imageBadge}
                </ImageBadge>
              )}
            </div>
          </div>
        )}

        <div className={rightColumnClassNames}>
          <div className={contentWrapperClassNames}>
            <div>
              {(leftHeader || rightHeader) && (
                <div className="row">
                  {leftHeader && (
                    <div className={leftHeaderClassNames}>
                      <div className={styles.leftHeader}>{leftHeader}</div>
                    </div>
                  )}
                  {rightHeader && (
                    <div className={rightHeaderClassNames}>
                      <div className={styles.rightHeader}>{rightHeader}</div>
                    </div>
                  )}
                </div>
              )}

              <div className="row">
                <div className="col-xs-12">
                  <div>{title}</div>
                </div>

                {(date || deliveryTypes || language) && (
                  <div className="col-xs-12">
                    <div className={styles.details}>
                      {date && <div className={styles.date}>{date}</div>}
                      {language && (
                        <div className={styles.language}>{language}</div>
                      )}
                      {deliveryTypes && (
                        <div className={styles.deliveryTypes}>
                          {deliveryTypes}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {(sponsors || description || showMoreBadge) && (
              <div className={styles.desktopOnly}>
                <div className="row">
                  <div className="col-xs-12">
                    <div className={footerClassNames}>
                      {description && (
                        <div className={styles.descriptionWrapper}>
                          <div className={styles.description}>
                            {description}
                          </div>
                        </div>
                      )}
                      <div className={styles.showMoreBadge}>
                        {showMoreBadge}
                      </div>
                      {sponsors && (
                        <div className={styles.sponsors}>{sponsors}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {showMoreBadge && (
              <div className={styles.mobileOnly}>
                <div className="row">
                  <div className="col-xs-12">
                    <div className={styles.showMoreBadge}>{showMoreBadge}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </CardWrapper>
  );
}

ProductListCard.defaultProps = {
  imageSize: ProductListCardImageSizes.small,
};
