import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withBrand } from '../../../providers/BrandProvider';

import Link from '../Link/Link';

import './BookingButton.scss';

export function BookingButton(props) {
  const {
    brand: { accentColor } = {},
    btnSize = 'large',
    isMenu = false,
    siteHeader: {
      data: {
        booking = false,
        bookingForm: { bookingFormType, customBookingUrl, buttonText } = {},
        registrationName: bookButtonText = 'BOOK_NOW',
      } = {},
    } = {},
    t,
  } = props;
  const bookingBtnClass = classnames('c-booking-button', {
    'c-booking-button--menu-type': isMenu,
  });
  const isCustomBooking = bookingFormType === 'CUSTOM';
  const initialButtonText = t(`event.book-now.${bookButtonText}`);

  return booking ? (
    <Link
      type="button"
      size={btnSize}
      to={isCustomBooking ? customBookingUrl : '/'}
      event={isCustomBooking ? null : { type: 'BUY_LINK' }}
      style={{ backgroundColor: accentColor }}
      className={bookingBtnClass}
    >
      <span className="c-booking-button__text">
        {isCustomBooking ? (
          <>{buttonText || initialButtonText}</>
        ) : (
          <>{initialButtonText}</>
        )}
      </span>
      <span className="c-booking-button__text-short">
        {bookButtonText ? (
          <>{initialButtonText}</>
        ) : isCustomBooking ? (
          <>{buttonText || t('event.book-now.mobile')}</>
        ) : (
          <>{t('event.book-now.mobile')}</>
        )}
      </span>
    </Link>
  ) : null;
}

BookingButton.propTypes = {
  btnSize: PropTypes.string,
  isMenu: PropTypes.bool,
  brand: PropTypes.object,
  siteHeader: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader,
  };
}

export default connect(mapStateToProps)(
  withTranslation()(withBrand(BookingButton)),
);
