import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { PropsWithTestId } from '../../../../../../types';
import cn from 'classnames';
import styles from './ToggleFilterButton.module.scss';

export enum ToggleFilterButtonThemes {
  default = 'default',
  rounded = 'rounded',
}

export enum TextAlignments {
  left = 'left',
  center = 'center',
  right = 'right',
}

export interface ToggleFilterButtonProps
  extends PropsWithTestId<
    PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>
  > {
  theme: keyof typeof ToggleFilterButtonThemes;
  textAlign: keyof typeof TextAlignments;
  iconName?: string;
}

export function ToggleFilterButton(props: ToggleFilterButtonProps) {
  const {
    theme,
    iconName,
    textAlign,
    children,
    className,
    testId,
    ...restProps
  } = props;
  const buttonClassNames = cn(
    styles.button,
    className,
    styles[theme],
    styles[textAlign],
  );

  return (
    <button {...restProps} className={buttonClassNames} data-testid={testId}>
      {iconName && (
        <span
          className={cn('material-icons', styles.icon)}
          data-testid="toggle-button-icon"
        >
          {iconName}
        </span>
      )}
      <span className={styles.label}>{children}</span>
    </button>
  );
}

ToggleFilterButton.defaultProps = {
  theme: ToggleFilterButtonThemes.default,
  textAlign: TextAlignments.center,
  testId: 'toggle-filter-button-action',
};
