import React from 'react';
import PropTypes from 'prop-types';

import './FormMessage.scss';

export default function FormMessage(props) {
  const { enabled = true, type = 'default', className = '' } = props;

  return (
    <div
      className={`c-form-message ${
        enabled ? 'visible' : 'hidden'
      } ${type} ${className}`}
    >
      {props.children}
    </div>
  );
}

FormMessage.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'danger']),
  enabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
