import { HubLandingPage } from '../../../../../../types';
import { useRootSelector } from '../../../../../../store/hooks';
import {
  selectAudienceHubPage,
  selectAudienceHubPageLogo,
} from '../../../store/features/page';
import { selectAudienceHubOptions } from '../../../store/features/options';
import { HubModuleBuilder } from '../../../../shared/siteBuilder/HubModuleBuilder';
import PageLayout from '../../../layouts/PageLayout';
import { MetaTags } from '../../../../shared/MetaTags';
import ErrorBoundary from '../../../../../common/ErrorBoundary';

export default function Page() {
  const {
    data: {
      landingPage: {
        modules,
        details: { name: pageName, title, metaDescription },
      },
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectAudienceHubPage<HubLandingPage>());
  const {
    data: { name: hubName },
  } = useRootSelector(selectAudienceHubOptions);
  const pageLogo = useRootSelector(selectAudienceHubPageLogo);

  const logo = pageLogo ?? brandLogo;
  const heading = title || pageName;

  return (
    <PageLayout>
      <MetaTags
        title={heading}
        titleSuffix={hubName}
        description={metaDescription}
        thumbnail={logo?.path}
      />
      {modules.map((module, index) => (
        <ErrorBoundary key={index}>
          <HubModuleBuilder module={module} />
        </ErrorBoundary>
      ))}
    </PageLayout>
  );
}
