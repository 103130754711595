import React from 'react';
import styled from 'styled-components';
import Spinner from '../../../components/shared/Spinner/Spinner';

const CallToAction = (props) => {
  const {
    className,
    additionalClasses,
    activeClass,
    handleClick = () => {},
    elementType,
    messaging,
    loading,
  } = props;

  const CTALink = styled(elementType)``;

  const combinedClasses = [className, additionalClasses, activeClass].join(' ');

  return elementType === 'input' ? (
    <CTALink {...props} className={combinedClasses} onClick={handleClick} />
  ) : (
    <CTALink {...props} className={combinedClasses} onClick={handleClick}>
      {loading && <Spinner />}
      {messaging}
    </CTALink>
  );
};

export default CallToAction;
