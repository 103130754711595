import React from 'react';

import CurvedCTA from '../CurvedCTA';
import BlockCTA from '../BlockCTA';

const StyledCTA = (props) => {
  switch (props.styleType) {
    case 'block':
      return <BlockCTA {...props} />;
    case 'curved':
      return <CurvedCTA {...props} />;
    default:
      return null;
  }
};

export default StyledCTA;
