import { isFunction, stringifyQueryParams } from '../../helpers';
import { X_INFORMA_USER_ACCESS_TOKEN } from '../../constants';

const REQUEST = 'LEAD_GEN_FORM_REQUEST';
const SUCCESS = 'LEAD_GEN_FORM_SUCCESS';
const FAILURE = 'LEAD_GEN_FORM_FAILURE';
const REMOVE = 'LEAD_GEN_FORM_REMOVE';

const initialState = {
  loaded: false,
  statusCode: null,
};

export default function leadGenFormReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        loading: true,
        leadGenType: action.data.leadGenType,
      });

    case SUCCESS:
      return Object.assign({}, state, {
        statusCode: 200,
        loading: false,
        loaded: true,
        data: {
          ...action.data.json,
        },
      });

    case FAILURE:
      return Object.assign(
        {},
        {
          statusCode: action.data.statusCode,
          loaded: false,
          data: {
            ...action.data.json,
          },
        },
      );

    case REMOVE:
      return Object.assign({}, initialState);

    default:
      return state;
  }
}

export function fetchLeadGenFormRequest(data) {
  return { type: REQUEST, data };
}

export function fetchLeadGenFormSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchLeadGenFormFailure(data) {
  return { type: FAILURE, data };
}

export function removeLeadGenData() {
  return { type: REMOVE };
}

export function fetchLeadGenFormAction(
  { leadGenType, leadGenId, queryParams = '' },
  callback,
) {
  return function (dispatch, getState, { Api, i18n }) {
    dispatch(fetchLeadGenFormRequest({ leadGenType }));

    const {
      pageConfig: { isPreview },
    } = getState();
    const token = queryParams.token;
    delete queryParams.token;
    const query = stringifyQueryParams(queryParams);

    return Api()
      .service('LeadGenService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
          [X_INFORMA_USER_ACCESS_TOKEN]: token,
        },
      })
      .fetchLeadGenForm({ leadGenType, leadGenId, queryParams: query })
      .then((response) => {
        if (isFunction(callback)) {
          const data = {
            statusCode: response.statusCode,
            ...response.json,
          };

          callback(data);
        }

        if (response.statusCode === 403) {
          return dispatch(fetchLeadGenFormSuccess(response));
        } else {
          return dispatch(fetchLeadGenFormFailure(response));
        }
      });
  };
}

export function removeLeadGenDataAction() {
  return function (dispatch) {
    return dispatch(removeLeadGenData());
  };
}
