import React from 'react';
import styled from 'styled-components';
import { color } from '../../styles/informatech/colours/colours';
import { typography } from '../../styles/informatech/typography/typography';
import { breakpoint, spacing } from '../../styles/shared';
import MenuList from '../MenuList';

const Division = styled.div`
  background-color: ${color.backgroundInformaTechBlue};
  color: ${color.lightest};

  a {
    font-family: ${typography.type.primary};
  }
`;

const DivisionWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;

  ${({ bottomBorder }) =>
    bottomBorder && `border-bottom: 1px solid ${color.lightest};`}

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    flex-direction: column;
  }

  @media (max-width: ${breakpoint.mobileBreakpoint}) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const DivisionalMenu = styled(MenuList)`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    width: 100%;
    justify-content: center;
    padding-bottom: ${spacing.menuItems};
    margin-left: -${spacing.menuItems};
  }
`;

export const DivisionalFooter = ({ menuItems, bottomBorder, Logo }) => {
  return (
    <Division>
      <DivisionWrap bottomBorder={bottomBorder}>
        {Logo}
        <DivisionalMenu items={menuItems} />
      </DivisionWrap>
    </Division>
  );
};
