import React from 'react';
import styled, { css } from 'styled-components';

import LeadGenForm from '../../../components/leadGen/LeadGenForm/LeadGenForm';
import LeadGenLoginForm from '../../../components/leadGen/LeadGenLoginForm/LeadGenLoginForm';
import TabbedViewSwitcher from '../TabbedViewSwitcher';
import StyledCTA from '../StyledCTA';

import { typography as ICTypography } from '../../styles/informaconnect/typography/typography';
import { typography as KNectTypography, breakpoint } from '../../styles/shared';

const LoginRegister = styled(TabbedViewSwitcher)`
  button {
    min-width: 250px;
    width: 250px;
    margin-right: 25px;
    transition: margin 0s !important;

    @media screen and (max-width: ${breakpoint.tabletBreakpoint}) {
      display: block;
      margin: 0 auto;

      &:first-of-type {
        margin-bottom: 20px;
      }

      &.lead-gen-login-button {
        margin: 20px 0 0 0;
      }
    }
  }

  .tabOuter {
    padding-bottom: 50px;
  }

  .viewOuter {
    padding: 0 0 50px 0;
    background: linear-gradient(180deg, #f3f3f3 0%, #ffffff 300px);
  }

  .lead-gen-login-form {
    border-bottom: 0;
  }

  .lead-gen-form__title {
    padding-top: 0;
  }

  .lead-gen-register-form {
    padding-bottom: 0;
  }

  ${(props) =>
    props.isArticle &&
    css`
      button {
        min-width: 200px;
        width: 200px;
      }

      .tabOuter {
        padding-bottom: 33px;
      }

      .viewOuter {
        background: #ffffff;
        padding: 33px 0;
      }

      .lead-gen-login-form {
        padding: 0;
      }
    `}
`;

const Header = styled.h2`
  font-family: ${KNectTypography.type.secondary};
  font-size: 32px;
  line-height: 30px;
  margin-bottom: 5px;
  //text-transform: uppercase;

  ${(props) =>
    props.isArticle &&
    css`
      font-family: ${ICTypography.type.primary};
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 6px;
      color: #002244;
      text-transform: none;
    `}
`;
Header.displayName = 'Header';

const Message = styled.p`
  font-size: 16px;
  margin-bottom: 22px;

  ${(props) =>
    props.isArticle &&
    css`
      font-family: ${ICTypography.type.primary};
      line-height: 28px;
      color: #333333;
    `}
`;

const Container = styled.div`
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (max-width: ${breakpoint.largeDesktopBreakpoint}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const getAccessMessage = (tenantId) => {
  switch (tenantId) {
    case 'INFORMACONNECT':
      return 'Log in or Create an Informa Connect Account to Access this content';
    case 'KNECT365':
      return 'Log in or Create a KNect365 Account to Access this content';
    default:
      return 'Log in or Create an account to Access this content';
  }
};

const LoginRegisterSwitcher = ({
  LGFormData,
  LGLoginFormData: {
    buttonStyle: { backgroundColor, borderColor },
    tenantId,
  },
  LGLoginFormData,
  leadGenType,
  formClass,
  siteType = 'EVENT',
}) => {
  const buttonColours = {
    activeBG: backgroundColor,
    borderColour: borderColor,
  };

  const isArticle = leadGenType === 'article';

  const accessMessage = getAccessMessage(tenantId);
  const siteTypeName = siteType.toLowerCase();

  return (
    <div>
      <Container className="container">
        <div className="col-xs-12">
          <Header isArticle={isArticle}>{accessMessage}</Header>
          <Message isArticle={isArticle}>
            {`Creating an account also means you'll get special access to ${siteTypeName} updates and exclusive industry content.`}
          </Message>
        </div>
      </Container>
      <div>
        <LoginRegister
          noInitialState
          tabInnerClass={`col-xs-12 col-md-9 ${formClass}`}
          viewInnerClass={`col-xs-12 col-md-9 ${formClass}`}
          isArticle={isArticle}
          viewMap={[
            {
              tab: (
                <StyledCTA
                  elementType="button"
                  messaging="Log in"
                  colours={buttonColours}
                  className="switcherButton"
                  styleType={isArticle ? 'curved' : 'block'}
                />
              ),
              view: (
                <LeadGenLoginForm
                  {...LGLoginFormData}
                  isArticle={isArticle}
                  data-test-lead-gen="lead-gen-login-form"
                />
              ),
            },
            {
              tab: (
                <StyledCTA
                  elementType="button"
                  messaging="Create account"
                  colours={buttonColours}
                  className="switcherButton"
                  styleType={isArticle ? 'curved' : 'block'}
                />
              ),
              view: <LeadGenForm {...LGFormData} />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default LoginRegisterSwitcher;
