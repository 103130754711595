import { HEXtoRGBA, getImgixUrl } from '../../helpers';
import { colorLuminance } from '../../components/styled/helpers';

export default class SiteBrandService {
  constructor(brandObject) {
    this.brandObject = Object.assign(
      { colours: {}, brandElements: {} },
      brandObject,
    );
  }

  getUpdatedColor(color, percentage) {
    return colorLuminance(this.brandObject.colours[color], percentage);
  }

  getPrimaryColor() {
    return this.getUpdatedColor('primary', 0);
  }

  getLightPrimaryColor() {
    return this.getUpdatedColor('primary', 5);
  }

  getDarkPrimaryColor() {
    return this.getUpdatedColor('primary', -10);
  }

  getCustomPrimaryColor(percentage) {
    return this.getUpdatedColor('primary', percentage);
  }

  getSecondaryColor() {
    return this.getUpdatedColor('secondary', 0);
  }

  getLightSecondaryColor() {
    return this.getUpdatedColor('secondary', 5);
  }

  getDarkSecondaryColor() {
    return this.getUpdatedColor('secondary', -10);
  }

  getCustomSecondaryColor(percentage) {
    return this.getUpdatedColor('secondary', percentage);
  }

  getAccentColor() {
    return this.getUpdatedColor('accent', 0);
  }

  getLightAccentColor() {
    return this.getUpdatedColor('accent', 5);
  }

  getDarkAccentColor() {
    return this.getUpdatedColor('accent', -10);
  }

  getCustomAccentColor(percentage) {
    return this.getUpdatedColor('accent', percentage);
  }

  getLogoSize() {
    return this.brandObject.logoSize;
  }

  getLogoPath() {
    return this.brandObject.logo?.path;
  }

  getInvertedLogoPath() {
    return this.brandObject.whiteLogo?.path;
  }

  getSupergraphicSVGPath() {
    return this.brandObject.brandElements?.supergraphic?.path;
  }

  getPatternSVGPath() {
    return this.brandObject.brandElements?.pattern?.path;
  }

  getSupportingSVGPath() {
    return this.brandObject.brandElements?.supporting?.path;
  }

  getBackgroundImage(tenantId, { path } = {}) {
    const backgroundImagePath = getImgixUrl(tenantId, path);
    return backgroundImagePath ? `url(${backgroundImagePath})` : 'none';
  }

  getRGBAColor(color, alpha) {
    return HEXtoRGBA(this.brandObject.colours[color], alpha);
  }
}
