import { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import s from './HelperText.module.scss';

export interface HelperTextProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  color: 'normal' | 'danger';
}

export default function HelperText(props: PropsWithChildren<HelperTextProps>) {
  const { color, className, children } = props;

  return <div className={cn(s.wrapper, s[color], className)}>{children}</div>;
}

HelperText.defaultProps = {
  color: 'normal',
};
