import React from 'react';

import SitePartners from '../../sections/SitePartners/SitePartners';

import './ModalEventPartners.scss';

export default function ModalEventPartners() {
  return (
    <div className="modal-event-partners">
      <SitePartners />
    </div>
  );
}
