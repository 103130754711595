import React from 'react';
import { UniqueIdentifier } from '../../../../../../types';
import styles from './ProductsPreviewList.module.scss';

export interface ProductsPreviewListProps<T> {
  products: T[];
  countToShow?: number;
  children: (product: T) => React.ReactNode;
}

export function ProductsPreviewList<T extends { id: UniqueIdentifier }>(
  props: ProductsPreviewListProps<T>,
) {
  const { products, countToShow, children } = props;

  return (
    <div className="row">
      {products.slice(0, countToShow).map((product) => (
        <div key={product.id} className="col-xs-12 col-md-4">
          <div className={styles.productWrapper}>{children(product)}</div>
        </div>
      ))}
    </div>
  );
}

ProductsPreviewList.defaultProps = {
  countToShow: 6,
};
