import { DiscoveryTopic } from '../../../../../store/features/discovery';

export function getDiscoveryTopicsByBrand(
  topics: DiscoveryTopic[] = [],
  brandName: string,
) {
  return topics
    .filter((topic) => topic && topic.brand === brandName)
    .map((topic) => topic.name);
}
