import React from 'react';

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    console.error(error, info);

    this.setState({
      hasError: true,
    });
  }

  render() {
    const { errorMessage = null, children } = this.props;

    if (this.state.hasError) {
      return errorMessage;
    }

    return <>{children}</>;
  }
}
