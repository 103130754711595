import {
  fetchBookingClosedMessageAction,
  BOOKING_CLOSED,
} from '../siteDucks/bookingClosed';

export const SUCCESS = 'COURSE_OPTIONS_SUCCESS';
export const FAILURE = 'COURSE_OPTIONS_FAILURE';

const initialState = {
  loaded: false,
};

export default function courseOptionsReducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          booking: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case BOOKING_CLOSED:
      return Object.assign(
        {},
        {
          loaded: true,
          booking: false,
          ...action.data.json,
          statusCode: 423,
        },
      );

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

export function fetchCourseOptionsSuccess(data) {
  return { type: SUCCESS, data };
}

export function courseOptionsFailure(data) {
  return { type: FAILURE, data };
}

export function fetchCourseOptionsAction(basketId) {
  return function (dispatch, getState, { Api, qs, i18n }) {
    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'GET',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .courseOptions({ siteId, basketId })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchCourseOptionsSuccess(response));
        } else if (response.statusCode === 423) {
          return dispatch(fetchBookingClosedMessageAction());
        } else {
          return dispatch(courseOptionsFailure(response));
        }
      });
  };
}
