import { HTMLAttributes } from 'react';
import cn from 'classnames';
import Patterns from '../../../../../../../utils/pattern/patterns';
import s from './PasswordStrengthBar.module.scss';
import Characteristic from './Characteristic';

export function validateLength(password: string, length: number) {
  return password.length >= length;
}

export function validateUppercase(password: string) {
  return /[A-Z]/.test(password);
}

export function validateLowercase(password: string) {
  return /[a-z]/.test(password);
}

export function validateNumber(password: string) {
  return /\d/.test(password);
}

export interface PasswordStrengthBarProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  password: string;
  length: number;
  uppercase: boolean;
  lowercase: boolean;
  number: boolean;
  showInvalid: boolean;
}

export default function PasswordStrengthBar(props: PasswordStrengthBarProps) {
  const { password, showInvalid, className, ...characteristics } = props;
  const { length, uppercase, lowercase, number } = characteristics;

  return (
    <div className={cn(s.wrapper, className)}>
      <div className={s.label}>
        Minimum requirements for a <strong>strong</strong> password:
      </div>
      <div className={s.body}>
        <Characteristic
          count={length}
          label="characters"
          showInvalid={showInvalid}
          validate={() => validateLength(password, length)}
        />
        {uppercase && (
          <Characteristic
            count={1}
            label="uppercase"
            showInvalid={showInvalid}
            validate={() => validateUppercase(password)}
          />
        )}
        {lowercase && (
          <Characteristic
            count={1}
            label="lowercase"
            showInvalid={showInvalid}
            validate={() => validateLowercase(password)}
          />
        )}
        {number && (
          <Characteristic
            count={1}
            label="number"
            showInvalid={showInvalid}
            validate={() => validateNumber(password)}
          />
        )}
      </div>
    </div>
  );
}

PasswordStrengthBar.defaultProps = {
  length: Patterns.passwordLength,
  uppercase: true,
  lowercase: true,
  number: true,
  showInvalid: false,
};
