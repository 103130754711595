import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleTurtl } from '../../../store/features/siteArticles/siteArticleDetails';

import './TurtlEmbed.module.scss';

export interface TurtlEmbedProps {
  turtl: ArticleTurtl;
}

export default function TurtlEmbed(props: TurtlEmbedProps) {
  const { turtl: { storyId, linkUrl, linkTitle } = {} } = props;
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = 'https://app-static.turtl.co/embed/turtl.embed.v1.js';
    script.async = true;
    script.setAttribute('data-turtl-script', 'embed');
    script.setAttribute(
      'data-turtl-assets-hostname',
      'https://assets.turtl.co',
    );

    const turtlBox = document.getElementById('turtl-embed-bar');
    turtlBox && turtlBox.appendChild(script);

    return () => {
      turtlBox && turtlBox.replaceChildren();
    };
    // eslint-disable-next-line
  }, []);

  if (!storyId || !linkUrl || !linkTitle) {
    return null;
  }

  return (
    <div id="turtl-embed-bar" data-testid="turtl-embed-bar">
      <a
        id="turtl-link"
        className="turtl-embed"
        target="_blank"
        rel="noopener noreferrer"
        data-turtl-embed-type="animation"
        data-turtl-link-text={t('site.article.click-to-read.button')}
        data-turtl-width="500"
        data-turtl-display-mode="lightbox"
        data-turtl-animation-mode="hover"
        data-turtl-color="#1EB1C7"
        data-turtl-story-id={storyId}
        href={linkUrl + '?teaser=yes'}
        title={linkTitle}
      >
        {t('site.article.click-to-read.button')} {linkTitle}
      </a>
    </div>
  );
}
