import React from 'react';
import PropTypes from 'prop-types';
import { addQueryParamsToUrl } from '../../../helpers';
import CallToAction from '../CallToAction/CallToAction';
import useVirtualContentAccessStatus from '../../../hooks/useVirtualContentAccessStatus';

export default function VirtualContentAccessCTA(props) {
  const {
    ctaConfig: {
      productCodes,
      notLoggedInUrl,
      notLoggedInText,
      loggedInUnmatchedUrl,
      loggedInUnmatchedText,
      loggedInUrl,
      loggedInText,
    } = {},
  } = props;

  const accessStatus = useVirtualContentAccessStatus(productCodes);

  /**
   * Do not display CTA if status === NONE
   */
  if (accessStatus === 'NONE') {
    return null;
  }

  const ctaCustomConfig = { type: 'CUSTOM' };

  if (accessStatus === 'LOADING') {
    ctaCustomConfig.customUrl = '#';
    ctaCustomConfig.customText = notLoggedInText || 'Loading, please wait...';
  } else if (accessStatus === 'LOGIN') {
    ctaCustomConfig.customUrl = notLoggedInUrl
      ? addQueryParamsToUrl(notLoggedInUrl, {
          redirectUrl: document.location.href,
        })
      : undefined;
    ctaCustomConfig.customText = notLoggedInText;
  } else if (accessStatus === 'BOOK_NOW') {
    ctaCustomConfig.customText = loggedInUnmatchedText;

    if (loggedInUnmatchedUrl) {
      ctaCustomConfig.customUrl = loggedInUnmatchedUrl;
    } else {
      /**
       * If no booking url provided then use standard BUY_LINK CTA
       */
      ctaCustomConfig.type = 'BUY_LINK';
    }
  } else if (accessStatus === 'GRANTED') {
    ctaCustomConfig.customUrl = loggedInUrl;
    ctaCustomConfig.customText = loggedInText;
  }

  let labelOverwrite;
  if (ctaCustomConfig.type === 'BUY_LINK') {
    /**
     * Do not display BUY_LINK CTA if no custom text (label) provided
     */
    if (!ctaCustomConfig.customText) {
      return null;
    }

    /**
     * Override standard BUY_LINK text (label) by custom one
     */
    labelOverwrite = ctaCustomConfig.customText;
    delete ctaCustomConfig.customText;
  } else {
    /**
     * Do not display CTA if no custom text or url provided
     */
    if (!ctaCustomConfig.customUrl || !ctaCustomConfig.customText) {
      return null;
    }
  }

  return (
    <CallToAction
      {...props}
      ctaConfig={ctaCustomConfig}
      labelOverwrite={labelOverwrite}
      loading={accessStatus === 'LOADING'}
    />
  );
}

VirtualContentAccessCTA.propTypes = {
  ctaConfig: PropTypes.shape({
    type: PropTypes.oneOf(['CUSTOM_LOGIN']),
    productCodes: PropTypes.arrayOf(PropTypes.string),
    notLoggedInUrl: PropTypes.string,
    notLoggedInText: PropTypes.string,
    loggedInUnmatchedUrl: PropTypes.string,
    loggedInUnmatchedText: PropTypes.string,
    loggedInUrl: PropTypes.string,
    loggedInText: PropTypes.string,
  }).isRequired,
};
