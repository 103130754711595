import React from 'react';
import styled from 'styled-components';
import InformaTechLogoWhite from './informa-tech-logo-footer@2x.png';
import { color } from '../../styles/informatech/colours/colours';
import { typography } from '../../styles/informatech/typography/typography';
import { breakpoint } from '../../styles/shared';

const INFORMA_TECH_LOGO_HREF = 'https://tech.informa.com';

const Logo = styled.img`
  width: 240px;
  height: 60px;
  margin-top: 35px;
  margin-bottom: 35px;
  align-self: flex-start;

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    margin-top: 40px;
    margin-bottom: 60px;
  }
`;

const Prefix = styled.span`
  font-family: ${typography.type.primary};
  font-size: ${typography.size.s1}px;
  color: ${color.lightest};
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 71px;

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    top: 40px;
  }
`;

const LogoWrap = styled.a`
  position: relative;
  display: inline-block;
`;

export const InformaTechLogo = ({ alt, showPrefix }) => {
  return (
    <LogoWrap href={INFORMA_TECH_LOGO_HREF}>
      {showPrefix ? <Prefix>Brought to you by</Prefix> : null}
      <Logo src={InformaTechLogoWhite} alt={alt} />
    </LogoWrap>
  );
};
