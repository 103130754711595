import qs from 'qs';

export function clearParams(params = '') {
  return params.replace(/^\?+|\//g, '');
}

export function filterValidStreamsAsObject(queryParams = '') {
  const qParams = qs.parse(clearParams(queryParams));

  if (qParams.stream) {
    const isInteger = (e) => /^\d+$/.test(e);

    qParams.stream = Array.isArray(qParams.stream)
      ? qParams.stream.filter((e) => isInteger(e))
      : isInteger(qParams.stream)
      ? qParams.stream
      : null;
  }

  return qParams;
}

export function filterValidStreamsAsQueryString(queryParams = '') {
  const qParams = filterValidStreamsAsObject(queryParams);
  return qs.stringify(qParams, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
    indices: false,
    skipNulls: true,
  });
}
