const X_MA_SEGMENTS = 'x-ma-segments';
const X_MA_CID = 'x-ma-cid';

export function getContentMAHeader(req, maConfig = {}, maSegments = {}) {
  const maHeader = {};

  const segments = req?.universalCookies?.get(X_MA_SEGMENTS);

  if (maSegments[X_MA_SEGMENTS] || segments) {
    // Preview mode
    maHeader[X_MA_SEGMENTS] = maSegments[X_MA_SEGMENTS] || segments;
  } else if (maConfig[X_MA_SEGMENTS]) {
    maHeader[X_MA_SEGMENTS] = maConfig[X_MA_SEGMENTS];
  }
  return maHeader;
}

export function getHeaderMAHeader(req, maConfig = {}, maSegments = {}) {
  const maHeader = {};

  const segments = req?.universalCookies?.get(X_MA_SEGMENTS);

  if (maSegments[X_MA_SEGMENTS] || segments) {
    // Preview mode
    maHeader[X_MA_SEGMENTS] = maSegments[X_MA_SEGMENTS] || segments;
  } else if (maConfig[X_MA_SEGMENTS] && maConfig[X_MA_CID]) {
    maHeader[X_MA_SEGMENTS] = maConfig[X_MA_SEGMENTS];
    maHeader[X_MA_CID] = maConfig[X_MA_CID];
  }
  return maHeader;
}
