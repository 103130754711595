import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import {
  getStyledPropertyValue,
  renderStyledElementStyles,
} from '../../../helpers';
import SVGEmbed from '../../../../shared/SVGEmbed';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  svg {
    .cls-1 {
      fill: ${({ theme, colors }) =>
        getStyledPropertyValue(theme, colors.color1) ||
        theme.brandColors.primary};
    }

    .cls-2 {
      fill: ${({ theme, colors }) =>
        getStyledPropertyValue(theme, colors.color2) ||
        theme.brandColors.secondary};
    }

    .cls-3 {
      fill: ${({ theme, colors }) =>
        getStyledPropertyValue(theme, colors.color3) ||
        theme.brandColors.accent};
    }
  }

  ${({ theme, visualStyles }) => renderStyledElementStyles(theme, visualStyles)}
`;

export const StyledSVGEmbed = ({
  href = '',
  colors = {},
  visualStyles,
  ...props
}) => {
  const themeConfig = useContext(ThemeContext);

  return (
    <Wrapper colors={colors} visualStyles={visualStyles}>
      <SVGEmbed
        href={getStyledPropertyValue(themeConfig, href)}
        transparent={false}
        {...props}
      />
    </Wrapper>
  );
};

StyledSVGEmbed.propTypes = {
  href: PropTypes.string.isRequired,
  colors: PropTypes.shape({
    color1: PropTypes.string,
    color2: PropTypes.string,
    color3: PropTypes.string,
  }),
  visualStyles: PropTypes.object,
};
