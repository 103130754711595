import { PricingPackagesFormValues } from '../../../store/features/packages/types';
import { getProductIdsFromPackageFormValues } from '../getProductIdsFromPackageFormValues';

export interface GetFetchPricingProductsParamsProps {
  basketId: string;
  formValues: PricingPackagesFormValues;
  vipCodeForValidation?: string;
  excludeProductIds?: string[];
}

export function getFetchPricingProductsParams(
  props: GetFetchPricingProductsParamsProps,
) {
  const {
    basketId,
    formValues: {
      quantity,
      delegateTypeId,
      siteOptionIndex,
      vipCode,
      packageFormValues,
    },
    vipCodeForValidation,
    excludeProductIds,
  } = props;
  const vipCodeValue = vipCodeForValidation || vipCode;

  return {
    basketId,
    courseOptionSubPath: siteOptionIndex,
    queryParams: {
      quantity,
      delegateTypeId,
      productIds: getProductIdsFromPackageFormValues(
        packageFormValues,
        excludeProductIds,
      ),
      vipCode: vipCodeValue ? [vipCodeValue] : undefined,
    },
  };
}
