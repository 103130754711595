import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';

import LeadGenCheckbox from '../../../LeadGenFormItems/LeadGenCheckbox/LeadGenCheckbox';
import Modal from '../../../../shared/Modal/Modal';
import ModalEventPartners from '../../../../shared/ModalEventPartners/ModalEventPartners';

import './LeadGenConsentFirstStatements.scss';

export const Anchor = (props) => {
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a {...props} />;
};

export function LeadGenConsentFirstStatements(props) {
  const {
    consentData: { checkboxes = [], fallbackQuestion, id } = {},
    pageConfig: { tenantConfig: { outputName: tenantName } = {} } = {},
    siteHeader: { data: { title: eventName } = {} } = {},
    color,
    t,
  } = props;
  const ModalLink = Modal(Anchor, ModalEventPartners);

  return (
    <div className="c-lead-gen-consent-first-statements">
      {fallbackQuestion && (
        <div className="c-lead-gen-consent-first-statements__text">
          <Trans
            values={{
              eventName,
              tenantName,
              eventPartners: t('leadGen.third-party-header.content-partners'),
              partners: t('leadGen.third-party-header.partners'),
            }}
            components={[
              <ModalLink
                withTopPanel
                style={{
                  color,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  textTransform: 'lowercase',
                }}
              />,
            ]}
          >
            {fallbackQuestion}
          </Trans>
        </div>
      )}
      {checkboxes.length > 0 && (
        <>
          <div className="c-lead-gen-consent-first-statements__checkbox-title">
            Please tick the relevant box:
          </div>
          <div className="c-lead-gen-consent-first-statements__checkbox-wrapper">
            {checkboxes.map(({ label, name }) => {
              return (
                <div
                  className="c-lead-gen-consent-first-statements__checkbox"
                  key={name}
                >
                  <Field
                    name={`${id}.${name}`}
                    label={label}
                    component={LeadGenCheckbox}
                    color={color}
                    isCheckboxGroup={false}
                    type="checkbox"
                    labelSize="small"
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

LeadGenConsentFirstStatements.propTypes = {
  consentData: PropTypes.shape({
    checkboxes: PropTypes.array,
    fallbackQuestion: PropTypes.string,
    id: PropTypes.string,
  }),
  color: PropTypes.string,
  pageConfig: PropTypes.shape({
    tenantConfig: PropTypes.shape({
      outputName: PropTypes.string,
    }),
    siteType: PropTypes.string,
  }),
  siteHeader: PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
    siteHeader: state.siteHeader,
  };
}

export default withTranslation()(
  connect(mapStateToProps)(LeadGenConsentFirstStatements),
);
