import { createSelector } from '@reduxjs/toolkit';
import {
  GenericState,
  HubLayout,
  UniqueIdentifier,
} from '../../../../../../types';
import { RootState } from '../../../../../../store/store';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../../store/features/generic';

export interface BrandHubLayout extends HubLayout {}

export interface BrandHubLayoutParams {
  brandHubId: UniqueIdentifier;
}

export const fetchBrandHubLayout = createGenericAsyncThunk<
  BrandHubLayout,
  BrandHubLayoutParams
>(
  'brandHubLayout/fetchBrandHubLayout',
  async ({ brandHubId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/brand-hub/${brandHubId}/layout`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: BrandHubLayout = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const brandHubLayoutSlice = createGenericSlice({
  name: 'brandHubLayout',
  initialState: {
    status: 'idle',
    data: {},
  } as GenericState<BrandHubLayout>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<BrandHubLayout, BrandHubLayoutParams>(
      builder,
      fetchBrandHubLayout,
    );
  },
});

export const selectBrandHubLayout = createSelector(
  [(state: RootState) => state.brandHub.layout],
  (data) => data,
);

export const { start, fulfilled, rejected } = brandHubLayoutSlice.actions;

export default brandHubLayoutSlice.reducer;
