import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SiteBrandService from '../../services/SiteBrandService';

export const BrandContext = React.createContext({});
const { Provider, Consumer } = BrandContext;

class BrandProvider extends Component {
  constructor(props) {
    super(props);

    this.brandObject = new SiteBrandService(props.options.brand);
  }

  render() {
    const { pageConfig: { tenantId } = {} } = this.props;
    const siteBrand = this.brandObject;

    const brandObject = {
      primaryColor: siteBrand.getPrimaryColor(),
      lightPrimaryColor: siteBrand.getLightPrimaryColor(),
      darkPrimaryColor: siteBrand.getDarkPrimaryColor(),
      getCustomPrimaryColor() {
        return siteBrand.getCustomPrimaryColor(...arguments);
      },

      secondaryColor: siteBrand.getSecondaryColor(),
      lightSecondaryColor: siteBrand.getLightSecondaryColor(),
      darkSecondaryColor: siteBrand.getDarkSecondaryColor(),
      getCustomSecondaryColor() {
        return siteBrand.getCustomSecondaryColor(...arguments);
      },

      accentColor: siteBrand.getAccentColor(),
      lightAccentColor: siteBrand.getLightAccentColor(),
      darkAccentColor: siteBrand.getDarkAccentColor(),
      getCustomAccentColor() {
        return siteBrand.getCustomAccentColor(...arguments);
      },

      logoSize: siteBrand.getLogoSize(),
      logoPath: siteBrand.getLogoPath(),
      invertedLogoPath: siteBrand.getInvertedLogoPath(),

      supergraphicSVGPath: siteBrand.getSupergraphicSVGPath(),
      patternSVGPath: siteBrand.getPatternSVGPath(),
      supportingSVGPath: siteBrand.getSupportingSVGPath(),

      getBackgroundImage(obj) {
        return siteBrand.getBackgroundImage(tenantId, obj);
      },

      getRGBAColor(brandColour, alpha) {
        return siteBrand.getRGBAColor(brandColour, alpha);
      },
    };

    return <Provider value={brandObject}>{this.props.children}</Provider>;
  }
}

BrandProvider.propTypes = {
  options: PropTypes.shape({
    brand: PropTypes.shape({
      colours: PropTypes.shape({}),
      brandElements: PropTypes.shape({}),
    }),
  }),
  pageConfig: PropTypes.shape({
    tenantId: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    options: state.options.data,
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(withRouter(BrandProvider));

function withBrand(WrappedComponent) {
  return function withBrandContainer(props) {
    return (
      <Consumer>
        {(brandObject) => <WrappedComponent {...props} brand={brandObject} />}
      </Consumer>
    );
  };
}

export { Consumer as BrandConsumer, withBrand };
