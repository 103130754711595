import fetch from 'cross-fetch';
import { isAbsoluteUrl, isBrowser, isFunction } from '../../helpers';

export default class MultipleAPIService {
  constructor(externalConfig) {
    this._serviceConfig = externalConfig;

    this._api = {
      services: {},
    };

    /**
     * For each service in the config
     */
    Object.keys(this._serviceConfig.services).forEach((service) => {
      this._api.services[service] = {
        versions: {},
      };

      /**
       * For each API version in the given service
       */
      Object.keys(this._serviceConfig.services[service]).forEach((version) => {
        this._api.services[service].versions[version] = {};

        /**
         * For each API method (endpoint) in the given API version
         */
        Object.keys(this._serviceConfig.services[service][version]).forEach(
          (methodName) => {
            /**
             * Create a function which applies request parameters.
             * e.g. API.services('ContentService').versions('v1').getProfileData()
             */
            this._api.services[service].versions[version][methodName] = async (
              ...args
            ) => {
              const pathname = this._serviceConfig.services[service][version][
                methodName
              ](...args);
              const params = this._requestConfig.headers;

              /**
               * If Redis enabled then try to fetch data from its cache
               */
              const redisAnswer = await this._getRedisObject({
                pathname,
                params,
              });
              const cachedResponseObject = Array.isArray(redisAnswer)
                ? redisAnswer[0]
                : redisAnswer;

              /**
               * Resolve Redis cached data if available
               */
              if (cachedResponseObject) {
                return Promise.resolve(JSON.parse(cachedResponseObject));
              }

              /**
               * If there is no cached data then send request as usual
               */
              return this._sendRequest(pathname);
            };
          },
        );

        this._api.services[service].versions[version].setup = (requestConfig) =>
          this.setup(requestConfig);
      });
    });
  }

  init(cfg, clientConfig, serverConfig, logger, isPreview, req) {
    this._cfg = cfg;
    this._requestConfig = {};
    this._clientConfig = clientConfig;
    this._serverConfig = serverConfig;
    this._logger = logger;
    this._isPreview = isPreview;
    this._req = req;
    this._cacheEnabled = false;

    return this;
  }

  service(service) {
    this._service = service;

    return this;
  }

  services() {
    return Object.keys(this._api.services);
  }

  version(version) {
    this._version = version;
    this._requestConfig = {};

    return this._api.services[this._service].versions[this._version];
  }

  versions() {
    return Object.keys(this._api.services[this._service].versions);
  }

  setup(requestConfig = {}) {
    if (!this._service) {
      return console.error('MultipleAPIService: service is not specified');
    }

    if (!this._version) {
      return console.error('MultipleAPIService: version is not specified');
    }

    this._requestConfig = isFunction(requestConfig)
      ? requestConfig(
          this._req,
          this._clientConfig,
          this._serverConfig,
          this._cfg,
        )
      : requestConfig;

    return this._api.services[this._service].versions[this._version];
  }

  cache(enabled, cb) {
    this._cacheEnabled = typeof enabled === 'undefined' ? true : enabled;
    this._redisCacheCallback = cb;

    return this;
  }

  _isCacheEnabled() {
    return (
      !this._isPreview &&
      this._cacheEnabled &&
      this._req &&
      this._req.redisClient.isReady()
    );
  }

  _getRedisObject({ pathname, params }) {
    if (this._isCacheEnabled()) {
      return this._req.redisClient.getRedisObject(this._req, {
        pathname,
        params,
      });
    }

    return Promise.resolve(null);
  }

  _setRedisObject({ pathname, params }, json) {
    if (this._isCacheEnabled()) {
      this._req.redisClient.setRedisObject(
        this._req,
        {
          pathname,
          params,
        },
        json,
      );
    }
  }

  /* istanbul ignore next */
  _log(method, statusCode, url, obj) {
    if (!this._logger) {
      return;
    }

    const logObject = {
      preview: this._isPreview || undefined,
      service: this._service,
      method,
      statusCode,
      url,
    };

    if (statusCode >= 500) {
      this._logger.error(
        Object.assign(logObject, {
          error: obj,
        }),
      );
    } else {
      this._logger.info(logObject);
    }
  }

  _addHostname(path) {
    if (this._isRelativeUrl(path)) {
      return path;
    }

    return (
      this._serverConfig.api.services[this._service][this._version].host + path
    );
  }

  _isRelativeUrl(path) {
    return isAbsoluteUrl(path) || isBrowser();
  }

  _sendRequest(pathname) {
    /**
     * Add hostname to the request pathname (Node app only)
     */
    const url = this._addHostname(pathname);

    let statusCode = 200;

    return fetch(url, this._requestConfig)
      .then(
        (response) => {
          statusCode = response.status;

          return response.json();
        },
        (error) => {
          statusCode = error.status || 500;
          return { json: error };
        },
      )
      .then(
        (json) => {
          this._log(
            this._requestConfig.method || 'GET',
            statusCode,
            json.url || url,
            json,
          );

          if (json && json.error) {
            return { json: json.error, statusCode };
          }

          /**
           * If Redis enabled then store response data to its cache
           */
          if (statusCode === 200 && this._isCacheEnabled()) {
            const params = this._requestConfig.headers;
            this._setRedisObject({ pathname, params }, { json, statusCode });

            if (isFunction(this._redisCacheCallback)) {
              this._redisCacheCallback(this._req.redisClient, json, this._req);
            }
          }

          return { json, statusCode };
        },
        /* istanbul ignore next */
        (error) => {
          statusCode = error.status || 500;
          this._log(
            this._requestConfig.method || 'GET',
            statusCode,
            error.url || url,
            { json: error },
          );
          return { json: error, statusCode };
        },
      );
  }
}
