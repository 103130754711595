import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import type { GenericState } from '../../../types';
import type { RootState } from '../../store';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../generic';

export interface FestivalProGuest {
  id: string;
  name: string;
  category: string;
  categories: string[];
  order: Record<string, string>;
}

export interface FestivalProGuestsArgs {
  eventId: string;
  categoryId: string;
  amount?: number;
}

export const fetchFestivalProGuests = createGenericAsyncThunk<
  FestivalProGuest[],
  Pick<FestivalProGuestsArgs, 'eventId'>
>(
  'festivalProGuests/fetchFestivalProGuests',
  async ({ eventId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/api/v1/festival-pro/events/${eventId}/guests`,
    );
    const data: FestivalProGuest[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const fetchFestivalProGuestsByCategory = createGenericAsyncThunk<
  FestivalProGuest[],
  FestivalProGuestsArgs
>(
  'festivalProGuests/fetchFestivalProGuestsByCategory',
  async ({ eventId, categoryId, amount = '' }, { extra, rejectWithValue }) => {
    const m = amount || 8;
    const response = await extra.ajax.get(
      `/api/v1/festival-pro/events/${eventId}/guests/categories/${categoryId}?amount=${m}`,
    );
    const data: FestivalProGuest[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const festivalProGuestsSlice = createGenericSlice({
  name: 'festivalProGuests',
  initialState: {
    status: 'idle',
    data: [],
  } as GenericState<FestivalProGuest[]>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases(builder, fetchFestivalProGuests);
  },
});

export const selectFestivalProGuestsMap = createSelector(
  [(state: RootState) => state.festivalProGuests.data],
  (guests) => {
    return guests.reduce((acc, guest) => {
      guest.categories.forEach((category) => {
        if (!acc[category]) {
          acc[category] = [guest];
        } else {
          acc[category].push(guest);
        }
      });

      return acc;
    }, {} as Record<string, FestivalProGuest[]>);
  },
);

export const selectFestivalProGuestById = (id: string) => {
  return (state: RootState) => {
    const [guestId, index = 0] = id.split('-');

    const guest = state.festivalProGuests.data.find(
      (guest) => guest.id === guestId,
    );
    if (!guest) return null;

    return { ...guest, category: guest.categories[Number(index)] ?? '' };
  };
};

export const selectFestivalProGuestsStatus = (state: RootState) =>
  state.festivalProGuests.status;

export function sortGuests(guests: FestivalProGuest[]) {
  if (guests.length === 0) return guests;

  const { category } = guests[0];

  return sortBy(guests, (guest) => {
    return +guest.order[category] || 1000000;
  });
}

export default festivalProGuestsSlice.reducer;
