import { PricingOrderReceiptPackageSelection } from '../../../store/features/orderReceipt/types';

export function getPackageSelectionDataForGTM(
  packageSelection: PricingOrderReceiptPackageSelection[],
) {
  const variant = packageSelection
    .map((selection) => selection.productName)
    .join('');
  const category = packageSelection
    .map((selection) => selection.optionNames?.join(''))
    .join('');

  return { variant, category };
}
