import { useMemo } from 'react';
import { HubBasePage } from '../../../../../types';
import { DiscoveryContentTypes } from '../../../../../constants';
import { useRootSelector } from '../../../../../store/hooks';
import { selectAudienceHubPage } from '../../store/features/page';
import { selectAudienceHubOptions } from '../../store/features/options';
import {
  selectHubDiscovery,
  HubPageNames,
  SortTypes,
} from '../../../../../store/features/discovery';
import PageLayout from '../../layouts/PageLayout';
import { MetaTags } from '../../../shared/MetaTags';
import { AggregatedProductSearchPage } from '../../../shared/siteBuilder/AggregatedProductSearchPage';

export function Search() {
  const {
    data: {
      page: {
        details: { title, description, metaDescription, searchQueryData },
      },
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectAudienceHubPage<HubBasePage>());
  const {
    data: { name: hubName, subBrandName: brandName, l2Topic: topicName },
  } = useRootSelector(selectAudienceHubOptions);
  const {
    data: { facets, records },
  } = useRootSelector(selectHubDiscovery);

  const heading = title || 'Search';

  const defaultValues = useMemo(
    () => ({
      type: [DiscoveryContentTypes.ALL],
      requestedSortType: SortTypes.FEATURED,
      deliveryType: [],
      subBrands: [brandName],
      sectors: [],
      topics: [],
      month: [],
      location: [],
      language: [],
      awardGroup: [],
      informationType: [],
      partners: [],
      creditPartners: [],
      mainFilterTopic: topicName,
      accessTypes: [],
      createdTimeFrame: [],
      page: 1,
      count: 20,
      searchInput: '',
    }),
    [brandName, topicName],
  );

  return (
    <PageLayout>
      <MetaTags
        title={heading}
        titleSuffix={hubName}
        description={metaDescription}
        thumbnail={brandLogo?.path}
      />
      <AggregatedProductSearchPage
        pageName={HubPageNames.search}
        title={heading}
        subtitle={description}
        records={records}
        facets={facets}
        defaultValues={defaultValues}
        searchQueryData={searchQueryData}
      />
    </PageLayout>
  );
}
