import { createSelector } from '@reduxjs/toolkit';
import { SiteTypePath, SiteTypes, UniqueIdentifier } from '../../../types';

export type TenantsConfig = {
  [key: string]: {
    domain: string;
  };
};

type ContentConfig = {
  terms: { termsAndConditionsUrl: string };
};

export type TenantConfig = {
  outputName: string;
  contentConfig: ContentConfig;
  domain: string;
  brandHubId: string;
};

export type ElementsProperties = {
  [key: string]: {
    height: number;
  };
};

export interface PageConfig {
  siteId: UniqueIdentifier;
  sitePath: string;
  siteTypePath: SiteTypePath;
  canonicalUrl: string;
  parentDomain: string;
  tenantId: string;
  portfolioId: string;
  portfolioName: string;
  homeOrigin: string;
  siteArticlePath: string;
  tenantsConfig: TenantsConfig;
  tenantConfig: TenantConfig;
  cookieSitePath: string;
  location: Location;
  siteType: SiteTypes;
  elementsProperties: ElementsProperties;
  singleSiteMode: boolean;
  ipAddress: string;
}

export const selectPageConfig = createSelector(
  [(state) => state.pageConfig as PageConfig],
  (data) => data,
);
