import React from 'react';
import { HTMLtoJSX } from '../../../../../../helpers';
import styles from './ProductModuleHeading.module.scss';

export interface ProductModuleHeadingProps {
  heading: React.ReactNode;
  text?: React.ReactNode;
}

export function ProductModuleHeading(props: ProductModuleHeadingProps) {
  const { heading, text } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>{heading}</div>
      {text && <div className={styles.text}>{HTMLtoJSX(text)}</div>}
    </div>
  );
}
