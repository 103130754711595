const v1 = {
  courseOptions({ siteId, basketId }) {
    return `/v1/site/${siteId}/purchase/${basketId}/site-options`;
  },
  siteProducts({ siteId, basketId, queryParams }) {
    return `/v1/site/${siteId}/purchase/${basketId}/site-products${queryParams}`;
  },
  courseOptionProducts({ siteId, basketId, queryParams, courseOptionIndex }) {
    const courseOptionSubPath = courseOptionIndex
      ? `/${courseOptionIndex}`
      : '';
    return `/v1/site/${siteId}/purchase/${basketId}/course-products${courseOptionSubPath}${queryParams}`;
  },
  chosenProducts({ siteId, basketId, queryParams = '' }) {
    return `/v1/site/${siteId}/purchase/${basketId}/packages${queryParams}`;
  },
  deleteChosenProduct({ siteId, basketId, packageId }) {
    return `/v1/site/${siteId}/purchase/${basketId}/packages/${packageId}`;
  },
  updateChosenProducts({ siteId, basketId, packageId, quantity }) {
    return `/v1/site/${siteId}/purchase/${basketId}/packages/${packageId}/quantity/${quantity}`;
  },
  billingDetails({ siteId, basketId }) {
    return `/v1/site/${siteId}/purchase/${basketId}/billing-details`;
  },
  delegateDetails({ siteId, basketId, packageId, delegateId, country = '' }) {
    return `/v1/site/${siteId}/purchase/${basketId}/packages/${packageId}/delegates/${delegateId}?selectedCountry=${country}`;
  },
  contactDetails({ siteId, basketId, contactType }) {
    return `/v1/site/${siteId}/purchase/${basketId}/contacts/${contactType}`;
  },
  confirmPurchase({ siteId, basketId }) {
    return `/v1/site/${siteId}/purchase/${basketId}/confirm-purchase`;
  },
  orderReceipt({ siteId, orderId }) {
    return `/v1/site/${siteId}/payment/${orderId}/receipt`;
  },
  checkVipCode({ siteId, vipCode }) {
    return `/v1/site/${siteId}/promotion/${vipCode}`;
  },
  applyPromotionCode({ siteId, basketId, code }) {
    return `/v1/site/${siteId}/purchase/${basketId}/promotion/${code}`;
  },
  uploadFiles({ siteId, basketId }) {
    return `/v1/site/${siteId}/purchase/${basketId}/files`;
  },
  coursePricing({ siteId }) {
    return `/v1/courses/${siteId}/options-display`;
  },
};

export default v1;
