import { ReactNode } from 'react';
import styles from './ProductBlogCard.module.scss';
import cn from 'classnames';

export enum ProductBlogCardVariants {
  default = 'default',
  list = 'list',
}

export interface ProductBlogCardProps {
  heading: ReactNode;
  variant?: keyof typeof ProductBlogCardVariants;
  image?: ReactNode;
  topics?: ReactNode;
  description?: ReactNode;
  className?: string;
}

export default function ProductBlogCard(props: ProductBlogCardProps) {
  const {
    variant = ProductBlogCardVariants.default,
    image,
    topics,
    heading,
    description,
    className,
  } = props;
  const isListVariant = variant === ProductBlogCardVariants.list;

  const imageListClassNames = cn('col-xs-12', {
    'col-sm-4 col-md-2': isListVariant,
  });
  const bodyListClassNames = cn('col-xs-12', {
    'col-sm-8 col-md-10': isListVariant,
  });
  const topicsListClassNames = cn(styles.topics, {
    [styles.topicsListVariant]: isListVariant,
  });
  const descriptionListClassNames = cn(styles.fixedHeight, {
    [styles.fixedHeightListVariant]: isListVariant,
  });

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className="row">
        {image && <div className={imageListClassNames}>{image}</div>}

        <div className={bodyListClassNames}>
          {topics && <div className={topicsListClassNames}>{topics}</div>}

          <div className={styles.title}>{heading}</div>

          {description && (
            <div className={descriptionListClassNames}>{description}</div>
          )}
        </div>
      </div>
    </div>
  );
}
