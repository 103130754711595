import React from 'react';
import styled from 'styled-components';

import Error from '../Error';
import Label from '../Label';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
`;

const GroupHeader = (props) => {
  const { label, isRequired, isError, error, prefix } = props;
  return (
    <Header className={prefix + '__header'}>
      <Label prefix={prefix} label={label} isRequired={isRequired} />
      <Error
        className={prefix + '__error'}
        data-test-lead-gen={prefix + '-error'}
        isError={isError}
        error={error}
      />
    </Header>
  );
};

export default GroupHeader;
