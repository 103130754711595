import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import qs from 'qs';
import Auth from '../../../../../../../services/AuthService';
import { redirect } from '../../../../../../../helpers';
import { isExternalUrl } from '../../../../../../../helpers/isExternalUrl';
import s from './LoginForm.module.scss';
import Form from '../../shared/Form';
import TextField from '../../shared/TextField';
import HelperText from '../../shared/HelperText';
import InputControl from '../../shared/InputControl';
import Anchor from '../../shared/Anchor';
import Button from '../../shared/Button';

const errors = {
  DEFAULT: 'Something went wrong. Please try again later.',
  USER_NOT_FOUND:
    'The username and password you entered did not match our records. Please try again.',
  CREDENTIALS_DOESNT_MATCH:
    'The username and password you entered did not match our records. Please try again.',
  PASSWORD_RESET_REQUIRED:
    'Your password has expired. Please check your email for a reset link.',
};
const REQUIRED = 'Required';

interface User {
  username: string;
  password: string;
}

export default function LoginForm() {
  const form = useForm<User>({
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const { search: searchParams } = useLocation();
  const [error, setError] = useState('');

  const {
    formState: { isSubmitting },
  } = form;

  const handleSubmit: SubmitHandler<User> = useCallback(
    async (user) => {
      setError('');

      const { username, password } = user;
      const response = await Auth.login({ username, password });
      const data = await response.json();

      if (data.token) {
        Auth.setToken(data);

        const { redirectUrl = '' } = qs.parse(searchParams, {
          ignoreQueryPrefix: true,
        }) as { redirectUrl: string };

        const isExternal = isExternalUrl(
          redirectUrl,
          document.location.hostname,
        );
        redirect(
          redirectUrl && !isExternal ? redirectUrl : '/account/profile/',
        );
        return;
      }

      const { errorCode } = data as {
        errorCode: keyof typeof errors;
      };
      setError(errors[errorCode] || errors.DEFAULT);
    },
    [searchParams],
  );

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <InputControl>
        <TextField
          type="email"
          name="username"
          label="Email Address"
          validation={{ required: REQUIRED }}
        />
      </InputControl>
      <InputControl>
        <TextField
          type="password"
          name="password"
          label="Password"
          validation={{ required: REQUIRED }}
        />
      </InputControl>
      <Anchor
        to="/account/forgotten-password/"
        className={s.forgottenPasswordAnchor}
      >
        Forgot your password?
      </Anchor>
      {error && (
        <HelperText color="danger" className={s.errorHelperText}>
          {error}
        </HelperText>
      )}
      <Button
        type="submit"
        color="success"
        disabled={isSubmitting}
        className={s.submitButton}
      >
        Login
      </Button>
    </Form>
  );
}
