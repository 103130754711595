export const fixTagA = (item) => {
  const tagsA = item.match(/<a href="(.*?)">/g);
  let result = item;

  if (tagsA) {
    tagsA.forEach((el = '') => {
      result = item.replace(el, el.replace(/<\/?mark>/g, ''));
    });
  }

  return result;
};

const tagsRepairing = (item = '') => {
  const el = item.split('<mark>');
  const size = el.length - 1;
  let newItem = '';

  if (size) {
    let flag = false;

    el.forEach((e, k) => {
      if (
        /<(a|div|span|p|img|ul|ol|li|b|i|br|hr|h1|h2|h3|h4|h5|h6|strong|em)?[\s]?([^>]+)?$/.test(
          e,
        )
      ) {
        newItem += flag ? e.replace(/<\/mark>/, '') : e;
        flag = true;
      } else {
        newItem +=
          (flag ? e.replace(/<\/mark>/, '') : e) + (k === size ? '' : '<mark>');
        flag = false;
      }
    });

    return fixTagA(newItem);
  }

  return item;
};

const replacer = (source = '', destination) => {
  let response = source;
  destination.forEach((item) => {
    const cleanString = item.replace(/<\/?mark>/g, '');
    if (response !== item) {
      response = response.replace(cleanString, item);
    }
  });
  return unescape(response);
};

export const searchInside = (key, destination, item = []) => {
  item.forEach((e) => {
    e[key] = replacer(e[key], destination);
  });
};

export const transformToHighlightedSession = (item = {}) => {
  item.highlights &&
    Object.keys(item.highlights).forEach((key) => {
      const keys = key.split('.');
      if (keys.length > 1) {
        searchInside(keys[1], item.highlights[key], item[keys[0]]);
      } else {
        item[key] = tagsRepairing(replacer(item[key], item.highlights[key]));
      }
    });
};

export default function highlights(items = []) {
  // main parser
  items.forEach((session) => {
    if (session.type === 'stream_group' && session.streams) {
      session.streams.forEach((stream) => {
        stream.streamItems.forEach((streamSession) => {
          if (streamSession.highlights) {
            transformToHighlightedSession(streamSession);
          }
        });
      });
    } else if (session.highlights) {
      transformToHighlightedSession(session);
    }
  });

  return items;
}
