import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LeadGenInput from '../LeadGenInput/LeadGenInput';
import LeadGenPassStrength from './LeadGenPassStrength/LeadGenPassStrength';

import './LeadGenInputPass.scss';

export default class LeadGenInputPass extends Component {
  static propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    isRequired: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      passwordChecking: {
        isMinLength: false,
        isUpperCase: false,
        isLowerCase: false,
        isNumber: false,
      },
      value: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { input: { value } = {} } = props;

    if (state.value !== value) {
      const minLength = 8;
      const isMinLength = value && value.length >= minLength;
      const isLowerCase = /[a-z]/.test(value);
      const isUpperCase = /[A-Z]/.test(value);
      const isNumber = /\d/.test(value);

      return {
        value,
        passwordChecking: {
          isMinLength: isMinLength || false,
          isLowerCase,
          isUpperCase,
          isNumber,
        },
      };
    }
    return null;
  }

  renderPasswordBarHint() {
    const { passwordChecking } = this.state;

    return (
      <>
        <LeadGenPassStrength
          {...passwordChecking}
          data-test-lead-gen="lead-gen-pass-strength"
        />
      </>
    );
  }

  render() {
    const { meta: { active, visited } = {} } = this.props;

    return (
      <div
        className="lead-gen-input-pass"
        data-test-lead-gen="lead-gen-input-pass"
      >
        <LeadGenInput {...this.props} />
        {(visited || active) && this.renderPasswordBarHint()}
      </div>
    );
  }
}
