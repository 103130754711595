import { PropsWithChildren, ReactNode } from 'react';
import s from './Paper.module.scss';
import { Anchor } from '../../../../../../shared/Anchor';
import RoundIcon from '../RoundIcon';

export interface PaperProps {
  heading?: ReactNode;
  text?: ReactNode;
  backAnchor?: string;
}
export default function Paper(props: PropsWithChildren<PaperProps>) {
  const { heading, text, backAnchor, children } = props;

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        {backAnchor && (
          <Anchor to={backAnchor} className={s.anchor}>
            <RoundIcon icon="arrow_back" />
          </Anchor>
        )}
        {heading && <h3 className={s.heading}>{heading}</h3>}
      </div>
      {text && <div className={s.text}>{text}</div>}
      <div className={s.body}>{children}</div>
    </div>
  );
}
