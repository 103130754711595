import React, { PropsWithChildren } from 'react';
import { Wrapper, Container, CloseText, CloseBtn } from './index.style';

export interface VideoModalProps {
  onClose: () => void;
  close: {
    type: string;
    text: string;
  };
}

export const VideoModal = ({
  onClose,
  close,
  children,
}: PropsWithChildren<VideoModalProps>) => (
  <Wrapper onClick={onClose} data-testid="video-modal">
    <Container>
      {close?.type === 'button' ? (
        <CloseBtn data-testid="video-modal-close-btn">{close.text}</CloseBtn>
      ) : (
        <CloseText data-testid="video-modal-close-btn">{close.text}</CloseText>
      )}
      {children}
    </Container>
  </Wrapper>
);

VideoModal.defaultProps = {
  close: {
    text: '×',
  },
};
