import React from 'react';
import styled from 'styled-components';
import { color } from '../../../styles/informaconnect/colours/colours';

const LabelText = styled.p`
  color: ${color.formText};
  font-size: 16px;
  line-height: 24px;
`;

const Label = ({ label, prefix, isRequired }) => {
  return (
    <LabelText
      className={prefix + '__title'}
      data-test-lead-gen={prefix + '-title'}
    >
      {label}
      {isRequired ? ' *' : ''}
    </LabelText>
  );
};

export default Label;
