import React, { HTMLAttributes } from 'react';
import { PropsWithTestId } from '../../../../../../types';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import cn from 'classnames';
import styles from './Input.module.scss';

export interface InputProps extends HTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder?: string;
  validation?: RegisterOptions;
}

export function Input(props: PropsWithTestId<InputProps>) {
  const { name, validation, className, testId, ...inputProps } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const wrapperClassName = cn(styles.wrapper, className, {
    [styles.error]: !!errors[name],
  });

  return (
    <div className={wrapperClassName} data-testid={testId}>
      <input {...inputProps} {...register(name, validation)} />
      <ErrorMessage
        name={name}
        render={({ message }) => (
          <span className={styles.errorMessage}>{message}</span>
        )}
      />
    </div>
  );
}

Input.defaultProps = {
  type: 'text',
  testId: 'email-opt-in-text-input',
};
