import React from 'react';
import { Helmet } from 'react-helmet';

export default function SiteMeta(props) {
  const { helmet, robots, stRobots, renderError, defaultOpenGraphImage } =
    props;

  return (
    <Helmet>
      <title>{helmet.title}</title>
      <meta name="robots" content={robots} />
      <meta name="st:robots" content={stRobots} />
      <meta name="keywords" content={helmet.meta.keywords} />
      <meta
        name="description"
        content={helmet.meta.description || helmet.description}
      />
      <meta name="thumbnail" content={helmet.meta.thumbnail || helmet.image} />
      <meta
        name="twitter:card"
        content={helmet.meta['twitter:card'] || 'summary'}
      />
      <meta property="og:title" content={helmet.og.title || helmet.title} />
      <meta
        property="og:description"
        content={helmet.og.description || helmet.description}
      />
      <meta property="og:image" content={defaultOpenGraphImage} />
      {!renderError && (
        <meta property="og:url" content={helmet.og.url || helmet.url} />
      )}
      {!renderError && <link rel="canonical" href={helmet.canonical} />}
    </Helmet>
  );
}
