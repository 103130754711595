import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { useTranslation } from 'react-i18next';
import { withBrand } from '../../../../providers/BrandProvider';
import SiteLayout from '../../../layouts/SiteLayout/SiteLayout';

const divisionsList = {
  realEstateConferences: 'CA08-1',
};

const TermsAndConditions = loadable(() =>
  import(
    /* webpackChunkName: "TermsAndConditions" */ './TermsAndConditions/TermsAndConditions'
  ),
);
const TermsAndConditionsIC = loadable(() =>
  import(
    /* webpackChunkName: "TermsAndConditionsIC" */ './termsAndConditionsDistance/TermsAndConditionsIC/TermsAndConditionsIC'
  ),
);
const TermsAndConditionsLMA = loadable(() =>
  import(
    /* webpackChunkName: "TermsAndConditionsLMA" */ './termsAndConditionsDistance/TermsAndConditionsLMA/TermsAndConditionsLMA'
  ),
);
const TermsAndConditionsREC = loadable(() =>
  import(
    /* webpackChunkName: "TermsAndConditionsREC" */ './termsAndConditionsByDivision/TermsAndConditionsREC'
  ),
);

export function TermsAndConditionsView(props) {
  const {
    siteContent: { data: { termsAndConditionId } = {} } = {},
    options: {
      data: { deliveryType, division: { id: divisionId } = {} } = {},
    } = {},
    brand,
  } = props;
  const { t } = useTranslation();

  const getTermsAndConditions = () => {
    if (divisionId === divisionsList.realEstateConferences) {
      return (
        <TermsAndConditionsREC
          data-test-component-name="TermsAndConditionsREC"
          brand={brand}
        />
      );
    }

    if (deliveryType !== 'DISTANCE') {
      return (
        <TermsAndConditions
          data-test-component-name="TermsAndConditions"
          brand={brand}
        />
      );
    }

    switch (termsAndConditionId) {
      case 'UPDATED_PARTICIPANT_TERMS_AND_CONDITIONS_LMA':
        return (
          <TermsAndConditionsLMA
            data-test-component-name="TermsAndConditionsLMA"
            brand={brand}
          />
        );
      case 'UPDATED_TRAINING_PARTICIPANT_TERMS_INFORMA_CONNECT':
        return (
          <TermsAndConditionsIC
            data-test-component-name="TermsAndConditionsIC"
            brand={brand}
          />
        );
      default:
        return (
          <TermsAndConditions
            data-test-component-name="TermsAndConditions"
            brand={brand}
          />
        );
    }
  };

  return (
    <SiteLayout helmet={{ title: t('event.terms-and-conditions') }}>
      {getTermsAndConditions()}
    </SiteLayout>
  );
}

TermsAndConditionsView.propTypes = {
  siteContent: PropTypes.shape({
    data: PropTypes.shape({
      termsAndConditionId: PropTypes.string,
    }),
  }),
  options: PropTypes.shape({
    data: PropTypes.shape({
      deliveryType: PropTypes.string,
      division: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
  brand: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    siteContent: state.siteContent,
    options: state.options,
  };
}

export default connect(mapStateToProps)(withBrand(TermsAndConditionsView));
