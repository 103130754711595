const REQUEST = 'PORTFOLIO_REQUEST';
const SUCCESS = 'PORTFOLIO_SUCCESS';
const FAILURE = 'PORTFOLIO_FAILURE';

const initialState = {
  loaded: false,
};

export default function portfolioReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

export function fetchPortfolioRequest(data) {
  return { type: REQUEST, data };
}

export function fetchPortfolioSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchPortfolioFailure(data) {
  return { type: FAILURE, data };
}

export function fetchPortfolioAction() {
  return function (dispatch, getState, { Api, i18n }) {
    dispatch(fetchPortfolioRequest());

    const {
      pageConfig: { isPreview, portfolioId },
    } = getState();

    return Api()
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchPortfolio({ portfolioId })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchPortfolioSuccess(response));
        } else {
          return dispatch(fetchPortfolioFailure(response));
        }
      });
  };
}
