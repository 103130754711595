import { isDevelopment, isEnv } from '../index';
import { TenantsConfig } from '../../store/features/pageConfig';

export type GenerateDomainUrlProps = {
  domains: TenantsConfig;
  tenant: string;
  domain: string;
  path: string;
};

export function generateDomainUrl(props: GenerateDomainUrlProps): string {
  const { domains, tenant, domain, path } = props;
  const { domain: host } = domains[tenant];
  const env = isEnv();

  // for tenants with subdomains
  const vertical = tenant !== domain ? domain.toLowerCase() : '';

  let subdomain = '';

  if (env === 'prod') {
    subdomain = vertical ? `${vertical}.` : '';
  } else {
    subdomain = vertical ? `${vertical}-${env}.` : `${env}.`;
  }

  const port = isDevelopment() ? ':3000' : '';

  return `https://${subdomain}${host}${port}/${path}/`;
}
