import { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../../../../../types';
import styles from './ImageBadge.module.scss';

export enum ImageBadgePosition {
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomRight = 'bottomRight',
  bottomLeft = 'bottomLeft',
}

export interface ImageBadgeProps
  extends PropsWithChildren<PropsWithTestId<HTMLAttributes<HTMLDivElement>>> {
  position: keyof typeof ImageBadgePosition;
}

export function ImageBadge(props: ImageBadgeProps) {
  const { position, children, className, testId, ...restProps } = props;
  const badgeClassNames = cn(className, styles.wrapper, styles[position]);

  return (
    <div {...restProps} className={badgeClassNames} data-testid={testId}>
      {children}
    </div>
  );
}

ImageBadge.defaultProps = {
  position: ImageBadgePosition.topRight,
  testId: 'image-badge',
};
