import React from 'react';
import { connect } from 'react-redux';
import HubLayout from '../../../layouts/HubLayout/HubLayout';
import HubNotFoundSection from '../../../baseSections/HubNotFoundSection/HubNotFoundSection';
import SearchFormSection from '../../../baseSections/SearchFormSection/SearchFormSection';

export function HubNotFoundView(props) {
  const { pageConfig: { tenantId } = {} } = props;

  return (
    <HubLayout
      helmet={{ title: 'Not Found' }}
      fullHeightChild
      shortStaticMenu
      browseTopicsPopup
      staticMenu={false}
    >
      <HubNotFoundSection />
      {tenantId === 'INFORMACONNECT' && <SearchFormSection />}
    </HubLayout>
  );
}

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(HubNotFoundView);
