import loadable from '@loadable/component';
import { HubModuleTypes } from '../../../../../constants';

import type { HubModule } from '../../../../../types';
import type { AccordionModuleType } from '../modules/AccordionModule';
import type { AlertModuleType } from '../modules/AlertModule';
import type { ColumnTextModuleType } from '../modules/ColumnTextModule';
import type { FeaturedColumnTextModuleType } from '../modules/FeaturedColumnTextModule/types';
import type { ArticlesModuleType } from '../modules/ArticlesModule';
import type { CarouselModuleType } from '../modules/CarouselModule/types';
import type { CoursesModuleType } from '../modules/CoursesModule';
import type { EventsModuleType } from '../modules/EventsModule';
import type { FileDownloadModuleType } from '../modules/FileDownloadModule';
import type { HeroModuleType } from '../modules/HeroModule';
import type { ImageModuleType } from '../modules/ImageModule';
import type { IframeModuleType } from '../modules/IframeModule';
import type { RelatedSitesModuleType } from '../modules/RelatedSitesModule';
import type { SectorsModuleType } from '../modules/SectorsModule';
import type { StatisticsModuleType } from '../modules/StatisticsModule';
import type { SpeakersModuleType } from '../modules/SpeakersModule/types';
import type { TestimonialsModuleType } from '../modules/TestimonialsModule';
import type { VideoModuleType } from '../modules/VideoModule/types';
import type { StreamlyVideoModuleType } from '../modules/StreamlyVideoModule';
import type { SponsorsModuleType } from '../modules/SponsorsModule/types';
import type { RichTextModuleType } from '../modules/RichTextModule';

const AccordionModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubAccordionModule" */ '../modules/AccordionModule'
    ),
);
const AlertModule = loadable(
  () =>
    import(/* webpackChunkName: "HubAlertModule" */ '../modules/AlertModule'),
);
const ArticlesModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubArticlesModule" */ '../modules/ArticlesModule'
    ),
);
const CarouselModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubCarouselModule" */ '../modules/CarouselModule'
    ),
);
const ColumnTextModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubColumnTextModule" */ '../modules/ColumnTextModule'
    ),
);
const FeaturedColumnTextModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubFeaturedColumnTextModule" */ '../modules/FeaturedColumnTextModule'
    ),
);
const CoursesModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubCoursesModule" */ '../modules/CoursesModule'
    ),
);
const EventsModule = loadable(
  () =>
    import(/* webpackChunkName: "HubEventsModule" */ '../modules/EventsModule'),
);
const FileDownloadModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubFileDownloadModule" */ '../modules/FileDownloadModule'
    ),
);
const HeroModule = loadable(
  () => import(/* webpackChunkName: "HubHeroModule" */ '../modules/HeroModule'),
);
const IframeModule = loadable(
  () =>
    import(/* webpackChunkName: "HubIframeModule" */ '../modules/IframeModule'),
);
const ImageModule = loadable(
  () =>
    import(/* webpackChunkName: "HubImageModule" */ '../modules/ImageModule'),
);
const RelatedSitesModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubRelatedSitesModule" */ '../modules/RelatedSitesModule'
    ),
);
const SectorsModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubSectorsModule" */ '../modules/SectorsModule'
    ),
);
const StatisticsModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubStatisticsModule" */ '../modules/StatisticsModule'
    ),
);
const SpeakersModule = loadable(
  () =>
    import(
      /* webpackChunkName: "SpeakersModule" */ '../modules/SpeakersModule'
    ),
);
const TestimonialsModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubTestimonialsModule" */ '../modules/TestimonialsModule'
    ),
);
const VideoModule = loadable(
  () =>
    import(/* webpackChunkName: "HubVideoModule" */ '../modules/VideoModule'),
);
const StreamlyVideoModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubStreamlyVideoModule" */ '../modules/StreamlyVideoModule'
    ),
);
const SponsorsModule = loadable(
  () =>
    import(
      /* webpackChunkName: "SponsorsModule" */ '../modules/SponsorsModule'
    ),
);
const RichTextModule = loadable(
  () =>
    import(
      /* webpackChunkName: "HubRichTextModule" */ '../modules/RichTextModule'
    ),
);

export interface HubModuleBuilderProps {
  module: HubModule;
  home?: boolean;
}

export function HubModuleBuilder({ module, home }: HubModuleBuilderProps) {
  switch (module['@type']) {
    case HubModuleTypes.Accordion:
      return <AccordionModule module={module as AccordionModuleType} />;
    case HubModuleTypes.AlertBanner:
      return <AlertModule module={module as AlertModuleType} />;
    case HubModuleTypes.Carousel:
      return <CarouselModule module={module as CarouselModuleType} />;
    case HubModuleTypes.ColumnText:
      return <ColumnTextModule module={module as ColumnTextModuleType} />;
    case HubModuleTypes.FeaturedColumnText:
      return (
        <FeaturedColumnTextModule
          module={module as FeaturedColumnTextModuleType}
        />
      );
    case HubModuleTypes.Articles:
      return <ArticlesModule module={module as ArticlesModuleType} />;
    case HubModuleTypes.Courses:
      return <CoursesModule module={module as CoursesModuleType} />;
    case HubModuleTypes.Events:
      return <EventsModule module={module as EventsModuleType} />;
    case HubModuleTypes.FileDownload:
      return <FileDownloadModule module={module as FileDownloadModuleType} />;
    case HubModuleTypes.Hero:
      return <HeroModule module={module as HeroModuleType} />;
    case HubModuleTypes.Iframe:
      return <IframeModule module={module as IframeModuleType} />;
    case HubModuleTypes.Image:
      return <ImageModule module={module as ImageModuleType} />;
    case HubModuleTypes.RelatedSites:
      return <RelatedSitesModule module={module as RelatedSitesModuleType} />;
    case HubModuleTypes.Sectors:
      return <SectorsModule module={module as SectorsModuleType} home={home} />;
    case HubModuleTypes.Statistics:
      return <StatisticsModule module={module as StatisticsModuleType} />;
    case HubModuleTypes.SponsorCategory:
      return <SponsorsModule module={module as SponsorsModuleType} />;
    case HubModuleTypes.Speakers:
      return <SpeakersModule module={module as SpeakersModuleType} />;
    case HubModuleTypes.Testimonials:
      return <TestimonialsModule module={module as TestimonialsModuleType} />;
    case HubModuleTypes.Video:
      return <VideoModule module={module as VideoModuleType} />;
    case HubModuleTypes.StreamlyVideo:
      return <StreamlyVideoModule module={module as StreamlyVideoModuleType} />;
    case HubModuleTypes.RichText:
      return <RichTextModule module={module as RichTextModuleType} />;
    default:
      return null;
  }
}
