import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { renderHTMLString } from '../../../../../../../helpers';
import { renderStyledElementStyles } from '../../../../../helpers';
import { getVenueData } from '../../../../../../../store/siteDucks/siteHeader';

const Wrapper = styled.div`
  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.siteLayout.siteHeader.builder?.elements?.venue?.event,
    );
  }}
  text-align: ${({ theme }) => (theme.globalVariables.isRTL ? 'left' : 'right')}
`;

const Date = styled.div`
  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.siteLayout.siteHeader.builder?.elements?.venue?.event?.elements
        ?.date,
    );
  }}
`;

const Address = styled.span`
  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.siteLayout.siteHeader.builder?.elements?.venue?.event?.elements
        ?.address,
    );
  }}
`;

export default function EventVenue() {
  const { formattedDate, venueName, venueCity, shortInfo } =
    useSelector(getVenueData);

  return (
    <Wrapper data-testid="header-event-venue">
      {formattedDate && <Date>{renderHTMLString(formattedDate)}</Date>}
      {venueName && <Address>{venueName}</Address>}
      {venueCity && <span>{venueCity}</span>}
      {shortInfo && <div>{renderHTMLString(shortInfo)}</div>}
    </Wrapper>
  );
}
