import { useMemo } from 'react';
import { HubBasePage } from '../../../../../types';
import { DiscoveryContentTypes } from '../../../../../constants';
import { useRootSelector } from '../../../../../store/hooks';
import { selectAudienceHubPage } from '../../store/features/page';
import { selectAudienceHubOptions } from '../../store/features/options';
import {
  selectHubDiscoveryFacets,
  selectHubDiscoverySiteRecord,
  DiscoverySite,
  HubPageNames,
  SortTypes,
} from '../../../../../store/features/discovery';
import PageLayout from '../../layouts/PageLayout';
import { MetaTags } from '../../../shared/MetaTags';
import { SiteCard } from '../../../shared/productFilter/cards';
import { AggregatedProductPage } from '../../../shared/siteBuilder/AggregatedProductPage';

export function Courses() {
  const {
    data: {
      page: {
        details: { title, description, metaDescription, searchQueryData },
      },
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectAudienceHubPage<HubBasePage>());
  const {
    data: { name: hubName, subBrandName: brandName, l2Topic: topicName },
  } = useRootSelector(selectAudienceHubOptions);
  const facets = useRootSelector(selectHubDiscoveryFacets);
  const record = useRootSelector((state) =>
    selectHubDiscoverySiteRecord(state, DiscoveryContentTypes.COURSE),
  );

  const defaultValues = useMemo(
    () => ({
      deliveryType: [],
      subBrands: [brandName],
      sectors: [],
      topics: [],
      month: [],
      location: [],
      language: [],
      awardGroup: [],
      informationType: [],
      partners: [],
      creditPartners: [],
      availableSubBrands: [brandName],
      mainFilterTopic: topicName,
      requestedSortType: SortTypes.FEATURED,
      page: 1,
      count: 20,
      searchInput: '',
      type: [DiscoveryContentTypes.COURSE],
    }),
    [brandName, topicName],
  );

  const heading = title || 'Courses';

  return (
    <PageLayout>
      <MetaTags
        description={metaDescription}
        title={heading}
        titleSuffix={hubName}
        thumbnail={brandLogo?.path}
      />
      <AggregatedProductPage<DiscoverySite>
        contentType={DiscoveryContentTypes.COURSE}
        defaultValues={defaultValues}
        pageName={HubPageNames.courses}
        title={heading}
        facets={facets}
        record={record}
        renderProduct={(product) => <SiteCard data={product} cardType="list" />}
        subtitle={description}
        searchQueryData={searchQueryData}
      />
    </PageLayout>
  );
}
