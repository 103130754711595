import React from 'react';
import PropTypes from 'prop-types';

import './Form.scss';

export default class Form extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onValidation: PropTypes.func,

    error: PropTypes.bool,
    loading: PropTypes.bool,

    className: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (
      typeof this.props.onValidation === 'function' &&
      !this.props.onValidation(event)
    ) {
      return false;
    }

    const data = await this.props.onSubmit(event).then((response) => {
      if (response.status !== 200) {
        return response.json().then((data) => {
          return data;
        });
      }
      return response.json();
    });
    const error = !data || data.error;

    if (error || data?.errorCode) {
      this.props.onError && this.props.onError(data, event);
    } else {
      this.props.onSuccess && this.props.onSuccess(data, event);
    }
  }

  render() {
    const { error = false, loading = false, className = null } = this.props;

    const formClasses = [];
    error && formClasses.push('error');
    loading && formClasses.push('loading');
    className && formClasses.push(className);

    return (
      <form
        noValidate
        ref={this.formRef}
        onSubmit={(event) => this.handleSubmit(event)}
        className={`c-form ${formClasses.join(' ')}`}
      >
        {this.props.children}
      </form>
    );
  }
}
