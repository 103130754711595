import React from 'react';
import { HubModuleCTA } from '../../../../../types';
import { getCallToActionAttrs } from '../../helpers';
import {
  Button,
  ButtonProps,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '../../Button';
import styles from './CallToAction.module.scss';

export enum CallToActionAlign {
  left = 'left',
  center = 'center',
  right = 'right',
}

export interface CallToActionProps
  extends Omit<ButtonProps, 'to' | 'pathPrefix' | 'openInNewTab' | 'query'> {
  align: keyof typeof CallToActionAlign;
  link: HubModuleCTA;
}

export function CallToAction({ align, link, ...props }: CallToActionProps) {
  const { url, label, ...attrs } = getCallToActionAttrs(link);

  return (
    <div className={styles[align]}>
      <Button to={url} {...props} {...attrs}>
        {label}
      </Button>
    </div>
  );
}

CallToAction.defaultProps = {
  type: ButtonType.button,
  variant: ButtonVariant.contained,
  size: ButtonSize.large,
  fullWidth: false,
  align: CallToActionAlign.left,
};
