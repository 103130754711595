import React from 'react';
import PropTypes from 'prop-types';

import './FormBlock.scss';

export default function FormBlock(props) {
  const { className = null, enabled = true } = props;

  return (
    <div
      className={`c-form-block ${className} ${enabled ? 'visible' : 'hidden'}`}
    >
      {props.heading && (
        <div className="c-form-block__header">
          <h2 className="c-form-block__heading">{props.heading}</h2>
          <div className="c-form-block__panel">
            {props.panel && props.panel()}
          </div>
        </div>
      )}
      <div className="c-form-block__body">{props.children}</div>
    </div>
  );
}

FormBlock.propTypes = {
  panel: PropTypes.func,
  enabled: PropTypes.bool,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
