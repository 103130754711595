import React from 'react';
import styled from 'styled-components';
import MenuItemGroup from '../../utils/MenuItemGroup';
import SectionHeading from '../SectionHeading';
import { breakpoint } from '../../styles/shared';

import Icon from '../Icon';

const Social = styled.div`
  @media screen and (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    text-align: center;
  }
`;

const IconWrap = styled.a`
  margin-right: 25px;
  text-decoration: none;

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: ${breakpoint.desktopPlusBreakpoint}) {
    margin-right: 0;
    margin-left: 25px;
  }

  @media screen and (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    margin-right: 25px;
    margin-left: 0;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const StyledIcon = styled(Icon)`
  @media screen and (max-width: ${breakpoint.desktopPlusBreakpoint}) {
    margin-bottom: 25px;
  }
`;

const IconContainer = styled.div`
  display: inline-flex;

  @media screen and (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    display: inline-block;
    margin: 0 auto;
    max-width: 335px;
    text-align: left;
  }
`;

const SocialIcon = ({ link, type }) => {
  return (
    <IconWrap href={link}>
      <StyledIcon type={type} />
    </IconWrap>
  );
};

const SocialMenu = ({ title, items }) => {
  return (
    <Social>
      <SectionHeading>{title}</SectionHeading>
      <IconContainer>
        <MenuItemGroup WrappingComponent={SocialIcon} menuItems={items} />
      </IconContainer>
    </Social>
  );
};

export default SocialMenu;
