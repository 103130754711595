import React from 'react';
import styled from 'styled-components';
import ContainerRoundedBottom from '../ContainerRoundedBottom';
import AlertIcon from '../AlertIcon';

import { brand } from '../../styles/informaconnect/colours/colours';
import { typography } from '../../styles/informaconnect/typography/typography';

const Heading = styled.h4`
  font-family: ${typography.type.primary};
  font-size: 18px;
  color: ${brand.darkBlueAlt};
  font-weight: ${typography.weight.semibold};
  line-height: 28px;
`;

const Message = styled.p`
  font-family: ${typography.type.primary};
  font-size: 18px;
  color: #333333;
  line-height: 26px;
`;

const AlertBar = ({ className, heading, message, alertType }) => {
  return (
    <ContainerRoundedBottom className={className}>
      <AlertIcon alertType={alertType} />
      <Heading>{heading}</Heading>
      <Message>{message}</Message>
    </ContainerRoundedBottom>
  );
};

export { AlertBar };
