export const SUCCESS = 'ARTICLE_HOME_SUCCESS';
export const FAILURE = 'ARTICLE_HOME_FAILURE';
export const REMOVE = 'ARTICLE_HOME_REMOVE';
export const GATED = 'ARTICLE_HOME_GATED';
export const PORTFOLIO = 'ARTICLE_PORTFOLIO';

const initialState = {
  loaded: false,
  gated: false,
  portfolio: null,
};

export default function articleHomeReducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          data: {},
          ...action.data.json,
        },
      );

    case REMOVE:
      return Object.assign({}, initialState, {
        gated: true,
        data: {
          author: state.data.author,
          communityPath: state.data.communityPath,
          articleId: state.data.articleId,
          datePublished: state.data.datePublished,
          title: state.data.title,
          relatedTopics: state.data.relatedTopics,
          articleObjective: state.data.articleObjective,
        },
      });

    case GATED:
      return {
        ...state,
        gated: action.data.gated,
      };
    case PORTFOLIO:
      return {
        ...state,
        portfolio: action.data.portfolio,
      };

    default:
      return state;
  }
}

export function fetchArticleHomeSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchArticleHomeFailure(data) {
  return { type: FAILURE, data };
}

export function removeArticleHome() {
  return { type: REMOVE };
}

export function setArticleGated(data) {
  return { type: GATED, data };
}

export function setPortfolio(data) {
  return { type: PORTFOLIO, data };
}

export function removeArticleHomeAction() {
  return function (dispatch) {
    return dispatch(removeArticleHome());
  };
}

export function setArticleGatedAction(gated) {
  return function (dispatch) {
    return dispatch(setArticleGated({ gated }));
  };
}

export function setPortfolioAction(portfolio) {
  return function (dispatch) {
    return dispatch(setPortfolio({ portfolio }));
  };
}

export function fetchArticleHomeAction() {
  return function (dispatch, getState, { Api, i18n }) {
    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    return Api()
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchArticleHome({ siteId })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchArticleHomeSuccess(response));
        } else {
          return dispatch(fetchArticleHomeFailure(response));
        }
      });
  };
}
