import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './FormDescription.module.scss';

export interface FormDescriptionProps extends HTMLAttributes<HTMLElement> {
  title: string;
  body?: string;
}

export function FormDescription({
  title,
  body,
  className,
}: FormDescriptionProps) {
  return (
    <div className={cn(styles.wrapper, className)}>
      <h2 data-testid="email-opt-in-form-title" className={styles.title}>
        {title}
      </h2>
      {body && (
        <p
          data-testid="email-opt-in-form-description"
          className={styles.description}
        >
          {body}
        </p>
      )}
    </div>
  );
}
