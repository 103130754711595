export const REQUEST = 'SITE_PARTNERS_REQUEST';
export const SUCCESS = 'SITE_PARTNERS_SUCCESS';
export const FAILURE = 'SITE_PARTNERS_FAILURE';

const initialState = {
  loaded: false,
};

export default function sitePartnersReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case FAILURE:
      return Object.assign({}, state, {
        error: true,
        statusCode: action.data.statusCode,
        ...action.data.json,
      });

    default:
      return state;
  }
}

export function fetchSitePartnersRequest(data) {
  return { type: REQUEST, data };
}

export function fetchSitePartnersSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchSitePartnersFailure(data) {
  return { type: FAILURE, data };
}

export function fetchSitePartnersAction() {
  return function (dispatch, getState, { Api, i18n }) {
    dispatch(fetchSitePartnersRequest());

    const {
      pageConfig: { isPreview, siteId, siteTypePath },
    } = getState();

    return Api()
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchSitePartners({ siteId, siteTypePath })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchSitePartnersSuccess(response));
        } else {
          return dispatch(fetchSitePartnersFailure(response));
        }
      });
  };
}
