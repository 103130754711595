import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { goTo } from '../../../helpers';
import { localizePath } from '../../../helpers/locale';
import { useRootSelector } from '../../../store/hooks';
import { selectOptions } from '../../../store/features/options';
import { selectPageConfig } from '../../../store/features/pageConfig';
import { useMagnifyingGlassSearch } from './useMagnifyingGlassSearch';
import s from './MagnifyingGlassSearch.module.scss';

export interface MagnifyingGlassSearchProps {
  disableAnimation?: boolean;
}

export default function MagnifyingGlassSearch(
  props: MagnifyingGlassSearchProps,
) {
  const { disableAnimation } = props;
  const { t, i18n } = useTranslation();
  const { sitePath } = useRootSelector(selectPageConfig);
  const {
    locales: { primary: primaryLocale },
  } = useRootSelector(selectOptions);
  const {
    open,
    value,
    inputRef,
    handleChange,
    handleFocus,
    handleBlur,
    handleKeyDown,
    handleSearch,
    handleClose,
  } = useMagnifyingGlassSearch({
    onSearch: (searchTerm) => {
      const pageUrl = sitePath ? `/${sitePath}/search/` : '/search/';
      const redirectUrl = `${pageUrl}?searchInput=${searchTerm}`;

      goTo(localizePath(redirectUrl, i18n.language, primaryLocale));
    },
  });

  const className = cn(s.wrapper, {
    open,
    [s.open]: open,
    [s.animated]: !disableAnimation,
  });

  return (
    <div className={className}>
      <div className={s.dynamicArea}>
        <input
          type="text"
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className={s.input}
          placeholder={t('global.widgets.magnifying-glass-search.placeholder')}
          ref={inputRef}
        />
        <div onClick={handleClose} className={s.closeButton} role="button">
          <span className="material-icons">close</span>
        </div>
      </div>
      <div onClick={handleSearch} className={s.searchButton} role="button">
        <span className="material-icons">search</span>
      </div>
    </div>
  );
}
