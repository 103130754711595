import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import cn from 'classnames';
import s from './Button.module.scss';

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color: 'accent' | 'success' | 'grey';
}

export default function Button(props: ButtonProps) {
  const { color, children, className, ...buttonProps } = props;

  return (
    <button
      type="button"
      className={cn(s.button, className, s[color])}
      {...buttonProps}
    >
      <span className={s.label}>{children}</span>
    </button>
  );
}

Button.defaultProps = {
  color: 'accent',
};
