import { ArticleHomeRelatedArticle } from '../../../../../../../../store/features/articles/home';
import {
  CardAnchor,
  Image,
  Title,
  Badge,
  CardButton,
} from '../../../../../../shared/productFilter/cards/components';
import { SmallFeaturedProductCard } from '../../../../../../shared/productFilter/products';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export interface SmallRelatedArticleCardProps {
  article: ArticleHomeRelatedArticle;
}

export function SmallRelatedArticleCard(props: SmallRelatedArticleCardProps) {
  const {
    article: {
      articlePath,
      title,
      imageUrl: imagePath,
      primaryTopic,
      externalLinkEnabled,
      externalLink,
    },
  } = props;

  return (
    <CardAnchor
      to={externalLinkEnabled ? externalLink : '/'}
      pathPrefix={articlePath}
    >
      <SmallFeaturedProductCard
        image={<Image imageStyle="smallList" path={imagePath} />}
        title={
          <Title size="tiny">
            {title}{' '}
            {externalLinkEnabled && (
              <OpenInNewIcon
                fontSize="inherit"
                sx={{ verticalAlign: 'middle' }}
                data-testid="external-link"
              />
            )}
          </Title>
        }
        topics={
          primaryTopic && (
            <Badge theme="outlined" size="tiny">
              {primaryTopic}
            </Badge>
          )
        }
        showMoreBadge={<CardButton size="small">Read More</CardButton>}
      />
    </CardAnchor>
  );
}
