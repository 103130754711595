import informaDefaultImage from '../../../../../../assets/images/informaconnect/Informa-default-image.png';
import styles from './Loader.module.scss';
import cn from 'classnames';
import { HTMLAttributes } from 'react';
import { PropsWithTestId } from '../../../../../../types';

export interface LoaderProps
  extends PropsWithTestId<HTMLAttributes<HTMLImageElement>> {}

export const Loader = (props: LoaderProps) => {
  const { testId, className, ...restProps } = props;

  return (
    <img
      {...restProps}
      src={informaDefaultImage}
      alt=""
      className={cn(styles.loader, className)}
      data-testid={testId}
    />
  );
};

Loader.defaultProps = {
  testId: 'loader',
};
