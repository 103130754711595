import { useState, useCallback } from 'react';
import { DiscoveryStreamlyVideo } from '../../../../../store/features/discovery';

export type VideoParams = Pick<DiscoveryStreamlyVideo, 'url' | 'title'>;

export function useStreamlyVideoParams() {
  const [params, setParams] = useState<VideoParams | null>(null);

  const handleUpdateParams = useCallback((newParams: VideoParams) => {
    setParams(newParams);
  }, []);

  const handleCleanParams = useCallback(() => {
    setParams(null);
  }, []);

  return { params, handleUpdateParams, handleCleanParams };
}
