import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import clientConfig from '../../../clientConfig';
import { allowScroll, inIframe, preventScroll } from '../../../helpers';
import { addElementsPropertiesAction } from '../../../store/commonDucks/pageConfig';

import CookieMessage from './CookieMessage/CookieMessage';

export class CookieBanner extends Component {
  state = {
    accepted: true,
  };

  componentDidMount() {
    if (this.isOneTrustCookiesConsentEnabled()) return;

    if (this.props.cookies.get('cookies_accepted') !== 'accepted') {
      this.timerHandle = setTimeout(() => {
        if (window.innerWidth <= 812) {
          preventScroll();
        }

        this.setState({ accepted: false });
      }, 500);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerHandle);
  }

  acceptPolicy = () => {
    const expiresDate = new Date();
    expiresDate.setFullYear(expiresDate.getFullYear() + 1);

    allowScroll();

    this.setState({ accepted: true });

    this.props.cookies.set('cookies_accepted', 'accepted', {
      path: '/',
      domain: document.location.hostname.split('.').slice(-2).join('.'),
      expires: expiresDate,
    });

    this.props.addElementsPropertiesAction({ cookieMessage: { height: 0 } });
  };

  isOneTrustCookiesConsentEnabled() {
    const {
      pageConfig: {
        isPreview,
        tenantConfig: { oneTrustCookiesConsentNoticeId } = {},
      } = {},
    } = this.props;

    return (
      clientConfig.oneTrustCookiesConsentNotice &&
      oneTrustCookiesConsentNoticeId &&
      !isPreview
    );
  }

  isShowCookieMessage() {
    return !this.state.accepted && !inIframe();
  }

  render() {
    if (this.isOneTrustCookiesConsentEnabled() || !this.isShowCookieMessage()) {
      return null;
    }

    return (
      <CookieMessage
        addElementsPropertiesAction={this.props.addElementsPropertiesAction}
        acceptCookie={this.acceptPolicy}
        accepted={this.state.accepted}
        tenantId={this.props.pageConfig.tenantConfig.id}
      />
    );
  }
}

CookieBanner.propTypes = {
  pageConfig: PropTypes.object,
  addElementsPropertiesAction: PropTypes.func,
  cookies: PropTypes.instanceOf(Cookies),
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addElementsPropertiesAction(elProperties) {
      return dispatch(addElementsPropertiesAction(elProperties));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(CookieBanner));
