import { useEffect, useRef } from 'react';

export function useDocumentClick(state: boolean, onClick: () => void) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      if (state && !ref.current?.contains(e.target as Node)) {
        onClick();
      }
    };
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [onClick, state]);

  return ref;
}
