import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import { siteTypesEnum } from '../../helpers';
import {
  fetchBookingClosedMessageAction,
  BOOKING_CLOSED,
} from '../siteDucks/bookingClosed';

const SUCCESS = 'SITE_PRODUCTS_SUCCESS';
const FAILURE = 'SITE_PRODUCTS_FAILURE';

const initialState = {
  loaded: false,
};

export default function siteProductsReducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          booking: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case BOOKING_CLOSED:
      return Object.assign(
        {},
        {
          loaded: true,
          ...action.data.json,
          statusCode: 423,
        },
      );

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

export function fetchSiteProductsSuccess(data) {
  return { type: SUCCESS, data };
}

export function siteProductsFailure(data) {
  return { type: FAILURE, data };
}

export function fetchSiteProductsAction(
  basketId,
  params = {},
  courseOptionIndex,
) {
  return function (dispatch, getState, { Api, qs, i18n }) {
    const {
      pageConfig: { isPreview, siteId, siteType },
    } = getState();

    const { EVENT } = siteTypesEnum();
    const siteProductsMethod =
      siteType === EVENT ? 'siteProducts' : 'courseOptionProducts';
    const queryParams = qs.stringify(params, {
      addQueryPrefix: true,
    });

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'GET',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      [siteProductsMethod]({ siteId, basketId, queryParams, courseOptionIndex })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchSiteProductsSuccess(response));
        } else if (response.statusCode === 423) {
          return dispatch(fetchBookingClosedMessageAction());
        } else {
          return dispatch(siteProductsFailure(response));
        }
      });
  };
}

export const getHourConditionsTranslateKeys = createSelector(
  (state) => state.siteProducts.data?.carbonOffsetForm?.carbonOffsetInputs,
  (carbonOffsetInputs = []) =>
    carbonOffsetInputs[0]
      ? carbonOffsetInputs[0].options.map((option) => option.i18nKey)
      : [],
);

export const getCarbonPriceMapper = createSelector(
  (state) => state.siteProducts.data?.carbonOffsetForm?.carbonOffsetInputs,
  (carbonOffsetInputs = []) => {
    if (carbonOffsetInputs.length === 0) return {};
    const allInputOptions = carbonOffsetInputs.map((input) => input?.options);

    return flatten(allInputOptions).reduce((priceMapper, option) => {
      priceMapper[option.value] = option.name;

      return priceMapper;
    }, {});
  },
);
