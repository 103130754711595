import React from 'react';
import PropTypes from 'prop-types';

import './LeadGenPassStrength.scss';

export default function LeadGenPassStrength({
  isMinLength,
  isUpperCase,
  isLowerCase,
  isNumber,
}) {
  return (
    <div
      className="lead-gen-input-pass__strength"
      data-test-lead-gen="lead-gen-input-pass-strength"
    >
      <p className="lead-gen-input-pass__strength-text">
        Minimum requirements for a <span>strong</span> password:
      </p>
      <ul className="lead-gen-input-pass__strength-list">
        <li
          className={`lead-gen-input-pass__strength-item ${
            isMinLength ? 'valid' : 'invalid'
          }`}
        >
          8 characters
        </li>
        <li
          className={`lead-gen-input-pass__strength-item ${
            isUpperCase ? 'valid' : 'invalid'
          }`}
        >
          1 uppercase
        </li>
        <li
          className={`lead-gen-input-pass__strength-item ${
            isLowerCase ? 'valid' : 'invalid'
          }`}
        >
          1 lowercase
        </li>
        <li
          className={`lead-gen-input-pass__strength-item ${
            isNumber ? 'valid' : 'invalid'
          }`}
        >
          1 number
        </li>
      </ul>
    </div>
  );
}

LeadGenPassStrength.propTypes = {
  isMinLength: PropTypes.bool,
  isUpperCase: PropTypes.bool,
  isLowerCase: PropTypes.bool,
  isNumber: PropTypes.bool,
};
