import { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './Title.module.scss';

export enum TitleSizes {
  tiny = 'tiny',
  extraSmall = 'extraSmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {
  size: keyof typeof TitleSizes;
}

export const Title = (props: PropsWithChildren<TitleProps>) => {
  const { size, children, className, ...restProps } = props;

  return (
    <h3 {...restProps} className={cn(styles.heading, styles[size], className)}>
      {children}
    </h3>
  );
};

Title.defaultProps = {
  size: TitleSizes.medium,
};
