import { useEffect, useState } from 'react';
import { useDeviceDetect } from '../../../../../../hooks';
import { HubProductFilterPosition } from '../../../../../../constants';
import { ShowMoreProductsButton } from '../../../productFilter/products';

export interface ShowMoreButtonProps {
  count: number;
  url?: string;
  bottomButtonLabel?: string;
  topButtonLabel?: string;
  filterPosition: HubProductFilterPosition;
  query?: Record<string, any>;
}

export default function ShowMoreButton(props: ShowMoreButtonProps) {
  const {
    topButtonLabel,
    bottomButtonLabel,
    filterPosition,
    url,
    count,
    query,
  } = props;
  const [topButtonDynamicLabel, setTopButtonDynamicLabel] =
    useState(topButtonLabel);
  const { isMobile } = useDeviceDetect();
  const FILTER_TOP = filterPosition === HubProductFilterPosition.TOP;

  useEffect(() => {
    if (isMobile) {
      setTopButtonDynamicLabel('See All');
    } else {
      setTopButtonDynamicLabel(topButtonLabel);
    }
  }, [isMobile, topButtonLabel]);

  if (!url) {
    return null;
  }

  return (
    <ShowMoreProductsButton to={url} query={query} count={!FILTER_TOP && count}>
      {FILTER_TOP ? topButtonDynamicLabel : bottomButtonLabel}
    </ShowMoreProductsButton>
  );
}
