export const BOOKING_CLOSED = 'BOOKING_CLOSED';

export function bookingClosed(data) {
  return { type: BOOKING_CLOSED, data };
}

export function fetchBookingClosedMessageAction() {
  return function (dispatch, getState, { Api, i18n }) {
    const {
      pageConfig: { isPreview, siteId, siteTypePath },
    } = getState();

    return Api()
      .service('ContentService')
      .version('v1')
      .setup({
        method: 'GET',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchBookingClosedMessage({ siteTypePath, siteId })
      .then((response) => dispatch(bookingClosed(response)));
  };
}
