import {
  ChangeEventHandler,
  InputHTMLAttributes,
  KeyboardEventHandler,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import cn from 'classnames';
import s from './KeywordSearch.module.scss';
import { PropsWithTestId } from '../../../../../../types';
import {
  useKeywordSearch,
  useKeywordSearchSuggestions,
} from '../../../../../../hooks';
import { useDocumentClick } from '../../../../../../hooks/useDocumentClick';
import { DiscoverySuggestion } from '../../../../../../store/features/discoverySuggestions';
import SearchSuggestions from './SearchSuggestions';
import { HubDiscoveryValues } from '../../../../../../store/features/discovery';

type CustomInputType = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'value' | 'onChange' | 'onInput' | 'form'
>;

export interface KeywordSearchProps
  extends CustomInputType,
    ReturnType<typeof useKeywordSearch> {
  theme: 'normal' | 'bordered';
  onResetValue?: () => void;
  form?: UseFormReturn<HubDiscoveryValues>;
  enableSuggestions?: boolean;
}

export function KeywordSearch(props: PropsWithTestId<KeywordSearchProps>) {
  const {
    value,
    setValue,
    handleChange,
    handleKeyDown,
    handleSearch,
    handleReset,
    onResetValue,
    form,
    enableSuggestions,
    theme,
    testId,
    ...inputProps
  } = props;

  const handleSelectSuggestion = useCallback(
    (suggestion: DiscoverySuggestion) => {
      const suggestionValue = suggestion.title;

      setValue(suggestionValue);
      handleSearch(suggestionValue);
    },
    [handleSearch, setValue],
  );

  const {
    open: suggestionsOpen,
    setOpen: setSuggestionsOpen,
    setCursorIndex: setSuggestionCursorIndex,
    fetchAction: fetchSuggestions,
    disableSearchOnEnter,
    ...keywordSearchSuggestionsProps
  } = useKeywordSearchSuggestions(handleSelectSuggestion, form);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    handleChange(e);

    if (enableSuggestions) {
      const searchValue = e.target.value;

      if (searchValue) {
        fetchSuggestions(searchValue);
      } else {
        setSuggestionsOpen(false);
      }
    }
  };

  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (enableSuggestions && disableSearchOnEnter) return;

    handleKeyDown(e);
  };

  const handleSearchValue = () => {
    handleSearch(value);
    setSuggestionsOpen(false);
  };

  const handleResetValue = () => {
    handleReset();
    setSuggestionsOpen(false);

    if (onResetValue) {
      onResetValue();
    }
  };

  const [suggestionsActive, setSuggestionsActive] = useState(false);

  const componentRef = useDocumentClick(suggestionsOpen, () => {
    setSuggestionCursorIndex(-1);
    setSuggestionsActive(false);
  });

  const handleInputFocus = () => {
    setSuggestionsActive(true);
  };

  const handleComponentClick = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const wrapperClassName = cn(s.wrapper, s[theme], {
    [s.showSuggestions]: suggestionsOpen && suggestionsActive,
  });

  return (
    <div
      ref={componentRef}
      onClick={handleComponentClick}
      className={wrapperClassName}
      data-testid={testId}
    >
      <div className={s.inputWrapper}>
        <input
          type="text"
          value={value}
          onInput={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onFocus={handleInputFocus}
          placeholder="Enter your search term or a topic"
          {...inputProps}
        />
        {value && (
          <span
            onClick={handleResetValue}
            className={cn(s.clearIcon, 'material-icons')}
          >
            clear
          </span>
        )}
        <div onClick={handleSearchValue} className={s.button}>
          <span className={cn('material-icons', s.searchIcon)}>search</span>
        </div>
      </div>
      {suggestionsOpen && suggestionsActive && (
        <div className={s.suggestionsWrapper}>
          <SearchSuggestions
            value={value}
            theme={theme}
            {...keywordSearchSuggestionsProps}
          />
        </div>
      )}
    </div>
  );
}

KeywordSearch.defaultProps = {
  theme: 'normal',
  enableSuggestions: false,
  testId: 'keyword-search',
};
