import React from 'react';
import HubLayout from '../../../layouts/HubLayout/HubLayout';
import HubServerErrorSection from '../../../baseSections/HubServerErrorSection/HubServerErrorSection';

export default function HubServerErrorView() {
  return (
    <HubLayout helmet={{ title: 'Error' }}>
      <HubServerErrorSection />
    </HubLayout>
  );
}
