import { isString, stringifyQueryParams } from '../index';

export type ParamsType = {
  id: string;
  name: string;
};

const processURLQuery = (params?: string | ParamsType) => {
  if (typeof params === 'undefined') {
    return null;
  }

  if (isString(params)) {
    return params;
  }

  return stringifyQueryParams(params);
};

export default processURLQuery;
