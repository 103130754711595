import { HTMLAttributes } from 'react';
import cn from 'classnames';
import s from './RoundIcon.module.scss';

export interface RoundIconProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  icon: string;
}

export default function RoundIcon({ icon, className }: RoundIconProps) {
  return (
    <div className={cn(s.wrapper, className)}>
      <span className={cn(s.icon, 'material-icons')}>{icon}</span>
    </div>
  );
}
