export const SUCCESS = 'INDUSTRY_HEADER_SUCCESS';
export const FAILURE = 'INDUSTRY_HEADER_FAILURE';

const initialState = {
  loaded: false,
};

export default function industryHeaderReducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

export function fetchIndustryHeaderSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchIndustryHeaderFailure(data) {
  return { type: FAILURE, data };
}

export function fetchIndustryHeaderAction() {
  return function (dispatch, getState, { Api, i18n }) {
    const {
      pageConfig: { isPreview, siteId, siteTypePath },
    } = getState();

    return Api()
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchIndustryHeader({ siteId, siteTypePath })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchIndustryHeaderSuccess(response));
        } else {
          return dispatch(fetchIndustryHeaderFailure(response));
        }
      });
  };
}
