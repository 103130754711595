import {
  DiscoveryLocation,
  DiscoverySite,
} from '../../../../../store/features/discovery';
import { DeliveryType } from '../../../../../constants';
import { getDeliveryTypeName } from '../../../../../helpers/getDeliveryTypeName';

// this custom delivery types logic according to this task CAAS-6743
export function getSiteCardDeliveryTypes(
  deliveryTypes: DiscoverySite['deliveryTypes'] = [],
  locations: DiscoveryLocation[] = [],
) {
  const locationsList = getFormattedLocationsList(locations);
  const deliveryTypesList = deliveryTypes.map((deliveryType) =>
    getDeliveryTypeName(deliveryType),
  );
  const deliveryTypeIndexToDelete = findIndex(deliveryTypes, [
    DeliveryType.PHYSICAL,
    DeliveryType.IN_PERSON,
    DeliveryType.CLASSROOM,
  ]);

  if (deliveryTypeIndexToDelete !== -1) {
    deliveryTypesList.splice(deliveryTypeIndexToDelete, 1);
    deliveryTypesList.unshift(...locationsList);
  }

  return deliveryTypesList.filter(Boolean);
}

export function getFormattedLocationsList(locations: DiscoveryLocation[]) {
  const uniqueLocationsList = getUniqueLocationsList(locations);
  const locationsList: string[] = [];

  uniqueLocationsList.slice(0, 2).forEach((location) => {
    const { city, searchCity, country } = location || {};
    const locationAsText = searchCity
      ? [searchCity, country].filter(Boolean).join(', ')
      : city;

    if (locationAsText && !locationsList.includes(locationAsText)) {
      locationsList.push(locationAsText);
    }
  });

  return locationsList;
}

export function getUniqueLocationsList(
  locations: DiscoveryLocation[],
): DiscoveryLocation[] {
  return locations.reduce((uniqueLocationsList, location) => {
    const isDuplicate = uniqueLocationsList.some(
      (item) => item.searchCity === location.searchCity,
    );

    if (!isDuplicate) {
      uniqueLocationsList.push(location);
    }

    return uniqueLocationsList;
  }, [] as DiscoveryLocation[]);
}

export function findIndex(values: string[], keys: string[]) {
  for (const key of keys) {
    const index = values.indexOf(key);

    if (index !== -1) {
      return index;
    }
  }

  return -1;
}
