import loadable from '@loadable/component';
import { HubHeader, HubSector, UploadedImage } from '../../../../types';

const ClassicHeader = loadable(
  () =>
    import(
      /* webpackChunkName: "ClassicHeader" */ '../../../headers/ClassicHeader'
    ),
);

const Nav = loadable(
  () => import(/* webpackChunkName: "HubNav" */ '../../shared/Nav'),
);

export interface HeaderProps {
  header: HubHeader;
  sectors: HubSector[];
  brandLogo: UploadedImage;
}

export function Header(props: HeaderProps) {
  const {
    header: {
      headerNavBar: {
        disableNavbarHomeLink,
        displayBrandLogoInNavbar,
        displayKeywordSearch,
        searchPlaceholderText,
        searchDisplayPlace,
        displayTextContent,
        textContent,
      },
      navBarItems,
    },
    sectors,
    brandLogo,
  } = props;

  return (
    <ClassicHeader
      logo={brandLogo}
      logoAlt="Brand Logo"
      displayBrandLogo={!displayBrandLogoInNavbar}
      displaySearch={displayKeywordSearch && searchDisplayPlace === 'HEADER'}
      displayMobileSearchButton={displayKeywordSearch}
      searchPlaceholderText={searchPlaceholderText}
      displayTextContent={displayTextContent}
      textContent={textContent}
      mobileNavBarContent={
        <Nav
          links={navBarItems}
          sectors={sectors}
          displayHomeLink={!disableNavbarHomeLink}
          displayKeywordSearch={displayKeywordSearch}
          searchPlaceholderText={searchPlaceholderText}
        />
      }
    />
  );
}
