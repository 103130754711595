import { stringifyQueryParams } from '../../helpers';

export const REQUEST = 'SITE_AGENDA_REQUEST';
export const SUCCESS = 'SITE_AGENDA_SUCCESS';
export const FAILURE = 'SITE_AGENDA_FAILURE';

const initialState = {
  loaded: false,
};

export default function siteAgendaReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case FAILURE:
      return Object.assign({}, state, {
        error: true,
        statusCode: action.data.statusCode,
        ...action.data.json,
      });

    default:
      return state;
  }
}

export function fetchAgendaRequest(data) {
  return { type: REQUEST, data };
}

export function fetchAgendaSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchAgendaFailure(data) {
  return { type: FAILURE, data };
}

export function fetchAgendaAction(params) {
  return function (dispatch, getState, { Api, i18n }) {
    dispatch(fetchAgendaRequest());

    const {
      pageConfig: { isPreview, siteId, location, siteTypePath },
      siteType: { data: { primaryLocale = 'en' } = {} } = {},
    } = getState();

    const search = stringifyQueryParams(params) || location.search;
    const isPrimaryLocaleInSearch = search.includes('primaryLocale');

    let agendaDay = location.pathname.replace(/\/+$/, '').split('/').pop();
    if (agendaDay.toLowerCase() === 'agenda') {
      agendaDay = '';
    }

    let queryParams = `?primaryLocale=${primaryLocale}`;
    if (search) {
      queryParams = isPrimaryLocaleInSearch
        ? `${search}`
        : `${search}&primaryLocale=${primaryLocale}`;
    }

    return Api()
      .cache(true)
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchAgenda({ siteTypePath, siteId, queryParams, agendaDay })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchAgendaSuccess(response));
        } else {
          return dispatch(fetchAgendaFailure(response));
        }
      });
  };
}
