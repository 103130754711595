import { siteTypesEnum } from '../../helpers';

export const REQUEST = 'SITE_SPEAKER_DETAILS_REQUEST';
export const SUCCESS = 'SITE_SPEAKER_DETAILS_SUCCESS';
export const FAILURE = 'SITE_SPEAKER_DETAILS_FAILURE';

const initialState = {
  loaded: false,
};

export default function siteSpeakerDetailsReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case FAILURE:
      return Object.assign({}, state, {
        error: true,
        statusCode: action.data.statusCode,
        ...action.data.json,
      });

    default:
      return state;
  }
}

export function fetchSiteSpeakerDetailsRequest(data) {
  return { type: REQUEST, data };
}

export function fetchSiteSpeakerDetailsSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchSiteSpeakerDetailsFailure(data) {
  return { type: FAILURE, data };
}

export function fetchSiteSpeakerDetailsAction(name) {
  return function (dispatch, getState, { Api, i18n }) {
    dispatch(fetchSiteSpeakerDetailsRequest());

    const {
      pageConfig: { isPreview, siteId, siteType },
    } = getState();

    const { EVENT } = siteTypesEnum();
    const apiMethod =
      siteType === EVENT
        ? 'fetchSiteSpeakerDetails'
        : 'fetchSiteTrainerDetails';

    return Api()
      .cache(true)
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      [apiMethod]({ siteId, name: encodeURIComponent(name) })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchSiteSpeakerDetailsSuccess(response));
        } else {
          return dispatch(fetchSiteSpeakerDetailsFailure(response));
        }
      });
  };
}
