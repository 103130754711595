import React from 'react';

export function removeTagsFromString(
  string: React.ReactNode,
  allowedTags: string[] = [],
) {
  if (typeof string !== 'string') {
    return string;
  }

  const allowedTagsString = allowedTags
    .map((tag) => `<${tag}>`)
    .join('')
    .toLowerCase();

  return string.replace(/<\/?([a-z0-9]*)\b[^>]*>/gi, (tag, allowedTag) => {
    const allowed =
      allowedTagsString.indexOf(`<${allowedTag.toLowerCase()}>`) > -1;

    return allowed ? tag : '';
  });
}
