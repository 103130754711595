import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import qs from 'qs';
import { useHubContext } from '../../../../context/hubContext';
import { useKeywordSearchSuggestions } from '../../../../../../../hooks';
import s from './SearchSuggestions.module.scss';
import { Anchor } from '../../../../../../shared/Anchor';
import AnimatedCallToAction from '../../../../AnimatedCallToAction';
import SuggestedItem from './SuggestedItem';

export interface SearchSuggestionsProps
  extends Omit<
    ReturnType<typeof useKeywordSearchSuggestions>,
    | 'open'
    | 'setOpen'
    | 'fetchAction'
    | 'setCursorIndex'
    | 'disableSearchOnEnter'
  > {
  value: string;
  theme: 'normal' | 'bordered';
}

export default function SearchSuggestions(
  props: SearchSuggestionsProps & Partial<Pick<HTMLDivElement, 'className'>>,
) {
  const {
    value,
    theme,
    className,
    cursorIndex,
    suggestions,
    total,
    onSelectSuggestion,
  } = props;

  const { indexPath } = useHubContext();

  const { search } = useLocation();
  const { type } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  return (
    <div className={cn(s.wrapper, s[theme], className)}>
      <ul>
        {suggestions.map((suggestion, index) => (
          <li key={`${suggestion.id}-${suggestion.type}`}>
            <SuggestedItem
              suggestion={suggestion}
              onClick={onSelectSuggestion}
              selected={cursorIndex === index}
            />
          </li>
        ))}
      </ul>
      <Anchor
        to="/search/"
        pathPrefix={indexPath}
        query={{ type, searchInput: value }}
        className={cn(s.viewAllResults, 'animated-cta-trigger')}
      >
        <AnimatedCallToAction icon="east">
          <b>View all results</b> matching ‘{value}’ <b>({total})</b>
        </AnimatedCallToAction>
      </Anchor>
    </div>
  );
}

SearchSuggestions.defaultProps = {
  theme: 'normal',
};
