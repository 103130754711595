import { PricingProduct } from '../../../store/features/products/types';

export function getBundledProductsMap(secondaryProducts: PricingProduct[]) {
  const onlyBundledProducts = secondaryProducts.filter(
    (product) => product.bundledWith.length > 0,
  );

  return onlyBundledProducts.reduce((accumulator, currentValue) => {
    accumulator[currentValue.id] = currentValue.bundledWith.map(
      (item) => item.mandatoryProductId,
    );

    return accumulator;
  }, {} as Record<string, string[]>);
}
