const v1 = {
  fetchLeadGenForm({ leadGenType, leadGenId, queryParams }) {
    return `/v1/gate/content/${leadGenType}/${leadGenId}${queryParams}`;
  },
  createLeadGenUser({ queryParams }) {
    return `/v1/gate/user/register${queryParams}`;
  },
  updateLeadGenUser({ queryParams }) {
    return `/v1/gate/user/update${queryParams}`;
  },
  subscribe(subscriptionType) {
    return `/v1/subscribe/${subscriptionType}`;
  },
  createLeadGenGuest({ queryParams }) {
    return `/v1/gate/guests${queryParams}`;
  },
};

export default v1;
