import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useProductPageFilter } from '../../productFilter/hooks';
import {
  DiscoveryContent,
  FacetsConfigData,
  FetchHubPageDiscoveryParams,
  Discovery,
  DiscoveryRecord,
  HubDiscoveryValues,
} from '../../../../../store/features/discovery';
import { DiscoveryContentTypes } from '../../../../../constants';
import { isFilterEnabled } from '../../helpers';
import { HubModuleWrapper } from '../HubModuleWrapper';
import {
  ProductModule,
  ProductModuleHeading,
  ProductFilterProvider,
  ProductFilter,
  ProductCountInfo,
  ProductsList,
  NoProductsFound,
} from '../../productFilter/products';
import { FilterFacets, SortProductsFilter } from '../../productFilter/filters';
import { KeywordSearch } from '../components';
import styles from './AggregatedProductPage.module.scss';

export interface AggregatedProductPageProps<T> {
  contentType: DiscoveryContentTypes;
  pageName: FetchHubPageDiscoveryParams['pageName'];
  title: string;
  subtitle: string;
  facets: Discovery['facets'];
  record: DiscoveryRecord<T>;
  renderProduct: (product: T) => React.ReactNode;
  defaultValues: HubDiscoveryValues;
  searchQueryData: FacetsConfigData;
}

export function AggregatedProductPage<T extends DiscoveryContent>(
  props: AggregatedProductPageProps<T>,
) {
  const {
    contentType,
    pageName,
    title,
    subtitle,
    facets,
    record: { results, totalPages, count: totalCount },
    renderProduct,
    defaultValues,
    searchQueryData,
  } = props;
  const { search } = useLocation();
  const enableFilter = isFilterEnabled(searchQueryData.facetsConfig);

  const initializedFilterValues = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const form = useForm<HubDiscoveryValues>({
    defaultValues: {
      ...defaultValues,
      ...initializedFilterValues,
    },
  });

  const {
    changeFilterHandler,
    resetFilterHandler,
    resetTextFilterHandler,
    loadMoreFilterResultsHandler,
    keywordSearchProps,
    loading,
    defaultFiltersApplied,
  } = useProductPageFilter({
    form,
    defaultValues,
    initialValue: initializedFilterValues.searchInput?.toString(),
    searchQueryData,
    pageName,
    contentType,
  });

  return (
    <ProductFilterProvider form={form} onChangeFilter={changeFilterHandler}>
      <HubModuleWrapper
        type="product"
        elementId=""
        theme="LIGHT"
        className={styles.removeTopPaddingOnMobile}
      >
        <ProductModule
          heading={<ProductModuleHeading heading={title} text={subtitle} />}
          leftFilter={
            <ProductFilter
              heading="Filter"
              onResetFilter={resetFilterHandler}
              disableResetFilter={defaultFiltersApplied}
            >
              {facets && <FilterFacets facets={facets} />}
            </ProductFilter>
          }
          showLeftFilter={enableFilter}
          keywordSearch={
            <KeywordSearch
              {...keywordSearchProps}
              onResetValue={resetTextFilterHandler}
              placeholder="Filter by keyword"
              theme="bordered"
              form={form}
              enableSuggestions
            />
          }
          keywordSearchLeftToolbar={
            totalCount > 0 && <ProductCountInfo count={totalCount} />
          }
          keywordSearchRightToolbar={
            <SortProductsFilter contentType={contentType} />
          }
          loading={loading}
        >
          {results.length > 0 ? (
            <ProductsList
              products={results}
              onLoadMore={loadMoreFilterResultsHandler}
              totalPage={totalPages}
            >
              {(product) => renderProduct(product)}
            </ProductsList>
          ) : (
            <NoProductsFound />
          )}
        </ProductModule>
      </HubModuleWrapper>
    </ProductFilterProvider>
  );
}
