import { ArticleHomeRelatedArticle } from '../../../../../../../../store/features/articles/home';
import {
  Image,
  Title,
  Badge,
  CardAnchor,
  CardButton,
} from '../../../../../../shared/productFilter/cards/components';
import { ProductListCard } from '../../../../../../shared/productFilter/products';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export enum RelatedArticleCardSizes {
  small = 'small',
  large = 'large',
}

export interface RelatedArticleCardProps {
  article: ArticleHomeRelatedArticle;
  size: keyof typeof RelatedArticleCardSizes;
}

export function RelatedArticleCard(props: RelatedArticleCardProps) {
  const {
    article: {
      articlePath,
      title,
      imageUrl: imagePath,
      primaryTopic,
      externalLinkEnabled,
      externalLink,
    },
    size,
  } = props;

  return (
    <CardAnchor
      to={externalLinkEnabled ? externalLink : '/'}
      pathPrefix={articlePath}
    >
      <ProductListCard
        image={<Image imageStyle="list" path={imagePath} />}
        imageSize={size === RelatedArticleCardSizes.large ? 'small' : 'large'}
        title={
          <Title size={size}>
            {title}{' '}
            {externalLinkEnabled && (
              <OpenInNewIcon
                fontSize="inherit"
                sx={{ verticalAlign: 'middle' }}
              />
            )}
          </Title>
        }
        leftHeader={
          primaryTopic && (
            <Badge theme="outlined" size="tiny">
              {primaryTopic}
            </Badge>
          )
        }
        showMoreBadge={<CardButton>Read More</CardButton>}
      />
    </CardAnchor>
  );
}

RelatedArticleCard.defaultProps = {
  size: RelatedArticleCardSizes.small,
};
