import React from 'react';
import styled from 'styled-components';
import { color } from '../../styles/informatech/colours/colours';
import SocialIcon from '../../../components/shared/SocialIcon';

const Roundel = styled.span`
  display: inline-flex;
  background-color: white;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-size: 20px !important;
  color: ${color.backgroundGreyBlue};
  align-items: center;
  justify-content: center;
  padding: 6px;
`;

const Icon = ({ className, type }) => {
  return (
    <Roundel className={className}>
      <SocialIcon type={type} />
    </Roundel>
  );
};

export default Icon;
