import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import qs from 'qs';
import {
  concatPath,
  isAbsoluteUrl,
  isDevelopment,
  isEnv,
  isFileUrl,
  isMailToUrl,
  isTelephoneUrl,
} from '../../../helpers';
import { useRootSelector } from '../../../store/hooks';
import { selectPageConfig } from '../../../store/features/pageConfig';

export interface AnchorProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  to: string;
  pathPrefix?: string | string[];
  openInNewTab?: boolean;
  query?: Record<string, any>;
  tenant?: string;
}

export function Anchor({
  to,
  pathPrefix,
  openInNewTab,
  query,
  tenant,
  className,
  children,
  ...props
}: AnchorProps) {
  const { pathname } = useLocation();
  const { tenantId, tenantsConfig } = useRootSelector(selectPageConfig);
  const isExternalURL = useMemo(
    () =>
      isAbsoluteUrl(to) || isFileUrl(to) || isMailToUrl(to) || isTelephoneUrl(),
    [to],
  );

  const isDifferentDomain = tenant && tenantId !== tenant;
  const queryString = qs.stringify(query, {
    addQueryPrefix: true,
  });
  const pathPrefixArray = Array.isArray(pathPrefix) ? pathPrefix : [pathPrefix];
  const url = isExternalURL ? to : concatPath(pathPrefixArray.concat([to]));

  const anchorClassName = cn(className, {
    active: url === pathname,
  });

  const env = isEnv();
  const host = isDifferentDomain ? tenantsConfig[tenant].domain : '';
  const port = isDevelopment() ? ':3000' : '';
  const domain = isDifferentDomain ? `https://${env}.${host}${port}` : '';
  const href = `${domain}${url}${queryString}`;

  if ((isExternalURL && openInNewTab !== false) || openInNewTab) {
    return (
      <a
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        className={anchorClassName}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <a href={href} className={anchorClassName} {...props}>
      {children}
    </a>
  );
}
