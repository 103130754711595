import React from 'react';
import SiteLayout from '../../../layouts/SiteLayout/SiteLayout';
import NotFoundSection from '../../../siteBaseSections/NotFoundSection/NotFoundSection';

export default function NotFoundView() {
  return (
    <SiteLayout helmet={{ title: 'Not Found' }}>
      <NotFoundSection />
    </SiteLayout>
  );
}
