import { HTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './ShowMoreToggle.module.scss';

export interface ShowMoreToggleProps extends HTMLAttributes<HTMLSpanElement> {
  open: boolean;
  count: number;
}

export function ShowMoreToggle(props: ShowMoreToggleProps) {
  const { open, count, className, ...restProps } = props;

  return (
    <span {...restProps} className={cn(styles.wrapper, className)}>
      {open ? 'Less' : `More (${count})`}
    </span>
  );
}
