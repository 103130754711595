import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { isFileUrl, isMailToUrl, isTelephoneUrl } from '../../../helpers';
import Spinner from '../Spinner/Spinner';

/**
 * WHITE LABEL
 */
export function LinkTo(props) {
  const {
    to = '/',
    label,
    labelOverwrite,
    translationKey,
    translationKeyOverwrite,
    allowTrailingSlash = true,
    loading,
    children,
    className,
    style,
    lazyBackgroundImage,
    target,
    t,
    testId,
    draggable,
  } = props;

  let linkUrl = to;
  if (
    !isMailToUrl(linkUrl) &&
    !isTelephoneUrl(linkUrl) &&
    !isFileUrl(linkUrl)
  ) {
    linkUrl = linkUrl.replace(/\/+/g, '/').replace(/:\/+/g, '://');

    if (linkUrl.indexOf('?') === -1 && allowTrailingSlash) {
      linkUrl = linkUrl.replace(/\/?$/, '/');
    }
  }

  const componentClassNames = classNames({
    lazy: lazyBackgroundImage,
    [className]: className,
  });

  return (
    <a
      href={linkUrl}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : null}
      data-background-image={lazyBackgroundImage}
      className={componentClassNames}
      style={style}
      data-testid={testId}
      draggable={draggable}
    >
      {loading && <Spinner />}
      {typeof labelOverwrite !== 'undefined'
        ? labelOverwrite
        : typeof translationKeyOverwrite !== 'undefined'
        ? t(translationKeyOverwrite)
        : children || label || (translationKey ? t(translationKey) : null)}
    </a>
  );
}

LinkTo.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  label: PropTypes.string,
  labelOverwrite: PropTypes.string,
  translationKey: PropTypes.string,
  translationKeyOverwrite: PropTypes.string,
  allowTrailingSlash: PropTypes.bool,
  loading: PropTypes.bool,
  lazyBackgroundImage: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  testId: PropTypes.string,
  draggable: PropTypes.bool,
};

export default withTranslation()(LinkTo);
