import React from 'react';
import cn from 'classnames';
import styles from './RelatedProducts.module.scss';

export interface RelatedProductsProps<T> {
  products: T[];
  keyName: keyof T;
  children: (product: T) => React.ReactNode;
  heading?: React.ReactNode;
  showMoreCTA?: React.ReactNode;
}
export function RelatedProducts<T>(props: RelatedProductsProps<T>) {
  const { products, keyName, heading, showMoreCTA, children } = props;

  const gridClassName = cn(
    'col-xs-12',
    products.length <= 1 ? 'col-sm-12' : 'col-sm-6',
  );

  return (
    <div className={styles.wrapper}>
      {heading && <h2 className={styles.heading}>{heading}</h2>}
      <div className="row">
        {products.map((product) => (
          <div
            key={product[keyName] as unknown as string}
            className={gridClassName}
          >
            <div className={styles.productWrapper}>{children(product)}</div>
          </div>
        ))}
      </div>
      {showMoreCTA && <div className={styles.showMoreCTA}>{showMoreCTA}</div>}
    </div>
  );
}
