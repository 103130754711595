import moment from 'moment';
import Utils from '../index';
import { getCorrectLocale } from '../../helpers';

export const stableGridConfig = {
  streamWidth: 225, // as pixels
  streamHeight: 68, // as pixels
  streamMargin: 5, // as pixels
  streamPaddingLeft: 6, // as pixels
  sessionTimeStep: 5, // as minutes
  sessionTimeStepValue: 32, // as pixels: 5 min = 32 px
  sessionSpacing: 5, // as pixels
  sessionPadding: 6, // as pixels
};

export function getHeadingData(selectedDay = 1, allDates = [], locale = 'en') {
  const day = allDates[selectedDay - 1];
  const formattedDate =
    day && day.date
      ? Utils.formattedTime(day.date, 'dddd, D MMMM YYYY', locale)
      : false;
  const selectedDayAsText = day && day.name;

  return {
    selectedDayAsText,
    formattedDate,
  };
}

export function getAgendaDayFromPath(pathname) {
  const agendaDay = pathname.replace(/\/+$/, '').split('/').pop();

  if (agendaDay.toLowerCase() === 'co-located-agenda') {
    return '';
  }

  return agendaDay;
}

export function addLocaleToQuery(search = '', primaryLocale) {
  const isPrimaryLocaleInSearch = search.includes('primaryLocale');

  if (search) {
    return isPrimaryLocaleInSearch
      ? `${search}`
      : `${search}&primaryLocale=${primaryLocale}`;
  }

  return `?primaryLocale=${primaryLocale}`;
}

export function sortSessionsByTime(sessions) {
  return sessions.sort(
    (leftTime, rightTime) =>
      moment(leftTime.startTime, 'HH:mm').valueOf() -
      moment(rightTime.startTime, 'HH:mm').valueOf(),
  );
}

export function getMinMaxSessionsTime(sessions = []) {
  const momentsStart = [];
  const momentsEnd = [];

  sessions.forEach((session) => {
    session.startTime && momentsStart.push(moment(session.startTime, 'HH:mm'));
    session.endTime && momentsEnd.push(moment(session.endTime, 'HH:mm'));
  });

  return {
    minSessionTime: moment.min(momentsStart) || null,
    maxSessionTime: moment.max(momentsEnd) || null,
  };
}

export function getTopOffsetAsMinutes(startTime, minStartTime) {
  const momentStartTime = moment(startTime, 'HH:mm').valueOf();
  const momentMinStartTime = moment(minStartTime, 'HH:mm').valueOf();

  return (
    moment.duration(momentStartTime).asMinutes() -
    moment.duration(momentMinStartTime).asMinutes()
  );
}

export function convertMinutesToPixels(minutes, minuteStep, minuteStepValue) {
  return (minutes / minuteStep) * minuteStepValue;
}

export function sortOutStreamsByBreakGroups(session, streamsMap) {
  const { gatheredStreams = [] } = session;
  const gsGroups = [];
  const allStreams = Object.keys(streamsMap);
  let streamGroup = '';

  if (gatheredStreams.length > 1) {
    const sortedGs = gatheredStreams.sort(
      (a, b) => allStreams.indexOf(a) - allStreams.indexOf(b),
    );

    sortedGs.forEach((stream, index) => {
      const nextStream = sortedGs[index + 1];
      const distanceIndex =
        allStreams.indexOf(nextStream) - allStreams.indexOf(stream);

      if (distanceIndex === 1) {
        streamGroup = streamGroup.concat(`${stream}__`);
      } else if (distanceIndex !== 1) {
        streamGroup = streamGroup.concat(`${stream}`);
        gsGroups.push(streamGroup.split('__'));
        streamGroup = '';
      }
    });
  }

  return gsGroups;
}

export function convertTimeTo12Format(time, locale) {
  const momentLocale = getCorrectLocale(locale);

  return moment(time, 'HH:mm').locale(momentLocale).format('h:mma');
}
