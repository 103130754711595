import React, { HTMLAttributes, useEffect } from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../types';
import qs from 'qs';
import IframeResizer from 'iframe-resizer-react';
import {
  defaultOptions,
  sourceSettings,
  scriptId,
  VideoModuleSources,
} from './constants';
import styles from './VideoPlayer.module.scss';

export interface VideoPlayerProps extends HTMLAttributes<HTMLElement> {
  source: VideoModuleSources;
  title: string;
  autoplay: boolean;
  controls: boolean;
  streamlyUrl?: string;
  id?: string;
}

declare global {
  interface Window {
    _wq: Array<unknown>;
  }
}

const defaultProps = {
  testId: 'hub-module-video-player',
  autoplay: true,
  controls: true,
};

export default function VideoPlayer(props: PropsWithTestId<VideoPlayerProps>) {
  const { title, id, source, streamlyUrl, className, testId } = props;
  const params = qs.stringify(
    {
      ...defaultOptions,
      ...props,
    },
    { addQueryPrefix: true },
  );
  const settings = sourceSettings[source];

  useEffect(() => {
    if (source === VideoModuleSources.WISTIA) {
      window._wq = window._wq ?? [];
      window._wq.push({
        id,
        options: {
          playerColor: '#000000',
          ...props,
        },
      });
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.src = 'https://fast.wistia.com/assets/external/E-v1.js';
        script.async = true;
        script.id = scriptId;

        document.body.appendChild(script);
      }
    }
    // eslint-disable-next-line
  }, []);

  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <div data-testid={testId} className={wrapperClassName}>
      {(source === VideoModuleSources.YOUTUBE ||
        source === VideoModuleSources.VIMEO) && (
        <iframe
          src={`${settings.path}${id}${params}`}
          title={title}
          allowFullScreen
          allow={settings.allow}
          frameBorder="0"
          data-testid={`${source}-embed`}
        />
      )}
      {source === VideoModuleSources.WISTIA && (
        <div
          className={`wistia_embed wistia_async_${id} videoFoam=true`}
          onClick={(e) => e.stopPropagation()}
          data-testid={`${source}-embed`}
        />
      )}
      {source === VideoModuleSources.STREAMLY_VIDEO && (
        <IframeResizer
          allow={settings.allow}
          frameBorder="0"
          data-testid={`${source}-embed`}
          title={title}
          src={streamlyUrl}
          style={{ width: '1px', minWidth: '100%' }}
        />
      )}
    </div>
  );
}

VideoPlayer.defaultProps = defaultProps;
