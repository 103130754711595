import React from 'react';
import styled from 'styled-components';
import MenuItemGroup from '../../utils/MenuItemGroup';
import SectionHeading from '../SectionHeading';
import { breakpoint } from '../../styles/shared';

const Menu = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    text-align: center;
  }

  @media screen and (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    margin-bottom: 30px;
  }

  @media screen and (min-width: ${breakpoint.tabletPlusBreakpoint}) and (max-width: ${breakpoint.desktopMediumBreakpoint}) {
    max-width: 200px;
    margin-right: 60px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media screen and (max-width: ${breakpoint.desktopMediumBreakpoint}) {
    flex-wrap: wrap;
  }
`;

const Item = styled.a`
  display: flex;
  padding-bottom: 30px;

  @media screen and (min-width: ${breakpoint.desktopMediumBreakpoint}) {
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    justify-content: center;
  }
`;

const StackedMenu = styled.div`
  flex-direction: column;
  display: inline-flex;

  width: 100%;
  min-width: 230px;

  @media screen and (min-width: ${breakpoint.tabletPlusBreakpoint}) and (max-width: ${breakpoint.desktopMediumBreakpoint}) {
    min-width: 200px;
  }

  @media screen and (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    width: 100%;
  }
`;

const MenuItem = ({ link, content }) => {
  return <Item href={link}>{content}</Item>;
};

const TwoColumnMenu = ({ title, menuLeft, menuRight }) => {
  return (
    <Menu>
      <SectionHeading>{title}</SectionHeading>
      <MenuWrapper>
        <StackedMenu>
          <MenuItemGroup WrappingComponent={MenuItem} menuItems={menuLeft} />
        </StackedMenu>
        <StackedMenu>
          <MenuItemGroup WrappingComponent={MenuItem} menuItems={menuRight} />
        </StackedMenu>
      </MenuWrapper>
    </Menu>
  );
};

export default TwoColumnMenu;
