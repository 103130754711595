import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconBadge } from '../../../shared/IconBadge/IconBadge';
import { renderHTMLString } from '../../../../helpers';
import classNames from 'classnames';
import { Link } from 'react-scroll';
import ImageTag from '../../../shared/ImageTag/ImageTag';
import './LeadGenTeaserPortrait.scss';

export function LeadGenTeaserPortrait(props) {
  const {
    agendaDownloadButton,
    brand = {},
    iconID,
    teaserHeading,
    teaserBody,
    thumbnail = {},
    withoutImage,
    leadGenType,
  } = props;

  const contentClasses = classNames('col-xs-12', {
    'col-md-9': leadGenType !== 'agenda',
    'col-sm-8': !withoutImage,
  });

  const contentWrapperClasses = classNames(
    'lead-gen-teaser-portrait__content-wrapper',
    {
      'lead-gen-teaser-portrait__content-wrapper--with-img': !withoutImage,
    },
  );

  const badgeClass = withoutImage
    ? 'c-icon-badge--portrait-no-image'
    : 'c-icon-badge--portrait';

  const scrollLinkClasses = classNames('scroll-link', {
    'visible-xs': thumbnail.path && !withoutImage,
    [`${badgeClass}`]: agendaDownloadButton,
  });

  return (
    <div
      className="lead-gen-teaser-portrait row"
      data-test-lead-gen="lead-gen-teaser-portrait"
    >
      {!withoutImage && (
        <div className="col-sm-4 col-md-3 hidden-xs">
          <div className="lead-gen-teaser-portrait__image-wrapper">
            <ImageTag
              src={thumbnail.path}
              imgixParams="w=280&h=355"
              className="lead-gen-teaser-portrait__image"
              lazy
              data-test-lead-gen="lead-gen-teaser-portrait-image"
            />
            {iconID && (
              <Link to="viewOuter" smooth duration={500}>
                <IconBadge
                  brand={brand}
                  iconID={iconID}
                  classname={badgeClass}
                />
              </Link>
            )}
          </div>
        </div>
      )}
      <div
        className={contentClasses}
        data-test-lead-gen="lead-gen-teaser-portrait__content-container"
      >
        <div className={contentWrapperClasses}>
          {teaserHeading && (
            <>
              <div className="lead-gen-teaser-portrait__title">
                {agendaDownloadButton ? (
                  <IconBadge
                    brand={brand}
                    iconID={iconID}
                    isRound="true"
                    className={scrollLinkClasses}
                  />
                ) : (
                  <Link
                    to="viewOuter"
                    smooth
                    duration={500}
                    className={scrollLinkClasses}
                  >
                    <IconBadge
                      brand={brand}
                      iconID={iconID}
                      isRound="true"
                      className={badgeClass}
                    />
                  </Link>
                )}
                <h1
                  className="lead-gen-teaser-portrait__title-text"
                  data-test-lead-gen="lead-gen-teaser-portrait-title"
                >
                  {teaserHeading}
                </h1>
              </div>
              <hr className="lead-gen-teaser-portrait__underline" />
            </>
          )}
          {agendaDownloadButton && agendaDownloadButton()}
          {teaserBody && (
            <div
              className="lead-gen-teaser-portrait__body"
              data-test-lead-gen="lead-gen-teaser-portrait-body"
            >
              {renderHTMLString(teaserBody)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

LeadGenTeaserPortrait.propTypes = {
  agendaDownloadButton: PropTypes.func,
  teaserHeading: PropTypes.string,
  teaserBody: PropTypes.string,
  thumbnail: PropTypes.object,
  withoutImage: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(LeadGenTeaserPortrait);
