import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';

const Section = styled.section`
  ${({ vStyles }) => vStyles}
`;

export const SiteBuilderModule = ({
  section: { id, elementId, type },
  moduleTheme = {},
  vStyles,
  children,
}) => (
  <Section
    id={elementId}
    data-section-id={id}
    vStyles={vStyles}
    className={`${type?.replace(/^event_/, '')?.replace(/_/g, '-')}`}
  >
    <ThemeProvider theme={{ module: moduleTheme }}>{children}</ThemeProvider>
  </Section>
);

SiteBuilderModule.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string,
    elementId: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  moduleTheme: PropTypes.object,
  vStyles: PropTypes.string,
  children: PropTypes.node,
};
