import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import clientConfig from '../../../clientConfig';
import Auth from '../../../services/AuthService';
import Link from '../Link/Link';
import getPOneUserEvents from '../../../helpers/getPOneUserEvents';
import classnames from 'classnames';

import './AccountWidget.scss';

export class AccountWidget extends Component {
  static propTypes = {
    siteHeader: PropTypes.object,
    children: PropTypes.node,
    pageConfig: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      accountName: null,
      showAuthorization: false,
      accountMenuOpened: false,
    };

    this.accountMenuRef = React.createRef();

    this.toggleAccountMenu = this.toggleAccountMenu.bind(this);
  }

  componentDidMount() {
    const {
      siteHeader: {
        data: {
          partneringOne = false,
          partneringOneEventId,
          partneringOneEventUrl,
        } = {},
      } = {},
    } = this.props;

    const shortAccountDetails = Auth.getShortAccountDetails();

    if (partneringOne) {
      getPOneUserEvents().then((userEvents) => {
        const userIsParticipant =
          userEvents && userEvents.includes(partneringOneEventId);
        this.setState({
          loggedIn: userIsParticipant && userEvents.length,
          accountName: shortAccountDetails.fullName,
          pOneUrl: userIsParticipant
            ? partneringOneEventUrl
            : clientConfig.partneringOne.partneringOneDashBoardUrl,
        });
      });
    } else {
      this.toggleWidgetView(shortAccountDetails);
    }

    document.addEventListener('click', (event) => this.closeAccountMenu(event));
  }

  componentWillUnmount() {
    document.removeEventListener('click', (event) =>
      this.closeAccountMenu(event),
    );
  }

  closeAccountMenu(event) {
    if (
      this.accountMenuRef.current &&
      !this.accountMenuRef.current.contains(event.target)
    ) {
      this.setState({
        accountMenuOpened: false,
      });
    }
  }

  toggleWidgetView(shortAccountDetails) {
    const { shortName } = this.props;
    if (shortAccountDetails.loggedIn) {
      this.setState({
        loggedIn: true,
        showAuthorization: false,
        accountName: shortName
          ? shortAccountDetails.forename
          : shortAccountDetails.fullName,
      });
    } else {
      this.setState({
        showAuthorization: true,
      });
    }
  }

  toggleAccountMenu() {
    this.setState((state) => {
      return {
        accountMenuOpened: !state.accountMenuOpened,
      };
    });
  }

  render() {
    const { accountName, accountMenuOpened, pOneUrl } = this.state;
    const {
      siteHeader: { data: { partneringOne = false } = {} } = {},
      pageConfig: {
        account: { authorized } = {},
        siteType,
        tenantConfig: { outputName } = {},
      } = {},
      shortName = false,
      isMobile = false,
    } = this.props;

    const dropdownTextDirection =
      partneringOne && siteType === 'EVENT' ? 'right' : 'left';

    const isWidgetShown = partneringOne || this.state.loggedIn;
    const buttonClasses = classnames('c-account-widget__button', {
      shortName,
      isMobile,
    });

    return (
      <div className="c-account-widget">
        {isWidgetShown && !authorized && this.props.children}
        {isWidgetShown && authorized && (
          <div ref={this.accountMenuRef} className="c-account-widget__wrapper">
            <div className={buttonClasses} onClick={this.toggleAccountMenu}>
              <span>{accountName}</span>
            </div>
            <div
              className={`c-account-widget__dropdown text-${dropdownTextDirection} ${
                accountMenuOpened ? 'visible' : 'hidden'
              }`}
            >
              <ul>
                <li>
                  <Link to="/account/profile" parent>
                    {`My${partneringOne ? ' ' + outputName : ''} Profile`}
                  </Link>
                </li>
                {partneringOne && (
                  <li className="account-widget-link-p1">
                    <Link to={pOneUrl}>partneringONE™</Link>
                  </li>
                )}
                <li>
                  <span onClick={() => Auth.logout()}>Log out</span>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader,
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(AccountWidget);
