import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DiscoveryFilter } from '../../../../../../../store/features/discovery';
import { Input } from '../index';

export interface CheckboxProps {
  data: DiscoveryFilter;
}

export function Checkbox(props: CheckboxProps) {
  const { register } = useFormContext();
  const {
    data: {
      label,
      count,
      filterValue: { values, type: name },
    },
  } = props;

  const disabled = count === 0;

  return (
    <Input label={label} count={count} disabled={disabled}>
      <input
        {...register(name)}
        type="checkbox"
        value={values[0]}
        disabled={disabled}
      />
    </Input>
  );
}
