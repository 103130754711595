import React, { HTMLAttributes } from 'react';
import { Anchor } from '../../../../../shared/Anchor';
import { PropsWithTestId } from '../../../../../../types';
import cn from 'classnames';
import styles from './PermissionsText.module.scss';

export interface PermissionsTextProps extends HTMLAttributes<HTMLElement> {
  permissionTextName: string;
}

export function PermissionsText({
  className,
  testId,
  permissionTextName,
  ...restProps
}: PropsWithTestId<PermissionsTextProps>) {
  const wrapperClassName = cn(styles.wrapper, className);
  return (
    <div {...restProps} className={wrapperClassName} data-testid={testId}>
      <p>
        By clicking Sign Up, you indicate that you wish to receive news and
        promotions via Email about related products and services from{' '}
        {permissionTextName}, including those of carefully selected business
        partners. We will not share your details with partners for their own
        marketing purposes unless you provide permission.
      </p>
      <p>
        You can opt out of hearing from us at any time by clicking the
        unsubscribe link in our emails or by contacting us. To understand how we
        use your data, please see our{' '}
        <Anchor to="https://www.informa.com/privacy-policy">
          privacy policy.
        </Anchor>
      </p>
    </div>
  );
}

PermissionsText.defaultProps = {
  testId: 'email-opt-in-permission-text',
};
