import { useEffect, useState } from 'react';
import Auth from '../services/AuthService';

export default function useVirtualContentAccessStatus(productCodes = []) {
  const [accessStatus, setAccessStatus] = useState('NONE');

  useEffect(() => {
    if (Auth.isLoggedIn()) {
      setAccessStatus('LOADING');

      if (productCodes.length === 0) {
        setAccessStatus('GRANTED');
      } else {
        Auth.checkEventAttendance(productCodes)
          .then((response) => {
            if (response.status === 200) {
              setAccessStatus('GRANTED');
            } else {
              setAccessStatus('BOOK_NOW');
            }
          })
          .catch(() => {
            setAccessStatus('NONE');
          });
      }
    } else {
      setAccessStatus('LOGIN');
    }
  }, [productCodes]);

  return accessStatus;
}
