import {
  createSlice,
  ActionReducerMapBuilder,
  AsyncThunk,
  Draft,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  AsyncThunkOptions,
} from '@reduxjs/toolkit';
import { Response } from 'cross-fetch';
import { GenericState, RejectedPayloadData } from '../../../types';
import AjaxService from '../../../services/AjaxService';
import { RootDispatch, RootState } from '../../store';

export type ProcessRejectedValue = (
  response: Response,
  data: unknown,
) => RejectedPayloadData<typeof data>;

export type AsyncThunkConfig = {
  state: RootState;
  dispatch: RootDispatch;
  extra: {
    ajax: AjaxService;
    processRejectedValue: ProcessRejectedValue;
    headers: {
      'Accept-Language': string;
      'Published-State': string;
    };
    i18n: {
      language: string;
    };
  };
  rejectValue: RejectedPayloadData<unknown>;
};

export const createGenericSlice = <
  T,
  Reducers extends SliceCaseReducers<GenericState<T>>,
>({
  name,
  initialState,
  reducers,
  extraReducers,
}: {
  name: string;
  initialState: GenericState<T>;
  reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>;
  extraReducers?: (builder: ActionReducerMapBuilder<GenericState<T>>) => void;
}) =>
  createSlice({
    name,
    initialState,
    reducers: {
      start(state) {
        state.status = 'pending';
      },
      fulfilled(state: GenericState<T>, action: PayloadAction<T>) {
        state.data = action.payload;
        state.status = 'fulfilled';
        state.statusCode = 200;
        delete state.error;
      },
      rejected(
        state: GenericState<T>,
        action: PayloadAction<RejectedPayloadData<T>>,
      ) {
        state.status = 'rejected';
        state.statusCode = action.payload.statusCode;
        state.error = action.payload;
      },
      ...reducers,
    },
    extraReducers,
  });

export const createGenericBuilderCases = <T, A = void>(
  builder: ActionReducerMapBuilder<GenericState<T>>,
  asyncThunk: AsyncThunk<Draft<T>, A, AsyncThunkConfig>,
) => {
  builder.addCase(asyncThunk.pending, (state) => {
    state.status = 'pending';
  });
  builder.addCase(asyncThunk.fulfilled, (state, action) => {
    state.data = action.payload;
    state.status = 'fulfilled';
    state.statusCode = 200;
    delete state.error;
  });
  builder.addCase(asyncThunk.rejected, (state, action) => {
    state.status = 'rejected';
    state.statusCode = action.payload?.statusCode as number;
    state.error = action.payload;
  });
};

export function createGenericAsyncThunk<Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    AsyncThunkConfig
  >,
  options?: AsyncThunkOptions<ThunkArg, AsyncThunkConfig>,
): AsyncThunk<Returned, ThunkArg, AsyncThunkConfig> {
  return createAsyncThunk<Returned, ThunkArg, AsyncThunkConfig>(
    typePrefix,
    async (arg, thunkAPI) => {
      try {
        return await payloadCreator(arg, thunkAPI);
      } catch (err) {
        return thunkAPI.rejectWithValue({
          data: {
            error: {
              type: 'ConnectionError',
            },
          },
          statusCode: 503,
        });
      }
    },
    options,
  );
}

export const processRejectedValue: ProcessRejectedValue = (response, data) => {
  return {
    data,
    statusCode: response.status,
  };
};
