import { combineReducers } from '@reduxjs/toolkit';
import homeReducer from './home';

const articleReducer = {
  article: combineReducers({
    home: homeReducer,
  }),
};

export default articleReducer;
