import { ReactNode } from 'react';
import styles from './SmallFeaturedProductCard.module.scss';

export interface SmallFeaturedProductCardProps {
  title: ReactNode;
  image?: ReactNode;
  date?: ReactNode;
  deliveryTypes?: ReactNode;
  topics?: ReactNode;
  showMoreBadge?: ReactNode;
}

export function SmallFeaturedProductCard({
  title,
  image,
  date,
  deliveryTypes,
  topics,
  showMoreBadge,
}: SmallFeaturedProductCardProps) {
  return (
    <div className={styles.wrapper}>
      {image && <div className={styles.image}>{image}</div>}

      <div className={styles.body}>
        <div className={styles.content}>
          {topics && <div className={styles.topics}>{topics}</div>}
          <div>{title}</div>
          {date && <div className={styles.date}>{date}</div>}
          {deliveryTypes && (
            <div className={styles.deliveryTypes}>{deliveryTypes}</div>
          )}
        </div>
        {showMoreBadge && (
          <div className={styles.showMoreBadge}>{showMoreBadge}</div>
        )}
      </div>
    </div>
  );
}
