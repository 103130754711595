import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './SiteLowerFooterCopyright.scss';
import { HTMLtoJSX } from '../../../../../../helpers';

/**
 * WHITE LABEL
 */
export function SiteLowerFooterCopyright(props) {
  const { pageConfig: { tenantConfig: { copyright } = {} } = {} } = props;

  return !copyright ? null : (
    <div className="c-site-lower-footer-copyright">{HTMLtoJSX(copyright)}</div>
  );
}

SiteLowerFooterCopyright.propTypes = {
  pageConfig: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(SiteLowerFooterCopyright);
