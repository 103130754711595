import { ArticleList } from './ArticleList';
import { SiteList } from './SiteList';
import TopicHomesList from './TopicHomesList';
import StreamlyVideosList from './StreamlyVideosList';
import { DiscoveryContentTypes } from '../../../../../../constants';
import { UseInfiniteScrollProps } from '../../../../../../hooks';

export enum ViewMode {
  list = 'list',
  preview = 'preview',
}

export interface ContentTypeBuilderProps {
  contentType: DiscoveryContentTypes;
  viewMode: keyof typeof ViewMode;
  loadMoreFilterResults?: UseInfiniteScrollProps['fetchMoreData'];
  previewCount?: number;
}

export function ContentTypeBuilder(props: ContentTypeBuilderProps) {
  const { contentType, viewMode, loadMoreFilterResults, previewCount } = props;

  switch (contentType) {
    case DiscoveryContentTypes.EVENT:
      return (
        <SiteList
          type={DiscoveryContentTypes.EVENT}
          mode={viewMode}
          loadMoreResults={loadMoreFilterResults}
          previewCount={previewCount}
        />
      );
    case DiscoveryContentTypes.COURSE:
      return (
        <SiteList
          type={DiscoveryContentTypes.COURSE}
          mode={viewMode}
          loadMoreResults={loadMoreFilterResults}
          previewCount={previewCount}
        />
      );
    case DiscoveryContentTypes.ARTICLE:
      return (
        <ArticleList
          mode={viewMode}
          loadMoreResults={loadMoreFilterResults}
          previewCount={previewCount}
        />
      );
    case DiscoveryContentTypes.AUDIENCE_HOME:
      return (
        <TopicHomesList
          mode={viewMode}
          loadMoreResults={loadMoreFilterResults}
          previewCount={previewCount}
        />
      );
    case DiscoveryContentTypes.STREAMLY_VIDEO:
      return (
        <StreamlyVideosList
          mode={viewMode}
          loadMoreResults={loadMoreFilterResults}
          previewCount={previewCount}
        />
      );
    default:
      return null;
  }
}
