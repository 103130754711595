export enum VideoModuleSources {
  YOUTUBE = 'YOUTUBE',
  WISTIA = 'WISTIA',
  VIMEO = 'VIMEO',
  STREAMLY_VIDEO = 'STREAMLY_VIDEO',
}

type SettingType = {
  path: string;
  allow: string;
};

export const scriptId = 'wistia_script';

export const sourceSettings: { [key in VideoModuleSources]: SettingType } = {
  [VideoModuleSources.YOUTUBE]: {
    path: 'https://www.youtube.com/embed/',
    allow:
      'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
  },
  [VideoModuleSources.VIMEO]: {
    path: 'https://player.vimeo.com/video/',
    allow: 'autoplay; fullscreen; picture-in-picture;',
  },
  [VideoModuleSources.WISTIA]: {
    path: 'https://fast.wistia.net/embed/iframe/',
    allow: '',
  },
  [VideoModuleSources.STREAMLY_VIDEO]: {
    path: '',
    allow: '',
  },
};

export const defaultOptions = {
  autoplay: 1,
  controls: 1,
  rel: 0,
};
