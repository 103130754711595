import {
  ProductsPreviewList,
  ProductsList,
} from '../../../../productFilter/products';
import { ArticleCard } from '../../../../productFilter/cards';

import { ViewMode } from '../index';
import { useRootSelector } from '../../../../../../../store/hooks';
import { selectHubDiscoveryArticleRecord } from '../../../../../../../store/features/discovery';
import { UseInfiniteScrollProps } from '../../../../../../../hooks';

export interface ArticleListProps {
  mode: keyof typeof ViewMode;
  loadMoreResults?: UseInfiniteScrollProps['fetchMoreData'];
  previewCount?: number;
}

export function ArticleList(props: ArticleListProps) {
  const { mode, loadMoreResults, previewCount } = props;
  const { results, totalPages } = useRootSelector(
    selectHubDiscoveryArticleRecord,
  );

  return mode === ViewMode.list && loadMoreResults ? (
    <ProductsList
      products={results}
      onLoadMore={loadMoreResults}
      totalPage={totalPages}
    >
      {(product) => <ArticleCard data={product} cardType="list" />}
    </ProductsList>
  ) : (
    <ProductsPreviewList products={results} countToShow={previewCount}>
      {(product) => <ArticleCard data={product} />}
    </ProductsPreviewList>
  );
}
