import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../store/features/generic';
import { GenericState } from '../../../../../types';
import { RootState } from '../../../../../store/store';
import { selectPageConfig } from '../../../../../store/features/pageConfig';
import { CoursePricingOptionsData, CoursePricingOptionsParams } from './types';
import { fetchSitePricingBookingClosed } from '../bookingClosed';

export const fetchCoursePricingOptions = createGenericAsyncThunk<
  CoursePricingOptionsData,
  CoursePricingOptionsParams
>(
  'coursePricingOptions/fetchCoursePricingOptions',
  async ({ basketId }, { extra, rejectWithValue, getState, dispatch }) => {
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.get(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/site-options`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: CoursePricingOptionsData = await response.json();

    if (response.status !== 200) {
      if (response.status === 423) {
        dispatch(fetchSitePricingBookingClosed());
      }

      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const coursePricingOptionsSlice = createGenericSlice({
  name: 'coursePricingOptions',
  initialState: {
    status: 'idle',
    data: {
      data: {
        options: [],
      },
    },
  } as GenericState<CoursePricingOptionsData>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<
      CoursePricingOptionsData,
      CoursePricingOptionsParams
    >(builder, fetchCoursePricingOptions);
  },
});

export const selectCoursePricingOptionsData = (state: RootState) =>
  state.pricing.courseOptions.data.data;

export const { start, fulfilled, rejected } = coursePricingOptionsSlice.actions;

export default coursePricingOptionsSlice.reducer;
