import React from 'react';
import { FacetType, HubFacetTypes } from '../../../../../../types';
import {
  Discovery,
  DiscoveryFilter,
} from '../../../../../../store/features/discovery';
import { FilterFacetGroup } from '../FilterFacetGroup';
import { Checkbox, Radio } from '../index';
import {
  headingMap,
  facetTypeDefaultOrder,
  deliveryTypeOrder,
  awardGroupOrder,
  qualificationOrder,
  zeroCountFacetsHiddenList,
  maxFiltersToShowCountMap,
  facetTypeOrderByBrandUrlSuffix,
} from './constants';
import { selectSiteType } from '../../../../../../store/features/siteType';
import { useRootSelector } from '../../../../../../store/hooks';

export function sortByFacetTypeOrder(
  a: FacetType,
  b: FacetType,
  facetTypeOrderDestination: string[],
) {
  const aIndex = facetTypeOrderDestination.indexOf(a);
  const bIndex = facetTypeOrderDestination.indexOf(b);

  if (aIndex > bIndex) {
    return 1;
  } else if (aIndex < bIndex) {
    return -1;
  }

  return 0;
}

export function sortByTypeOrder(order: string[]) {
  return function (a: DiscoveryFilter, b: DiscoveryFilter) {
    const aIndex = order.indexOf(a.label);
    const bIndex = order.indexOf(b.label);

    if (aIndex > bIndex) {
      return 1;
    } else if (aIndex < bIndex) {
      return -1;
    }

    return 0;
  };
}

export function sortByFacetType(
  facetType: FacetType,
  filters: DiscoveryFilter[],
): DiscoveryFilter[] {
  if ([FacetType.EVENT_TYPES, FacetType.COURSE_TYPES].includes(facetType)) {
    return filters.sort(sortByTypeOrder(deliveryTypeOrder));
  }

  if ([FacetType.AWARD_GROUPS].includes(facetType)) {
    return filters.sort(sortByTypeOrder(awardGroupOrder));
  }

  if ([FacetType.INFORMATION_TYPES].includes(facetType)) {
    return filters.sort(sortByTypeOrder(qualificationOrder));
  }

  return filters;
}

const filterNonZeroFilters = (
  facets: DiscoveryFilter[],
  facetName: FacetType,
) =>
  facets.filter((facet) => {
    if (!facet.label) {
      return false;
    }

    if (zeroCountFacetsHiddenList.includes(facetName)) {
      return facet.count > 0;
    }

    return true;
  });

export interface FilterFacetsProps {
  facets: Discovery['facets'];
}

export function FilterFacets({ facets }: FilterFacetsProps) {
  const { brandUrlSuffix } = useRootSelector(selectSiteType);
  const facetTypeOrderDestination =
    facetTypeOrderByBrandUrlSuffix[brandUrlSuffix] ?? facetTypeDefaultOrder;
  const facetTypes = Object.keys(facets) as [HubFacetTypes];
  const sortedFacetTypes = [...facetTypes].sort((a, b) =>
    sortByFacetTypeOrder(a, b, facetTypeOrderDestination),
  );

  return (
    <>
      {sortedFacetTypes
        .filter((facetType) => facets[facetType].length > 0)
        .map((facetType) => {
          const zeroFilter = facets[facetType][0];
          const inputType = zeroFilter.type === 'ALL' ? 'radio' : 'checkbox';
          const inputName = zeroFilter.filterValue.type;
          const filters = filterNonZeroFilters(facets[facetType], facetType);
          const sortedFilters = sortByFacetType(facetType, filters);

          return (
            filters.length > 0 && (
              <FilterFacetGroup
                key={facetType}
                type={inputType}
                name={inputName}
                maxFiltersToShowCount={maxFiltersToShowCountMap[facetType]}
                heading={headingMap[facetType]}
                filters={sortedFilters}
                renderFilterItem={(filter, type) => (
                  <>
                    {type === 'radio' && <Radio data={filter} />}
                    {type === 'checkbox' && <Checkbox data={filter} />}
                  </>
                )}
              />
            )
          );
        })}
    </>
  );
}
