import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MultiLevelNavHomeBtn from '../MultiLevelNavHomeBtn/MultiLevelNavHomeBtn';
import './MultiLevelNavList.scss';

export function MultiLevelNavList(props) {
  const {
    level = '1',
    navBarItems = [],
    withHomeNav,
    fixedState,
    siteHeader: { data: { fullScreen } = {} } = {},
    children,
  } = props;

  const onGroupItemMouseEnter = () => {
    if (level === '1' && !fixedState) {
      document.dispatchEvent(new CustomEvent('MultiLevelNavEnter'));
    }
  };

  const onGroupItemMouseLeave = () => {
    if (level === '1') {
      document.dispatchEvent(new CustomEvent('MultiLevelNavLeave'));
    }
  };

  const componentClassNames = classNames('c-multi-level-nav-list', {
    [`c-multi-level-nav-list--level-${level}`]: level,
    [`c-multi-level-nav-list--level-${level}-fullscreen`]: fullScreen,
    'c-multi-level-nav-list--fixed-state': fixedState,
  });

  return navBarItems.length === 0 ? null : (
    <div
      className={componentClassNames}
      onMouseEnter={onGroupItemMouseEnter}
      onMouseLeave={onGroupItemMouseLeave}
    >
      <ul className="c-multi-level-nav-list__list">
        {withHomeNav && <MultiLevelNavHomeBtn />}
        {navBarItems.map((navBarItem, index) => (
          <li key={index} className="c-multi-level-nav-list__item">
            {children(navBarItem)}
          </li>
        ))}
      </ul>
    </div>
  );
}

MultiLevelNavList.propTypes = {
  withHomeNav: PropTypes.bool,
  navBarItems: PropTypes.array,
  fixedState: PropTypes.bool,
  level: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader,
  };
}

export default connect(mapStateToProps)(MultiLevelNavList);
