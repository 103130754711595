export function divideArrayIntoParts<T>(array: T[] = [], size: number) {
  const parts = [];
  const partsLength = Math.ceil(array.length / size);

  for (let i = 0; i < size; i++) {
    const startIndex = i * partsLength;
    parts.push(array.slice(startIndex, startIndex + partsLength));
  }

  return parts;
}
