import React from 'react';
import styled from 'styled-components';
import MenuItemGroup from '../../utils/MenuItemGroup';
import { typography } from '../../styles/informatech/typography/typography';
import { breakpoint } from '../../styles/shared';

const List = styled.ul`
  list-style-type: none;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    flex-wrap: wrap;
    padding-top: 0;
  }
`;

const Item = styled.li`
  display: inline-flex;
  padding-left: 30px;
  font-size: ${typography.size.s2}px;

  @media (max-width: ${breakpoint.tabletPlusBreakpoint}) {
    padding-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: inherit;

    :hover {
      cursor: pointer;
    }
  }
`;

const MenuItem = (props) => {
  return (
    <Item>
      <a href={props.link} className={props.linkClass}>
        {props.content}
      </a>
    </Item>
  );
};

const MenuList = ({ className, items }) => {
  return (
    <List className={className}>
      <MenuItemGroup WrappingComponent={MenuItem} menuItems={items} />
    </List>
  );
};

export default MenuList;
