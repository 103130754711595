import React, { ReactNode } from 'react';
import cn from 'classnames';
import { CardWrapper } from '../../../cards/components/CardWrapper';
import {
  ImageBadge,
  ImageBadgePosition,
} from '../../../cards/components/Image/ImageBadge';
import styles from './ProductCard.module.scss';

export interface ProductCardProps {
  title: ReactNode;
  backgroundColor?: string;
  image?: ReactNode;
  imageBadge?: ReactNode;
  imageBadgePosition?: keyof typeof ImageBadgePosition;
  topics?: ReactNode;
  date?: ReactNode;
  language?: ReactNode;
  deliveryTypes?: ReactNode;
  showMoreBadge?: ReactNode;
  sponsors?: ReactNode;
  largeLogo?: ReactNode;
  leftFooter?: ReactNode;
  rightFooter?: ReactNode;
  description?: ReactNode;
  className?: string;
}

export function ProductCard(props: ProductCardProps) {
  const {
    title,
    backgroundColor,
    image,
    imageBadge,
    imageBadgePosition,
    topics,
    date,
    language,
    deliveryTypes,
    showMoreBadge,
    sponsors,
    largeLogo,
    leftFooter,
    rightFooter,
    description,
    className,
  } = props;
  const leftFooterClassNames = cn(rightFooter ? 'col-xs-6' : 'col-xs-12');
  const rightFooterClassNames = cn(leftFooter ? 'col-xs-6' : 'col-xs-12');

  return (
    <CardWrapper
      backgroundColor={backgroundColor}
      className={cn(styles.wrapper, className)}
    >
      <div className="row">
        <div className="col-xs-5 col-sm-3 col-md-12">
          {image && (
            <div className={styles.image}>
              {image}

              {imageBadge && (
                <ImageBadge position={imageBadgePosition}>
                  {imageBadge}
                </ImageBadge>
              )}
            </div>
          )}
        </div>

        <div className="col-xs-7 col-sm-9 col-md-12">
          <div className={styles.body}>
            {topics && <div className={styles.topics}>{topics}</div>}

            <div className={styles.title}>{title}</div>

            {(date || language || deliveryTypes) && (
              <div className={styles.details}>
                {date && <div className={styles.date}>{date}</div>}

                {language && <div className={styles.language}>{language}</div>}

                {deliveryTypes && (
                  <div className={styles.deliveryTypes}>{deliveryTypes}</div>
                )}
              </div>
            )}

            {showMoreBadge && (
              <div className={styles.mobileOnlyAttendanceType}>
                {showMoreBadge}
              </div>
            )}

            {(sponsors || description || largeLogo) && (
              <div className={styles.fixedHeight}>
                {sponsors && <div className={styles.sponsors}>{sponsors}</div>}
                {largeLogo && (
                  <div className={styles.largeLogo}>{largeLogo}</div>
                )}
                {description && (
                  <div className={styles.description}>{description}</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {(leftFooter || rightFooter) && (
        <div className={styles.footer}>
          <div className="row">
            {leftFooter && (
              <div className={leftFooterClassNames}>
                <div className={styles.leftFooter}>{leftFooter}</div>
              </div>
            )}
            {rightFooter && (
              <div className={rightFooterClassNames}>
                <div className={styles.rightFooter}>{rightFooter}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </CardWrapper>
  );
}
