import React from 'react';
import styled from 'styled-components';
import { color } from '../../../styles/informatech/colours/colours';

const Footer = styled.footer`
  background: ${color.backgroundGreyBlue};
  color: ${color.lightest};
  z-index: 6;

  a {
    color: white;
  }

  & > div:first-child {
    padding-top: 60px;
  }
`;

export const DivisionalMarketingFooter = ({ children }) => {
  return <Footer>{children}</Footer>;
};
