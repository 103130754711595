import cn from 'classnames';
import { Anchor, AnchorProps } from '../../../../../../../shared/Anchor';
import styles from './DownloadNowAnchor.module.scss';

export interface DownloadAnchorProps extends AnchorProps {}

export function DownloadAnchor(props: DownloadAnchorProps) {
  const { className, children, ...restProps } = props;

  const anchorClassName = cn(styles.anchor, className);

  return (
    <Anchor {...restProps} className={anchorClassName}>
      <span className={cn('material-icons', styles.icon)}>
        download_for_offline
      </span>
      <span className={styles.label}>{children}</span>
    </Anchor>
  );
}
