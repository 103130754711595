import { createSelector } from '@reduxjs/toolkit';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../../store/features/generic';
import {
  GenericState,
  HubOptions,
  UniqueIdentifier,
} from '../../../../../../types';
import { RootState } from '../../../../../../store/store';

export interface AudienceHubOptions extends HubOptions {
  l2Topic: string;
  l1Topics: string[];
  linkedBrands: string[];
}

export interface AudienceHubOptionsParams {
  audienceHubId: UniqueIdentifier;
}

export const fetchAudienceHubOptions = createGenericAsyncThunk<
  AudienceHubOptions,
  AudienceHubOptionsParams
>(
  'audienceHubOptions/fetchAudienceHubOptions',
  async ({ audienceHubId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/audience-home/${audienceHubId}/options`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );
    const data: AudienceHubOptions = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const audienceHubOptionsSlice = createGenericSlice({
  name: 'audienceHubOptions',
  initialState: {
    status: 'idle',
    data: {},
  } as GenericState<AudienceHubOptions>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<AudienceHubOptions, AudienceHubOptionsParams>(
      builder,
      fetchAudienceHubOptions,
    );
  },
});

export const selectAudienceHubOptions = createSelector(
  [(state: RootState) => state.audienceHUB.options],
  (data) => data,
);

export const selectAudienceHubPrimaryTopic = createSelector(
  [(state: RootState) => selectAudienceHubOptions(state)],
  ({ data }) => data.l2Topic,
);

export const { start, fulfilled, rejected } = audienceHubOptionsSlice.actions;

export default audienceHubOptionsSlice.reducer;
