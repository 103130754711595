import { combineReducers } from '@reduxjs/toolkit';

import sitePricingProductsReducer from './features/products/index';
import sitePricingPackagesReducer from './features/packages/index';
import sitePricingContentReducer from './features/content/index';
import sitePricingCheckoutDetailsReducer from './features/checkoutDetails/index';
import coursePricingOptionsReducer from './features/courseOptions/index';
import sitePricingBookingClosedReducer from './features/bookingClosed';
import sitePricingVIPCodeReducer from './features/discounts';
import sitePricingOrderReceiptReducer from './features/orderReceipt';
import sitePackageFormDefaultValuesReducer from './features/packageFormDefaultValues';

const pricingReducer = {
  pricing: combineReducers({
    products: sitePricingProductsReducer,
    packages: sitePricingPackagesReducer,
    content: sitePricingContentReducer,
    courseOptions: coursePricingOptionsReducer,
    checkoutDetails: sitePricingCheckoutDetailsReducer,
    bookingClosed: sitePricingBookingClosedReducer,
    vipCode: sitePricingVIPCodeReducer,
    orderReceipt: sitePricingOrderReceiptReducer,
    packageFormDefaultValues: sitePackageFormDefaultValuesReducer,
  }),
};

export default pricingReducer;
