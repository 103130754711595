import cloneDeep from 'lodash/cloneDeep';
import { isFunction } from '../../helpers';

const MY_UPDATE = 'MY_CONTACT_DETAILS_UPDATE';
const OTHER_UPDATE = 'OTHER_CONTACT_DETAILS_UPDATE';
const MY_SUCCESS = 'MY_CONTACT_DETAILS_SUCCESS';
const OTHER_SUCCESS = 'OTHER_CONTACT_DETAILS_SUCCESS';
const MY_FAILURE = 'MY_CONTACT_DETAILS_FAILURE';
const OTHER_FAILURE = 'OTHER_CONTACT_DETAILS_FAILURE';
const ADD_OTHER_FORM = 'ADD_OTHER_FORM';
const REMOVE_OTHER_FORM = 'REMOVE_OTHER_FORM';

const initialState = {
  my: {
    loaded: false,
    data: {},
    meta: {},
  },
  other: {
    loaded: false,
    data: [],
    meta: {},
  },
};

export default function contactDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case MY_UPDATE:
      return Object.assign(
        {},
        {
          my: {
            loaded: true,
            statusCode: action.data.statusCode,
            ...action.data.json,
          },
        },
      );

    case OTHER_UPDATE:
      return Object.assign(
        {},
        {
          other: {
            loaded: true,
            statusCode: action.data.statusCode,
            ...action.data.json,
          },
        },
      );

    case MY_SUCCESS:
      return Object.assign(cloneDeep(state), {
        my: {
          loaded: true,
          statusCode: 200,
          booking: action.data.statusCode !== 423,
          ...action.data.json,
        },
      });

    case OTHER_SUCCESS:
      return Object.assign(cloneDeep(state), {
        other: {
          loaded: true,
          statusCode: 200,
          booking: action.data.statusCode !== 423,
          ...action.data.json,
        },
      });

    case MY_FAILURE:
      return Object.assign({}, cloneDeep(state), {
        my: {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      });

    case OTHER_FAILURE:
      return Object.assign({}, cloneDeep(state), {
        other: {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      });

    case ADD_OTHER_FORM:
      return Object.assign({}, cloneDeep(state), {
        other: {
          ...state.other,
          data: [
            ...action.data.forms,
            { contactType: action.data.contactType },
          ],
        },
      });

    case REMOVE_OTHER_FORM:
      return Object.assign({}, cloneDeep(state), {
        other: {
          ...state.other,
          data: state.other.data.filter(
            (form, index) => index !== action.index,
          ),
        },
      });

    default:
      return state;
  }
}

export function fetchMyContactDetailsSuccess(data) {
  return { type: MY_SUCCESS, data };
}

export function fetchOtherContactDetailsSuccess(data) {
  return { type: OTHER_SUCCESS, data };
}

export function updateMyContactDetailsSuccess(data) {
  return { type: MY_UPDATE, data };
}

export function updateOtherContactDetailsSuccess(data) {
  return { type: OTHER_UPDATE, data };
}

export function myContactDetailsFailure(data) {
  return { type: MY_FAILURE, data };
}

export function otherContactDetailsFailure(data) {
  return { type: OTHER_FAILURE, data };
}

export function addOtherContactDetailsFormAction(forms, contactType) {
  return {
    type: ADD_OTHER_FORM,
    data: {
      forms,
      contactType,
    },
  };
}

export function removeOtherContactDetailsFormAction(index) {
  return {
    type: REMOVE_OTHER_FORM,
    index,
  };
}

export function fetchContactDetailsAction(basketId, contactType) {
  return function (dispatch, getState, { Api, qs, i18n }) {
    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'GET',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .contactDetails({ siteId, basketId, contactType })
      .then((response) => {
        if (response.statusCode === 200 || response.statusCode === 423) {
          const actionCreator =
            contactType === 'my'
              ? fetchMyContactDetailsSuccess
              : fetchOtherContactDetailsSuccess;
          return dispatch(actionCreator(response));
        } else {
          const actionCreator =
            contactType === 'my'
              ? myContactDetailsFailure
              : otherContactDetailsFailure;
          return dispatch(actionCreator(response));
        }
      });
  };
}

export function updateContactDetailsAction(
  basketId,
  contactType,
  bodyParams,
  callback,
) {
  return function (dispatch, getState, { Api, qs, i18n }) {
    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'PUT',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
        body: JSON.stringify(bodyParams),
      })
      .contactDetails({ siteId, basketId, contactType })
      .then((response) => {
        if (isFunction(callback)) {
          const data = {
            statusCode: response.statusCode,
            ...response.json,
          };

          callback(data);
        }

        if (response.statusCode === 200) {
          const actionCreator =
            contactType === 'my'
              ? updateMyContactDetailsSuccess
              : updateOtherContactDetailsSuccess;
          return dispatch(actionCreator(response));
        } else {
          const actionCreator =
            contactType === 'my'
              ? myContactDetailsFailure
              : otherContactDetailsFailure;
          return dispatch(actionCreator(response));
        }
      });
  };
}
