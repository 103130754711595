import { useGetImgixImageUrl } from '../../../../../../../hooks';
import { PropsWithTestId } from '../../../../../../../types';
import cn from 'classnames';

import styles from './Image.module.scss';

export enum ImageStyles {
  default = 'default',
  list = 'list',
  smallList = 'smallList',
}

export interface ImageProps {
  path: string;
  imageStyle: keyof typeof ImageStyles;
  className?: string;
}

export function Image(props: PropsWithTestId<ImageProps>) {
  const { path, imageStyle, testId, className } = props;
  const image = useGetImgixImageUrl(path);

  return (
    <div className={cn(styles.wrapper, className)}>
      <div
        className={cn(styles.image, styles[imageStyle])}
        style={{ backgroundImage: image ? `url(${image})` : 'none' }}
        data-testid={testId}
      />
    </div>
  );
}

Image.defaultProps = {
  imageStyle: ImageStyles.default,
  testId: 'card-image',
};
