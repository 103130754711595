import { HTMLAttributes, PropsWithChildren } from 'react';
import Sticky from 'react-sticky-el';
import loadable from '@loadable/component';
import cn from 'classnames';
import styles from './Layout.module.scss';
import { useRootSelector } from '../../../../../store/hooks';
import { useDeviceDetect } from '../../../../../hooks';
import {
  useSetUserDetailsToDataLayer,
  useSetOptionsToDataLayer,
  useSetProductCodeDataToDataLayer,
} from '../../../shared/hooks';
import { HubContextProvider } from '../../../shared/context/hubContext';
import { BrandHubLayout } from '../../store/features/layout';
import { selectBrandHubOptions } from '../../store/features/options';
import { selectBrandHubPageLogo } from '../../store/features/page';
import InformaRibbon from '../../../../common/InformaRibbon/InformaRibbon';
import CookieBanner from '../../../../common/CookieBanner/CookieBanner';
import { Header } from '../../Header';
import { Footer } from '../../Footer';
import Advertising from '../../../shared/Advertising';
import ErrorBoundary from '../../../../common/ErrorBoundary';
import PageError from '../../../shared/PageError';

const Nav = loadable(
  () => import(/* webpackChunkName: "HubNav" */ '../../../shared/Nav'),
);

const MobileNav = loadable(
  () =>
    import(/* webpackChunkName: "HubMobileNav" */ '../../../shared/MobileNav'),
);

export interface LayoutProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  layout: BrandHubLayout;
  adUnit?: string;
  networkId?: string;
}

export default function Layout(props: PropsWithChildren<LayoutProps>) {
  const { adUnit, networkId, layout, className, children } = props;
  const { header, footer, sectors } = layout;
  const { data: options } = useRootSelector(selectBrandHubOptions);
  const pageLogo = useRootSelector(selectBrandHubPageLogo);

  const {
    brandUrlSuffix,
    subBrandName,
    showSponsorLogos,
    theme,
    advertisingEnabled,
  } = options;

  const {
    navBarItems,
    headerNavBar: {
      brandLogo,
      disableNavbarHomeLink,
      displayBrandLogoInNavbar,
      displayKeywordSearch,
      displayTextContent,
      searchDisplayPlace,
      searchPlaceholderText,
      textContent,
    },
  } = header;

  const { isDesktop } = useDeviceDetect();
  useSetUserDetailsToDataLayer();
  useSetOptionsToDataLayer(options);
  useSetProductCodeDataToDataLayer(options);

  const logo = pageLogo ?? brandLogo;
  const logoInHeader = !displayBrandLogoInNavbar;
  const searchInHeader =
    displayKeywordSearch && searchDisplayPlace === 'HEADER';
  const textContentInHeader = displayTextContent && !!textContent;
  const headerDisplayed = logoInHeader || searchInHeader || textContentInHeader;

  return (
    <HubContextProvider
      theme={theme}
      brandPath={brandUrlSuffix}
      brandName={subBrandName}
      showSponsorLogos={showSponsorLogos}
    >
      <div className={cn(styles.wrapper, 'brand-hub')}>
        <CookieBanner />
        <InformaRibbon />
        {advertisingEnabled && (
          <Advertising
            adUnit={adUnit || options.adUnit}
            networkId={networkId || options.networkId}
            advSlots={['728_1']}
            advSize="728_90"
          />
        )}
        <Sticky
          stickyClassName="sticky"
          wrapperClassName={styles.headerSticky}
          disabled={isDesktop}
        >
          <header className={styles.header}>
            {headerDisplayed ? (
              <Header header={header} sectors={sectors} brandLogo={logo} />
            ) : (
              <MobileNav header={header} sectors={sectors} brandLogo={logo} />
            )}
          </header>
        </Sticky>
        <Sticky wrapperClassName={styles.navSticky}>
          <nav className={styles.nav}>
            <Nav
              links={navBarItems}
              sectors={sectors}
              displayHomeLink={!disableNavbarHomeLink}
              brandLogo={logo}
              displayBrandLogo={displayBrandLogoInNavbar}
              displayKeywordSearch={
                displayKeywordSearch && searchDisplayPlace === 'NAVBAR'
              }
              searchPlaceholderText={searchPlaceholderText}
              displayUser={!headerDisplayed}
            />
          </nav>
        </Sticky>
        <main className={cn(styles.body, className)}>
          <ErrorBoundary errorMessage={<PageError />}>{children}</ErrorBoundary>
        </main>
        <footer className={styles.footer}>
          <Footer footer={footer} sectors={sectors} />
        </footer>
      </div>
    </HubContextProvider>
  );
}
