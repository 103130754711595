import type { GenericState } from '../../../types';
import type { RootState } from '../../store';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../generic';

export interface FestivalProSchedule {
  tabs: [];
}

export interface FestivalProScheduleArgs {
  eventId: string;
  guestId: string;
}

export const fetchFestivalProEventSchedule = createGenericAsyncThunk<
  FestivalProSchedule,
  Pick<FestivalProScheduleArgs, 'eventId'>
>(
  'festivalProSchedule/fetchFestivalProEventSchedule',
  async ({ eventId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/api/v1/festival-pro/events/${eventId}/schedule`,
    );
    const data: FestivalProSchedule = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const fetchFestivalProGuestSchedule = createGenericAsyncThunk<
  FestivalProSchedule,
  FestivalProScheduleArgs
>(
  'festivalProSchedule/fetchFestivalProGuestSchedule',
  async ({ eventId, guestId }, { extra, rejectWithValue }) => {
    const id = guestId.split('-')[0];
    const response = await extra.ajax.get(
      `/api/v1/festival-pro/events/${eventId}/guests/${id}/schedule`,
    );
    const data: FestivalProSchedule = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const festivalProScheduleSlice = createGenericSlice({
  name: 'festivalProSchedule',
  initialState: {
    status: 'idle',
    data: {
      tabs: [],
    },
  } as GenericState<FestivalProSchedule>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases(builder, fetchFestivalProEventSchedule);
    createGenericBuilderCases(builder, fetchFestivalProGuestSchedule);
  },
});

export const selectFestivalProSchedule = (state: RootState) =>
  state.festivalProSchedule.data;

export const selectFestivalProScheduleStatus = (state: RootState) =>
  state.festivalProSchedule.status;

export default festivalProScheduleSlice.reducer;
