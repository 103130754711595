import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { selectTenantConfig } from '../../../../store/features/tenantConfig';
import s from './PageError.module.scss';

export default function PageError() {
  const { t } = useTranslation();
  const {
    support: { privacyEmail, techSupportEmail, customerServiceEmail },
  } = useSelector(selectTenantConfig);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-8">
          <div className={s.wrapper}>
            <h3 className={s.title}>Error</h3>
            <div className={s.text}>
              <p>
                Unfortunately an error occurred processing your request, please
                try again later. If you are experiencing problems please use the
                contact points below:
              </p>
              <ul>
                <li>
                  <Trans
                    i18nKey="event.error.supportmessageprivacy"
                    values={{ email: privacyEmail }}
                  >
                    <a
                      href={`mailto:${privacyEmail}`}
                      title={t('event.error.supportmessageprivacy.title')}
                    >
                      {privacyEmail}
                    </a>
                  </Trans>
                </li>
                <li>
                  <Trans
                    i18nKey="event.error.supportmessagetech"
                    values={{ email: techSupportEmail }}
                  >
                    <a
                      href={`mailto:${techSupportEmail}`}
                      title={t('event.error.supportmessagetech.title')}
                    >
                      {techSupportEmail}
                    </a>
                  </Trans>
                </li>
                <li>
                  <Trans
                    i18nKey="event.error.supportmessagecs"
                    values={{
                      email: customerServiceEmail,
                    }}
                  >
                    <a
                      href={`mailto:${customerServiceEmail}`}
                      title={t('event.error.supportmessagecs.title')}
                    >
                      {customerServiceEmail}
                    </a>
                  </Trans>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
