import { useCallback, useState } from 'react';
import { UniqueIdentifier } from '../../../../../../types';
import { useRootDispatch } from '../../../../../../store/hooks';
import { updateModuleById as updateBrandModuleById } from '../../../../brandHub/store/features/page';
import { updateModuleById as updateAudienceModuleById } from '../../../../audienceHub/store/features/page';
import {
  fetchHubModuleDiscovery,
  FetchHubModuleDiscoveryParams,
  Discovery,
  HubDiscoveryValues,
} from '../../../../../../store/features/discovery';

export interface ProductModule {
  id: UniqueIdentifier;
  searchQueryData: HubDiscoveryValues;
}

export interface UseProductModuleFilterProps<T> {
  module: T;
  moduleName: FetchHubModuleDiscoveryParams['moduleName'];
  brandName: string;
}

export function useProductModuleFilter<T extends ProductModule>({
  module,
  moduleName,
  brandName,
}: UseProductModuleFilterProps<T>) {
  const dispatch = useRootDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleFilterChange = useCallback(
    (values: HubDiscoveryValues) => {
      const { subBrands: selectedSubBrands = [], ...filterValues } = values;

      const {
        id: moduleId,
        searchQueryData: {
          type,
          deliveryType: availableDeliveryType,
          subBrands: initialSubBrands = [],
          sectors: availableSectors,
          topics: availableTopics,
          location: availableLocation,
          language: availableLanguage,
          awardGroup: availableAwardGroup,
          informationType: availableInformationType,
          partners: availablePartners,
          creditPartners: availableCreditPartners,
          ...initialFilterValues
        },
      } = module;

      const subBrands =
        selectedSubBrands.length > 0 ? selectedSubBrands : initialSubBrands;

      const requestBody = {
        values: {
          ...initialFilterValues,
          ...filterValues,
          subBrands: initialFilterValues.facetsConfig?.subBrandsEnabled
            ? subBrands
            : [brandName],
          availableSubBrands:
            initialSubBrands.length > 0 ? initialSubBrands : [brandName],
          availableDeliveryType,
          availableSectors,
          availableTopics,
          availableLocation,
          availableLanguage,
          availableAwardGroup,
          availableInformationType,
          availablePartners,
          availableCreditPartners,
        },
        moduleName,
      };

      setLoading(true);
      dispatch(fetchHubModuleDiscovery(requestBody))
        .then(({ payload, meta: { requestStatus } }) => {
          if (requestStatus === 'fulfilled') {
            const data = {
              moduleId,
              discovery: payload as Discovery,
            };
            dispatch(updateBrandModuleById(data));
            dispatch(updateAudienceModuleById(data));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [brandName, dispatch, module, moduleName],
  );

  return { loading, handleFilterChange };
}
