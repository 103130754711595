import { HubLandingPage } from '../../../../../types';
import { useRootSelector } from '../../../../../store/hooks';
import { selectBrandHubPage } from '../../store/features/page';
import Page from './Page';
import NotFoundPage from '../NotFound';

export function Landing() {
  const { statusCode } = useRootSelector(selectBrandHubPage<HubLandingPage>());

  return statusCode === 404 ? <NotFoundPage /> : <Page />;
}
