export class Color {
  constructor() {
    if (arguments.length === 1) {
      this.red = parseInt(arguments[0].slice(0, 2), 16);
      this.green = parseInt(arguments[0].slice(2, 4), 16);
      this.blue = parseInt(arguments[0].slice(4, 6), 16);

      this.hsl = new HSLColor(this);
    } else {
      this.red = arguments[0];
      this.green = arguments[1];
      this.blue = arguments[2];

      this.hsl = new HSLColor(this);
    }
  }

  lighten(percentage) {
    const updatedLightness = Math.min(this.hsl.lightness + percentage, 100.0);
    return new HSLColor(
      this.hsl.hue,
      this.hsl.saturation,
      updatedLightness,
    ).toColor();
  }

  darken(percentage) {
    const updatedLightness = Math.max(this.hsl.lightness - percentage, 0.0);
    return new HSLColor(
      this.hsl.hue,
      this.hsl.saturation,
      updatedLightness,
    ).toColor();
  }

  transformedHSL(saturation, lightness) {
    return new HSLColor(this.hsl.hue, saturation, lightness).toColor();
  }
}

class HSLColor {
  constructor() {
    if (arguments.length === 1) {
      const color = arguments[0];

      const r = color.red / 255;
      const g = color.green / 255;
      const b = color.blue / 255;

      const max = Math.max(r, Math.max(g, b));
      const min = Math.min(r, Math.min(g, b));
      const l = (max + min) / 2;
      const d = max - min;
      let h, s;

      if (max === min) {
        h = s = 0;
      } else {
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
          default:
            h = 0;
            break;
        }

        h /= 6;
      }

      this.hue = h * 360;
      this.saturation = s * 100;
      this.lightness = l * 100;
    } else {
      this.hue = arguments[0];
      this.saturation = arguments[1];
      this.lightness = arguments[2];
    }
  }

  static calculateColor(h, s, l) {
    const m2 = l <= 0.5 ? l * (s + 1) : l + s - l * s;
    const m1 = l * 2 - m2;

    let ha;
    if (h < 0) {
      ha = h + 1;
    } else if (h > 1) {
      ha = h - 1;
    } else {
      ha = h;
    }

    if (ha * 6 < 1) {
      return m1 + (m2 - m1) * ha * 6;
    } else if (ha * 2 < 1) {
      return m2;
    } else if (ha * 3 < 2) {
      return m1 + (m2 - m1) * (2 / 3 - ha) * 6;
    } else {
      return m1;
    }
  }

  static hex2(value) {
    const hex = value.toString(16).toUpperCase();
    return (hex.length === 1 ? '0' : '') + hex;
  }

  getHexCode(red, green, blue) {
    return `#${this.constructor.hex2(red)}${this.constructor.hex2(
      green,
    )}${this.constructor.hex2(blue)}`;
  }

  toColor() {
    const h = this.hue / 360;
    const s = this.saturation / 100;
    const l = this.lightness / 100;

    const red = Math.round(
      this.constructor.calculateColor(h + 1 / 3, s, l) * 255,
    );
    const green = Math.round(this.constructor.calculateColor(h, s, l) * 255);
    const blue = Math.round(
      this.constructor.calculateColor(h - 1 / 3, s, l) * 255,
    );

    return this.getHexCode(red, green, blue);
  }
}

export function colorLuminance(hex = '#000000', percentage = 0) {
  const hexCode = hex.replace('#', '');

  if (percentage === 0) {
    return `#${hexCode}`;
  }

  return new Color(hexCode)[percentage > 0 ? 'lighten' : 'darken'](
    Math.abs(percentage),
  );
}

export function createNewHexColorViaHSL(
  hex = '#000000',
  saturation = 0,
  lightness = 0,
) {
  const hexCode = hex.replace('#', '');

  return new Color(hexCode).transformedHSL(saturation, lightness);
}
