import React from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import qs from 'qs';
import clientConfig from '../../../clientConfig';
import Route from '../Route/Route';
import Auth from '../../../services/AuthService';
import routes from '../../../routes';
import GTM from '../../../utils/initialGTM';
import findActiveRoutesGroup from '../../../helpers/findActiveRoutesGroup';
import {
  optimizeImgixImages,
  siteTypesEnum,
  removeSitePathFromRoute,
  removeBrandPathFromRoute,
  lazyLoadingRefresh,
} from '../../../helpers';
import { localizeRoutePath } from '../../../helpers/locale';
import { updatePageConfigAction } from '../../../store/commonDucks/pageConfig';

import HubNotFoundView from '../../views/basePages/HubNotFoundView/HubNotFoundView';
import HubServerErrorView from '../../views/basePages/HubServerErrorView/HubServerErrorView';
import NotFoundPage from '../../hub/brandHub/pages/NotFound';
import ErrorPage from '../../hub/brandHub/pages/Error';
import NotFoundView from '../../views/siteBasePages/NotFoundView/NotFoundView';
import ServerErrorView from '../../views/siteBasePages/ServerErrorView/ServerErrorView';

const {
  BRAND_HUB,
  AUDIENCE_HUB,
  EVENT,
  COURSE,
  ARTICLE,
  L1_TOPIC_HUB,
  L2_TOPIC_HUB,
} = siteTypesEnum();

export class App extends React.Component {
  componentDidMount() {
    const {
      pageConfig: { tenantId, siteType, audienceType, siteMaskingPath } = {},
    } = this.props;

    const isAAAServiceEnabledForTenant =
      clientConfig.api.services.AAAService[tenantId]?.enabled;

    if (isAAAServiceEnabledForTenant && !siteMaskingPath) {
      this.validateAuth();
    }

    this.checkTrackerId();

    const analyticsId = this.getAnalyticsIdBySiteType(siteType, audienceType);

    if (analyticsId) {
      GTM.pushGoogleAnalyticsId(analyticsId);
    }

    // CAAS-4270 Blurry logo rendering
    optimizeImgixImages();

    lazyLoadingRefresh(true);
  }

  getAnalyticsIdBySiteType(siteType, audienceType) {
    switch (siteType) {
      case BRAND_HUB:
        return this.props.brandHubAnalyticsId;

      case AUDIENCE_HUB:
        return this.props.audienceHubAnalyticsId;

      case EVENT:
      case COURSE:
        return this.props.siteAnalyticsId;

      case ARTICLE:
        if (audienceType === AUDIENCE_HUB) {
          return this.props.audienceHubAnalyticsId;
        } else {
          return this.props.legacyAudienceHomeAnalyticsId;
        }

      case L1_TOPIC_HUB:
        return this.props.industryHomeAnalyticsId;

      case L2_TOPIC_HUB:
        return this.props.legacyAudienceHomeAnalyticsId;

      default:
        return null;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.updatePageConfig(document.location);
    }
  }

  updatePageConfig(location) {
    const {
      hash,
      host,
      hostname,
      href,
      origin,
      pathname,
      port,
      protocol,
      search,
    } = location;

    this.props.updatePageConfig({
      location: {
        hash,
        host,
        hostname,
        href,
        origin,
        pathname,
        port,
        protocol,
        search,
      },
    });
  }

  async validateAuth() {
    const accessToken = Auth.getToken();

    if (accessToken) {
      this.props.updatePageConfig({
        account: {
          authorized: true,
          token: accessToken,
        },
      });
    }

    const response = await Auth.validateToken()
      .then((response) => response.json())
      .catch(() => ({}));

    if (response && response.token) {
      Auth.setToken(response);

      this.props.updatePageConfig({
        account: {
          authorized: true,
          token: response.token,
        },
      });
    } else {
      Auth.removeToken();

      this.props.updatePageConfig({
        account: {
          authorized: false,
          token: null,
        },
      });
    }
  }

  checkTrackerId() {
    const {
      pageConfig: {
        tenantConfig: { domain },
      },
      cookies,
    } = this.props;

    const { search } = document.location;
    const { tracker_id: trackerId } = qs.parse(search.slice(1));

    if (trackerId) {
      const value = Array.isArray(trackerId) ? trackerId[0] : trackerId;
      const expires = new Date(Date.now() + 2592000000); // 30 days

      cookies.set('tracker_id', value, { path: '/', domain, expires });
    }
  }

  render() {
    const { EVENT, COURSE } = siteTypesEnum();
    const {
      tenantId,
      siteType,
      audienceType,
      brandSubPath,
      renderError,
      singleSiteMode,
      newPaymentJourneyEnabled,
    } = this.props.pageConfig;
    const routesGroup = findActiveRoutesGroup(
      siteType,
      audienceType,
      routes,
      newPaymentJourneyEnabled,
    );

    return (
      <Switch>
        {routesGroup.map(({ exact, path, type, component: Component }) => {
          let routePath = localizeRoutePath(path);

          if (singleSiteMode) {
            routePath = removeSitePathFromRoute(routePath);
          }

          if (!brandSubPath) {
            routePath = removeBrandPathFromRoute(routePath);
          }

          return (
            <Route
              key={path}
              exact={exact}
              type={type}
              path={routePath}
              render={(props) => {
                if (renderError === 404) {
                  if (siteType === EVENT || siteType === COURSE) {
                    return <NotFoundView />;
                  }

                  if (tenantId === 'KNECT365') {
                    return <HubNotFoundView />;
                  }

                  return <NotFoundPage />;
                }

                if (renderError === 500) {
                  if (siteType === EVENT || siteType === COURSE) {
                    return <ServerErrorView />;
                  }

                  if (tenantId === 'KNECT365') {
                    return <HubServerErrorView />;
                  }

                  return <ErrorPage />;
                }

                return <Component {...props} />;
              }}
            />
          );
        })}
        <Route
          render={() => {
            if (renderError === 500) {
              if (siteType === EVENT || siteType === COURSE) {
                return <ServerErrorView />;
              }

              if (tenantId === 'KNECT365') {
                return <HubServerErrorView />;
              }

              return <ErrorPage />;
            }

            if (siteType === EVENT || siteType === COURSE) {
              return <NotFoundView />;
            }

            if (tenantId === 'KNECT365') {
              return <HubNotFoundView />;
            }

            return <NotFoundPage />;
          }}
        />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
    brandHubAnalyticsId: state.brandHub.options.data?.googleAnalyticsOption,
    audienceHubAnalyticsId:
      state.audienceHUB.options.data?.googleAnalyticsOption,
    industryHomeAnalyticsId:
      state.brandHub.industryHeader.data?.googleAnalyticsId,
    siteAnalyticsId: state.siteHeader.data?.googleAnalyticsId,
    legacyAudienceHomeAnalyticsId: state.audienceHeader.data?.googleAnalyticsId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePageConfig(data) {
      return dispatch(updatePageConfigAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCookies(withRouter(App)));
