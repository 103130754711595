import React from 'react';
import VideoModal from '../VideoModal';
import VideoPlayer from '../VideoPlayer';
import { VideoModuleSources } from '../VideoPlayer/constants';
import { VideoParams } from '../hooks';

export type StreamlyVideoModalProps = {
  open: boolean;
  onClose: () => void;
  videoParams: VideoParams;
};

export default function StreamlyVideoModal({
  open,
  videoParams,
  onClose,
}: StreamlyVideoModalProps) {
  return (
    <VideoModal open={open} onClose={onClose}>
      <VideoPlayer
        source={VideoModuleSources.STREAMLY_VIDEO}
        title={videoParams.title}
        streamlyUrl={videoParams.url}
      />
    </VideoModal>
  );
}
