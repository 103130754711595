import { isValid } from 'date-fns';

export function isDateValid(date: Date | number | string | null) {
  return !!date && isValid(date instanceof Date ? date : new Date(date));
}

export function getTimezoneOffset(date: Date) {
  return date.getTimezoneOffset() * 60000;
}

// export function convertDateToISOString(date?: Date, ISOFormat = true) {
//   if (!date || !isDateValid(date)) return null;
//
//   const dateString = new Date(
//     date.getTime() - getTimezoneOffset(date),
//   ).toISOString();
//
//   return ISOFormat ? dateString : dateString.slice(0, 10);
// }

export const convertISOStringToDate = (date: string | null) => {
  if (!date || !isDateValid(date)) return null;

  const outputDate = new Date(date);
  return new Date(outputDate.getTime() + getTimezoneOffset(outputDate));
};
