import { useEffect } from 'react';
import gtm from '../../../../../utils/initialGTM';
import { AudienceHubOptions } from '../../../audienceHub/store/features/options';
import { BrandHubOptions } from '../../../brandHub/store/features/options';
import { useRootSelector } from '../../../../../store/hooks';
import { siteTypesEnum } from '../../../../../helpers';
import { selectPageConfig } from '../../../../../store/features/pageConfig';

export function useSetProductCodeDataToDataLayer(
  options: AudienceHubOptions | BrandHubOptions,
) {
  const { BRAND_HUB, AUDIENCE_HUB } = siteTypesEnum();
  const { siteId, sitePath, siteType, tenantConfig } =
    useRootSelector(selectPageConfig);

  const buildType = () => {
    if (siteType === BRAND_HUB) return 'brand';
    if (siteType === AUDIENCE_HUB) return 'audience';
  };

  const buildPath = () => {
    if (siteType === BRAND_HUB) return '';
    if (siteType === AUDIENCE_HUB) return sitePath;
  };

  useEffect(() => {
    gtm.pushHubProductCodeData({
      ...options,
      id: siteId,
      domain: tenantConfig?.domain,
      path: buildPath(),
      type: buildType(),
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
}
