import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
// @ts-ignore
import { DFPSlotsProvider, AdSlot } from 'react-dfp';
import { getClientConfig } from '../../../../helpers';
import styles from './index.module.scss';

interface AdvertisingProps extends HTMLAttributes<HTMLDivElement> {
  advSlots: string[];
  advSize: string;
  networkId: string;
  adUnit: string;
}

export default function Advertising(props: AdvertisingProps) {
  const { advSlots, advSize, networkId, adUnit, className, ...elementProps } =
    props;
  const { advertising } = getClientConfig();
  const { sizeMapping, sizes } = advertising[advSize];

  return (
    <div {...elementProps} className={cn(styles.wrapper, className)}>
      <DFPSlotsProvider
        dfpNetworkId={networkId}
        sizeMapping={sizeMapping}
        collapseEmptyDivs
      >
        {advSlots.map((slotId, i) => (
          <div key={i} data-testid={`advertising-slot-${slotId}-${i}`}>
            <AdSlot
              adUnit={adUnit}
              sizes={sizes}
              sizeMapping={sizeMapping}
              slotId={slotId}
              targetingArguments={{ pos: slotId }}
            />
          </div>
        ))}
      </DFPSlotsProvider>
    </div>
  );
}
