import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import { renderHTMLString } from '../../../../../../../helpers';
import {
  getLimitedCourseOptions,
  getVenueData,
} from '../../../../../../../store/siteDucks/siteHeader';
import { devices } from '../../../../../helpers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
  line-height: 21px;
  height: 100%;
  text-align: ${({ theme }) =>
    theme.globalVariables.isRTL ? 'left' : 'right'};
  color: ${({ brandColor }) => brandColor};
  @media only screen and (max-width: ${devices.breakpoints.desktop}) {
    font-size: 14px;
    line-height: 19px;
  }
  @media only screen and (max-width: ${devices.breakpoints.tablet}) {
    display: none;
  }
`;

const Date = styled.span`
  font-weight: bold;
`;

export default function CourseVenue() {
  const { deliveryType, shortInfo } = useSelector(getVenueData);
  const limitedUniqueCourseOptions = useSelector(getLimitedCourseOptions);
  const isClassRoomCourse = deliveryType === 'CLASSROOM';
  const themeConfig = useContext(ThemeContext);

  return (
    <Wrapper
      brandColor={themeConfig?.brandColors?.primary}
      data-testid="header-course-venue"
    >
      {limitedUniqueCourseOptions.map((option, index) => {
        const { venue: { shownInHeader, venueName } = {}, dateAsText } = option;
        const showOrWord =
          index !== 0 &&
          dateAsText &&
          limitedUniqueCourseOptions[index - 1].dateAsText;

        return (
          <div key={index} data-testid="header-course-venue-item">
            {!isClassRoomCourse && showOrWord && <span>{'or '}</span>}
            <Date>{renderHTMLString(dateAsText)}</Date>
            {isClassRoomCourse && shownInHeader && venueName && (
              <span>
                {dateAsText && ', '}
                {venueName}
              </span>
            )}
          </div>
        );
      })}
      {shortInfo && <div>{renderHTMLString(shortInfo)}</div>}
    </Wrapper>
  );
}
