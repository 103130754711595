import { FacetType } from '../../../../../../types';
import { DeliveryType } from '../../../../../../constants';

export const headingMap = {
  [FacetType.CONTENT_TYPE]: 'Products',
  [FacetType.EVENT_TYPES]: 'Attendance',
  [FacetType.COURSE_TYPES]: 'Attendance',
  [FacetType.AUDIENCE_HOME]: 'Topic Homes',
  [FacetType.SUB_BRANDS]: 'Brands',
  [FacetType.SECTORS]: 'Sectors',
  [FacetType.TOPICS]: 'Topics',
  [FacetType.DATES]: 'Dates',
  [FacetType.LOCATIONS]: 'Location',
  [FacetType.PUBLISHED_DATES]: 'Publish Date',
  [FacetType.AWARD_GROUPS]: 'Award Group',
  [FacetType.INFORMATION_TYPES]: 'Qualification',
  [FacetType.PARTNERS]: 'Delivery Partners',
  [FacetType.CREDIT_PARTNERS]: 'Credit Partners',
  [FacetType.LANGUAGES]: 'Languages',
  [FacetType.ACCESS_TYPES]: 'Access Types',
  [FacetType.CREATED_TIME_FRAME]: 'Created Time',
};

export const maxFiltersToShowCountMap = {
  [FacetType.CONTENT_TYPE]: 5,
  [FacetType.EVENT_TYPES]: 5,
  [FacetType.COURSE_TYPES]: 5,
  [FacetType.AUDIENCE_HOME]: 5,
  [FacetType.SUB_BRANDS]: 5,
  [FacetType.SECTORS]: 10,
  [FacetType.TOPICS]: 10,
  [FacetType.DATES]: 3,
  [FacetType.LOCATIONS]: 5,
  [FacetType.PUBLISHED_DATES]: 5,
  [FacetType.AWARD_GROUPS]: 5,
  [FacetType.INFORMATION_TYPES]: 5,
  [FacetType.PARTNERS]: 5,
  [FacetType.CREDIT_PARTNERS]: 5,
  [FacetType.LANGUAGES]: 5,
  [FacetType.ACCESS_TYPES]: 5,
  [FacetType.CREATED_TIME_FRAME]: 5,
};

export const facetTypeDefaultOrder = [
  FacetType.CONTENT_TYPE,
  FacetType.EVENT_TYPES,
  FacetType.COURSE_TYPES,
  FacetType.SUB_BRANDS,
  FacetType.SECTORS,
  FacetType.TOPICS,
  FacetType.DATES,
  FacetType.LOCATIONS,
  FacetType.PUBLISHED_DATES,
  FacetType.AWARD_GROUPS,
  FacetType.INFORMATION_TYPES,
  FacetType.PARTNERS,
  FacetType.CREDIT_PARTNERS,
  FacetType.LANGUAGES,
  FacetType.ACCESS_TYPES,
  FacetType.CREATED_TIME_FRAME,
];

export const facetTypeAcademyOrder = [
  FacetType.SECTORS,
  FacetType.DATES,
  FacetType.LOCATIONS,
  FacetType.LANGUAGES,
  FacetType.COURSE_TYPES,
  FacetType.SUB_BRANDS,
  FacetType.PARTNERS,
  FacetType.CONTENT_TYPE,
  FacetType.EVENT_TYPES,
  FacetType.TOPICS,
  FacetType.PUBLISHED_DATES,
  FacetType.AWARD_GROUPS,
  FacetType.INFORMATION_TYPES,
  FacetType.CREDIT_PARTNERS,
  FacetType.CREATED_TIME_FRAME,
  FacetType.ACCESS_TYPES,
];

export const facetTypeOrderByBrandUrlSuffix = {
  academy: facetTypeAcademyOrder,
} as {
  [name: string]: string[];
};

export const deliveryTypeOrder = [
  DeliveryType.PHYSICAL,
  DeliveryType.CLASSROOM,
  DeliveryType.IN_PERSON,
  DeliveryType.VIRTUAL,
  DeliveryType.ONLINE,
  DeliveryType.LIVE_ONLINE,
  DeliveryType.DISTANCE,
  DeliveryType.ONLINE_PROGRAMME,
  DeliveryType.ON_DEMAND,
  DeliveryType.FLEXI,
];

export const awardGroupOrder = ['Discover', 'Develop', 'Distinguish'];

export const qualificationOrder = [
  'Practical Training Course',
  'Award',
  'Certificate',
  'Advanced Certificate',
  'Diploma',
  'Degree',
  'Postgraduate Certificate',
  'Masters',
  'Doctorate',
];

export const zeroCountFacetsHiddenList = [
  FacetType.SECTORS,
  FacetType.TOPICS,
  FacetType.DATES,
  FacetType.LOCATIONS,
];
