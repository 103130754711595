import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import {
  isFunction,
  isUndefined,
  transformBooleanValuesToString,
} from '../../helpers';
import get from 'lodash/get';
import {
  BOOKING_CLOSED,
  fetchBookingClosedMessageAction,
} from '../siteDucks/bookingClosed';

const UPDATE = 'DELEGATE_DETAILS_UPDATE';
const SUCCESS = 'DELEGATE_DETAILS_SUCCESS';
const FAILURE = 'DELEGATE_DETAILS_FAILURE';

const initialState = {
  loaded: false,
  data: {},
  meta: {},
};

export default function delegateDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE:
      return Object.assign(
        {},
        {
          loaded: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          statusCode: 200,
          booking: action.data.statusCode !== 423,
          ...action.data.json,
        },
      );

    case BOOKING_CLOSED:
      return Object.assign(
        {},
        {
          loaded: true,
          ...action.data.json,
          statusCode: 423,
        },
      );

    case FAILURE:
      return Object.assign({}, state, {
        error: true,
        statusCode: action.data.statusCode,
        ...action.data.json,
      });

    default:
      return state;
  }
}

export function fetchDelegateDetailsSuccess(data) {
  return { type: SUCCESS, data };
}

export function updateDelegateDetailsSuccess(data) {
  return { type: UPDATE, data };
}

export function delegateDetailsFailure(data) {
  return { type: FAILURE, data };
}

export function fetchDelegateDetailsAction(
  basketId,
  packageId,
  delegateId,
  country,
) {
  return function (dispatch, getState, { Api, i18n }) {
    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'GET',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .delegateDetails({
        siteId,
        basketId,
        packageId,
        delegateId,
        country,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchDelegateDetailsSuccess(response));
        } else if (response.statusCode === 423) {
          return dispatch(fetchBookingClosedMessageAction());
        } else {
          return dispatch(delegateDetailsFailure(response));
        }
      });
  };
}

export function updateDelegateDetailsAction(
  basketId,
  packageId,
  delegateId,
  bodyParams,
  callback,
) {
  return function (dispatch, getState, { Api, i18n }) {
    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'PUT',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
        body: JSON.stringify(bodyParams),
      })
      .delegateDetails({ siteId, basketId, packageId, delegateId })
      .then((response) => {
        if (isFunction(callback)) {
          const data = {
            statusCode: response.statusCode,
            ...response.json,
          };

          callback(data);
        }

        if (response.statusCode === 200) {
          return dispatch(updateDelegateDetailsSuccess(response));
        } else {
          return dispatch(delegateDetailsFailure(response));
        }
      });
  };
}

export const getTransformedRegions = createSelector(
  (state) => state.delegateDetails.meta?.regions,
  (regions = []) => {
    const transformedRegions = {};
    regions.forEach((region) => {
      transformedRegions[region.id] = region.options;
    });

    return transformedRegions;
  },
);

export const getTransformedHonorific = createSelector(
  (state) => state.delegateDetails.meta?.honorific,
  (honorific = []) =>
    honorific.map((el) => ({
      value: el,
      label: el,
    })),
);

export const getFormDefaultValues = createSelector(
  [
    (state) => state.delegateDetails.data,
    (state) => state.delegateDetails.meta,
    (state) => getAdHocDefaultValues(state),
    (state) => getPermissionsDefaultValues(state),
    (state, props) => props.adHocPrefix,
    (state, props) => props.marketingTermsAcceptancePrefix,
    (state, props) => props.marketingPermissionsPrefix,
  ],
  (
    { id, ...values },
    { marketingTermsAccepted, needToShowThisTicketFor },
    adHocDefaultValues,
    permissionsDefaultValues,
    adHocPrefix,
    marketingTermsAcceptancePrefix,
    marketingPermissionsPrefix,
  ) => {
    const defaultValues = cloneDeep(values);

    /**
     * If there is no `defaultValues.delegateTermAcceptance` object then:
     *  - if `marketingTermsAccepted` equals to `true` (it means that "Myself" has been chosen)
     *    then set `purchasedFor` to `false` (set this ticket for "Another delegate" by default).
     *    "Myself" radio button is disabled in that case.
     */
    if (!defaultValues[marketingTermsAcceptancePrefix]) {
      defaultValues[marketingTermsAcceptancePrefix] = {
        purchasedFor: marketingTermsAccepted ? false : undefined,
      };
    }

    /**
     * Set `purchaseFor` to `true` ("Myself") in case if radio buttons have to be hidden
     */
    const purchaseFor =
      defaultValues[marketingTermsAcceptancePrefix].purchasedFor;
    if (isUndefined(purchaseFor) && !needToShowThisTicketFor) {
      defaultValues[marketingTermsAcceptancePrefix].purchasedFor = true;
    }

    /**
     * Radio buttons support only string values, so transform booleans to strings
     */
    [
      'statusCDR',
      'statusCFP',
      'statusInvestmentNumber',
      `${marketingTermsAcceptancePrefix}.purchasedFor`,
    ].forEach((key) => {
      const value = get(defaultValues, key);
      const newValue = transformBooleanValuesToString(value, true);

      if (value !== newValue) {
        set(defaultValues, key, newValue);
      }
    });

    return {
      ...defaultValues,
      [adHocPrefix]: adHocDefaultValues,
      [marketingPermissionsPrefix]: permissionsDefaultValues,
    };
  },
);

export const getPermissionsDefaultValues = createSelector(
  (state) => state.delegateDetails.meta?.marketingPermissions,
  (marketingPermissions) => {
    const { consentForms = [] } = marketingPermissions || {};
    const defaultValues = {};

    consentForms.forEach((consentForm) => {
      defaultValues[consentForm.id] = {};
      consentForm.checkboxes.forEach((checkbox) => {
        defaultValues[consentForm.id][checkbox.name] = checkbox.checked;
      });
    });

    return defaultValues;
  },
);

export const getAdHocQuestions = createSelector(
  (state) => state.delegateDetails.meta?.adHoc,
  (adHoc = {}) => {
    const data = cloneDeep(adHoc);

    if (typeof data.sets === 'undefined') {
      data.sets = [];
    }

    data.sets.forEach((adHocSet) => {
      adHocSet.formInputs.forEach((field) => {
        if (field.type === 'CHECKBOX_GROUP') {
          field.name = field.name.replace(/\[]$/, '');

          // Field name special characters
          field.options.forEach((option) => {
            option.value = option.value
              .replace(/\./g, '__dot__')
              .replace(/,/g, '__cma__');
          });
        }
      });
    });

    return data;
  },
);

export const getAdHocDefaultValues = createSelector(
  (state) => getAdHocQuestions(state),
  (adHoc) => {
    const defaultValues = {};

    adHoc.sets.forEach((adHocSet) => {
      adHocSet.formInputs.forEach(({ type, name, value, options = [] }) => {
        if (type === 'CHECKBOX_GROUP') {
          const values = {};

          options.forEach(({ value, checked, freeText }) => {
            if (freeText) {
              values.__other__ = value;
              values.__checkbox__ = !!checked;
            } else {
              values[value] = !!checked;
            }
          });

          set(defaultValues, name, values);
        } else if (type === 'RADIO') {
          const freeTextOption = options.find((option) => option.freeText);

          if (freeTextOption?.value) {
            const innerValue = freeTextOption.value;
            set(defaultValues, `${name}.__other__`, innerValue);
            set(defaultValues, `${name}.__radio__`, '__checked__');
          } else {
            set(defaultValues, `${name}.__radio__`, value);
          }
        } else if (['FILE', 'FILE_GROUP'].includes(type)) {
          const values = options.map(({ path, size }) => ({ path, size }));
          set(defaultValues, name, values);
        } else {
          set(defaultValues, name, value);
        }
      });
    });

    return defaultValues;
  },
);
