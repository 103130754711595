import React, { HTMLAttributes } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { PropsWithTestId } from '../../../../../../types';
import cn from 'classnames';
import styles from './Select.module.scss';

export type SelectOption = {
  value: string | number;
  label: string;
};

export interface SelectProps extends HTMLAttributes<HTMLSelectElement> {
  blankOption?: string;
  name: string;
  validation?: RegisterOptions;
  options: SelectOption[];
}

export function Select(props: PropsWithTestId<SelectProps>) {
  const {
    name,
    validation,
    options,
    blankOption,
    className,
    testId,
    ...selectProps
  } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const wrapperClassName = cn(styles.wrapper, className, {
    [styles.error]: !!errors[name],
  });

  return (
    <div className={wrapperClassName}>
      <i className="material-icons">keyboard_arrow_down</i>
      <select
        data-testid={testId}
        {...selectProps}
        {...register(name, validation)}
      >
        {blankOption && (
          <option value="" disabled>
            {blankOption}
          </option>
        )}
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <ErrorMessage
        name={name}
        render={({ message }) => (
          <span className={styles.errorMessage}>{message}</span>
        )}
      />
    </div>
  );
}
