import { createSelector } from '@reduxjs/toolkit';
import { SiteLocales, UniqueIdentifier } from '../../../types';

interface SubBrand {
  brandName: string;
  brandUrlSuffix: string;
}

interface HotelMap {
  displayHotelsOnPostPurchasePage: boolean;
  hotelMapMCode: string;
}

interface AudienceHome {
  id: UniqueIdentifier;
  name: string;
  sitePath: string;
  brandUrlSuffix: string;
}

export interface InGoConfig {
  confirmationWidgetId: string;
  enabled: boolean;
  eventId: string;
  loginWidgetId: string;
  registrationWidgetId: string;
  socialWidgetId: string;
}

interface SiteOptionsLocales {
  primary: SiteLocales;
  secondary: Array<{
    locale: SiteLocales;
    live: boolean;
  }>;
}

interface SiteOptions {
  subBrands: SubBrand[];
  audienceHomes: AudienceHome[];
  networkId: UniqueIdentifier;
  advertisingEnabled: boolean;
  newPaymentJourneyEnabled: boolean;
  adUnit: string;
  deliveryType: string;
  cveDelegateSignupInfo: boolean;
  hotelMapView: HotelMap;
  inGoConfig: InGoConfig;
  locales: SiteOptionsLocales;
  productCode: string;
}

export const selectOptions = createSelector(
  [(state) => state.options.data as SiteOptions],
  (data) => data,
);
