const parsePurchasedProductsForGTM = (orderReceipt) => {
  if (!orderReceipt) {
    return null;
  }

  return orderReceipt.ticketPackages.flatMap((ticketPackage, index) => {
    return ticketPackage.packageSelection.map((ticketItem) => {
      return {
        name: ticketItem.productName,
        price: orderReceipt.totalPriceIncVat,
        variant: ticketItem.optionNames,
        quantity: orderReceipt.ticketPackages[index].delegates.length,
        brand: ticketItem.productName,
      };
    });
  });
};

export default parsePurchasedProductsForGTM;
