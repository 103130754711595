import { createContext, useContext, PropsWithChildren } from 'react';

export type HubContextType = {
  indexPath: string;
  brandPath: string;
  brandName: string;
  audiencePath?: string;
  showSponsorLogos?: boolean;
  theme?: string;
};

const HubContext = createContext<HubContextType>({} as HubContextType);

export const HubContextProvider = (
  props: PropsWithChildren<Omit<HubContextType, 'indexPath'>>,
) => {
  const { children, ...restProps } = props;
  const { brandPath, audiencePath } = restProps;
  const indexPath = [brandPath, audiencePath].filter(Boolean).join('/');

  return (
    <HubContext.Provider value={{ ...restProps, indexPath }}>
      {children}
    </HubContext.Provider>
  );
};

export function useHubContext() {
  return useContext<HubContextType>(HubContext);
}
