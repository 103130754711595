import React from 'react';
import cn from 'classnames';
import styles from './FormHeader.module.scss';
import { Button } from '../../../Button';
import { FloatingEmailOptInStates } from '../../../FloatingEmailOptInModule';

export interface FormHeaderProps {
  emailOptInModuleState?: FloatingEmailOptInStates;
  title: string;
  onActive: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onCollapse: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onClose: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export function FormHeader({
  title,
  emailOptInModuleState,
  onActive,
  onCollapse,
  onClose,
}: FormHeaderProps) {
  const collapsed =
    emailOptInModuleState === FloatingEmailOptInStates.collapsed;
  const closed = emailOptInModuleState === FloatingEmailOptInStates.closed;
  const isCollapsedOrClosed = collapsed || closed;
  const leftSideClassName = cn(styles.leftSide, {
    [styles.opacity]: collapsed,
  });

  const rightSideClassName = cn(styles.rightSide, {
    [styles.opacity]: collapsed,
  });

  const buttonClassName = cn({
    'hidden-xs hidden-sm': !collapsed,
  });

  const renderButton = () => {
    return (
      <Button
        to=""
        size="large"
        type="button"
        onClick={collapsed ? onClose : onCollapse}
        endIcon={collapsed ? 'close' : 'expand_more'}
        variant={isCollapsedOrClosed ? 'text' : 'outlined'}
        color={isCollapsedOrClosed ? 'white' : undefined}
        data-testid="email-opt-in-toggle-button"
        className={buttonClassName}
      >
        {isCollapsedOrClosed ? 'Close' : 'Hide'}
      </Button>
    );
  };

  return (
    <div className={styles.container}>
      <div className={leftSideClassName}>
        {collapsed && (
          <>
            <h2
              data-testid="email-opt-in-collapse"
              className={cn('hidden-xs hidden-sm', styles.title)}
            >
              {title}
            </h2>
            <Button
              to=""
              size="large"
              onClick={onActive}
              className={styles.submitButton}
              data-testid="email-opt-in-subscribe-button"
            >
              Subscribe
            </Button>
          </>
        )}
      </div>
      <div className={rightSideClassName}>{renderButton()}</div>
    </div>
  );
}
