import { useEffect, useState } from 'react';
import getPOneUserEvents from '../helpers/getPOneUserEvents';
import Auth from '../services/AuthService';
import { isBrowser } from '../helpers';

export default function usePOneAccess(ctaConfigType, partneringOneEventId) {
  const [isPOneAccess, setIsPOneAccess] = useState(false);

  useEffect(() => {
    if (ctaConfigType === 'PARTNERING_ONE' && isBrowser()) {
      getPOneUserEvents().then((p1UserEvents) => {
        if (Auth.isLoggedIn() && p1UserEvents.includes(partneringOneEventId)) {
          setIsPOneAccess(true);
        }
      });
    }
  }, [ctaConfigType, partneringOneEventId]);

  return isPOneAccess;
}
