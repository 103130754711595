import { PackageFormValues } from '../../../store/features/packages/types';
import { UniqueIdentifier } from '../../../../../types';
import { getSingleProductIdsList } from '../getPackageFormDefaultValues';

export const getProductIdsFromPackageFormValues = (
  values: PackageFormValues,
  excludeProductIds: UniqueIdentifier[] = [],
) => {
  const { productSingleIdsMap, productMultipleIds } = values;
  const singleProductIds = getSingleProductIdsList(productSingleIdsMap);

  return [...productMultipleIds, ...singleProductIds].filter(
    (id) => !excludeProductIds.includes(id),
  );
};
