import React from 'react';
import styled from 'styled-components';

import { color } from '../../styles/informaconnect/colours/colours';

const AlertIcons = {
  success: <i className="fa fa-check" aria-hidden="true" />,
  error: <i className="fa fa-close" aria-hidden="true" />,
  warning: <i className="fa fa-exclamation" aria-hidden="true" />,
};

const Icon = styled.div`
  padding: 4px;
  margin: 7px 18px 0 0;
  border: 2px solid;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  float: left;

  &.success {
    color: ${color.successGreen};

    & i {
      color: ${color.successGreen};
      padding-top: 7px;
      padding-left: 6px;
    }
  }
  &.error,
  &.error i {
    color: red;

    & i {
      color: red;
      padding-top: 6px;
      padding-left: 7px;
    }
  }
  &.warning,
  &.warning i {
    color: orange;

    & i {
      color: orange;
      padding-top: 7px;
      padding-left: 11px;
    }
  }
`;

const AlertIcon = ({ alertType }) => {
  return <Icon className={alertType}>{AlertIcons[alertType]}</Icon>;
};

export default AlertIcon;
