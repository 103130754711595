import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LeadGenCheckbox from '../LeadGenCheckbox/LeadGenCheckbox';

import './LeadGenCheckboxInterest.scss';

export default class LeadGenCheckboxInterest extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    color: PropTypes.string,
    isCheckboxGroup: PropTypes.bool,
    meta: PropTypes.object,
    isRequired: PropTypes.bool,
  };

  generateCheckboxGroup(input, color, isCheckboxGroup, isError, options = []) {
    const moreThanThree = options.length > 3;

    return options.map(({ label, value }, index) => {
      const isChecked = input.value.includes(value);
      return (
        <div
          className={`lead-gen-interest-checkbox__item ${
            moreThanThree ? 'lead-gen-interest-checkbox__item--two-cols' : ''
          }`}
          key={value}
          data-test-lead-gen="lead-gen-interest-checkbox-item"
        >
          <LeadGenCheckbox
            value={value}
            isCheckboxGroup={isCheckboxGroup}
            label={label}
            index={index}
            input={input}
            isChecked={isChecked}
            color={color}
            isError={isError}
            labelSize={moreThanThree ? 'small' : null}
          />
        </div>
      );
    });
  }

  renderTitles(label, isError, isRequired, meta) {
    const subLabels = label && label.split('. ');

    if (subLabels[0] && subLabels[1]) {
      return (
        <>
          <div
            className="lead-gen-interest-checkbox__title"
            data-test-lead-gen="lead-gen-interest-checkbox-title"
          >{`${subLabels[0]}.`}</div>
          <div
            className="lead-gen-interest-checkbox__subtitle"
            data-test-lead-gen="lead-gen-interest-checkbox-subtitle"
          >
            {`${subLabels[1]}${isRequired ? ' *:' : ':'}`}
          </div>
          {isError && (
            <div className="lead-gen-interest-checkbox__error">
              {meta.error}
            </div>
          )}
        </>
      );
    } else if (label) {
      return (
        <>
          <div className="lead-gen-interest-checkbox__title">
            {label}
            {isRequired ? ' *' : ''}
          </div>
          {isError && (
            <div className="lead-gen-interest-checkbox__error">
              {meta.error}
            </div>
          )}
        </>
      );
    }

    return null;
  }

  render() {
    const {
      meta = {},
      isRequired,
      input = {},
      options = [],
      color,
      label = '',
      isCheckboxGroup,
    } = this.props;
    const isError = meta.error && meta.touched;

    return (
      <div
        className="lead-gen-interest-checkbox"
        data-test-lead-gen="lead-gen-interest-checkbox"
      >
        <div className="lead-gen-interest-checkbox__header">
          {this.renderTitles(label, isError, isRequired, meta)}
        </div>
        <div className="lead-gen-interest-checkbox__group">
          {this.generateCheckboxGroup(
            input,
            color,
            isCheckboxGroup,
            isError,
            options,
          )}
        </div>
      </div>
    );
  }
}
