import { ReactNode } from 'react';
import cn from 'classnames';
import { Loader } from '../Loader';
import styles from './ProductModule.module.scss';
import { HubProductFilterPosition } from '../../../../../../constants';

export interface ProductModuleProps {
  heading?: ReactNode;
  leftFilter?: ReactNode;
  showLeftFilter?: boolean;
  topFilter?: ReactNode;
  showTopFilter?: boolean;
  keywordSearch?: ReactNode;
  keywordSearchLeftToolbar?: ReactNode;
  keywordSearchRightToolbar?: ReactNode;
  loading?: boolean;
  showMoreButton?: ReactNode;
  brandLogo?: ReactNode;
  children: ReactNode;
}

export function ProductModule(props: ProductModuleProps) {
  const {
    heading,
    leftFilter,
    showLeftFilter,
    topFilter,
    showTopFilter,
    keywordSearch,
    keywordSearchLeftToolbar,
    keywordSearchRightToolbar,
    loading,
    children,
    showMoreButton,
    brandLogo,
  } = props;
  const contentGridClassName = cn('col-xs-12', {
    'col-md-9': showLeftFilter && leftFilter,
  });
  const showLeftFilterBar = showLeftFilter && leftFilter;
  const showTopFilterBar = showTopFilter && topFilter;
  const showHeading = heading || showTopFilterBar;

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className="row">
          {showLeftFilterBar && (
            <div className="col-xs-12 col-md-3">
              <div className={styles.filterWrapper}>{leftFilter}</div>
            </div>
          )}
          <div
            className={contentGridClassName}
            data-testid="product-module-content"
          >
            {showHeading && (
              <div className={styles.headingWrapper}>
                <div className={styles.leftHeading}>{heading}</div>
                <div className={styles.rightHeading}>
                  {showTopFilterBar && (
                    <div className={styles.topFilterWrapper}>
                      <div className={styles.topFilter}>{topFilter}</div>
                      {showMoreButton && (
                        <div className={styles.showMoreButton}>
                          {showMoreButton}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {keywordSearch && (
              <div className={styles.keywordSearchWrapper}>
                {keywordSearch}
                {(keywordSearchLeftToolbar || keywordSearchRightToolbar) && (
                  <div className={styles.keywordSearchToolbar}>
                    <div>{keywordSearchLeftToolbar}</div>
                    <div>{keywordSearchRightToolbar}</div>
                  </div>
                )}
              </div>
            )}
            <div className={styles.body}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  {children}
                  {showMoreButton && !showTopFilter && (
                    <div className="row">
                      <div className="col-xs-12">
                        <div className={styles.bottomShowMoreButton}>
                          {showMoreButton}
                        </div>
                      </div>
                    </div>
                  )}
                  {brandLogo && (
                    <div className={styles.brandLogo}>{brandLogo}</div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductModule.defaultProps = {
  showLeftFilter: true,
  filterPosition: HubProductFilterPosition.LEFT,
};
