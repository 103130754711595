import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { isBrowser } from '../../helpers';

export function useDeviceDetect() {
  const [screen, setScreen] = useState(() => {
    if (isBrowser()) {
      return {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }

    return { width: 0, height: 0 };
  });

  useEffect(() => {
    if (!isBrowser()) return;

    const handleResize = debounce(() => {
      setScreen({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 100);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isMobile: screen.width < 768,
    isTablet: screen.width >= 768 && screen.width < 1024,
    isDesktop: screen.width >= 1024,
  } as const;
}
