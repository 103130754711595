import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';

export const devices = {
  breakpoints: {
    tablet: '768px',
    laptop: '1024px',
    desktop: '1200px',
  },
};

export const getStyledPropertyValue = (themeConfig, propertyValue = '') => {
  let value = propertyValue;
  const matchedThemeEntries = value.match(/\[[a-zA-Z0-9.]+]/g);

  if (matchedThemeEntries) {
    matchedThemeEntries.forEach((themeEntry) => {
      value = value.replace(
        themeEntry,
        get(themeConfig, themeEntry.replace(/^\[|]$/g, '')) || '',
      );
    });
  }

  return value;
};

const changeAlignFromLeftToRight = (renderConfig) => {
  let styles = renderConfig;

  if (styles?.textAlign === 'left') {
    // need to clone style object to avoid mismatching between server and client
    styles = Object.assign({}, renderConfig);
    styles.textAlign = 'right';
  }

  return styles;
};

const renderObject = (themeConfig, renderConfig = {}) => {
  const isRtlDirection = themeConfig?.globalVariables?.isRTL;
  const stylesConfig =
    isRtlDirection && !renderConfig.skipAlignLeftReplacement
      ? changeAlignFromLeftToRight(renderConfig)
      : renderConfig;

  return Object.keys(stylesConfig)
    .filter((property) => typeof stylesConfig[property] === 'string')
    .map((propertyName) => {
      // Skip inner styles of the object on rtl direction
      if (isRtlDirection && stylesConfig.skipCurrentStylesOnRtl) {
        return null;
      }

      const propertyValue = getStyledPropertyValue(
        themeConfig,
        stylesConfig[propertyName],
      );

      return `${kebabCase(propertyName)}: ${
        propertyValue === '' ? "''" : propertyValue
      };`;
    })
    .join(' ');
};

const renderProperties = (
  themeConfig,
  renderConfig = {},
  breakpoint = 0,
  down = false,
) => {
  const mainStyles = renderObject(themeConfig, renderConfig);
  const otherStyles = ['hover', 'before', 'after', 'rtl']
    .map((name) => {
      if (!renderConfig[name]) {
        return null;
      }

      if (themeConfig?.globalVariables?.isRTL) {
        if (name === 'rtl') {
          return `${renderObject(themeConfig, renderConfig[name])}`;
        }

        if (renderConfig[name]?.rtl) {
          return `&:${name} { ${renderObject(
            themeConfig,
            renderConfig[name],
          )} ${renderObject(themeConfig, renderConfig[name].rtl)} }`;
        }
      }

      return `&:${name} { ${renderObject(themeConfig, renderConfig[name])} }`;
    })
    .filter(Boolean)
    .join(' ');

  const styles = [mainStyles, otherStyles].filter(Boolean).join(' ');

  if (breakpoint && styles) {
    if (down) {
      const width = parseInt(breakpoint) - 1;
      return `@media only screen and (max-width: ${width}px) { ${styles} }`;
    }

    return `@media only screen and (min-width: ${breakpoint}) { ${styles} }`;
  }

  return styles;
};

export const renderStyledElementStyles = (themeConfig, renderConfig = {}) => {
  const mobileStyles = renderProperties(themeConfig, renderConfig);
  const mobileOnlyStyles = renderProperties(
    themeConfig,
    renderConfig.mobileOnly,
    devices.breakpoints.tablet,
    true,
  );
  const tabletStyles = renderProperties(
    themeConfig,
    renderConfig.tablet,
    devices.breakpoints.tablet,
  );
  const laptopStyles = renderProperties(
    themeConfig,
    renderConfig.laptop,
    devices.breakpoints.laptop,
  );
  const desktopStyles = renderProperties(
    themeConfig,
    renderConfig.desktop,
    devices.breakpoints.desktop,
  );

  return [
    mobileStyles,
    mobileOnlyStyles,
    tabletStyles,
    laptopStyles,
    desktopStyles,
  ]
    .filter(Boolean)
    .join(' ');
};
