export function splitListIntoChunks(list = [], size = 3) {
  const chunks = [];
  const newList = Object.assign([], list);

  while (newList.length > 0) {
    chunks.push(newList.splice(0, size));
  }

  return chunks;
}
