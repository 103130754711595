import React, { PropsWithChildren, useCallback, useState } from 'react';
import cn from 'classnames';
import { ToggleFilterButton, ResetFilterButton } from '../../filters';
import styles from './ProductFilter.module.scss';

export interface ProductFilterProps {
  heading?: React.ReactNode;
  onResetFilter?: () => void;
  disableResetFilter?: boolean;
}

export function ProductFilter(props: PropsWithChildren<ProductFilterProps>) {
  const { heading, onResetFilter, disableResetFilter, children } = props;

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const toggleFilterHandler = useCallback(() => {
    setShowFilter((show) => !show);
  }, []);

  const mobileFilterToggle = (
    <ToggleFilterButton
      onClick={toggleFilterHandler}
      textAlign="left"
      iconName="tune"
    >
      {showFilter ? 'Apply Filter' : 'Filter by Sector'}
    </ToggleFilterButton>
  );

  const filterClassName = cn(
    styles.filterWrapper,
    showFilter ? 'visible-xs visible-sm' : 'hidden-xs hidden-sm',
  );

  const mobileFilterToggleClassName = cn(styles.filterToggleWrapper, {
    'hidden-md hidden-lg': true,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingWrapper}>
        {heading && <div className={styles.heading}>{heading}</div>}
        {onResetFilter && (
          <div className={styles.resetFilter}>
            <ResetFilterButton
              onClick={onResetFilter}
              disabled={disableResetFilter}
            >
              Clear All
            </ResetFilterButton>
          </div>
        )}
      </div>
      <div className={mobileFilterToggleClassName}>{mobileFilterToggle}</div>
      <div className={filterClassName}>{children}</div>
      {showFilter && (
        <div className={mobileFilterToggleClassName}>{mobileFilterToggle}</div>
      )}
    </div>
  );
}
