import React from 'react';
import styled from 'styled-components';
import { typography } from '../../../styles/informaconnect/typography/typography';
import { color, brand } from '../../../styles/informaconnect/colours/colours';

export const SignInWithPropositionIC = ({ tenantName, children }) => {
  const FormContainer = styled.div`
    border: 1px solid ${color.formGrey};
    border-radius: 0 0 10px 10px;
    background-color: ${color.lightest};

    .styledButton {
      margin-top: 26px;
    }
  `;

  const BorderedColumn = styled.div`
    border-right: 0;
  `;

  const Columns = styled.div`
    padding: 40px;
  `;

  const FormHeader = styled.h3`
    color: ${brand.darkBlue};
    font-family: ${typography.type.secondary};
    font-size: ${typography.size.l1}px;
    line-height: 48px;
    margin-bottom: 21px;
  `;

  return (
    <FormContainer className="formContainer">
      <Columns>
        <FormHeader>{`Log in to your ${tenantName} account`}</FormHeader>
        <div className="row">
          <BorderedColumn className="col-xs-12 col-sm-12">
            {children}
          </BorderedColumn>
        </div>
      </Columns>
    </FormContainer>
  );
};
