import React from 'react';

export type StreamlyEmbedProps = {
  title: string;
  streamlyUrl: string;
};

export const StreamlyEmbed = ({ title, streamlyUrl }: StreamlyEmbedProps) => (
  <iframe
    src={streamlyUrl}
    title={title}
    frameBorder="0"
    data-testid="streamly-embed"
  />
);
