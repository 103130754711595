import { format, differenceInDays } from 'date-fns';
import { DeliveryType } from '../../../../../constants';
import { DiscoveryDate } from '../../../../../store/features/discovery';
import { convertISOStringToDate } from '../../../../../helpers';

const digitalTypes = [DeliveryType.DISTANCE, DeliveryType.ONLINE_PROGRAMME];
const regularTypes = [
  DeliveryType.PHYSICAL,
  DeliveryType.CLASSROOM,
  DeliveryType.IN_PERSON,
  DeliveryType.VIRTUAL,
  DeliveryType.LIVE_ONLINE,
  DeliveryType.ONLINE,
];

// this custom dates logic based on next task CAAS-6743
export function getSiteCardDate(
  date: DiscoveryDate,
  deliveryTypes: string[],
  timeAsText: string,
) {
  const { start: startDate, end: endDate } = date;
  const siteDates = getFormattedSiteCardDate(startDate, endDate, timeAsText);
  const datesToRender = [timeAsText];

  switch (true) {
    case (deliveryTypes.includes(DeliveryType.ON_DEMAND) ||
      deliveryTypes.includes(DeliveryType.FLEXI)) &&
      siteDates.length:
      break;
    case digitalTypes.some((type) => deliveryTypes.includes(type)):
      datesToRender.unshift(`Starting ${siteDates[0]}`);
      break;
    case regularTypes.some((type) => deliveryTypes.includes(type)):
      datesToRender.unshift(...siteDates);
      break;
    default:
      break;
  }

  return datesToRender.filter(Boolean);
}

export function getFormattedSiteCardDate(
  startDate?: string,
  endDate?: string,
  timeAsText?: string,
) {
  if (!startDate) {
    return [];
  }

  const start = new Date(startDate);
  const end = endDate && new Date(endDate);

  const formattedStartDate = format(
    convertISOStringToDate(startDate) as Date,
    'd MMM, yyyy',
  );
  const durationInDays = end ? differenceInDays(end, start) + 1 : 0;
  const formattedDuration = getDurationText(durationInDays);
  const skipDuration = durationInDays === 1 && timeAsText;

  if (end && formattedDuration && !skipDuration) {
    return [formattedStartDate, formattedDuration];
  }

  return [formattedStartDate];
}

export function getSiteCardDatesInMonthFormat(
  dates: DiscoveryDate[],
  deliveryTypes: string[],
) {
  const prefix = digitalTypes.some((type) => deliveryTypes.includes(type))
    ? '+\u00A0\u00A0Starting in'
    : '+\u00A0\u00A0';

  const datesInMonthFormat = dates.reduce((resultDates, { start }, index) => {
    const formattedStartDate =
      start && format(convertISOStringToDate(start) as Date, 'MMM');
    if (!formattedStartDate) {
      return resultDates;
    }

    if (index === 0) {
      resultDates.push(`${prefix} ${formattedStartDate}`);
    } else if (index < 2) {
      resultDates.push(formattedStartDate);
    }

    return resultDates;
  }, [] as string[]);

  if (dates.length >= 3) {
    datesInMonthFormat.push('more');
  }

  return datesInMonthFormat;
}

export function filterDatesByMonth(dates: DiscoveryDate[]) {
  return dates.filter((date, index, initArray) => {
    const formattedStartDate =
      date?.start &&
      format(convertISOStringToDate(date.start) as Date, 'MM/yyyy');
    const firstIndex = initArray.findIndex((searchDate) => {
      return (
        searchDate?.start &&
        format(new Date(searchDate.start), 'MM/yyyy') === formattedStartDate
      );
    });

    // return true if the current date is the first one with the same month and year
    return firstIndex === index;
  });
}

export function getDurationText(duration?: number) {
  if (!duration || duration < 1) {
    return null;
  }
  const suffix = duration === 1 ? 'day' : 'days';

  return `${duration} ${suffix}`;
}
