import {
  PricingProduct,
  PricingProductType,
} from '../../../store/features/products/types';
import { PricingProductSelectionTypes } from '../../../types/enums';

export function getProductTypeWithProductsById(
  productTypesWithProducts: PricingProductType[],
  productTypeId: PricingProductType['productTypeId'],
  selectionType?: PricingProductSelectionTypes,
) {
  const foundedProductType = productTypesWithProducts.find((productType) => {
    const isProductType = productType.productTypeId === productTypeId;

    if (selectionType) {
      return productType.selectionType === selectionType && isProductType;
    }

    return isProductType;
  });

  return foundedProductType || ({} as PricingProductType);
}

export function getRelatedPrimaryProductsData(
  productTypesWithProducts: PricingProductType[],
  selectedProductTypeId: PricingProductType['productTypeId'],
  selectedProductId: PricingProduct['id'],
) {
  const { products = [], name } = getProductTypeWithProductsById(
    productTypesWithProducts,
    selectedProductTypeId,
    PricingProductSelectionTypes.MULTIPLE_CHOICE,
  );

  return {
    products: products.filter((product) => product.id !== selectedProductId),
    name,
  };
}
