import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Route as OriginalRoute } from 'react-router-dom';
import Redirect from '../Redirect/Redirect';

export function Route(props) {
  const { exact, path, type, render, status = 302, ...rest } = props;
  const { pageConfig: { account: { authorized: isAuthorized } = {} } = {} } =
    rest;

  const isPublic = type === 'public';
  const isPrivate = type === 'private';
  const isStandard = !isPublic && !isPrivate;

  return (
    <OriginalRoute
      {...rest}
      render={(props) => (
        <>
          {isPrivate &&
            (isAuthorized ? (
              render(props)
            ) : (
              <Redirect exact={exact} to="/account/login" status={status} />
            ))}
          {isPublic &&
            (isAuthorized ? (
              <Redirect exact={exact} to="/account/profile" status={status} />
            ) : (
              render(props)
            ))}
          {isStandard && render(props)}
        </>
      )}
    />
  );
}

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(withCookies(Route));
