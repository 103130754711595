export const color = {
  primary: '#001F3B',
  accent: '#C617B3',
  highlightBlue: '#2E2DC4',
  highlightGrey: '#D4E0E5',
  backgroundMidGrey: '#525A5C',
  backgroundGreyBlue: '#3B4755',
  lightest: '#FFFFFF',
  lighter: '#F3F3F3',
  mediumlight: '#E1E1E1',
  medium: '#C5C5C5',
  mediumdark: '#2D2D2D',
  dark: '#000000',
  formGrey: '#CCCCCC',
  successGreen: '#70D77B',
  formBorder: '#DEE0E5',
  formText: '#333333',
  formError: '#C9474D',
};

export const brand = {
  purple: '#CC31C2',
  darkBlue: '#022244',
  darkBlueAlt: '#002244',
};
