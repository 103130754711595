import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './FormBoxInput.scss';

const SINGLE_CHOICE = 'SINGLE_CHOICE';
const MULTIPLE_CHOICE = 'MULTIPLE_CHOICE';

export default function FormBoxInput(props) {
  const {
    type,
    size = 'large',
    name,
    value,
    label,
    description,
    checked,
    disabled,
    onChange,
    noCheckbox = false,
    children,
  } = props;
  const inputType =
    type === SINGLE_CHOICE
      ? 'radio'
      : type === MULTIPLE_CHOICE
      ? 'checkbox'
      : type;

  return (
    <div
      className={`c-form-box-input c-form-box-input--${inputType} c-form-box-input--${size}${
        disabled ? ' c-form-box-input--disabled' : ''
      }${noCheckbox ? ' c-form-box-input--no-checkbox' : ''}`}
    >
      <label className="c-form-box-input__body">
        <input
          type={inputType}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <span
          className={`c-form-box-input__box c-form-box-input__box--${size}`}
        />
        <div className="c-form-box-input__content">
          {children || (
            <Fragment>
              <div className="c-form-box-input__label">{label}</div>
              {description && (
                <div className="c-form-box-input__description">
                  {description}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </label>
    </div>
  );
}

FormBoxInput.propTypes = {
  type: PropTypes.oneOf([
    'radio',
    'checkbox',
    'MULTIPLE_CHOICE',
    'SINGLE_CHOICE',
  ]),
  size: PropTypes.oneOf(['small', 'large']),
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  noCheckbox: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
};
