import React, { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../../../../types';
import styles from './index.module.scss';

export enum CardButtonSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface CardButtonProps
  extends PropsWithChildren<PropsWithTestId<HTMLAttributes<HTMLDivElement>>> {
  size: keyof typeof CardButtonSizes;
}

export const CardButton = (props: CardButtonProps) => {
  const { size, testId, children, className, ...restProps } = props;

  return (
    <div
      {...restProps}
      className={cn(styles.wrapper, className, styles[size])}
      data-testid={testId}
    >
      <div className={styles.label}>{children}</div>
      <div className={styles.iconWrapper}>
        <span className={cn('material-icons', styles.icon)}>east</span>
      </div>
    </div>
  );
};

CardButton.defaultProps = {
  size: CardButtonSizes.medium,
  testId: 'card-button',
};
