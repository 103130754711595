import React, { HTMLAttributes } from 'react';
import { renderHTMLString } from '../../../../../../helpers';
import styles from './Paragraph.module.scss';
import { PropsWithTestId } from '../../../../../../types';
import cn from 'classnames';

export interface ParagraphProps extends HTMLAttributes<HTMLElement> {
  text: string;
}

export function Paragraph({
  text,
  className,
  testId,
}: PropsWithTestId<ParagraphProps>) {
  const paragraphClassName = cn(styles.text, className);

  return (
    <div data-testid={testId} className={paragraphClassName}>
      {renderHTMLString(text)}
    </div>
  );
}

Paragraph.defaultProps = {
  testId: 'hub-module-paragraph',
};
