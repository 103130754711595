import cn from 'classnames';
import s from './Anchor.module.scss';
import {
  Anchor as OriginalAnchor,
  AnchorProps,
} from '../../../../../../shared/Anchor';

export default function Anchor(props: AnchorProps) {
  const { className, children, ...anchorProps } = props;

  return (
    <OriginalAnchor className={cn(s.anchor, className)} {...anchorProps}>
      {children}
    </OriginalAnchor>
  );
}
