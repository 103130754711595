import React from 'react';
import cn from 'classnames';
import styles from './CallToActionGroup.module.scss';
import { HubModuleCTA } from '../../../../types';
import { CallToAction } from './CallToAction';

export * from './CallToAction';

export interface CallToActionGroupProps
  extends React.BaseHTMLAttributes<HTMLDivElement> {
  primary: HubModuleCTA;
  secondary?: HubModuleCTA;
}

export function CallToActionGroup(props: CallToActionGroupProps) {
  const { primary, secondary, className, ...attrs } = props;

  if (!primary.shown && !secondary?.shown) {
    return null;
  }

  return (
    <div className={cn(styles.wrapper, className)} {...attrs}>
      {primary.shown && <CallToAction link={primary} variant="contained" />}
      {secondary?.shown && <CallToAction link={secondary} variant="outlined" />}
    </div>
  );
}
