import React, { HTMLAttributes, MouseEvent } from 'react';
import { intervalToDuration } from 'date-fns';
import { PropsWithTestId } from '../../../../../../types';
import {
  CardAnchor,
  Badge,
  BadgeProps,
  CardButton,
  CardButtonProps,
  Image,
  ImageProps,
  Title,
  TitleProps,
  TopicsList,
} from '../components';
import { ProductCard, ProductListCard } from '../../products';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { TopicsListType } from '../SiteCard';
import {
  DiscoveryFeaturedTypes,
  DiscoveryStreamlyVideo,
  DiscoveryStreamlyVideoAccessType,
} from '../../../../../../store/features/discovery';
import styles from './VideoCard.module.scss';
import {
  isPublicVideo,
  isRegisterVideo,
  isSubscribeVideo,
} from '../../../../../../utils/streamlyVideos';

export enum VideoCardTypes {
  default = 'default',
  list = 'list',
}

export interface VideoCardProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  onOpen: (videoParams: Pick<DiscoveryStreamlyVideo, 'url' | 'title'>) => void;
  data: DiscoveryStreamlyVideo;
  cardType?: keyof typeof VideoCardTypes;
  showFilter?: boolean;
}

const zeroPad = (num = 0) => String(num).padStart(2, '0');

const formatDuration = (duration: number) => {
  const { hours, minutes, seconds } = intervalToDuration({
    start: 0,
    end: duration * 1000,
  });

  return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
};

export const getCTAButtonText = (
  accessType: DiscoveryStreamlyVideoAccessType,
) => {
  if (isPublicVideo(accessType)) return 'Watch Now';
  if (isRegisterVideo(accessType)) return 'Register for Free';
  if (isSubscribeVideo(accessType)) return 'Subscribe';
};

export default function VideoCard(props: PropsWithTestId<VideoCardProps>) {
  const {
    data: {
      title,
      description,
      featuredType,
      thumbnailImageUrl,
      topics = [],
      duration,
      url,
      accessType,
    },
    showFilter,
    cardType,
    onOpen,
    testId,
  } = props;

  const handleToggleVideoPlayer = (event: MouseEvent<HTMLAnchorElement>) => {
    // We are using third-party service for aggregate videos and backend generates accessType for each one video.
    // Sometimes there is no accessType
    if (isPublicVideo(accessType)) {
      event.preventDefault();
      onOpen({
        url,
        title,
      });
    }
  };

  const getImage = (style?: ImageProps['imageStyle']) => (
    <Image imageStyle={style} path={thumbnailImageUrl} />
  );

  const getTitle = (
    size?: TitleProps['size'],
    className?: TitleProps['className'],
  ) => (
    <Title size={size} className={className}>
      {title}
    </Title>
  );

  const getTopicsList = ({ count }: TopicsListType) =>
    topics.length > 0 && (
      <TopicsList list={topics.slice(0, count) as unknown as string[]}>
        {(topic) => (
          <Badge theme="outlined" size="tiny">
            {topic}
          </Badge>
        )}
      </TopicsList>
    );

  const getCardButton = (size?: CardButtonProps['size']) => (
    <CardButton size={size}>{getCTAButtonText(accessType)}</CardButton>
  );

  const getFeaturedBadge = (size?: BadgeProps['size']) =>
    featuredType === DiscoveryFeaturedTypes.LARGE && (
      <Badge size={size} className={styles.bold}>
        Featured
      </Badge>
    );

  const getDescription = (size?: BadgeProps['size']) =>
    description && (
      <Badge
        testId="video-card-description"
        size={size}
        className={styles.description}
      >
        {description}
      </Badge>
    );

  const getDurationBadge = () =>
    !!duration && (
      <Badge className={styles.badge}>
        <>
          <PlayCircleFilledIcon />
          {formatDuration(duration)}
        </>
      </Badge>
    );

  return (
    <CardAnchor
      to={url}
      onClick={handleToggleVideoPlayer}
      openInNewTab
      data-testid={testId}
    >
      {cardType === VideoCardTypes.default && (
        <ProductCard
          image={getImage()}
          topics={getTopicsList({ count: 1 })}
          title={getTitle('extraSmall')}
          description={getDescription()}
          leftFooter={getCardButton()}
          showMoreBadge={getCardButton()}
          imageBadge={getDurationBadge()}
          imageBadgePosition="bottomRight"
        />
      )}
      {cardType === VideoCardTypes.list && (
        <ProductListCard
          image={getImage('list')}
          imageSize={showFilter ? 'medium' : 'small'}
          leftHeader={getTopicsList({ count: 3 })}
          rightHeader={getFeaturedBadge()}
          title={getTitle('small')}
          description={getDescription()}
          showMoreBadge={getCardButton('large')}
          imageBadge={getDurationBadge()}
          imageBadgePosition="bottomRight"
        />
      )}
      <div itemScope itemType="https://schema.org/Video">
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:video" content={url} />
      </div>
    </CardAnchor>
  );
}

VideoCard.defaultProps = {
  cardType: VideoCardTypes.default,
  testId: 'video-card',
};
