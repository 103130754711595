import React, { HTMLAttributes } from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../../../types';
import styles from './Line.module.scss';

export interface LineProps extends HTMLAttributes<HTMLElement> {
  withMargins: boolean;
}

export function Line({
  withMargins,
  className,
  testId,
}: PropsWithTestId<LineProps>) {
  const lineClassName = cn(styles.line, className, {
    [styles.lineMargins]: withMargins,
  });

  return <hr data-testid={testId} className={lineClassName} />;
}

Line.defaultProps = {
  testId: 'hub-module-line',
  withMargins: true,
};
