import { isFunction } from '../../helpers';
import {
  BOOKING_CLOSED,
  fetchBookingClosedMessageAction,
} from '../siteDucks/bookingClosed';

const SUCCESS = 'ORDER_RECEIPT_SUCCESS';
const FAILURE = 'ORDER_RECEIPT_FAILURE';

const initialState = {
  loaded: false,
};

export default function orderReceiptReducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          statusCode: 200,
          ...action.data.json,
        },
      );

    case BOOKING_CLOSED:
      return Object.assign(
        {},
        {
          loaded: true,
          ...action.data.json,
          statusCode: 423,
        },
      );

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

export function fetchOrderReceiptSuccess(data) {
  return { type: SUCCESS, data };
}

export function orderReceiptFailure(data) {
  return { type: FAILURE, data };
}

export function fetchOrderReceiptAction(orderId, callback) {
  return function (dispatch, getState, { Api, qs, i18n }) {
    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'GET',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .orderReceipt({ siteId, orderId })
      .then((response) => {
        if (isFunction(callback)) {
          const data = {
            statusCode: response.statusCode,
            ...response.json,
          };

          callback(data);
        }

        if (response.statusCode === 200) {
          return dispatch(fetchOrderReceiptSuccess(response));
        } else if (response.statusCode === 423) {
          return dispatch(fetchBookingClosedMessageAction());
        } else {
          return dispatch(orderReceiptFailure(response));
        }
      });
  };
}
