import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import BaseSectionWrapper from '../baseSectionComponents/BaseSectionWrapper/BaseSectionWrapper';
import BaseSectionTitle from '../baseSectionComponents/BaseSectionTitle/BaseSectionTitle';
import BaseSectionText from '../baseSectionComponents/BaseSectionText/BaseSectionText';
import CallToAction from '../../shared/CallToAction/CallToAction';
import './ServerErrorSection.scss';

export function ServerErrorSection(props) {
  const { hub, pageConfig: { tenantConfig: { support } = {} } = {} } = props;

  const { t } = useTranslation();

  return (
    <BaseSectionWrapper
      section={{ type: 'server_error' }}
      backgroundColor="#fff"
    >
      <div className="c-server-error-section">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <BaseSectionTitle text={t('event.error')} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-8">
              <BaseSectionText
                text={
                  <p>
                    {t('event.error.message')} {t('event.error.supportmessage')}
                  </p>
                }
                jsx
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <ul className="c-server-error-section__listing">
                <li>
                  <Trans
                    i18nKey="event.error.supportmessageprivacy"
                    values={{ email: support.privacyEmail }}
                  >
                    <a
                      href={`mailto:${support.privacyEmail}`}
                      title={t('event.error.supportmessageprivacy.title')}
                    >
                      {support.privacyEmail}
                    </a>
                  </Trans>
                </li>
                <li>
                  <Trans
                    i18nKey="event.error.supportmessagetech"
                    values={{ email: support.techSupportEmail }}
                  >
                    <a
                      href={`mailto:${support.techSupportEmail}`}
                      title={t('event.error.supportmessagetech.title')}
                    >
                      {support.techSupportEmail}
                    </a>
                  </Trans>
                </li>
                <li>
                  <Trans
                    i18nKey="event.error.supportmessagecs"
                    values={{
                      email: support.customerServiceEmail,
                    }}
                  >
                    <a
                      href={`mailto:${support.customerServiceEmail}`}
                      title={t('event.error.supportmessagecs.title')}
                    >
                      {support.customerServiceEmail}
                    </a>
                  </Trans>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="c-server-error-section__cta">
                {hub ? (
                  <CallToAction to="/" label="Home page" />
                ) : (
                  <CallToAction
                    mode="black"
                    ctaConfig={{ type: 'HOME_LINK' }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseSectionWrapper>
  );
}

ServerErrorSection.propTypes = {
  hub: PropTypes.bool,
  pageConfig: PropTypes.shape({
    tenantConfig: PropTypes.shape({
      support: PropTypes.shape({
        privacyEmail: PropTypes.string,
        techSupportEmail: PropTypes.string,
        customerServiceEmail: PropTypes.string,
      }),
    }),
  }),
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(ServerErrorSection);
