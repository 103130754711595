import React from 'react';
import './HubNotFoundSection.scss';

export default function HubNotFoundSection() {
  return (
    <div className="c-hub-not-found-section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h2 className="c-hub-not-found-section__title">404</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <h3 className="c-hub-not-found-section__subtitle">
              PAGE NOT FOUND
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p className="c-hub-not-found-section__text">
              The page you are looking for has either moved or no longer exists
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
