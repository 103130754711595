import cloneDeep from 'lodash/cloneDeep';
import { isFunction, stringifyQueryParams } from '../../helpers';

const SUCCESS = 'AUDIENCE_EVENTS_SUCCESS';
const FAILURE = 'AUDIENCE_EVENTS_FAILURE';
const LOADING = 'AUDIENCE_EVENTS_LOADING';
const PRELOAD = 'AUDIENCE_EVENTS_PRELOAD';

const initialState = {
  loaded: false,
  loading: false,
};

export default function audienceEventsReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return Object.assign({}, cloneDeep(state), {
        loading: true,
      });

    case PRELOAD: {
      const previousState = cloneDeep(state);
      previousState.data.displayed = previousState.data.displayed.concat(
        action.data.json.data?.displayed || [],
      );
      return Object.assign(previousState, {
        loading: false,
      });
    }

    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          loading: false,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case FAILURE:
      return Object.assign(state, {
        error: true,
        loading: false,
        statusCode: action.data.statusCode,
      });

    default:
      return state;
  }
}

export function fetchAudienceEventsSuccess(data) {
  return { type: SUCCESS, data };
}

export function loadAudienceEventsSuccess(data) {
  return { type: PRELOAD, data };
}

export function fetchAudienceEventsFailure(data) {
  return { type: FAILURE, data };
}

export function fetchAudienceEventsLoading() {
  return { type: LOADING };
}

export function fetchAudienceEventsAction(
  { page = 1, count = 20 } = {},
  callback,
) {
  return function (dispatch, getState, { Api, i18n, qs }) {
    dispatch(fetchAudienceEventsLoading());

    const {
      pageConfig: { isPreview, siteId },
    } = getState();

    const basicView = page !== 1;
    const query = stringifyQueryParams({ page, count, basicView });

    return Api()
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .fetchAudienceEvents({ siteId, query })
      .then((response) => {
        if (isFunction(callback)) {
          callback();
        }

        if (response.statusCode === 200) {
          if (basicView) {
            return dispatch(loadAudienceEventsSuccess(response));
          }

          return dispatch(fetchAudienceEventsSuccess(response));
        } else {
          return dispatch(fetchAudienceEventsFailure(response));
        }
      });
  };
}
