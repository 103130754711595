const REQUEST = 'COURSE_PRICING_REQUEST';
const SUCCESS = 'COURSE_PRICING_SUCCESS';
const FAILURE = 'COURSE_PRICING_FAILURE';

const initialState = {
  loaded: false,
};

export default function coursePricingReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case SUCCESS:
      return Object.assign(
        {},
        {
          loaded: true,
          loading: false,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          loading: false,
        },
      );

    default:
      return state;
  }
}

export function fetchCoursePricingRequest() {
  return { type: REQUEST };
}

export function fetchCoursePricingSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchCoursePricingFailure(data) {
  return { type: FAILURE, data };
}

export function fetchCoursePricingAction() {
  return function (dispatch, getState, { Api, i18n }) {
    const {
      coursePricing: { loading, loaded },
      pageConfig: { siteId, isPreview },
    } = getState();

    if (loading || loaded) {
      return null;
    }

    dispatch(fetchCoursePricingRequest());

    return Api()
      .service('CommerceService')
      .version('v1')
      .setup({
        method: 'GET',
        headers: {
          'Accept-Language': i18n.language,
          'Content-Type': 'application/json',
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      })
      .coursePricing({ siteId })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchCoursePricingSuccess(response));
        } else {
          return dispatch(fetchCoursePricingFailure(response));
        }
      });
  };
}
