import LinkTo from '../../../../../shared/LinkTo/LinkTo';
import ImageTag from '../../../../../shared/ImageTag/ImageTag';
import './SiteLowerFooterLogo.scss';

/**
 * WHITE LABEL
 */
export default function SiteLowerFooterLogo({ lowerFooter }) {
  const {
    logo: { link = '/', target, url, alt },
  } = lowerFooter;

  return (
    <LinkTo to={link} target={target} className="c-site-lower-footer-logo">
      <ImageTag
        src={url}
        alt={alt}
        lazy={false}
        imgixParams="h=55"
        className="c-site-lower-footer-logo__img"
      />
    </LinkTo>
  );
}
