import { useRootSelector } from '../../../../../../../store/hooks';
import { selectArticleHome } from '../../../../../../../store/features/articles/home';
import { selectAudienceHubOptions } from '../../../../store/features/options';

import { SmallRelatedProducts } from '../SmallRelatedProducts';
import { Button } from '../../../../../shared/Button';
import { SmallRelatedArticleCard } from '../SmallRelatedProducts/SmallRelatedArticleCard';
import { SmallRelatedEventCard } from '../SmallRelatedProducts/SmallRelatedEventCard';

export default function ArticleRightSidebar() {
  const {
    data: {
      communityPath: audiencePath,
      relatedArticles = [],
      relatedTopics = [],
      showMoreRelatedArticlesLink,
      featuredEvent,
      showMoreEventsLink,
    },
  } = useRootSelector(selectArticleHome);
  const {
    data: { brandUrlSuffix: brandPath },
  } = useRootSelector(selectAudienceHubOptions);
  const pathPrefix = [brandPath, audiencePath];

  return (
    <>
      {relatedArticles.length > 0 && (
        <SmallRelatedProducts
          heading="Related Articles"
          products={relatedArticles}
          keyName="articleId"
          showMoreCTA={
            showMoreRelatedArticlesLink && (
              <Button
                to="/articles"
                query={{ topics: relatedTopics }}
                pathPrefix={pathPrefix}
                size="medium"
                fullWidth
              >
                View all related articles
              </Button>
            )
          }
        >
          {(article) => <SmallRelatedArticleCard article={article} />}
        </SmallRelatedProducts>
      )}
      {featuredEvent && (
        <SmallRelatedProducts
          heading="Related Event"
          products={[featuredEvent]}
          keyName="eventId"
          showMoreCTA={
            showMoreEventsLink && (
              <Button
                to="/events"
                pathPrefix={pathPrefix}
                size="medium"
                fullWidth
              >
                View all upcoming events
              </Button>
            )
          }
        >
          {(event) => <SmallRelatedEventCard event={event} />}
        </SmallRelatedProducts>
      )}
    </>
  );
}
