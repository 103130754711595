import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import NavAccordion from '../../NavAccordion/NavAccordion';
import './MultiLevelNavMobile.scss';

export default class MultiLevelNavMobile extends Component {
  componentRef = createRef();

  state = {
    menuHeight: 'initial',
  };

  componentDidMount() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const offsetSelfTop = this.componentRef.current.offsetTop;
    const offsetWrapperTop =
      document.querySelector('.c-multi-level-nav')?.offsetTop || 0;
    const offsetParentTop =
      offsetWrapperTop +
      (offsetWrapperTop > 0 ? parseInt(document.body.style.top || 0) : 0);
    const offsetTop = offsetParentTop + offsetSelfTop;

    this.setState(() => ({
      menuHeight: `calc(var(--vh, 1vh) * 100 - ${offsetTop}px)`,
    }));
  }

  render() {
    const { withHomeNav, navBarItems = [], background } = this.props;

    return (
      <div
        ref={this.componentRef}
        className="c-multi-level-nav-mobile"
        style={{
          minHeight: this.state.menuHeight,
          maxHeight: this.state.menuHeight,
        }}
      >
        <div className="c-multi-level-nav-mobile__body" style={{ background }}>
          <NavAccordion withHomeNav={withHomeNav} navBarItems={navBarItems} />
        </div>
      </div>
    );
  }
}

MultiLevelNavMobile.propTypes = {
  withHomeNav: PropTypes.bool,
  navBarItems: PropTypes.array.isRequired,
  background: PropTypes.string,
};
