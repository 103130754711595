export const breakpoint = {
  mobileBreakpoint: '400px',
  tabletBreakpoint: '768px',
  desktopBreakpoint: '1024px',
  largeDesktopBreakpoint: '1200px',
  tabletPlusBreakpoint: '860px',
  desktopMediumBreakpoint: '1015px',
  desktopPlusBreakpoint: '1166px',
  ultraWideBreakpoint: '1920px',
};

export const spacing = {
  menuItems: '30px',
};

export const typography = {
  type: {
    primary: '"Open Sans", sans-serif',
    secondary:
      '"Barlow Condensed Extra Bold", "Proxima Nova ExCn Bl", sans-serif',
    materialIcons: '"Material Icons", monospace',
  },
  weight: {
    regular: '400',
    semibold: '700',
    bold: '900',
  },
  size: {
    s1: '12',
    s2: '14',
    s3: '16',
    m1: '20',
    m2: '24',
    m3: '28',
    l1: '32',
    l2: '40',
    l3: '48',
    code: '90',
  },
};
