import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { localizePath } from '../../../../../../helpers/locale';
import { renderStyledElementStyles, devices } from '../../../../helpers';
import { StyledCallToAction } from '../../../../siteBuilderModules/sharedComponents';

const Wrapper = styled.div`
  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.siteLayout.siteHeader.widgets?.languageSelector,
    );
  }}
`;

const ListItem = styled.li`
  display: inline-block;
  border: 1px solid #dee0e5;
  pointer-events: ${({ isSelected }) => isSelected && 'none'};
  background-color: ${({ isSelected, theme }) =>
    isSelected &&
    theme.siteLayout.siteHeader.widgets?.languageSelector?.elements?.item
      ?.settings?.selectedBGColor};

  &:hover {
    background-color: ${({ theme }) =>
      theme.siteLayout.siteHeader.widgets?.languageSelector?.elements?.item
        ?.settings?.selectedBGColor};
    a {
      color: ${({ theme }) =>
        theme.siteLayout.siteHeader.widgets?.languageSelector?.elements?.link
          ?.settings?.selectedColor};
    }
  }

  &:first-child {
    border-radius: ${({ theme }) =>
      theme.globalVariables.isRTL ? '0 3px 3px 0' : '3px 0 0 3px'};
  }

  &:last-child {
    border-radius: ${({ theme }) =>
      theme.globalVariables.isRTL ? '3px 0 0 3px' : '0 3px 3px 0'};
  }

  &:not(:first-child) {
    margin-left: -1px;
  }

  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.siteLayout.siteHeader.widgets?.languageSelector?.elements?.item,
    );
  }}

  a {
    display: block;
    line-height: 1;
    padding: 3px 12px 5px;
    text-decoration: none;
    color: ${({ isSelected, theme }) =>
      isSelected
        ? theme.siteLayout.siteHeader.widgets?.languageSelector?.elements?.link
            ?.settings?.selectedColor
        : theme.siteLayout.siteHeader.widgets?.languageSelector?.elements?.link
            ?.settings?.color};

    @media only screen and (max-width: ${devices.breakpoints.tablet}) {
      padding: 5px;
    }
  }
`;

const LanguageLabel = styled.span`
  font-size: 12px;
  vertical-align: middle;
`;

export function LanguageSelector() {
  const { data: { locales: { primary, secondary = [] } = {} } = {} } =
    useSelector((state) => state.options);
  const pathName = useSelector((state) => state.pageConfig?.location?.pathname);
  const {
    t,
    i18n: { language: currentLng },
  } = useTranslation();
  const liveSecondaryLng = useMemo(
    () => secondary.filter((locale) => locale.live).map((lng) => lng.locale),
    [secondary],
  );
  const languages = [primary, ...liveSecondaryLng];

  return (
    languages.length > 1 && (
      <Wrapper data-testid="header-language-selector">
        <ul>
          {languages.map((language) => {
            const isSelectedLng = currentLng === language;
            const preventClick = isSelectedLng
              ? { onClick: (e) => e.preventDefault() }
              : {};

            return (
              <ListItem
                key={language}
                isSelected={isSelectedLng}
                data-testid="header-language-item"
              >
                <StyledCallToAction
                  type="link"
                  to={localizePath(pathName, language, primary)}
                  data-testid="header-language-cta"
                  {...preventClick}
                >
                  <LanguageLabel>
                    {t(`global.languages.${language}.label`)}
                  </LanguageLabel>
                </StyledCallToAction>
              </ListItem>
            );
          })}
        </ul>
      </Wrapper>
    )
  );
}
