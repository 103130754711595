import clientConfig from '../clientConfig';

export function localeInPath(path: string) {
  const reqPath = (path || '/').toLowerCase();
  const allowedLanguages = [...clientConfig.i18n.whitelist, 'cimode'];
  const lngIndex = allowedLanguages.indexOf(reqPath.slice(1).split('/')[1]);

  return lngIndex > -1 ? allowedLanguages[lngIndex] : '';
}

export function localizeRoutePath(path: string) {
  if (path.search(':lng') > -1) {
    const allowedLanguages = [...clientConfig.i18n.whitelist, 'cimode'].join(
      '|',
    );
    return path.replace(':lng', `:lng(${allowedLanguages})?`);
  }

  return path;
}

export function localizePath(path: string, lng = '', primaryLng = '') {
  let pathname = path;
  if (localeInPath(pathname)) {
    pathname = deLocalizePath(pathname);
  }

  if (!lng || lng.toLowerCase() === primaryLng) {
    return pathname;
  }

  const pathArr = pathname.slice(1).split('/');
  pathArr.splice(1, 0, lng.toLowerCase());

  return '/' + pathArr.filter((s) => s.length).join('/') + '/';
}

export function deLocalizePath(path: string) {
  const pathLng = localeInPath(path);
  if (!pathLng) {
    return path;
  }

  const allowedLanguages = [...clientConfig.i18n.whitelist, 'cimode'];

  const pathArr = path.slice(1).split('/');
  if (allowedLanguages.indexOf(pathLng) > -1) {
    pathArr.splice(1, 1);
  }

  return '/' + pathArr.filter((s) => s.length).join('/');
}
