import { useEffect } from 'react';
import Auth from '../../../../../services/AuthService';
import gtm from '../../../../../utils/initialGTM';

export function useSetUserDetailsToDataLayer() {
  useEffect(() => {
    const { 'cognito:username': userId, loggedIn } =
      Auth.getShortAccountDetails();

    if (loggedIn) {
      gtm.pushUserID(userId);
    }

    gtm.isUserLoggedIn(loggedIn);
  }, []);
}
