import {
  filterDatesByMonth,
  getSiteCardDate,
  getSiteCardDatesInMonthFormat,
} from '../../../../helpers';

import { Badge, BadgeSizes } from '../Badge';
import { TextListSpacer } from '../TextListSpacer';
import { DiscoveryDate } from '../../../../../../../store/features/discovery';

import styles from './Date.module.scss';

export interface DateProps {
  dates: DiscoveryDate[];
  deliveryTypes: string[];
  timeAsText: string;
  dateSize: keyof typeof BadgeSizes;
  extraDateSize: keyof typeof BadgeSizes;
}

export function Date(props: DateProps) {
  const { dates, deliveryTypes, timeAsText, dateSize, extraDateSize } = props;
  const [primaryDate = {}, ...restDates] = filterDatesByMonth(dates);

  const datesList = getSiteCardDate(primaryDate, deliveryTypes, timeAsText);
  const extraDatesList = getSiteCardDatesInMonthFormat(
    restDates,
    deliveryTypes,
  );

  if (datesList.length === 0 && extraDatesList.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {datesList.length > 0 && (
        <Badge size={dateSize}>
          <TextListSpacer list={datesList} />
        </Badge>
      )}

      {extraDatesList.length > 0 && (
        <Badge size={extraDateSize}>
          <TextListSpacer list={extraDatesList} />
        </Badge>
      )}
    </div>
  );
}

Date.defaultProps = {
  dateSize: BadgeSizes.small,
  extraDateSize: BadgeSizes.tiny,
};
