import s from './LeadGenForm.module.scss';
import { useRootSelector } from '../../../../../../store/hooks';
import { selectArticleHome } from '../../../../../../store/features/articles/home';
import { selectAudienceHubOptions } from '../../../store/features/options';
import StaticPageLayout from '../../../layouts/StaticPageLayout';
import LeadGenContainer from '../../../../../leadGen/LeadGenContainer';

export function LeadGenForm() {
  const {
    data: { communityPath: audiencePath },
  } = useRootSelector(selectArticleHome);
  const {
    data: { name: audienceName },
  } = useRootSelector(selectAudienceHubOptions);

  return (
    <StaticPageLayout audiencePath={audiencePath}>
      <div className={s.wrapper}>
        <LeadGenContainer audienceName={audienceName} />
      </div>
    </StaticPageLayout>
  );
}
