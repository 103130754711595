import React from 'react';
import styles from './NoProductsFound.module.scss';

export interface NoProductsFoundProps
  extends React.HTMLAttributes<HTMLDivElement> {
  heading: React.ReactNode;
  text: React.ReactNode;
}

export function NoProductsFound(props: NoProductsFoundProps) {
  const { heading, text, ...restProps } = props;

  return (
    <div {...restProps}>
      <h4 className={styles.title}>{heading}</h4>
      <p className={styles.text}>{text}</p>
    </div>
  );
}

NoProductsFound.defaultProps = {
  heading: 'No results found',
  text: 'Please check to see if you spelt your search term correctly',
};
