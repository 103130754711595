import cn from 'classnames';
import s from './Characteristic.module.scss';

export interface CharacteristicProps {
  count: number;
  label: string;
  validate: () => boolean;
  showInvalid: boolean;
}

export default function Characteristic(props: CharacteristicProps) {
  const { count, label, showInvalid, validate } = props;

  const valid = validate();

  return (
    <div
      className={cn(s.wrapper, {
        [s.valid]: valid,
        [s.invalid]: showInvalid && !valid,
      })}
      data-testid="characteristic"
    >
      <span className={cn(s.icon, 'material-icons')}>close</span>
      <span className={s.count}>{count}</span>
      <span className={s.label}>{label}</span>
    </div>
  );
}
