import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from './store';

/*
 * Use throughout your app instead of plain `useDispatch` and `useSelector`
 */

/* istanbul ignore next */
export const useRootDispatch = () => useDispatch<RootDispatch>();

/* istanbul ignore next */
export const useRootSelector: TypedUseSelectorHook<RootState> = useSelector;
