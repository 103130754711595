import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';
import { isBrowser, isNumber, isString } from '../../../helpers';
import './SearchFormWidget.scss';

export class SearchFormWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      searchFieldPlaceholder: 'Enter your search term or a topic',
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchKeyDown = this.onSearchKeyDown.bind(this);
    this.onTriggerSearch = this.onTriggerSearch.bind(this);
    this.moveToTopics = this.moveToTopics.bind(this);
  }

  componentDidMount() {
    if (isBrowser() && document.body.clientWidth < 375) {
      this.setState({
        searchFieldPlaceholder: 'Enter search term',
      });
    }
  }

  onSearchChange(event) {
    event.preventDefault();

    const searchValue = event.target.value;
    this.setState(() => ({ searchValue }));
  }

  onSearchKeyDown(event) {
    const isReturnButton = event.which === 13;

    if (isReturnButton) {
      this.onTriggerSearch();
    }
  }

  onTriggerSearch() {
    const searchValue = this.state.searchValue.trim();

    if (searchValue) {
      document.location.href =
        '/search' +
        (searchValue ? '?query=' + encodeURIComponent(searchValue) : '');
    }
  }

  moveToTopics() {
    const { moveTo } = this.props;
    const headerHeight = 60;

    if (isString(moveTo)) {
      const $element = document.querySelector(moveTo);
      if ($element) {
        Scroll.animateScroll.scrollTo($element.offsetTop - headerHeight);
      }
    } else if (isNumber(moveTo)) {
      Scroll.animateScroll.scrollTo(moveTo - headerHeight);
    } else {
      const event = new CustomEvent('toggleL1TopicsPopup');
      document.dispatchEvent(event);
    }
  }

  render() {
    const {
      browseByTopicLabel = '',
      searchProposition = '',
      browseByTopicsDisabled = false,
    } = this.props;
    const { searchFieldPlaceholder } = this.state;

    return (
      <div className="c-search-form-widget">
        <p className="c-search-form-widget__proposition">
          {`${
            searchProposition ||
            'Search our events, courses, articles and people'
          }`}
        </p>
        <div className="c-search-form-widget__field">
          <input
            type="text"
            onChange={this.onSearchChange}
            onKeyDown={this.onSearchKeyDown}
            value={this.state.searchValue}
            placeholder={searchFieldPlaceholder}
            maxLength="500"
            className="c-search-form-widget__input outlined"
          />
          <div
            role="button"
            onClick={this.onTriggerSearch}
            className="c-search-form-widget__button"
          >
            <i className="material-icons">search</i>
          </div>
        </div>
        {!browseByTopicsDisabled && (
          <div
            role="button"
            onClick={this.moveToTopics}
            className="c-search-form-widget__link"
          >
            <span>{browseByTopicLabel || 'or browse by topic'}</span>
            {this.props.moveTo ? (
              <i className="material-icons c-search-form-widget__icon">
                arrow_downward
              </i>
            ) : (
              <i className="material-icons c-search-form-widget__icon">apps</i>
            )}
          </div>
        )}
      </div>
    );
  }
}

SearchFormWidget.propTypes = {
  moveTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  browseByTopicLabel: PropTypes.string,
  browseByTopicsDisabled: PropTypes.bool,
};

export default SearchFormWidget;
