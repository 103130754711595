import { createGenericAsyncThunk } from '../../../../../../store/features/generic';

export interface SubscribeNewsletter {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  state: string;
  productCode: string;
}

export enum SubscriptionType {
  newsletter = 'newsletter',
  'email/audience' = 'email/audience',
}

export interface SubscribeToNewsletterParams {
  subscriptionType: SubscriptionType;
  values: SubscribeNewsletter;
}

export const subscribeToNewsletter = createGenericAsyncThunk<
  SubscribeNewsletter,
  SubscribeToNewsletterParams
>(
  'subscribe/subscribeToNewsletter',
  async ({ subscriptionType, values }, { extra, rejectWithValue }) => {
    const valuesWithState = {
      ...values,
      state:
        values.country === 'US' || values.country === 'CA'
          ? values.state
          : null,
    };
    const response = await extra.ajax.post(
      `/caas/leadgen/v1/subscribe/${subscriptionType}`,
      valuesWithState,
    );
    const data: SubscribeNewsletter = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);
