import { HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import s from './Alert.module.scss';

export interface AlertProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  type: 'success' | 'danger';
  heading: ReactNode;
  text: ReactNode;
  icon: string;
}
export default function Alert(props: AlertProps) {
  const { type, heading, text, icon, className } = props;

  return (
    <div className={cn(s.wrapper, s[type], className)}>
      <div className={s.iconWrapper}>
        <span className={cn(s.icon, 'material-icons')}>{icon}</span>
      </div>
      <div className={s.body}>
        <h4 className={s.heading}>{heading}</h4>
        <div className={s.text}>{text}</div>
      </div>
    </div>
  );
}
