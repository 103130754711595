import React from 'react';
import styled from 'styled-components';

import { color } from '../../styles/informaconnect/colours/colours';

const Container = styled.div`
  border: 1px solid ${color.formGrey};
  border-radius: 0 0 10px 10px;
  background-color: ${color.lightest};
  padding: 17px 20px;
`;

const ContainerRoundedBottom = (props) => {
  return <Container {...props}>{props.children}</Container>;
};

export default ContainerRoundedBottom;
