import { useRootSelector } from '../../../../../store/hooks';
import { selectBrandHubLayout } from '../../store/features/layout';
import { selectBrandHubOptions } from '../../store/features/options';
import s from './Error.module.scss';
import StaticPageLayout from '../../layouts/StaticPageLayout';
import { MetaTags } from '../../../shared/MetaTags';
import PageError from '../../../shared/PageError';

export default function Error() {
  const {
    data: {
      header: {
        headerNavBar: { brandLogo },
      },
    },
  } = useRootSelector(selectBrandHubLayout);
  const {
    data: { name: hubName },
  } = useRootSelector(selectBrandHubOptions);

  return (
    <StaticPageLayout className={s.body}>
      <MetaTags
        title="Error"
        titleSuffix={hubName}
        thumbnail={brandLogo?.path}
      />
      <PageError />
    </StaticPageLayout>
  );
}
