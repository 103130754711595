import { ReactNode } from 'react';
import styles from './ProductsPreviewGrid.module.scss';

export interface ProductsPreviewGridProps {
  heading: ReactNode;
  subHeading?: ReactNode;
  button?: ReactNode;
  children: ReactNode;
}

export function ProductsPreviewGrid(props: ProductsPreviewGridProps) {
  const { heading, subHeading, button, children } = props;

  return (
    <div className={styles.wrapper}>
      <div className="row">
        <div className="col-xs-12">
          <div className={styles.headingWrapper}>
            <div className={styles.heading}>{heading}</div>
            {subHeading && (
              <div className={styles.subHeading}>{subHeading}</div>
            )}
          </div>
        </div>
      </div>

      {children}

      {button && (
        <div className="row">
          <div className="col-xs-12">
            <div className={styles.button}>{button}</div>
          </div>
        </div>
      )}
    </div>
  );
}
