import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../styles/shared';

import Radio from '../Radio';
import GroupHeader from '../GroupHeader';

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: ${breakpoint.tabletBreakpoint}) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .lead-gen-radio-btn__item {
    display: flex;
    width: 50%;

    @media (max-width: ${breakpoint.tabletBreakpoint}) {
      width: 100%;
    }
  }
`;

const getOptions = (props) => {
  const fullOptions = props.options;
  return fullOptions.map((radioBtn) => (
    <Radio
      {...props}
      radioBtn={radioBtn}
      key={radioBtn.value}
      fullWidth={radioBtn.fullWidth}
    />
  ));
};

const RadioGroup = (props) => {
  const prefix = 'lead-gen-radio-btn';
  return (
    <div className={prefix} data-test-lead-gen={prefix}>
      <GroupHeader prefix={prefix} {...props} />
      <RadioWrapper className="lead-gen-radio-btn__wrapper">
        {getOptions(props)}
      </RadioWrapper>
    </div>
  );
};

export default RadioGroup;
