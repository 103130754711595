import styles from './TextListSpacer.module.scss';

export interface TextListSpacerProps {
  list: string[];
}

export const TextListSpacer = ({ list }: TextListSpacerProps) => {
  return (
    <ul className={styles.list}>
      {list.map((item) => (
        <li className={styles.item} key={item}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default TextListSpacer;
