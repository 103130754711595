import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import getHash from './getHash';

export default function getSessions(
  agendaItems = [],
  streams = [],
  parameters = {},
) {
  const sessions = [];
  let index = 0;
  let groupId = 1;
  const streamDescription = {};
  let group;
  const { viewType = 'grid', sortBy = 'time', isLearning } = parameters;

  function pushItem(item, streamId, group) {
    const session = Object.assign(cloneDeep(item), {
      index,
      stream: streamDescription[streamId],
      group,
      itemHash:
        (streamId ? getHash(streamDescription[streamId].name) + '_' : '') +
        getHash(item.title) +
        (item.startTime ? '_' + getHash(item.startTime.replace(':', '-')) : ''),
    });
    sessions.push(session);
    index++;
  }

  streams.forEach((el) => {
    streamDescription[el.id] = {
      id: el.id,
      color: el.colorIndex,
      name: el.name,
    };
  });

  agendaItems.forEach((item) => {
    if (item.type === 'stream_group') {
      group = {
        id: groupId,
        title: item.title,
        suppressStreamTitles: item.suppressStreamTitles,
      };
      item.streams.forEach((stream) => {
        const description = streamDescription[stream.streamId];
        stream.name = description.name;
        stream.color = description.color;
        stream.streamItems.forEach((el) => {
          el.type !== 'space' &&
            (viewType === 'grid' ? el.enabled : true) &&
            pushItem(el, stream.streamId, group);
        });
      });
      groupId++;
    } else {
      item.type !== 'space' && pushItem(item);
    }
  });

  const controlSessions = cloneDeep(sessions);

  if (sortBy === 'time' && !isLearning) {
    sessions.sort(function (a, b) {
      if (a._startTime) {
        return a._startTime.localeCompare(b._startTime);
      }
      return a.startTime.localeCompare(b.startTime);
    });
  }

  const showViewMode = !isEqual(sessions, controlSessions);

  // need to reindex after sorting
  if (showViewMode) {
    sessions.forEach((item, key) => {
      item.index = key;
    });
  }

  return Object.assign(
    { items: sortBy === 'time' || showViewMode ? sessions : controlSessions },
    { showViewMode: sortBy === 'streams' || showViewMode },
  );
}

export function getSessionsIndexMap(sessions = []) {
  const sessionsIndexMapper = {};

  sessions.forEach((session = {}) => {
    if (session.stream?.id) {
      sessionsIndexMapper[`${session.stream.id}-${session.id}`] = session.index;
    } else if (session.id) {
      sessionsIndexMapper[session.id] = session.index;
    }
  });

  return sessionsIndexMapper;
}

export function getSessionIndexId(session = {}, streamId = '') {
  if (!session.id) return;

  if (streamId) {
    return `${streamId}-${session.id}`;
  }

  if (session.stream?.id) {
    return `${session.stream.id}-${session.id}`;
  }

  return session.id;
}
