const REQUEST = 'HUB_MENU_REQUEST';
const SUCCESS = 'HUB_MENU_SUCCESS';
const FAILURE = 'HUB_MENU_FAILURE';

const initialState = {
  loaded: false,
};

export default function hubMenuReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case SUCCESS: {
      const { data: { menuItems = [] } = {} } = action.data.json;
      const data = menuItems.reduce((acc, curr) => {
        return { ...acc, [curr.path]: curr };
      }, {});

      return Object.assign(
        {},
        {
          loaded: true,
          statusCode: action.data.statusCode,
          data,
        },
      );
    }

    case FAILURE:
      return Object.assign(
        {},
        {
          error: true,
          statusCode: action.data.statusCode,
          ...action.data.json,
        },
      );

    default:
      return state;
  }
}

export function fetchHubMenuRequest(data) {
  return { type: REQUEST, data };
}

export function fetchHubMenuSuccess(data) {
  return { type: SUCCESS, data };
}

export function fetchHubMenuFailure(data) {
  return { type: FAILURE, data };
}

export function fetchHubMenuAction() {
  return function (dispatch, getState, { Api, i18n }) {
    dispatch(fetchHubMenuRequest());

    const {
      pageConfig: { tenantId },
    } = getState();

    return Api()
      .service('ContentService')
      .version('v1')
      .setup({
        headers: {
          'Accept-Language': i18n.language,
        },
      })
      .fetchHubMenu({ tenantId })
      .then((response) => {
        if (response.statusCode === 200) {
          return dispatch(fetchHubMenuSuccess(response));
        } else {
          return dispatch(fetchHubMenuFailure(response));
        }
      });
  };
}
