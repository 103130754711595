import React from 'react';
import PropTypes from 'prop-types';

import LeadGenFormConstructor from '../../LeadGenFormItems/LeadGenFormConstructor';

import './LeadGenAdHocQuestions.scss';

export default function LeadGenAdHocQuestions(props) {
  const { adHocFormData = [], leadGenType } = props;
  const titleArticleClass =
    leadGenType === 'article'
      ? 'lead-gen-ad-hoc-questions__title--article'
      : '';

  return (
    <>
      <div
        className="row"
        data-test-lead-gen="lead-gen-ad-hoc-questions-constructor"
      >
        <div className="col-xs-12 col-sm-12">
          <h3
            className={`lead-gen-ad-hoc-questions__title ${titleArticleClass}`}
            data-test-lead-gen="lead-gen-ad-hoc-questions-title"
          >
            Tell us more about yourself
          </h3>
          <div className="lead-gen-ad-hoc-questions__subtitle">
            This will help ensure you see articles, market intelligence and
            event updates relevant to you.
          </div>
        </div>
      </div>
      <div className="row">
        <LeadGenFormConstructor
          formData={adHocFormData}
          sectionType="adHocQuestions"
        />
      </div>
    </>
  );
}

LeadGenAdHocQuestions.propTypes = {
  adHocFormData: PropTypes.array.isRequired,
  leadGenType: PropTypes.string,
};
