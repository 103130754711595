import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { withBrand } from '../../../../../providers/BrandProvider';
import { renderStyledElementStyles } from '../../../helpers';

const IconBadgeWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.iconBadge)}
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles?.elements?.iconBadge)}
  ${({ theme }) =>
    theme.globalVariables.isRTL
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}
  background: ${(props) => (props.bgColor ? `${props.bgColor}` : 'none')};
  border-radius: ${(props) => props.isRound && `50%`};

  .material-icons {
    font-size: 28px;
    color: #333;
  }
`;

export const IconBadge = withBrand((props) => {
  const {
    displayStyles,
    visualStyles,
    brand: { accentColor } = {},
    iconID,
    isRound,
    bgColor,
  } = props;

  return (
    <IconBadgeWrapper
      displayStyles={displayStyles}
      visualStyles={visualStyles}
      bgColor={bgColor || accentColor}
      isRound={isRound}
    >
      <i className="material-icons">{iconID}</i>
    </IconBadgeWrapper>
  );
});

IconBadge.propTypes = {
  displayStyles: PropTypes.object,
  visualStyles: PropTypes.object,
  brand: PropTypes.object,
  iconID: PropTypes.string.isRequired,
  isRound: PropTypes.bool,
  bgColor: PropTypes.string,
};

const StyledIconBadgeWrapper = styled.div`
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.iconBadge)}
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles?.elements?.iconBadge)}
`;

export const StyledIconBadge = ({ displayStyles, visualStyles, ...props }) => (
  <StyledIconBadgeWrapper
    displayStyles={displayStyles}
    visualStyles={visualStyles}
  >
    <IconBadge {...props} />
  </StyledIconBadgeWrapper>
);
