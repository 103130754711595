import { HTMLAttributes, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import loadable from '@loadable/component';
import cn from 'classnames';
import styles from './Layout.module.scss';
import { HubContextProvider } from '../../../shared/context/hubContext';
import { useRootSelector } from '../../../../../store/hooks';
import { useDeviceDetect } from '../../../../../hooks';
import {
  useSetUserDetailsToDataLayer,
  useSetOptionsToDataLayer,
  useSetProductCodeDataToDataLayer,
} from '../../../shared/hooks';
import { AudienceHubLayout } from '../../store/features/layout';
import { selectAudienceHubOptions } from '../../store/features/options';
import { selectAudienceHubPageLogo } from '../../store/features/page';
import InformaRibbon from '../../../../common/InformaRibbon/InformaRibbon';
import CookieBanner from '../../../../common/CookieBanner/CookieBanner';
import { Header } from '../../Header';
import { Footer } from '../../Footer';
import Advertising from '../../../shared/Advertising';
import ErrorBoundary from '../../../../common/ErrorBoundary';
import PageError from '../../../shared/PageError';

const Nav = loadable(
  () => import(/* webpackChunkName: "HubNav" */ '../../../shared/Nav'),
);

export interface LayoutProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  layout: AudienceHubLayout;
  audiencePath?: string;
}

export default function Layout(props: PropsWithChildren<LayoutProps>) {
  const {
    layout,
    audiencePath: customAudiencePath,
    className,
    children,
  } = props;
  const { header, footer, sectors } = layout;
  const { data: options } = useRootSelector(selectAudienceHubOptions);
  const pageLogo = useRootSelector(selectAudienceHubPageLogo);
  const { sitePath: audiencePath } = useParams<{ sitePath: string }>();

  const {
    navBarItems,
    headerNavBar: {
      brandLogo,
      disableNavbarHomeLink,
      displayBrandLogoInNavbar,
      displayKeywordSearch,
      searchDisplayPlace,
      searchPlaceholderText,
    },
  } = header;

  const { isDesktop } = useDeviceDetect();
  useSetUserDetailsToDataLayer();
  useSetOptionsToDataLayer(options);
  useSetProductCodeDataToDataLayer(options);

  return (
    <HubContextProvider
      theme={options.theme}
      brandPath={options.brandUrlSuffix}
      audiencePath={customAudiencePath || audiencePath}
      brandName={options.subBrandName}
      showSponsorLogos={options.showSponsorLogos}
    >
      <div className={cn(styles.wrapper, 'audience-hub')}>
        <CookieBanner />
        <InformaRibbon />
        {options.advertisingEnabled && (
          <Advertising
            adUnit={options.adUnit}
            networkId={options.networkId}
            advSlots={['728_1']}
            advSize="728_90"
          />
        )}
        <Sticky wrapperClassName={styles.headerSticky} disabled={isDesktop}>
          <header className={styles.header}>
            <Header header={header} options={options} sectors={sectors} />
          </header>
        </Sticky>
        <Sticky wrapperClassName={styles.navSticky}>
          <nav className={styles.nav}>
            <Nav
              links={navBarItems}
              sectors={sectors}
              displayHomeLink={!disableNavbarHomeLink}
              brandLogo={pageLogo ?? brandLogo}
              displayBrandLogo={displayBrandLogoInNavbar}
              displayKeywordSearch={
                displayKeywordSearch && searchDisplayPlace === 'NAVBAR'
              }
              searchPlaceholderText={searchPlaceholderText}
            />
          </nav>
        </Sticky>
        <main className={cn(styles.body, className)}>
          <ErrorBoundary errorMessage={<PageError />}>{children}</ErrorBoundary>
        </main>
        <footer className={styles.footer}>
          <Footer footer={footer} sectors={sectors} />
        </footer>
      </div>
    </HubContextProvider>
  );
}
