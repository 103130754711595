import React from 'react';
import {
  ProductsPreviewList,
  ProductsList,
} from '../../../../productFilter/products';
import VideoCard from '../../../../productFilter/cards/VideoCard';

import { ViewMode } from '../index';
import { useRootSelector } from '../../../../../../../store/hooks';
import { selectHubDiscoveryStreamlyVideosRecord } from '../../../../../../../store/features/discovery';
import { UseInfiniteScrollProps } from '../../../../../../../hooks';
import { useStreamlyVideoParams } from '../../../../hooks';
import StreamlyVideoModal from '../../../../StreamlyVideoModal';

export interface StreamlyVideosListProps {
  mode: keyof typeof ViewMode;
  loadMoreResults?: UseInfiniteScrollProps['fetchMoreData'];
  previewCount?: number;
}

export default function StreamlyVideosList(props: StreamlyVideosListProps) {
  const { mode, loadMoreResults, previewCount } = props;
  const { params, handleCleanParams, handleUpdateParams } =
    useStreamlyVideoParams();
  const { results, totalPages } = useRootSelector(
    selectHubDiscoveryStreamlyVideosRecord,
  );

  return (
    <>
      {mode === ViewMode.list && loadMoreResults ? (
        <ProductsList
          products={results}
          onLoadMore={loadMoreResults}
          totalPage={totalPages}
        >
          {(product) => (
            <VideoCard
              data={product}
              cardType="list"
              onOpen={handleUpdateParams}
            />
          )}
        </ProductsList>
      ) : (
        <ProductsPreviewList products={results} countToShow={previewCount}>
          {(product) => (
            <VideoCard data={product} onOpen={handleUpdateParams} />
          )}
        </ProductsPreviewList>
      )}
      {params && (
        <StreamlyVideoModal
          open={!!params}
          videoParams={params}
          onClose={handleCleanParams}
        />
      )}
    </>
  );
}
