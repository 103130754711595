import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { withBrand } from '../../../../../providers/BrandProvider';
import { HEXtoRGBA } from '../../../../../helpers';
import MultiLevelNavMenu from './MultiLevelNavMenu/MultiLevelNavMenu';
import MultiLevelNavList from './MultiLevelNavList/MultiLevelNavList';
import MultiLevelNavItem from './MultiLevelNavItem/MultiLevelNavItem';
import './MultiLevelNav.scss';

export function cleanPath(path) {
  return path ? path.replace(/^\//, '').split('/')[0].toLowerCase() : null;
}

export class MultiLevelNav extends Component {
  componentRef = React.createRef();

  componentDidMount() {
    if (this.props.fixedNav) {
      document.addEventListener('MultiLevelNavEnter', this.blurNav);
      document.addEventListener('MultiLevelNavLeave', this.unBlurNav);
    }
  }

  componentWillUnmount() {
    if (this.props.fixedNav) {
      document.removeEventListener('MultiLevelNavEnter', this.blurNav);
      document.removeEventListener('MultiLevelNavLeave', this.unBlurNav);
    }
  }

  blurNav = () => {
    this.componentRef.current.classList.add('c-multi-level-nav--blurred');
  };

  unBlurNav = () => {
    this.componentRef.current.classList.remove('c-multi-level-nav--blurred');
  };

  render() {
    const {
      topNav,
      fixedNav,
      withHomeNav,
      withMobileLogo,
      transparent,
      siteHeader: { data: { navBarItems = [] } = {} },
      pageConfig: {
        siteSubPath = '',
        tenantConfig: {
          ui: { theme },
        },
      },
      brand: { primaryColor, secondaryColor },
      miniBasket,
    } = this.props;

    const siteSubPathCleaned = cleanPath(siteSubPath);

    const navBarGroups = navBarItems.filter(
      (navBarItem) => navBarItem['@type'] === 'GroupNavBarItemView',
    );

    const matchedNavBarGroups = navBarGroups.map((navBarGroup) => {
      const matchedGroupItems = navBarGroup.groupItems.filter(
        (groupItem) => siteSubPathCleaned === cleanPath(groupItem.href),
      );

      return matchedGroupItems.length > 0 ? navBarGroup.groupItems : false;
    });

    const isPrimaryBg = [
      'OneOfAKind',
      'NajahStack',
      'ArtToronto',
      'TheArtistProject',
      'InteriorDesign',
    ].includes(theme);

    let background;
    if (
      theme === 'FanExpoHQ' ||
      theme === 'MEFCC' ||
      theme === 'InteriorDesignShow'
    ) {
      background = HEXtoRGBA(secondaryColor, 0.8);
    } else {
      const applyTransparent = isMobile ? 1 : 0.7;

      background = HEXtoRGBA(
        isPrimaryBg ? primaryColor : secondaryColor,
        transparent ? applyTransparent : 1,
      );
    }

    const fixedNavBarItems = matchedNavBarGroups.filter(Boolean)[0] || [];

    const componentClassNames = classNames('c-multi-level-nav', {
      'c-multi-level-nav--top-nav': topNav && navBarItems.length > 0,
      'c-multi-level-nav--fixed-nav': fixedNav && fixedNavBarItems.length > 0,
    });

    return !topNav && !fixedNav ? null : (
      <div
        ref={this.componentRef}
        className={componentClassNames}
        style={{ background }}
      >
        {topNav && (
          <MultiLevelNavMenu
            navBarItems={navBarItems}
            background={background}
            withHomeNav={withHomeNav}
            withMobileLogo={withMobileLogo}
            miniBasket={miniBasket}
          />
        )}
        {fixedNav && (
          <MultiLevelNavList
            navBarItems={fixedNavBarItems}
            fixedState
            level="2"
          >
            {(navBarItem) => (
              <MultiLevelNavItem navBarItem={navBarItem} level="2" />
            )}
          </MultiLevelNavList>
        )}
      </div>
    );
  }
}

MultiLevelNav.propTypes = {
  topNav: PropTypes.bool,
  fixedNav: PropTypes.bool,
  withHomeNav: PropTypes.bool,
  withMobileLogo: PropTypes.bool,
  siteHeader: PropTypes.object,
  pageConfig: PropTypes.shape({
    siteSubPath: PropTypes.string,
    tenantConfig: PropTypes.shape({
      ui: PropTypes.shape({
        theme: PropTypes.string,
      }),
    }),
  }),
  brand: PropTypes.object,
  miniBasket: PropTypes.node,
};

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader,
    pageConfig: state.pageConfig,
  };
}

export default connect(mapStateToProps)(withBrand(MultiLevelNav));
