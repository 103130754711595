import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';

import { renderStyledElementStyles } from '../../../../helpers';

import {
  CallToAction,
  ImageTag,
} from '../../../../siteBuilderModules/sharedComponents';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ImageWrapper = styled.div`
  img {
    height: auto;
    max-width: 100%;
    max-height: ${({ maxHeight }) => maxHeight};
  }
`;

const CTAWrapper = styled.div`
  a {
    display: flex;

    ${({ theme }) => {
      return renderStyledElementStyles(
        theme,
        theme.siteLayout.siteHeader.builder?.elements?.partnerLogo?.elements
          ?.cta,
      );
    }}
  }
`;

export function PartnerLogo() {
  const {
    data: {
      brandLogo: { path: logoPath = '' } = {},
      logoSize = 1,
      paymentHub: isPaymentHub,
      customLinkUrl,
      customLinkDisplayText,
      customLinkOpenInNewTab,
    } = {},
  } = useSelector((state) => state.siteHeader);
  const themeConfig = useContext(ThemeContext);

  if (!logoPath) {
    return null;
  }

  const ctaTarget = customLinkOpenInNewTab ? '_blank' : '_self';
  const { imgixParams, maxHeight } =
    themeConfig.siteLayout.siteHeader.builder?.settings?.logoSize[logoSize];
  const partnerLogoImg = (
    <ImageWrapper maxHeight={maxHeight}>
      <ImageTag
        src={logoPath}
        lazy={false}
        imgixParams={imgixParams}
        alt={customLinkDisplayText}
      />
    </ImageWrapper>
  );

  return (
    <Wrapper data-testid="header-partner-logo">
      {!isPaymentHub && customLinkUrl ? (
        <CTAWrapper data-testid="header-partner-logo-cta">
          <CallToAction
            type="link"
            to={customLinkUrl}
            ctaConfig={{
              shown: true,
              customUrl: customLinkUrl,
            }}
            target={ctaTarget}
          >
            {partnerLogoImg}
          </CallToAction>
        </CTAWrapper>
      ) : (
        partnerLogoImg
      )}
    </Wrapper>
  );
}
