import { HTMLAttributes, ComponentType } from 'react';
import cn from 'classnames';
import { ReactComponent as ArtsyIcon } from '../../../assets/images/tenants/social/artsy-logo.svg';
import { SocialIconType } from '../../../types';

import styles from './SocialIcon.module.scss';

type CustomHTMLAttributes = Pick<
  HTMLAttributes<HTMLDivElement>,
  'className' | 'style'
>;

interface SocialIconConfigItem {
  icon?: string;
  customIcon?: ComponentType<CustomHTMLAttributes>;
}

type SocialIconConfig = {
  [key in SocialIconType]: SocialIconConfigItem;
};

const socialIconConfig: SocialIconConfig = {
  facebook: {
    icon: 'fab fa-facebook-f',
  },
  instagram: {
    icon: 'fab fa-instagram',
  },
  youtube: {
    icon: 'fab fa-youtube',
  },
  twitter: {
    icon: 'fab fa-x-twitter',
  },
  threads: {
    icon: 'fab fa-threads',
  },
  linkedin: {
    icon: 'fab fa-linkedin-in',
  },
  blog: {
    icon: 'fa fa-rss',
  },
  artsy: {
    customIcon: ArtsyIcon,
  },
  flickr: {
    icon: 'fab fa-flickr',
  },
  tiktok: {
    icon: 'fab fa-tiktok',
  },
  streamly: {
    icon: 'fa-solid fa-circle-play',
  },
  whatsapp: {
    icon: 'fab fa-square-whatsapp',
  },
};

interface SocialIconProps extends CustomHTMLAttributes {
  type: SocialIconType;
}

export default function SocialIcon(props: SocialIconProps) {
  const { type, className, ...restProps } = props;
  const { icon, customIcon: CustomIcon } = socialIconConfig[type] ?? {};

  if (CustomIcon) {
    return (
      <CustomIcon className={cn(styles.customIcon, className)} {...restProps} />
    );
  }

  return <span className={cn(icon, className)} {...restProps} />;
}
