import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import qs from 'qs';
import clientConfig from '../clientConfig';
import Auth from '../services/AuthService';
import { isEnv, isFunction, siteTypesEnum } from '../helpers';

const { L1_TOPIC_HUB, L2_TOPIC_HUB, ARTICLE, EVENT, COURSE } = siteTypesEnum();

const maIntegration = {
  pageProps: () => window.__REDUX_STORE__,

  initMA() {
    if (!window.MA || isEnv('local')) {
      return;
    }

    const {
      hub: { data: { relatedTopics: hubRelatedTopics = [] } = {} } = {},
      chosenProducts: { meta: { trackId = '' } = {} } = {},
      pageConfig: {
        siteType,
        siteSubPath,
        location: { search = '' } = {},
        tenantConfig: { id: tenantId } = {},
      } = {},
      articleHome: {
        data: {
          articleId = '',
          datePublished = '',
          relatedTopics = [],
          title = '',
          author: { forename = '', surname = '' } = {},
          tags = [],
        } = {},
      } = {},
      siteSpeakerDetails: {
        data: {
          id: speakerId,
          forename: speakerForename,
          surname: speakerSurname,
          jobTitle,
          company,
          relatedSessions = [],
        } = {},
      } = {},
      siteAgenda: { data: { streams, formats, topics } = {} } = {},
    } = this.pageProps();

    const isRequestedSitePage = [
      L1_TOPIC_HUB,
      L2_TOPIC_HUB,
      ARTICLE,
      EVENT,
      COURSE,
    ].includes(siteType);

    this.MA = window.MA(clientConfig.MA[tenantId]);

    const accountDetails = Auth.getShortAccountDetails();

    this.sendMAPageView({
      userId: accountDetails['cognito:username'],
      userEmail: accountDetails.email,
    });
    this.sendMAQueryParams();

    // for HUBs and sites pages
    if (isRequestedSitePage) {
      const topicsLists = this._getTopicsList();
      const topicModel = {
        l1Topics: topicsLists.l1Topics,
        l2Topics: topicsLists.l2Topics,
        siteType,
        tags,
        relatedTopics: hubRelatedTopics.map((topic) => topic.name),
      };

      this.sendMATopicsModel(topicModel);
    }
    // for view-basket page
    else if (siteSubPath === 'purchase/view-basket' && trackId) {
      this.sendMAOrderDetails(trackId);
    }
    // for article page
    else if (articleId) {
      this.sendMAArticleModel({
        title,
        articleAuthor: `${forename} ${surname}`,
        published: datePublished,
        articleRelatedTopics: relatedTopics,
      });
    }
    // for speaker details page
    else if (speakerId) {
      const getTopics = () => {
        let topicList = [];
        relatedSessions.forEach((session) => {
          topicList = topicList.concat(session.topics);
        });

        return uniq(topicList);
      };

      this.sendMASpeakerModel({
        speakerCompany: company,
        speakerName: `${speakerForename} ${speakerSurname} ${jobTitle} at ${company}`,
        speakerStreamList: uniq(
          relatedSessions.map((session) => session.streamName),
        ),
        speakerFormatList: uniq(
          relatedSessions.map((session) =>
            session.format.toLowerCase().replace('_', ' '),
          ),
        ),
        speakerTopicList: getTopics(),
      });
    }
    // for agenda page
    else if (siteSubPath.indexOf('agenda/') === 0) {
      const filters = qs.parse(search.slice(1));
      const agendaStreamList =
        streams && filters.stream
          ? streams.filter((stream) => {
              const streams = filters.stream;
              return streams.includes(stream.filterId.toString());
            })
          : [];
      const agendaFormatList =
        formats && filters.format
          ? formats.filter(
              (format) =>
                filters.format.toLowerCase() === format.name.toLowerCase(),
            )
          : [];
      const agendaTopicList =
        topics && filters.topic
          ? topics.filter(
              (topic) =>
                filters.topic.toLowerCase() === topic.name.toLowerCase(),
            )
          : [];

      this.sendMAAgendaModel({
        agendaStreamList: agendaStreamList.map((res) => res.name),
        agendaFormatList: agendaFormatList.map((res) => res.name),
        agendaTopicList: agendaTopicList.map((res) => res.name),
      });
    }
  },

  isEnabledForTenant(tenantId) {
    return !!(clientConfig.MA && clientConfig.MA[tenantId]?.enabled);
  },

  runSafeMethod(cb) {
    if (this.MA && isFunction(cb)) {
      try {
        cb();
      } catch (err) {
        console.error(err);
      }
    }
  },

  sendMAPageView(args) {
    this.runSafeMethod(() => {
      this.MA.sendPageView(args);
    });
  },

  sendMAQueryParams() {
    this.runSafeMethod(() => {
      this.MA.sendQueryParams();
    });
  },

  sendMASpeakerModel(speakerModel) {
    this.runSafeMethod(() => {
      this.MA.sendSpeakerContent(speakerModel);
    });
  },

  sendMAArticleModel(articleModel) {
    this.runSafeMethod(() => {
      this.MA.sendArticleContent(articleModel);
    });
  },

  sendMAOrderDetails(orderId) {
    this.runSafeMethod(() => {
      this.MA.sendOrderDetails(orderId);
    });
  },

  sendMATopicsModel(topicModel) {
    this.runSafeMethod(() => {
      this.MA.sendTopics(topicModel);
    });
  },

  sendMAAgendaModel(agendaModel) {
    const defaultAgendaMAModel = {
      agendaName: '',
      agendaStreamName: '',
      agendaFormat: '',
      agendaSpeakers: [],
      agendaStreamList: [],
      agendaFormatList: [],
      agendaTopicList: [],
    };

    this.runSafeMethod(() => {
      this.MA.sendAgendaContent(merge(defaultAgendaMAModel, agendaModel));
    });
  },

  _getTopicsList() {
    const {
      industryHeader: { data: { l1Topics: industryTopics = [] } = {} } = {},
      audienceHeader: {
        data: {
          l1Topics: audienceL1Topics = [],
          l2Topics: audienceL2Topics = [],
        } = {},
      } = {},
      siteHeader: {
        data: {
          l1Topics: siteIndustryTopics = [],
          l2Topics: siteAudienceTopics = [],
        } = {},
      } = {},
    } = this.pageProps();

    const l1Topics = industryTopics.length
      ? industryTopics
      : audienceL1Topics.length
      ? audienceL1Topics
      : siteIndustryTopics;
    const l2Topics = audienceL2Topics.length
      ? audienceL2Topics
      : siteAudienceTopics;

    return {
      l1Topics,
      l2Topics,
    };
  },
};

export default maIntegration;
