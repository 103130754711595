import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './Select.module.scss';

interface Option {
  value: string;
  label: string;
}

export interface SelectProps {
  name: string;
  options: Option[];
}

export function Select(props: SelectProps) {
  const { register } = useFormContext();
  const { name, options } = props;

  return (
    <select {...register(name)} className={styles.select}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
