import { getLanguageName } from '../../../../../helpers/getLanguageName';

export function getSiteCardLanguages(languages: string[] = []) {
  const nonEnglishLanguages = languages.filter(
    (language) => language !== 'ENGLISH',
  );

  if (nonEnglishLanguages.length === 0) {
    return [];
  }

  const languagesList = languages.map((language) => getLanguageName(language));

  return languagesList.filter(Boolean);
}
