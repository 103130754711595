import moment from 'moment';
import { getCorrectLocale } from '../helpers';

export default function getEventDates(startDate, endDate, locale = 'en') {
  if (!startDate) {
    return;
  }

  const momentLocale = getCorrectLocale(locale);
  const dateFormat = 'YYYY-MM-DD';

  let formattedStartDate = moment(startDate, dateFormat)
    .locale(momentLocale)
    .format('DD MMM YYYY');
  let formattedEndDate = '';

  if (endDate && startDate !== endDate) {
    const startMonth = moment(startDate, dateFormat).month();
    const endMonth = moment(endDate, dateFormat).month();

    formattedStartDate = moment(startDate, dateFormat)
      .locale(momentLocale)
      .format(startMonth === endMonth ? 'DD' : 'DD MMM');

    formattedEndDate = moment(endDate, dateFormat)
      .locale(momentLocale)
      .format('DD MMM YYYY');
  }

  return `${formattedStartDate}${
    formattedEndDate.length ? ' - ' + formattedEndDate : ''
  }`;
}
