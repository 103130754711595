import { createSelector } from '@reduxjs/toolkit';
import {
  GenericState,
  UniqueIdentifier,
  UploadedImage,
} from '../../../../../../types';
import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../../store/features/generic';

export interface IndustryHeader {
  data: {
    id: UniqueIdentifier;
    name: string;
    path: string;
    subTitle: string;
    preTitle: string;
    l1TopicId: UniqueIdentifier;
    l1Topics: string[];
    customerValueProposition: string;
    googleAnalyticsId: string;
    headerImage: UploadedImage;
    headerLeft: string;
    headerRight: string;
    advertisingEnabled: boolean;
    networkId: string;
    adUnit: string;
  };
}

export interface IndustryHeaderParams {
  siteId: UniqueIdentifier;
}

export const fetchIndustryHeader = createGenericAsyncThunk<
  IndustryHeader,
  IndustryHeaderParams
>(
  'industryHeader/fetchIndustryHeader',
  async ({ siteId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `/caas/content/api/v1/l1hubs/${siteId}/header`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: IndustryHeader = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const industryHeaderSlice = createGenericSlice({
  name: 'industryHeader',
  initialState: {
    status: 'idle',
    data: {},
  } as GenericState<IndustryHeader>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<IndustryHeader, IndustryHeaderParams>(
      builder,
      fetchIndustryHeader,
    );
  },
});

export const selectIndustryHeader = createSelector(
  [(state) => state.brandHub.industryHeader],
  (data) => data.data.data,
);

export const { start, fulfilled, rejected } = industryHeaderSlice.actions;

export default industryHeaderSlice.reducer;
