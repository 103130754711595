import {
  createGenericAsyncThunk,
  createGenericBuilderCases,
  createGenericSlice,
} from '../../../../../store/features/generic';
import { selectPageConfig } from '../../../../../store/features/pageConfig';
import {
  FetchSitePricingCheckVIPCodeParams,
  UpdateSitePricingVIPCodeData,
  UpdateSitePricingVIPCodeParams,
} from './types';
import { GenericState } from '../../../../../types';

export const updateSitePricingVIPCode = createGenericAsyncThunk<
  UpdateSitePricingVIPCodeData,
  UpdateSitePricingVIPCodeParams
>(
  'sitePricingVIPCode/updateSitePricingVIPCode',
  async (params, { extra, rejectWithValue, getState }) => {
    const { basketId, code } = params;
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.put(
      `/caas/commerce/v1/site/${siteId}/purchase/${basketId}/promotion/${code}`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: UpdateSitePricingVIPCodeData = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

export const fetchSitePricingCheckVIPCode = createGenericAsyncThunk<
  UpdateSitePricingVIPCodeData,
  FetchSitePricingCheckVIPCodeParams
>(
  'sitePricingVIPCode/fetchSitePricingCheckVIPCode',
  async (params, { extra, rejectWithValue, getState }) => {
    const { code } = params;
    const { siteId } = selectPageConfig(getState());

    const response = await extra.ajax.fetch(
      `/caas/commerce/v1/site/${siteId}/promotion/${code}`,
      {
        headers: {
          'Accept-Language': extra.headers['Accept-Language'],
          'Published-State': extra.headers['Published-State'],
        },
      },
    );

    const data: UpdateSitePricingVIPCodeData = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  },
);

const sitePricingVIPCodeSlice = createGenericSlice({
  name: 'sitePricingVIPCode',
  initialState: {
    status: 'idle',
    data: {},
  } as GenericState<UpdateSitePricingVIPCodeData>,
  reducers: {},
  extraReducers: (builder) => {
    createGenericBuilderCases<
      UpdateSitePricingVIPCodeData,
      UpdateSitePricingVIPCodeParams
    >(builder, updateSitePricingVIPCode);

    createGenericBuilderCases<
      UpdateSitePricingVIPCodeData,
      FetchSitePricingCheckVIPCodeParams
    >(builder, fetchSitePricingCheckVIPCode);
  },
});

export const { start, fulfilled, rejected } = sitePricingVIPCodeSlice.actions;

export default sitePricingVIPCodeSlice.reducer;
