import { HTMLtoJSX, removeTagsFromString } from '../../../../../../../helpers';
import { useRootSelector } from '../../../../../../../store/hooks';
import { useGetImgixImageUrl } from '../../../../../../../hooks';
import { selectIndustryHeader } from '../../../../store/features/industryHeader';
import styles from './IndustryHomeHero.module.scss';

export default function IndustryHomeHero() {
  const { headerRight: quote, headerImage } =
    useRootSelector(selectIndustryHeader);
  const imageURL = useGetImgixImageUrl(headerImage?.path);
  const allowedTags = ['p', 'br', 'strong', 'em', 'u', 's', 'a'];

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: `url(${imageURL})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            {quote && (
              <div className={styles.quote}>
                {HTMLtoJSX(removeTagsFromString(quote, allowedTags))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
