import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import i18next from 'i18next';

import { withBrand } from '../../../providers/BrandProvider';
import Patterns from '../../../utils/pattern/patterns';

import LeadGenInput from './LeadGenInput/LeadGenInput';
import LeadGenCheckboxAdHoc from './LeadGenCheckboxAdHoc/LeadGenCheckboxAdHoc';
import LeadGenCheckboxInterest from './LeadGenCheckboxInterest/LeadGenCheckboxInterest';
import LeadGenSelect from './LeadGenSelect/LeadGenSelect';
import RadioGroup from '../../../informakit/components/Forms/RadioGroup';
import LeadGenCheckbox from './LeadGenCheckbox/LeadGenCheckbox';

export class LeadGenFormConstructor extends Component {
  static propTypes = {
    formData: PropTypes.array.isRequired,
    sectionType: PropTypes.string.isRequired,
    brand: PropTypes.object,
    audienceName: PropTypes.string,
    stateOptions: PropTypes.array,
    showCountryStates: PropTypes.bool,
  };

  replaceSymbols(string, regexp, newSubStr) {
    return string.replace(regexp, newSubStr);
  }

  addValidationFunctions(isRequired, inputType, inputName) {
    const validationList = [];
    if (isRequired) {
      if (inputType === 'CHECKBOX_GROUP') {
        validationList.push(atLeastOneRequired);
      } else {
        validationList.push(required);
      }
    }

    if (inputName === 'telephone') {
      validationList.push(telephone);
    }

    if (inputType === 'TEXT') {
      validationList.push(validChar);
    }

    return validationList;
  }

  render() {
    const {
      formData = [],
      sectionType = '',
      brand: { secondaryColor } = {},
      audienceName,
      stateOptions = [],
      showCountryStates,
      isUpdate = false,
    } = this.props;

    return (
      <>
        {formData.map((formInput) => {
          let comp = null;
          switch (formInput.type) {
            case 'TEXT': {
              // we need this class for changing order in LeadGenProfile component between inputs
              const telephoneClass =
                formInput.name === 'telephone'
                  ? 'lead-gen-profile__telephone'
                  : '';
              comp = (
                <div
                  key={formInput.name}
                  className={`col-xs-12 col-sm-${formInput.limit} ${telephoneClass}`}
                >
                  <Field
                    name={formInput.name}
                    label={formInput.label}
                    component={LeadGenInput}
                    isRequired={formInput.required}
                    inputType={formInput.type}
                    validate={this.addValidationFunctions(
                      formInput.required,
                      'TEXT',
                      formInput.name,
                    )}
                    isUpdate={isUpdate}
                    data-test-lead-gen="lead-gen-text-field"
                  />
                </div>
              );
              break;
            }

            case 'CHECKBOX_GROUP': {
              const name = this.replaceSymbols(formInput.name, /[[]]/gi, '');
              comp = (
                <div
                  key={name}
                  className={`col-xs-12 col-sm-${formInput.limit}`}
                >
                  <Field
                    name={name}
                    options={formInput.options}
                    label={formInput.label}
                    isCheckboxGroup
                    component={
                      sectionType === 'profile'
                        ? LeadGenCheckboxInterest
                        : LeadGenCheckboxAdHoc
                    }
                    color={secondaryColor}
                    isRequired={formInput.required}
                    validate={this.addValidationFunctions(
                      formInput.required,
                      'CHECKBOX_GROUP',
                      name,
                    )}
                    data-test-lead-gen="lead-gen-checkbox-group-field"
                  />
                </div>
              );
              break;
            }

            case 'CHECKBOX':
              // it's only for particular sponsoring checkbox
              comp =
                formInput.name === 'contentInterest.sponsoring' ? (
                  <div
                    key={formInput.name}
                    className={`col-xs-12 col-sm-${formInput.limit}`}
                  >
                    <div
                      key={formInput.label}
                      className="lead-gen-profile__sponsoring"
                    >
                      <Field
                        label={this.replaceSymbols(
                          formInput.label,
                          /{COMMUNITY_NAME}/gi,
                          audienceName,
                        )}
                        type="checkbox"
                        name={formInput.name}
                        isCheckboxGroup={false}
                        component={LeadGenCheckbox}
                        color={secondaryColor}
                        data-test-lead-gen="lead-gen-checkbox-field"
                      />
                    </div>
                  </div>
                ) : null;
              break;

            case 'SELECT':
              comp =
                formInput.name === 'state' ||
                formInput.name === 'profile.state' ? (
                  showCountryStates && stateOptions.length ? (
                    <div
                      key={formInput.name}
                      className={`col-xs-12 col-sm-${formInput.limit}`}
                    >
                      <Field
                        name={formInput.name}
                        options={stateOptions[0].options}
                        label={formInput.label}
                        component={LeadGenSelect}
                        isRequired={formInput.required}
                        validate={this.addValidationFunctions(
                          formInput.required,
                        )}
                        isUpdate={isUpdate}
                        data-test-lead-gen="lead-gen-checkbox-select-state"
                      />
                    </div>
                  ) : null
                ) : (
                  <div
                    key={formInput.name}
                    className={`col-xs-12 col-sm-${formInput.limit}`}
                  >
                    <Field
                      name={formInput.name}
                      options={formInput.options}
                      label={formInput.label}
                      component={LeadGenSelect}
                      isRequired={formInput.required}
                      validate={this.addValidationFunctions(formInput.required)}
                      data-test-lead-gen="lead-gen-checkbox-select"
                    />
                  </div>
                );
              break;

            case 'RADIO':
              comp = (
                <div
                  key={formInput.name}
                  className={`col-xs-12 col-sm-${formInput.limit}`}
                >
                  <Field
                    name={formInput.name}
                    options={formInput.options}
                    label={formInput.label}
                    type={formInput.type}
                    component={RadioGroup}
                    isRequired={formInput.required}
                    color={secondaryColor}
                    validate={this.addValidationFunctions(formInput.required)}
                    data-test-lead-gen="lead-gen-radio"
                    prefix="lead-gen-radio-btn"
                  />
                </div>
              );
              break;

            default:
              break;
          }

          return comp;
        })}
      </>
    );
  }
}

function required(value) {
  return value
    ? undefined
    : i18next.t('org.hibernate.validator.constraints.NotEmpty.message');
}

function atLeastOneRequired(values) {
  return values && values.length > 0
    ? undefined
    : i18next.t('informa.content.validation.atLeastOneOption');
}

function telephone(value) {
  return Patterns.isTelephoneNumber(value)
    ? undefined
    : i18next.t('informa.content.validation.Phone.message');
}

function validChar(value) {
  return Patterns.isValidChar(value) ? undefined : 'Invalid characters';
}

export default withBrand(LeadGenFormConstructor);
