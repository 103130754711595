import React from 'react';
import PropTypes from 'prop-types';

export default function MultiLevelNavBtnContent({
  navBarItemConfig,
  oneLineNavBarTitles,
  t,
}) {
  if (oneLineNavBarTitles || navBarItemConfig.group) {
    return (
      <span className="c-multi-level-nav-btn__one-line">
        <span>
          {navBarItemConfig.custom || navBarItemConfig.group
            ? navBarItemConfig.label?.replace(/▼/, '')
            : t(navBarItemConfig.translationKey)}
        </span>
        {navBarItemConfig.group && (
          <span className="material-icons c-multi-level-nav-btn__one-line-arrow">
            expand_more
          </span>
        )}
      </span>
    );
  }

  return (
    <>
      <span className="c-multi-level-nav-btn__upper">
        {navBarItemConfig.custom
          ? navBarItemConfig.upperLabel
          : t(navBarItemConfig.upperTranslationKey)}
        &nbsp;
      </span>
      <span className="c-multi-level-nav-btn__lower">
        {navBarItemConfig.custom
          ? navBarItemConfig.lowerLabel
          : t(navBarItemConfig.lowerTranslationKey)}
      </span>
    </>
  );
}

MultiLevelNavBtnContent.propTypes = {
  oneLineNavBarTitles: PropTypes.bool,
  navBarItemConfig: PropTypes.shape({
    custom: PropTypes.bool,
    group: PropTypes.bool,
    label: PropTypes.string,
    upperLabel: PropTypes.string,
    lowerLabel: PropTypes.string,
    translationKey: PropTypes.string,
    upperTranslationKey: PropTypes.string,
    lowerTranslationKey: PropTypes.string,
  }),
};
