import { useEffect } from 'react';
import gtm from '../../../../../utils/initialGTM';
import { AudienceHubOptions } from '../../../audienceHub/store/features/options';
import { BrandHubOptions } from '../../../brandHub/store/features/options';

export function useSetOptionsToDataLayer(
  options: AudienceHubOptions | BrandHubOptions,
) {
  useEffect(() => {
    gtm.pushHubOptionsData(options);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
}
