import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ChevronIcon.scss';

export default function ChevronIcon(props) {
  const { direction = 'bottom', className = '' } = props;

  const componentClassNames = classNames({
    'c-chevron-icon': true,
    [`c-chevron-icon--${direction}`]: direction,
    [className]: className,
  });

  return (
    <span className={componentClassNames}>
      {[1, 2].map((line, index) => (
        <span key={index} className="c-chevron-icon__line" />
      ))}
    </span>
  );
}

ChevronIcon.propTypes = {
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};
