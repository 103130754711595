import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import clientConfig from './clientConfig';

const i18nStore = window.__I18N__ || {};
const documentLanguage = document.documentElement.getAttribute('lang');

const i18nextConfig = {
  ...clientConfig.i18n,
  lng: documentLanguage,
  backend: {
    loadPath: '/locales/{{lng}}/translations.{{ns}}.json',
  },
  resources: {
    [documentLanguage]: {
      [clientConfig.i18n.defaultNS]: i18nStore,
    },
  },
  partialBundledLanguages: true,
};

i18next.use(Backend).use(LanguageDetector).init(i18nextConfig).then();

export default i18next;
