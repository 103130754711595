import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { renderStyledElementStyles, devices } from '../../../../../helpers';
import { getUTCTime } from '../../../../../../../helpers/getUTCTime';

const Wrapper = styled.div`
  user-select: none;
  display: none;
  @media (min-width: ${devices.breakpoints.tablet}) {
    display: block;
  }
`;

const Title = styled.h4`
  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.siteLayout.siteHeader.builder?.elements?.timer?.elements
        ?.countdownTimer?.title,
    );
  }}
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;

  li {
    position: relative;

    &:not(:${({ theme }) =>
      theme.globalVariables.isRTL ? 'last-child' : 'first-child'}) {
      margin-left: 11px;

      &::before {
        display: block;
        position: absolute;
        left: -6px;
        width: 1px;
        height: 100%;
        content: '';
        ${({ theme }) => {
          return renderStyledElementStyles(
            theme,
            theme.siteLayout.siteHeader.builder?.elements?.timer?.elements
              ?.countdownTimer?.timeDelimiter,
          );
        }}
      }
    }
  }
`;

const Time = styled.div`
  text-align: center;
  letter-spacing: 0.2px;
  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.siteLayout.siteHeader.builder?.elements?.timer?.elements
        ?.countdownTimer?.time,
    );
  }}
`;

const TimeLabel = styled.div`
  margin-top: 5px;
  text-align: center;
  text-transform: uppercase;
  ${({ theme }) => {
    return renderStyledElementStyles(
      theme,
      theme.siteLayout.siteHeader.builder?.elements?.timer?.elements
        ?.countdownTimer?.timeLabel,
    );
  }}
`;

const timerBoxList = [
  {
    time: 'days',
    label: 'feature.countdown-timer.days',
  },
  {
    time: 'hours',
    label: 'feature.countdown-timer.hours',
  },
  {
    time: 'minutes',
    label: 'feature.countdown-timer.minutes',
  },
  {
    time: 'seconds',
    label: 'feature.countdown-timer.seconds',
  },
];

export class CountdownTimer extends Component {
  constructor(props) {
    super(props);

    const { date, time = '00:00' } = props;
    const countDownDate = getUTCTime(date, time);
    const distance = this.getTimeDistance(countDownDate);

    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      countDownDate,
      expired: distance < 0,
    };

    this.timerInterval = null;
    this.updateTimer = this.updateTimer.bind(this);
  }

  componentDidMount() {
    if (!this.state.expired) {
      this.setTimer();
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  setTimer() {
    this.timerInterval = setInterval(this.updateTimer, 1000);
  }

  clearTimer() {
    clearInterval(this.timerInterval);
  }

  getTimeDistance(countDownDate) {
    const now = new Date().getTime();
    return countDownDate - now;
  }

  getTimeDistanceDigits(distance) {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  updateTimer() {
    const distance = this.getTimeDistance(this.state.countDownDate);

    if (distance < 0) {
      this.clearTimer();

      this.setState(() => ({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        expired: true,
      }));

      return;
    }

    this.setState(() => this.getTimeDistanceDigits(distance));
  }

  render() {
    const { date, title, hideSeconds, mask = '00', t } = this.props;
    const { expired } = this.state;

    return !expired && date ? (
      <Wrapper data-testid="header-countdown-timer">
        {title && <Title>{title}</Title>}
        <List>
          {timerBoxList.map(({ time, label }) => {
            if (time === 'seconds' && hideSeconds) {
              return null;
            }
            const timeValue = this.state[time];
            const timeStr = mask
              ? String(timeValue).padStart(mask.length, '0')
              : timeValue;

            return (
              <li data-testid={`countdown-timer-item-${time}`} key={time}>
                <Time>{timeStr}</Time>
                <TimeLabel>{t(label)}</TimeLabel>
              </li>
            );
          })}
        </List>
      </Wrapper>
    ) : null;
  }
}

CountdownTimer.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.string,
  title: PropTypes.string,
  hideSeconds: PropTypes.bool,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default withTranslation()(CountdownTimer);
