const initialState = {
  data: {},
};

export default function svgsReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADD_SVG':
      return { ...state, data: Object.assign({}, state.data, action.data) };

    case 'REMOVE_SVG':
      return state;

    default:
      return state;
  }
}
