import React, { PropsWithChildren, useEffect } from 'react';
import { PropsWithTestId } from '../../../../types';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import styles from './VideoModal.module.scss';

export interface VideoModalProps {
  open: boolean;
  onClose: () => void;
}

const defaultProps = {
  testId: 'hub-video-modal',
};

export default function VideoModal({
  open,
  onClose,
  children,
  testId,
}: PropsWithTestId<PropsWithChildren<VideoModalProps>>) {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (open) {
      document.addEventListener('keydown', onKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [open, onClose]);

  return (
    <Modal open={open} onClose={onClose} data-testid={testId}>
      <Fade in={open}>
        <Box className={styles.wrapper}>
          <div className={styles.innerWrapper}>
            <div className={styles.header}>
              <IconButton
                className={styles.button}
                onClick={onClose}
                sx={{ color: '#fff' }}
              >
                Close <ClearIcon className={styles.icon} />
              </IconButton>
            </div>
            <div className={styles.container}>{children}</div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

VideoModal.defaultProps = defaultProps;
