export const getUTCTime = (date: string, time?: string) => {
  const newDate = new Date(date);

  if (time) {
    const timeArray = time.split(':');
    newDate.setUTCHours(Number(timeArray[0]));
    newDate.setUTCMinutes(Number(timeArray[1]));
  }

  return newDate.getTime();
};
